import { Box, Typography } from "@mui/material";
import { insuranceStyles } from "./styles.";

type TopDisplayProps = {
  title: string;
  subTitle: string;
  children?: React.ReactNode;
};
export default function TopDisplay({
  title,
  subTitle,
  children,
}: TopDisplayProps) {
  const style = insuranceStyles();
  return (
    <Box className={style.topContainer}>
      <Box className={style.flexColumnStart}>
        <Typography
          variant="h6"
          className={style.text2Xl}
          sx={{
            color: "#5C5F66",
          }}
        >
          {title}
        </Typography>
        <Typography
          className={style.textMdBold}
          variant="body1"
          sx={{
            color: "#718096",
          }}
        >
          {subTitle}
        </Typography>
      </Box>
      <Box>{children}</Box>
    </Box>
  );
}
