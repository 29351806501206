import { DialogContent, DialogTitle, Grid, Paper, Theme, Container,Card,Box,Button,DialogActions,Dialog } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import axios from "axios";
import GenericDialog from "../components/GenericDialog";
import LoadingSpinner from "../components/LoadingSpinner";
import { useAppSelector } from "../../utils/hooks";
import useFetch from "../../utils/useFetch";
import EmptyList from "../components/EmptyList";
import MaterialTable from "@material-table/core";
import { Padding } from "@mui/icons-material";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
    },
    paper: {
      padding: theme.spacing(2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      color: "black !important",
      fontFamily: "ChronicaPro,sans-serif",
      fontSize: "16px",
      fontWeight: 400,
    },
    formCustomTitle: {
      fontWeight: 700,
      color: "#449afe",
      fontSize: "1.3rem",
      fontFamily: "ChronicaPro,sans-serif",
      width: 400,
    },
  })
);

type Props = {
  SurveyID: string;
  onCancel?: any;
  setOpen?:any;
  open?:any;
  
};

const ViewClientResponse:React.FC<Props>=({
    SurveyID,
    onCancel,
    open,
    setOpen,
   
  }: Props)=> {
  const classes = useStyles();
  const { 
    data,
    isLoading,
    isSuccess,
  } = useFetch(`api/v1/checkups/tickets/get-client-feedback?SurveyID=${SurveyID}`, ["ViewFeedBack", `${SurveyID}`]);

  const [columns, setColumns] = useState([
    {
  field: "comment",
  title: "Client Comment.",
  type: "string" as const,
  minWidth: 250,
},
{
  field: "rate",
  title: "Client Rating",
  minWidth: 250,
  type: "string" as const
},
{
  field: "dateAdded",
  title: "Date Recorded",
  minWidth: 250,
  type: "string" as const
},

  ]);

  const handleClose = (): void => {
    setOpen(false);

  }

  return (
    <Dialog open={open} onClose={onCancel} maxWidth="lg" fullWidth>
    <Container maxWidth={'lg'}>
      <Card>
        {isLoading ? <LoadingSpinner /> : null}
        {/* {isFetching ? <RefreshingCard message="Refreshing Clients" /> : null} */}
        {isSuccess ? (
          <MaterialTable
            title="Client Feedback"
            columns={columns}
            data={data}
            actions={[]}
            onRowClick={undefined}
            options={{
              columnsButton: true,
              sorting: true,
              rowStyle: rowData => ({
                backgroundColor: rowData.pharmacyBalance <= 1000 ? '#00000' : rowData.pharmacyBalance < 0 ? '#01579b' : '#00000',
              }),
              headerStyle: {
                backgroundColor: '#01579b',
                color: '#FFF',
              },
            }}
          />
        ) : (
          <EmptyList message="You may want to refine your filters" />
        )}
      </Card>
      <DialogActions>
        <Box sx={{ flexGrow: 1 }} />
        <Button variant="outlined" color="inherit" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Container>
  </Dialog>
  );
}

export default ViewClientResponse;
