import { Divider, Grid, IconButton, InputBase, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { ClearOutlined, Search } from "@mui/icons-material";
import React, { useState } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.common.white,
      padding: "2px 4px",
      marginLeft: 5,
      display: "flex",
      alignItems: "center",
      width: 290,
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: "5px",
      "&:focus-within": {
        border: `1px solid ${theme.palette.grey[600]}`,
      },
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 5,
    },
    divider: {
      height: 28,
      margin: 4,
    },
  })
);

type SearchProps = {
  value: string;
  placeholder?: string;
  id?: string;
  // onChange prop to pass the handler from parent
  onChange: (query: string) => void;
};

const SearchBar: React.FC<SearchProps> = ({
  value,
  placeholder,
  id,
  onChange,
}: SearchProps) => {
  const classes = useStyles();
  const [query, setQuery] = useState<string>(value || "");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setQuery(e.target.value);
    // Call the onChange handler passed from parent
    onChange(e.target.value);
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
  };

  const handleClear = () => {
    setQuery("");
    onChange(""); // Also clear in the parent
  };

  return (
    <Grid component="div" className={classes.root}>
      <InputBase
        onChange={handleChange}
        value={query}
        className={classes.input}
        placeholder={placeholder}
        inputProps={{ "aria-label": "Search below", id }}
      />
      <IconButton
        className={classes.iconButton}
        aria-label="search"
        onClick={handleSubmit}
      >
        <Search />
      </IconButton>
      <Divider className={classes.divider} orientation="vertical" />
      <IconButton
        className={classes.iconButton}
        aria-label="clear"
        onClick={handleClear}
      >
        <ClearOutlined />
      </IconButton>
    </Grid>
  );
};

SearchBar.defaultProps = {
  placeholder: "Type to search",
  id: "search-in-me",
};

export default SearchBar;
