import { Fragment } from "react";
import { Button, ButtonProps, Divider, Paper } from "@mui/material";
import { type DropDownProps } from "./types";

import { styled } from "@mui/material/styles";
interface ActionButtonProps extends ButtonProps {
  $color?: string;
}

const DropDown = ({
  items = [],
  top = "24px",
  left,
  bottom,
  right = "10px",
  width,
}: DropDownProps) => {
  return (
    <Paper
      id="addDrop"
      sx={{
        top: top ?? "unset",
        right: right ?? "unset",
        bottom: bottom ?? "unset",
        left: left ?? "unset",
        background: "#fff",
        minWidth: "151px",
        width: width ?? "auto",
        borderRadius: "8px",
        border: "1px solid #0000330F",
        boxShadow: "0px 12px  32px -16px #0000330F",
        display: "flex",
        flexDirection: "column",
        zIndex: 10,
        overflow: "hidden",
        color: "#646464",
        padding: "0px",
      }}
    >
      {items.filter(Boolean).map((item, index) => (
        <Fragment key={index}>
          <ActionButton
            $color={item.color}
            disabled={item.disabled}
            variant="text"
            onClick={(e) => {
              e.stopPropagation();
              item.onClick();
            }}
          >
            {item.label}
          </ActionButton>
          {index < items.length - 1 && <Divider sx={{ color: "#646464" }} />}
        </Fragment>
      ))}
    </Paper>
  );
};

export default DropDown;

const ActionButton = styled(({ $color, ...rest }: ActionButtonProps) => (
  <Button {...rest} />
))(({ theme, $color }) => ({
  color: $color ? $color : "#646464",
  textTransform: "capitalize",
  display: "flex",
  alignItems: "flex-start !important",

  padding: "8px 12px",
  alignSelf: "stretch",
  background: "none",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 100,
  lineHeight: "20px",
  letterSpacing: "0px",
  justifyItems: "start",
  justifyContent: "start",

  "&:hover": {
    backgroundColor: "#e2e8f040",
  },
  "&:focus": {
    backgroundColor: "#E2E8F0",
  },
}));
