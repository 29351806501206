/* eslint-disable @typescript-eslint/no-unused-vars */
import { createStyles, makeStyles } from "@mui/styles";
import { CreateOutlined } from "@mui/icons-material";
import LoadingSpinner from "../components/LoadingSpinner";
import PageContent from "../components/pageContent";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import {
  DataTableAction,
  DataTableRow,
} from "../../utils/types";
import useFetch from "../../utils/useFetch";
import CreateUser from "./CreatePackage";
import { setSelectedBookings, setSelectedUser, User } from "./subscription-logic/subscriptionSlice";
import moment from "moment";
import { Theme } from "@mui/material";
import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from '@material-table/exporters';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
    },
    name: {
      textTransform: "capitalize",
    },
    formField: {
      marginLeft: '4px',
      marginTop: '30px',
      [theme.breakpoints.down('md')]: {
        width: '20%',
        alignContent: 'center ',
      },
  },
  searchBox: {
    padding: theme.spacing(2, 0),
    width: "99%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  colHeader: {
    "&:hover": {
      color: "red"
    }
  }

}));



type Props = {
  roleName?: string;
  roleId?: string;
  userTypeCode?: string;
};
const SubscriptionsList: React.FC<Props> = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [query, setQuery] = useState("");
  const [user, setUser] = useState<User>();
  const [editing, setEditing] = useState(false);
  const [showAddUserModal, setShowAddToRoleModal] = useState(false);
  const [serviceType, setServiceType] = useState<any>();
  const [selectedRow, setSelectedRow] = useState(null);

  const [value, setValue] = React.useState<string>('');
  const [inputValue, setInputValue] = React.useState('');
  const { selectedUser } = useAppSelector((state) => state.login);

      var AgentNumber=(sessionStorage.getItem('agentCode'));
      const {
        data,
        isLoading,
        isSuccess
      } = useFetch(`api/v1/checkups/patient/agent-clients?agentnumber=${AgentNumber}`, ["user", ]);

    const[updatedData, setUpdatedData] = useState([]);
    let optionsData;
    (value?.length <=0 || value ==null)? 
    optionsData=data?.data || data || [] :
    optionsData=data.filter((data:any) => data.typeOfService.includes(value));


  const singleUser = useFetch(
    `/auth/get-single-user/?usercode=${user?.memberNumber}`,
    ["user", `${user?.memberNumber}`],
    { enabled: !!user?.memberNumber }
  );
   useEffect(() => {
     dispatch(setSelectedUser(user));
   }, [dispatch, user]);

  const [allData,setAllData] = useState(data);
  const [filteredData,setFilteredData] = useState(allData);

  const handleEditRow = (row: any) => {
    setUser(row);
    setEditing(true);
    setOpen(true);
  };


  const [columns, setColumns] = useState([
    { title: <div className={classes.colHeader}>Member No.</div>, 
    field: "memberNumber", 
    type: "string" as const
   },
    {
      title:  <div className={classes.colHeader}>Client Name</div>,
      field: "fullName",
      initialEditValue: "initial edit value",
      type: "string" as const,
      //render:(rowData:any)=>  <TableLink path={`/subscriptiondetails/${rowData.memberNumber}`} >{rowData.fullName}</TableLink>,
    },
    { title:<div className={classes.colHeader}>Package Name</div>, 
    field: "packageDescription", type: "string" as const },
    {
      title:<div className={classes.colHeader}>Agent Name</div>,
      field: "agentName",
      type: "string" as const
    },
    {
      title:<div className={classes.colHeader}>Phone Number</div>,
      field: "phoneNumber",
      type: "string" as const
    },
    {
      title:<div className={classes.colHeader}>Email</div>,
      field: "email",
      type: "string" as const
    },
    {
      title:<div className={classes.colHeader}>Payment Method</div>,
      field: "paidVia",
      type: "string" as const
    },
    {
      title:<div className={classes.colHeader}>Payment Status</div>,
      field: "paymentStatus",
      type: "string" as const
    },
    {
      title:<div className={classes.colHeader}>Date Created</div>,
      field: "created",
      type: "string" as const,
      render:  (value: any)=>moment(value.created)?.format("DD-MM-YYYY")
      
    },
    {
      title:<div className={classes.colHeader}>Expiry Date</div>,
      field: "expiryDate",
      type: "string" as const,
      render: (value: any)=> moment(value.expiryDate)?.format("DD-MM-YYYY")
    },
    {
      title:<div className={classes.colHeader}>Amount</div>,
      type: "string" as const
    },
    {
      title:<div className={classes.colHeader}>Card Delivery Location</div>,
      field: "deliveryLocation",
      type: "string" as const
    }
    
  ]);



const [searched, setSearched] = useState<string>("");

useEffect(()=>{
  (value?.length <=0 || value ==null)? 
    setUpdatedData(data?.data || data || [])
    : setUpdatedData(data.filter((data:any) => data.typeOfService.includes(value)))

},[data, value])

const[permission,setPermission]=useState<any[]>(
  [sessionStorage.getItem('role')]
)
//useEffect(()=>{window.location.reload()},[])
//const permission: string[]= sessionStorage.getItem('role');
  return (
    <PageContent>
      <div className={classes.root}>
        {/* <PageHeading
          title="Clients Accounts"
          links={links}
          setupPathname="/setup/users"
        /> */}

        {isLoading ? <LoadingSpinner /> : <></>}

      {isSuccess ? (
        <>

          <MaterialTable
            title="Subscription List"
            columns={columns}
            data={data}
            actions={
              ["SUPERADMIN","CS"].includes(permission[0]?.toUpperCase())? [
              {
                icon: () =><CreateOutlined />,
                onClick: (event: any,row: any) => {
                  handleEditRow(row);
                },
              }
            ]: 
            [] }
            onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
            options={  ["SUPERADMIN","ADMIN"].includes(permission[0]?.toUpperCase())? {
              columnsButton:true,
              exportMenu: [{
                label: 'Export PDF',
                exportFunc: (cols, datas) => ExportPdf(cols, datas, 'SubscriptionReport')
              }, {
                label: 'Export CSV',
                exportFunc: (cols, datas) => ExportCsv(cols, datas, 'SubscriptionReport')
              }],
              exportAllData: true,
              sorting: true,
              rowStyle: rowData => ({
                backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
              }),
              headerStyle: {
                backgroundColor: '#01579b',
                color: '#FFF',
              },
            }:{
              columnsButton:true,
              sorting: true,
              rowStyle: rowData => ({
                backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
              }),
              headerStyle: {
                backgroundColor: '#01579b',
                color: '#FFF',
              },}}

             />

        </>
      ) : ('<></>')}
      </div>
      <CreateUser
        queryString="clients"
        open={open}
        setOpen={setOpen}
        editing={editing}
        setEditing={setEditing}
        setEditLoading={singleUser.isLoading}
        setEditError={singleUser.isError}
      />
    </PageContent>
  );
};

SubscriptionsList.defaultProps = {
  roleName: undefined,
  roleId: undefined,
  userTypeCode: undefined,
};

export default SubscriptionsList;
