import * as React from "react";
import Box from "@mui/material/Box";

import CloseIcon from "@mui/icons-material/Close";

import { styled } from "@mui/material/styles";
import DialogActions from "@mui/material/DialogActions";

// import useMediaQuery from "@mui/material/useMediaQuery";

import StyledButton from "./button";
// import { SelectOption } from "./types";

import { ModalContext } from "../modal-context";

import {
  useEditAndCreateScheme,
  useEditInsurance,
} from "../../../utils/useInsuranceQuery";

import { InsuranceContext } from "../insurance-context";
import SchemeAddComponent, { SchemeType } from "./scheme-add";

type DialogButtonProps = {
  onClick: () => void;
};

type GlobalDrawerProps = {
  confirm?: boolean; // if you want to use the confirm modal
  handleClose?: () => void; // a function that can be hooked to the close modal
  handleConfirm?: () => void; // a function that can be hooked to the close modal
  confirmText?: string; // text to show the user in the confirm modal
  confirmButton?: string | React.ComponentType<DialogButtonProps>; // can be a reactNode that uses the isLoading state
};

type ConfirmProp = { firstTime: boolean } | boolean;

export default React.memo(function AddSchemeModal({
  confirm,

  handleClose,
  handleConfirm,
}: GlobalDrawerProps) {
  // context
  const { toggleDrawer } = React.useContext(InsuranceContext);
  const { setModalOpen, data } = React.useContext(ModalContext);
  // react-query hooks

  const { mutateAsync: editInsuranceScheme } = useEditAndCreateScheme();

  const [schemeListModels, setSchemeListModels] = React.useState<
    SchemeType[] | null
  >(null);

  const handleUpdateInsurance = async () => {
    try {
      if (!schemeListModels) return;
      setModalOpen({ loading: true });
      await editInsuranceScheme({
        insuranceAccountId: data?.paymentAccountID ?? "",
        insuranceAccountDesc: data?.description ?? "",
        schemeListModels,
      });
      setModalOpen({ loading: false });
      toggleDrawer();
    } catch (error) {
      console.log(error, "ERROR");
      setModalOpen({ loading: false });
    }
  };

  const [confirmModalOpen, setConfrimModalOpen] = React.useState<ConfirmProp>({
    firstTime: true,
  });

  let timer: undefined | number;

  function closeModal() {
    // check if it's the first time use of the confirmModalOpen state where confirm is true
    if (confirm && typeof confirmModalOpen === "object") {
      setConfrimModalOpen(true);
      return;
    }
    // check if confirm modal was opened, where confirm is true
    if (confirm && confirmModalOpen) {
      if (handleConfirm && typeof handleConfirm === "function") {
        handleConfirm();
      }
      // if handleClose
      if (handleClose && typeof handleClose === "function") {
        handleClose();
      }
      setConfrimModalOpen(false);
      if (timer) clearTimeout(timer);
      // close global model and reset confirmModalOpen to an object
      timer = setTimeout(handleReset, 500) as unknown as number;
      return;
    }
    if (handleClose && typeof handleClose === "function") {
      handleClose();
    }
    toggleDrawer();
    setModalOpen({ data: null, loading: false });
  }

  function handleCancel() {
    setConfrimModalOpen({
      firstTime: true,
    });
  }

  function handleReset() {
    toggleDrawer();
    setModalOpen({ data: null, loading: false });
    handleCancel();
  }

  React.useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  }, [timer]);

  return (
    <>
      <Container>
        <Box>
          <h6
            style={{
              color: "#2D3748",
              fontSize: 18,
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "28px",
              margin: 0,
            }}
          >
            Add Scheme to Insurance group
          </h6>
          {data?.description && (
            <p
              style={{
                color: "#2D3748",
                fontSize: 14,
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "20px",
                margin: 0,
              }}
            >
              Edit Insurance {data?.description}
            </p>
          )}
        </Box>
        <CloseIcon
          onClick={closeModal}
          sx={{
            alignSelf: "start",
          }}
        />
      </Container>

      <SchemeAddComponent
        items={schemeListModels}
        setItems={setSchemeListModels}
      />
      <DialogActions sx={{ alignSelf: "end" }}>
        <StyledButton
          color="#1A202C"
          bgColor="#EDF2F7"
          text="cancel"
          onClick={closeModal}
        />
        <StyledButton
          text={"Save Changes"}
          bgColor="#4C9DF8"
          onClick={handleUpdateInsurance}
          disabled={!schemeListModels}
        />
      </DialogActions>
    </>
  );
});

const Container = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
}));
