import {  FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Paper, Radio, RadioGroup, Snackbar, TextField, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Alert } from "@mui/lab";
import GenericDialog from "../components/GenericDialog";
import LoadingSpinner from "../components/LoadingSpinner";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ErrorType } from "../../utils/types";
import usePostRequest from "../../utils/usePostRequest";
import { createUserSuccess } from "../agent-accounts/agent-accounts-logic/profileSlice";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
    },

    inputStyle: {
      width: 400,
      marginBottom: theme.spacing(2),
    },
    autoComplete: {
      marginBottom: theme.spacing(1),
    },
    main: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
      zIndex: theme.zIndex.appBar - 1,
      outline: "none",
    },
    grid: {
      display: "grid",
      gridTemplateColumns: "1fr",
    },
    paper: {
      padding: theme.spacing(2),
      // textAlign: "center",
      //color: theme.palette.text.secondary,
      width: 'auto',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      color: 'black !important',
      fontFamily: "ChronicaPro,sans-serif",
      fontSize:"16px",
      fontWeight: 400,
    },
    formCustomTitle: {
      fontWeight:700,
      color: "#449afe",
      fontSize:"1.3rem",
      fontFamily: "ChronicaPro,sans-serif",
      width: 400,
    },
    CustomTitle: {
      fontWeight:700,
      color: "#022c5b",
      fontSize:"1.0rem! important",
      fontFamily: "ChronicaPro,sans-serif",
      width: 400,
    },
    inputLabel: {
      "&.focused": {
        color: "#449afe",
      },
      color: "#449afe",
    },
    errorText: {
      color: 'red',
      fontSize: 'small',
    },
  })
);



type ClearPaymentProps = {
  queryString: string;
  open: boolean;
  // eslint-disable-next-line no-unused-vars
  setOpen: (value: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  setEditing: (value: boolean) => void;
  editing: boolean;
  setEditLoading?: boolean;
  setEditError?: boolean;
};

function ClearPayments({
  queryString,
  open,
  setOpen,
  editing,
  setEditing,
  setEditLoading,
  setEditError,
}: ClearPaymentProps) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const [openSnack, setOpenSnack] = useState(true);
  
  const { selectedUser } = useAppSelector((state) => state.subscription);

  const [PaymentMethod, setPaymentMethod] = useState<string>('')
  const [AmountPaid, setAmountPaid] = useState<string>('')
  const [TransactionID, setTransactionID] = useState<string>("")
  const [dateReceived,setDateReceived] = useState("");
  const [correlationId,setCorrelationId] = useState("");
  const [TransactionIDErr, setTransactionIDErr] = useState<false | ErrorType>(false);
  const { handleSubmit, reset } = useForm();
  const [postError, setPostError] = useState<false | ErrorType>(false);

  var UserID =sessionStorage.getItem('UserID');

  const onSuccess = ({ data }: any) => {
    queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.includes("subscriptions"),
    });
    dispatch(createUserSuccess(data));
    setOpen(false);
    setEditing(false);
    queryClient.invalidateQueries(`${queryString}`);
  };
  const onFailure = (err: ErrorType) => {
    setPostError(err);
  };
  const handleAmountPaid = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAmountPaid(event.target.value);
  };
  const handleTransaction = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTransactionID(event.target.value);
  };
  const handlePaymentMethod = (event: SelectChangeEvent) => {
    setPaymentMethod(event.target.value as string);
  };
  const handleChange = (event:any) => {
    const { name, value } = event.target;
  setDateReceived(value);
  }
  const mutation = usePostRequest(
    "api/v1/checkups/pay/update-payment",
    {
      accountGroup: selectedUser?.accountGroup,
      amountPaid:AmountPaid,
      memberNumber:selectedUser?.memberNumber,
      paymentCode:TransactionID,
      updatedBy:UserID,
      paymentMethod:PaymentMethod,
      dateReceived,
      correlationId:'',
      redeemerName:selectedUser?.FirstName,
      redeemerSurname: selectedUser?.Surname,
      redeemerMobileNumber: selectedUser?.phoneNumber
    },
    onSuccess,
    onFailure
  );
  const onSubmit = () => {
    mutation.reset();
    if (!TransactionID) {
      setTransactionIDErr({ message: "The field is required" });
      return;
    }
    mutation.mutate();
  };

  const handleClose = () => {
    mutation.reset();
    reset();
  };

  const actions = [
    {
      label: 'Pay Now',
      primary: true,
      onClick: handleSubmit(onSubmit),
    },
  ];

  return (
    <div>
      {mutation.isSuccess && (
        <Snackbar
          open={openSnack}
          onClose={() => setOpenSnack(false)}
          autoHideDuration={6000}
        >
          <Alert onClose={handleClose} severity="success">
            {editing
              ? "Payment Completed Successfully"
              : "Payment Completedsuccessfully"}
          </Alert>
        </Snackbar>
      )}
      {editing && setEditError && (
        <Snackbar open={openSnack} onClose={() => setOpenSnack(false)}>
          <Alert onClose={handleClose} severity="error">
            'Something wrong happened, could not edit user'
          </Alert>
        </Snackbar>
      )}

      <GenericDialog
        title='Complete Subscription Payment'
        showDialog={open}
        onCloseDialog={() => {
          setOpen(false);
        }}
        actions={actions}
        isLoading={mutation.isLoading}
        width="sm"
        height={400}
        verticalAlign="flex-start"
      >
       
          {mutation.isLoading || setEditLoading ? <LoadingSpinner /> : <></>}
          <div className={classes.grid}>
            {postError ? (
              <Alert severity="error" className="error-container">
                {postError?.message}
              </Alert>
            ) : (
              <></>
            )}
            
            <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
            <Grid container spacing={3}>       
            
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Payment Method</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={PaymentMethod}
                label="Payment Method"
                onChange={handlePaymentMethod}
              >
                <MenuItem value="Bank">Bank</MenuItem>
                <MenuItem value="Paybill">Paybill</MenuItem>
                <MenuItem value="Vouncher">Vouncher</MenuItem>
                <MenuItem value="Cash">Cash</MenuItem>
                <MenuItem value="Cheque">Cheque</MenuItem>
                <MenuItem value="PesaPal">PesaPal</MenuItem>
                <MenuItem value="Complementary">Complementary</MenuItem>
                <MenuItem value="Staff Benefit">Staff Benefit</MenuItem>
              </Select>
            </FormControl>
           </Grid>

            <Grid item xs={12} sm={12}>
            <TextField
              size="small"
              type="text"
              InputLabelProps={{
                classes: {
                  root: classes.inputLabel,
                  focused: "focused",
                  shrink: "shrink"
                }}}
              name="TransactionID"
              label="Transaction Code"
              color="secondary"
              variant="outlined"
              fullWidth
              required
              defaultValue={TransactionID}
              onChange={handleTransaction}
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              size="small"
              type="number"
              InputLabelProps={{
                classes: {
                  root: classes.inputLabel,
                  focused: "focused",
                  shrink: "shrink"
                }}}
              name="AmountPaid"
              label="Amount Paid"
              color="secondary"
              variant="outlined"
              fullWidth
              required
              defaultValue={AmountPaid}
              onChange={handleAmountPaid}
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
          <TextField
            name="dob"
            label="Payment Received On"
            type="date"
            placeholder="mm/dd/yyyy"
            value={dateReceived}
            
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
              style: { position: 'absolute', backgroundColor: '#FFF', padding: '0 4px' },
            }}
            fullWidth
            required
          />
        </Grid>
          </Grid>
          </Paper>
          </Grid>

          </div>
  
      </GenericDialog>
    </div>
  );
}

ClearPayments.defaultProps = {
  setEditError: false,
  setEditLoading: false,
};
export default ClearPayments;
