/* eslint-disable no-unused-vars */
import { AxiosError } from 'axios';
import React from 'react';
import { useQuery } from 'react-query';
import api from '../api';
import { ErrorType } from '../utils/types';
import { makeErrorMessage } from './useGracefulAPIErrorHandling';

export interface FetchResults {
  isLoading: boolean;
  isRefreshing: boolean;
  isSuccess: boolean;
  isError: boolean;
  error: false | ErrorType;
  data: any;
  refetch:any;
}

/**
 * @description Abstract fetch logic that affects state into a reusable hook
 * @param  {string} path From whence to fetch
 * @param {string | string[]} name  Unique identifier for what's being fetched, for caching purposes
 * @param  {Objec} args extra configs

 * @return {FetchResults} State changes across the fetch cycle
 */
export default function useFetch(
  path: string,
  name: string | string[],
  args?: { [key: string]: any },
): FetchResults {
  //const [intervalMs, setIntervalMs] = React.useState(5000)
  const {
    isLoading,
    isSuccess,
    isError,
    isFetching: isRefreshing,
    data,
    error,
    refetch
  } = useQuery(
    name,
    async () => {
      const res = await api.get(path);
      return res.data;
    },
    {
     //refetchInterval: intervalMs,
      retry: (failureCount, err) => false,
      ...args,
    },
  );

  // useRedirectOnUnauthorised(error, path);
  return {
    isLoading,
    isRefreshing,
    isSuccess,
    isError,
    data,
    refetch,
    error: {
      message: error ? makeErrorMessage(error as AxiosError) : '',
      statusCode: (error as AxiosError)?.response?.status || 0,
    },
  };
}
