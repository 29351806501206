import { toast, Toaster } from 'sonner';
import { FaCheckCircle, FaExclamationCircle, FaInfoCircle } from 'react-icons/fa';

const Toast = () => {
  return <Toaster position="top-right" />;
};

export const showToast = (type: string, message: string) => {
  let options = {};

  switch (type) {
    case 'success':
      options = {
        icon: <FaCheckCircle />,
        style: {
          backgroundColor: 'green',
          color: 'white',
        },
      };
      toast.success(message, options);
      break;
    case 'error':
      options = {
        icon: <FaExclamationCircle />,
        style: {
          backgroundColor: 'red',
          color: 'white',
        },
      };
      toast.error(message, options);
      break;
    case 'info':
      options = {
        icon: <FaInfoCircle />,
        style: {
          backgroundColor: 'green',
          color: 'white',
        },
      };
      toast.info(message, options);
      break;
    default:
      options = {
        icon: <FaInfoCircle />,
        style: {
          backgroundColor: 'gray',
          color: 'white',
        },
      };
      toast(message, options);
  }
};

export default Toast;
