import { Box, Paper, Typography,Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import { TabbedViewTab } from "../../utils/types";

type TabbedViewProps = {
  tabs: TabbedViewTab[];
  direction?: "horizontal" | "vertical";
  activeTab: string;
  padding?: boolean;
  // eslint-disable-next-line no-unused-vars
  onChangeActiveTab: (name: any) => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      position: "relative",
      border: `1px solid ${theme.palette.grey[300]}`,
      display: "grid",
      // gridTemplateColumns: repeat(2, 1fr);
      gridTemplateColumns: "100px 1fr 100px",
      gridTemplateRows: "auto 1fr auto",
      alignItems: "center",
      borderRadius: 0,
    },
    vertical: {
      flexDirection: "column",
    },
    horizontal: {
      flexDirection: "row",
    },
    nav: {
      minWidth: 200,
      width: "auto",
      position: "sticky",
      left: 0,
      top: 0,
      zIndex: theme.zIndex.appBar + 10,
      backgroundColor: theme.palette.common.white,
      minHeight: "75vh",
      height: "auto",
      borderRight: `1px solid ${theme.palette.grey[300]}`,
    },
    title: {
      cursor: "pointer",
      padding: theme.spacing(1.5),
    },
    content: {
      height: "75vh",
      overflowY: "auto",
      padding: theme.spacing(0),
      paddingLeft: theme.spacing(15),
      width: "100%",
    },
    activeTab: {
      backgroundColor: theme.palette.grey[300],
      fontWeight: 900,
    },
    flexContainerVertical: {
      display: "flex",
      alignItems: "center",
    },
  })
);

const TabbedView: React.FC<TabbedViewProps> = ({
  tabs,
  direction,
  activeTab,
  onChangeActiveTab,
  padding,
}: TabbedViewProps) => {
  const classes = useStyles();
  const renderActiveTab = () =>
    tabs.find((t) => t.tabId === activeTab)?.content || <></>;
  const isActive = (tabName: string) => activeTab === tabName;

  return (
    <Paper
      className={classes.root}
      classes={{
        root: direction === "vertical" ? classes.vertical : classes.horizontal,
      }}
    >
      <nav className={classes.nav}>
        {tabs.map((t) => (
          <Typography
            onClick={() =>
              onChangeActiveTab(typeof t.title === "function" ? t : t.tabId)
            }
            key={t.tabId}
            color="textPrimary"
            className={!padding ? "" : classes.title}
            classes={{
              root:
                isActive(t.tabId) && typeof t.title !== "function"
                  ? classes.activeTab
                  : "",
            }}
          >
            {typeof t.title === "function" ? t.title() : t.title}
          </Typography>
        ))}
      </nav>
      <Box className={classes.content}>{renderActiveTab()}</Box>
    </Paper>
  );
};

TabbedView.defaultProps = {
  direction: "vertical",
  padding: true,
};

export default TabbedView;
