import * as React from "react";
import Box from "@mui/material/Box";

import CloseIcon from "@mui/icons-material/Close";

import { styled } from "@mui/material/styles";
import DialogActions from "@mui/material/DialogActions";

// import useMediaQuery from "@mui/material/useMediaQuery";
import StyledInput from "./input";
import StyledButton from "./button";
// import { SelectOption } from "./types";

import { SelectChangeEvent } from "@mui/material";
import { ModalContext } from "../modal-context";

import {
  InsuranceDataType,
  useEditInsurance,
} from "../../../utils/useInsuranceQuery";
import PhoneInput from "./phone-input";

import { SELECTVALUES } from "./constant";
import { InsuranceContext } from "../insurance-context";

type DialogButtonProps = {
  onClick: () => void;
};

type GlobalDrawerProps = {
  confirm?: boolean; // if you want to use the confirm modal
  handleClose?: () => void; // a function that can be hooked to the close modal
  handleConfirm?: () => void; // a function that can be hooked to the close modal
  confirmText?: string; // text to show the user in the confirm modal
  confirmButton?: string | React.ComponentType<DialogButtonProps>; // can be a reactNode that uses the isLoading state
};

type ConfirmProp = { firstTime: boolean } | boolean;

const URLERRORS = ["Image Url is required", "Invalid image url"];
const allowedKeys = [
  "insuranceAccountDesc",
  "phoneNumber",
  "code",
  "insuranceType",
  "imageUrl",
];

const checkhasChanged = (
  initial: Omit<InsuranceDataType, "disabled"> | null,
  current: Omit<InsuranceDataType, "disabled"> | null
) => {
  if (!initial || !current) return false;
  return (
    Object.keys(initial).concat(["category", "description"]) as (keyof Omit<
      InsuranceDataType,
      "disabled"
    >)[]
  ).some((key) => {
    if (current.hasOwnProperty(key)) {
      return initial[key]
        ? initial[key] !== current[key]
        : (initial[key] = "") !== current[key];
    }
    return (initial[key] = "") !== current[key];
  });
};
export default React.memo(function EditInsuranceModal({
  confirm,

  handleClose,
  handleConfirm,
}: GlobalDrawerProps) {
  // context
  const { toggleDrawer } = React.useContext(InsuranceContext);
  const { setModalOpen, data } = React.useContext(ModalContext);
  // react-query hooks

  const { mutateAsync: editInsurance } = useEditInsurance();

  const [editValue, setEditValue] = React.useState<InsuranceDataType | null>(
    data
  );

  const [errored, setErrored] = React.useState<Record<string, boolean>>({});

  // check is amy data has changed and there are no errors
  const hasChanged = data
    ? checkhasChanged(data, editValue) &&
      allowedKeys.every((key) => !errored[key])
    : false;

  // update the field name for groupType selec
  const handleSelect = React.useCallback((e: SelectChangeEvent<string>) => {
    const {
      target: { value },
    } = e;
    setEditValue(
      (prev) =>
        ({ ...(prev ? prev : {}), category: value } as InsuranceDataType)
    );
  }, []);

  // on input change update value record by the field name
  const handleValueChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const {
        target: { name, value },
      } = e;
      setEditValue(
        (prev) =>
          ({ ...(prev ? prev : {}), [name]: value } as InsuranceDataType)
      );
    },
    []
  );

  const handleErrored = React.useCallback((name: string, state: boolean) => {
    setErrored((prev) => ({ ...prev, [name]: state }));
  }, []);

  const handlePhoneChange = React.useCallback((e?: string) => {
    if (typeof e === "string") {
      setEditValue(
        (prev) =>
          ({ ...(prev ? prev : {}), phoneNumber: e } as InsuranceDataType)
      );
    }
  }, []);

  const handleUpdateInsurance = async () => {
    if (data?.paymentAccountID === undefined) return;
    try {
      setModalOpen({ loading: true });
      await editInsurance({
        ...editValue,
        insuranceType: editValue?.category,
        insuranceAccountDesc: editValue?.description,
        paymentAccountID: data?.paymentAccountID ?? "",
      });
      setModalOpen({ loading: false });
      toggleDrawer();
    } catch (error) {
      console.log(error, "ERROR");
      setModalOpen({ loading: false });
    }
  };

  const [confirmModalOpen, setConfrimModalOpen] = React.useState<ConfirmProp>({
    firstTime: true,
  });

  let timer: undefined | number;

  function closeModal() {
    // check if it's the first time use of the confirmModalOpen state where confirm is true
    if (confirm && typeof confirmModalOpen === "object") {
      setConfrimModalOpen(true);
      return;
    }
    // check if confirm modal was opened, where confirm is true
    if (confirm && confirmModalOpen) {
      if (handleConfirm && typeof handleConfirm === "function") {
        handleConfirm();
      }
      // if handleClose
      if (handleClose && typeof handleClose === "function") {
        handleClose();
      }
      setConfrimModalOpen(false);
      if (timer) clearTimeout(timer);
      // close global model and reset confirmModalOpen to an object
      timer = setTimeout(handleReset, 500) as unknown as number;
      return;
    }
    if (handleClose && typeof handleClose === "function") {
      handleClose();
    }
    toggleDrawer();
    setModalOpen({ data: null, loading: false });
  }

  function handleCancel() {
    setConfrimModalOpen({
      firstTime: true,
    });
  }

  function handleReset() {
    toggleDrawer();
    setModalOpen({ data: null, loading: false });
    handleCancel();
  }

  React.useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  }, [timer]);

  return (
    <>
      <Container>
        <Box>
          <h6
            style={{
              color: "#2D3748",
              fontSize: 18,
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "28px",
              margin: 0,
            }}
          >
            Edit Insurance group
          </h6>
          <p
            style={{
              color: "#2D3748",
              fontSize: 14,
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "20px",
              margin: 0,
            }}
          >
            Edit Insurance group of patients
          </p>
        </Box>
        <CloseIcon
          onClick={closeModal}
          sx={{
            alignSelf: "start",
          }}
        />
      </Container>

      <StyledInput
        setError={handleErrored}
        type="input"
        placeholder="Type in name of partner or insurance"
        setValue={handleValueChange}
        value={editValue?.["description"] ?? ""}
        name="description"
        label="Group name"
        id="group-name"
        errorText="Group name is required"
      />

      <StyledInput
        setError={handleErrored}
        type="select"
        defaultValue={editValue?.["category"] ?? ""}
        setValue={handleSelect}
        placeholder="Click to select"
        value={SELECTVALUES}
        label="Select group type"
        id="group-type"
        errorText="Group type is required"
      />

      <PhoneInput
        placeholder="Input phone number"
        value={editValue?.["phoneNumber"] ?? ""}
        name="phoneNumber"
        setValue={handlePhoneChange}
        label="Phone number"
        id="group-phone"
      />

      <StyledInput
        setError={handleErrored}
        type="input"
        placeholder="Enter code"
        value={editValue?.["code"] ?? ""}
        name="code"
        setValue={handleValueChange}
        disabled={data ? true : false}
        label="Insurance code"
        id="group-code"
        errorText="Insurance code is required"
      />
      <StyledInput
        setError={handleErrored}
        type="input"
        placeholder="Enter logo url"
        value={editValue?.["imageUrl"] ?? ""}
        name="imageUrl"
        setValue={handleValueChange}
        label="Logo URL"
        id="group-url"
        errorText={editValue?.["imageUrl"] ? URLERRORS[1] : URLERRORS[0]}
      />

      <DialogActions sx={{ alignSelf: "end" }}>
        <StyledButton
          color="#1A202C"
          bgColor="#EDF2F7"
          text="cancel"
          onClick={closeModal}
        />
        <StyledButton
          text={"Save Changes"}
          bgColor="#4C9DF8"
          onClick={handleUpdateInsurance}
          disabled={!hasChanged}
        />
      </DialogActions>
    </>
  );
});

const Container = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
}));
