import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AutosuggestHighlightParse  from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';

interface GoogleMapsProps {
  location: any;
  setLocation: Function;
  label: string;
  setLatitude: Function;
  setLongitude: Function;
  setCombinedLocations: Function;
}

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };
interface LocationData {
  locationName: string;
  latitude: string;
  longitude: string;
  type: string;
}

interface MainTextMatchedSubstrings {
    offset: number;
    length: number;
  }
  interface StructuredFormatting {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
  }
  interface PlaceType {
    description: string;
    structured_formatting: StructuredFormatting;
  }
  
export default function Location({ location,label, setLocation,setLatitude,setLongitude,setCombinedLocations }: GoogleMapsProps) {
  const GOOGLE_API_KEY = "AIzaSyCgKNwnDgSlZOBG31TNseJqfVFxK1x7tY8";

  const [value, setValue] = React.useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
  const loaded = React.useRef(false);
  const geocoder = React.useRef<google.maps.Geocoder | null>(null);

  useEffect(() => {
    if (typeof window !== 'undefined' && !loaded.current) {
      if (!document.querySelector('#google-maps')) {
        loadScript(
          `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places,geocode`,
          document.querySelector('head'),
          'google-maps',
        );
      }

      loaded.current = true;
    }
  }, []);

  const fetch = React.useMemo(
    () =>
      debounce(
        (
          request: { input: string },
          callback: (results?: readonly PlaceType[]) => void,
        ) => {
          (autocompleteService.current as any).getPlacePredictions(
            request,
            callback,
          );
        },
        400,
      ),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
      geocoder.current = new (window as any).google.maps.Geocoder();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    const locations: { locationName: string | undefined; latitude: number; longitude:number; type: string; }[] = [];
    setOptions(newValue ? [newValue, ...options] : options);
    setLocation(newValue?.description);
    setValue(newValue);
    if (geocoder.current && newValue) {
      geocoder.current.geocode({ placeId: newValue.place_id }, (results, status) => {
        if (status === 'OK' && results && results[0]) {
          const { lat, lng } = results[0].geometry.location;
          setLatitude(lat);
          setLongitude(lng);
            // Create a new location object
        const newLocation = {
          locationName: newValue?.description,
          latitude: lat(),
          longitude: lng(),
          type: "home",
        };
        setCombinedLocations((prevCombinedLocations: any[]) => {
          // Check if a location with the same type already exists in the list
          const existingIndex = prevCombinedLocations.findIndex(
            (location) => location.type === newLocation.type
          );

          // If the location exists, update it
          if (existingIndex !== -1) {
            const updatedLocations = [...prevCombinedLocations];
            updatedLocations[existingIndex] = newLocation;
            return updatedLocations;
          }

          // If the location does not exist, add the new location to the list
          return [...prevCombinedLocations, newLocation];
        });
      }
      });
    }
  };

  return (
    <Autocomplete
      id="google-map-demo"
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      fullWidth
      includeInputInList
      filterSelectedOptions
      value={location}
      noOptionsText="No locations"
      onChange={handleChange}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label={label} fullWidth
        InputLabelProps={{
            shrink: true,
            style: { position: 'absolute', backgroundColor: '#FFF', padding: '0 4px' },
          }}
        />
      )}
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings || [];

          const parts = AutosuggestHighlightParse (
            option.structured_formatting.main_text,
            matches.map((match: any) => [match.offset, match.offset + match.length]),
          );

        return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ display: 'flex', width: 44 }}>
                  <LocationOnIcon sx={{ color: 'text.secondary' }} />
                </Grid>
                <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                  {parts.map((part, index) => (
                    <Box
                      key={index}
                      component="span"
                      sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                    >
                      {part.text}
                    </Box>
                  ))}
                  <Typography variant="body2" color="text.secondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
      }}
    />
  );
}