/* eslint-disable @typescript-eslint/no-unused-vars */
import { createStyles, makeStyles } from "@mui/styles";
import {
  Call,
  CheckCircleOutline,
  CreateOutlined,
  EmailOutlined,
  HighlightOffOutlined,
  LocalOfferTwoTone,
  LockOpenOutlined,
  OpacityOutlined,
  RotateRightOutlined,
} from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BadError from "../components/BadError";
import LoadingSpinner, {
  RefreshingCard,
} from "../components/LoadingSpinner";
import Metadata from "../components/Metadata/Metadata";
import { ItemDetails } from "../components/Metadata/types";
import NotFoundPage from "../components/NotFound";
import PageHeading from "../components/PageHeading";
import Status from "../components/Status/Status";
import TabbedView from "../components/TabbedView";
import React, { ReactChild, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate , useParams } from "react-router-dom";
import { DSA_USER_TYPE_CODE } from "../../utils/constants";
import { is404Error } from "../../utils/helpers";
import { DataTableAction, ErrorType, TabbedViewTab } from "../../utils/types";
import useFetch from "../../utils/useFetch";
import usePostRequest from "../../utils/usePostRequest";
import { setSelectedUser } from "./dashboard-logic/dashboardSlice";
import {Theme} from '@mui/material'
import { RootState } from "../../store/configureStore";
import { useAppSelector } from "../../utils/hooks";
import ClientsPointSections from "./ClientsDetails";
import ClientPaymentDetails from "./paymentDetails";
import PatientJourney from "./PatientJourney";
import MedicalHistory from "./MedicalHistory";

const links = [
  {
    pathname: "",
    label: "",
    permission: "",
  },
];
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    icon: {
      fontSize: 20,
      marginRight: theme.spacing(1),
    },
  })
);

const SingleUser: React.FC = () => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const [remarks, setRemarks] = useState<string>("");
  const [remarksErr, setRemarksErr] = useState<false | ErrorType>(false);
  const [postErr, setPostErr] = useState<false | ErrorType>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [editing, setEditing] = useState(false);
  const [confirmAction, setConfirmAction] = useState<
    false | "activate" | "deactivate" | "reset password"
  >(false);
  const { id }: any = useParams();
  const { selectedBookings } = useAppSelector(
    (state: RootState) => state.clients,
  );
  // const {
  //   data,
  //   isLoading,
  //   isError,
  //   error,
  //   isRefreshing,
  // } = useFetch(`/auth/get-single-user/?usercode=${id}`, ["user", id]);

  const {
    data,
    isLoading,
    isError,
    error,
    isRefreshing,
    isSuccess
  } = useFetch(`api/v1/checkups/patient/Patients`, ["user", ]);


const  optionsData=data?.filter((data:any) => data.id.includes(id));
  const singleUser = useFetch(
    `/auth/get-single-user/?usercode=${data?.userCode}`,
    ["user", `${data?.userCode}`],
    { enabled: !!data?.userCode }
  );
  // useEffect(() => {
  //   dispatch(setSelectedUser(singleUser.data));
  // }, [singleUser]);
  const onSuccess = () => {
    queryClient.invalidateQueries(["user", data?.userCode]);
    setConfirmAction(false);
    setRemarks("");
  };
  const onFailure = (err: ErrorType) => {
    setPostErr(err);
  };

  const mutation = usePostRequest(
    "/auth/reset-user-password",
    { userCode: data?.userCode },
    onSuccess,
    onFailure
  );
  // : usePostRequest(
  //     '/auth/update-user-status',
  //     {
  //       userCode: data?.userCode,
  //       status: confirmAction === 'activate' ? 'Active' : 'Inactive',
  //       remarks,
  //     },
  //     onSuccess,
  //     onFailure,
  //   );
  const history = useNavigate ();
  const handleOpenModal = (
    action: "activate" | "deactivate" | "reset password"
  ) => {
    setConfirmAction(action);
    setPostErr(false);
    setRemarksErr(false);
  };
  const handleEditRow = () => {
    setEditing(true);
    setOpen(true);
  };

  const handleChangeuserStatus = () => {
    if (remarks === "") {
      setRemarksErr({ message: "Reason for this change is required" });
      return;
    }
    setPostErr(false);
    mutation.reset();
    mutation.mutate();
  };

  const handleRemarksChange = (value: string) => {
    setRemarksErr(false);
    setRemarks(value);
  };

  const userActions: DataTableAction[] = [
    {
      label: "Activate user",
      permission: ['ACTIVE'].includes(data?.status?.toUpperCase())
        ? 'ACTION-NOT-ALLOWED'
        : "",
      onClick: () => handleOpenModal("activate"),
      icon: () => <CheckCircleOutline />,
    },
    {
      label: "Deactivate user",
      permission: [undefined, '', 'INACTIVE'].includes(
        data?.status?.toUpperCase(),
      )
        ? 'ACTION-NOT-ALLOWED'
        : "",
      onClick: () => handleOpenModal("deactivate"),
      icon: () => <HighlightOffOutlined />,
    },
    {
      permission: [undefined, '', 'INACTIVE'].includes(
        data?.status?.toUpperCase(),
      )
        ? 'ACTION-NOT-ALLOWED'
        : "",
      label: "Reset password",
      onClick: () => handleOpenModal("reset password"),
      icon: () => <RotateRightOutlined />,
    },
    {
      permission: [undefined, '', 'INACTIVE'].includes(
        data?.status?.toUpperCase(),
      )
        ? 'ACTION-NOT-ALLOWED'
        : "",
      label: "Assign roles",
      onClick: () => history("/roles"),
      icon: () => <LockOpenOutlined />,
    },
    {
      permission: [undefined, '', 'INACTIVE'].includes(
        data?.status?.toUpperCase(),
      )
        ? 'ACTION-NOT-ALLOWED'
        : "",
      label: "Edit user",
      onClick: () => handleEditRow(),
      icon: () => <CreateOutlined />,
    },
  ];

  const userTypeCode = data?.userTypeCode;

  const userTypeFetch = useFetch(
    `/search/usertype/${userTypeCode}`,
    ["userType", id],
    {
      enabled: !!userTypeCode,
    }
  );


  const mainTabs: TabbedViewTab[] = [
    {
    tabId: 'Client Details',
      title: 'Client Details',
      content: <ClientsPointSections data={optionsData} />
    },
    {
      tabId: 'Payment Details',
        title: 'Payment Details',
        content: <ClientPaymentDetails data={optionsData} />
      },
      {
        tabId: 'Patient Journey',
        title: 'Patient Journey',
        content: <PatientJourney cycleID={id}/>
        },
    {
    tabId: 'Medical History',
    title: 'Medical History',
    content: <MedicalHistory data={optionsData} />
    },
  ];
  const [activeTab, setActiveTab] = useState(
    mainTabs[0].tabId
  );
  const handleChangeActiveTab = (name: string) => {
    setActiveTab(name);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError && is404Error(error)) {
    return <NotFoundPage message={`User with userId ${id} not found`} />;
  }

  if (isError) {
    return <BadError error={error} />;
  }

  const userType = userTypeFetch?.data;
  /**
   * Entity status manipulation logic
   */

  const modalActions: {
    label: string | ReactChild;
    onClick: () => void;
    primary: boolean;
  }[] = [
    {
      label: `${confirmAction}`,
      onClick: () => {
        handleChangeuserStatus();
      },
      primary: true,
    },
  ];

  /*
   * metadata needed to display profile header content
   */
  if (!data?.status) {
    data.status = "Active";
  }
  const metaData: ItemDetails = {
    imageUrl: data?.imageUrl,
    hasImageUpload: true,
    name: optionsData[0]?.fullName,
    statusContainer: data && (
      <Status
        status={data?.status}
        editable
        canActOnEntityStatus={false}
        confirmAction={confirmAction}
        setConfirmAction={setConfirmAction}
        remarksErr={remarksErr}
        remarks={remarks}
        type="User"
        handleRemarksChange={handleRemarksChange}
        modalActions={modalActions}
        mutation={mutation}
        postErr={postErr}
        open={open}
        setOpen={setOpen}
        editing={editing}
        setEditing={setEditing}
        entityActions={userActions}
        singleUser={singleUser}
      />
    ),
    uploadPath: `auth/upload-profile-picture?usercode=${data?.userCode}`,
    icon: AccountCircleIcon,
    queryNameToRefresh: ["user", data?.userCode],
    boxContent: [
      {
        title: optionsData[0]?.staff_id,
        icon: <LocalOfferTwoTone className={classes.icon} />,
      },
      {
        title: optionsData[0]?.email,
        icon: <EmailOutlined className={classes.icon} />,
      },
      {
        title: optionsData[0]?.phoneNumber,
        icon: <Call className={classes.icon} />,
      },
    ],
  };

  return (
    <div className={classes.root}>
      <PageHeading title="User Profile" links={links} />
      {isRefreshing ? <RefreshingCard message="Refreshing user" /> : <></>}
      <Metadata metadata={metaData} />
      <TabbedView
        tabs={mainTabs}
        activeTab={activeTab}
        onChangeActiveTab={handleChangeActiveTab}
      />
    </div>
  );
};

export default SingleUser;
