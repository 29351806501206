import React, { createContext, useContext, useEffect, useState } from "react";
import {
  HubConnectionBuilder,
  HubConnection,
  LogLevel,
  HttpTransportType,
} from "@microsoft/signalr";

interface SignalRContextProps {
  connection: HubConnection | null;
  messages: Array<{
    receiverName: string;
    receiverPhoneNumber: string;
    content: string;
  }>;
}

const SignalRContext = createContext<SignalRContextProps | undefined>(
  undefined
);

export const SignalRProvider: React.FC = ({ children }) => {
  const [connection, setConnection] = useState<HubConnection | null>(null);
  const [messages, setMessages] = useState<any[]>([]);

  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_BASE_API_URL3}chathub`, {
        transport: HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Debug)
      .build();
  
    const startConnection = async () => {
      try {
        await newConnection.start();
        console.log("Connected to SignalR hub");
  
        newConnection.on("ReceiveMessage", (receiverName, receiverPhoneNumber, content) => {
          setMessages((prevMessages) => [
            ...prevMessages,
            { receiverName, receiverPhoneNumber, content },
          ]);
        });
  
        setConnection(newConnection);
      } catch (error) {
        console.error("SignalR Connection Error: ", error);
      }
    };
  
    startConnection();
  
    return () => {
      if (newConnection) {
        newConnection.stop().catch((error) =>
          console.error("Error stopping connection: ", error)
        );
      }
    };
  }, []);
  

  return (
    <SignalRContext.Provider value={{ connection, messages }}>
      {children}
    </SignalRContext.Provider>
  );
};

export const useSignalR = () => {
  const context = useContext(SignalRContext);
  if (context === undefined) {
    throw new Error("useSignalR must be used within a SignalRProvider");
  }
  return context;
};
