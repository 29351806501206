/* eslint-disable @typescript-eslint/no-unused-vars */
import { createStyles, makeStyles } from "@mui/styles";
import LoadingSpinner from "../components/LoadingSpinner";
import PageContent from "../components/pageContent";
import Table from "../components/Table";
import React, { useEffect, useState } from "react";
import {  useAppDispatch, useAppSelector } from "../../utils/hooks";
import {
  DataTableColumn,
  DataTableRow,
} from "../../utils/types";
import useFetch from "../../utils/useFetch";
import { Box, Button, Card, CircularProgress, Container, Dialog, DialogActions, DialogTitle, Fade, Grid, Link, Menu, MenuItem, TextField, Theme } from "@mui/material";
import EmptyList from "../components/EmptyList";
import MaterialTable from "@material-table/core";
import MoreVertIcon  from '@mui/icons-material/MoreVert';
import { toDate } from "date-fns/esm";

const useStyles = makeStyles((theme: Theme) =>
createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
    },
    name: {
      textTransform: "capitalize",
    },
    formField: {
      marginLeft: '4px',
      marginTop: '30px',
      [theme.breakpoints.down('md')]: {
        width: '20%',
        alignContent: 'center ',
      },
  },
  searchBox: {
    padding: theme.spacing(2, 0),
    width: "99%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  menuItemNoIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuItemWithIcon: {
    display: 'grid',
    gridTemplateColumns: '50px 1fr',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    fontWeight: 800,
    fontSize: '0.95rem',
  },
  menuItem: {
    minWidth: 200,
    margin: theme.spacing(1, 2),
    backgroundColor: theme.palette.grey[100],
    borderRadius: '3px',
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.grey[700],
    },
  },
  button: {
    backgroundColor: 'blue',
    color: 'white',
    width: '50%',
    fontSize: '30px',
  },

}));
// ----------------------------------------------------------------------
type Props = {
    InvoiceNumber?: string;
    setOpenModalForm?: any;
    onCancel?: any;
  };
  const InvoiceItems: React.FC<Props> = ({
    InvoiceNumber,
    setOpenModalForm,
    onCancel,
  }: Props) => {
    const classes = useStyles();
    const [selectedRow, setSelectedRow] = useState(null);
    const {
        data,
        isLoading,
        isError,
        error,
        isRefreshing,
        isSuccess
      } = useFetch(`get-invoice-items?InvoiceNumber=${InvoiceNumber}`, ["InvoiceItems", `${InvoiceNumber}`]);
// &FromDate=${}&ToDate=${}

    const [columns, setColumns] = useState([
        {
      field: "description",
      title: "Description.",
      type: "string" as const,
      minWidth: 250,
    },
    {
      field: "type",
      title: "Visit Type",
      minWidth: 250,
      type: "string" as const
    },  
    {
      field: "departmentID",
      title: "Department Name",
      minWidth: 250,
      type: "string" as const
    },  
    {
      field: "amount",
      title: "Amount",
      minWidth: 250,
      type: "string" as const
    },
    {
      field: "sellingPrice",
      title: "Selling Price",
      minWidth: 250,
      type: "string" as const
    },
    {
      field: "savings",
      title: "Saving",
      minWidth: 250,
      type: "string" as const
    },
    {
      field: "totalAmount",
      title: "Total Amount",
      minWidth: 250,
      type: "string" as const
    }, 
  

    
  ]);

  return (
    <>
      <Container maxWidth={'lg'}>
        <Card>

        {isLoading ? <LoadingSpinner /> : <></>}
        {/* {isFetching ? <RefreshingCard message="Refreshing Clients" /> : <></>} */}
        
      {isSuccess ? (
        <>

            <MaterialTable
            title="Utilization Details"
            columns={columns}
            data={data}
            actions={[]}
            
            onRowClick={undefined}
            options={ {
              columnsButton:true,
              sorting: true,
              rowStyle: rowData => ({
                backgroundColor: (rowData.pharmacyBalance<=1000) ? '#00000' : (rowData.pharmacyBalance<0) ? '#01579b' : '#00000' 
              }),
              headerStyle: {
                backgroundColor: '#01579b',
                color: '#FFF',
              },}}

             />

        </>
      ) : (<EmptyList message="You may want to refine your filters" />)}
        </Card>
        <DialogActions>
        <Box sx={{ flexGrow: 1 }} />

        <Button variant="outlined" color="inherit" onClick={onCancel}>
          Close
        </Button>
      </DialogActions>
      </Container>


     
    </>
  );
}
InvoiceItems.defaultProps = {
    InvoiceNumber: undefined,
    onCancel: undefined,
    setOpenModalForm: undefined,
  };
  
  export default InvoiceItems;


