import {  Box,Grid, Paper, Snackbar, TextField, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Alert } from "@mui/lab";
import GenericDialog from "../components/GenericDialog";
import LoadingSpinner from "../components/LoadingSpinner";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useAppDispatch } from "../../utils/hooks";
import usePostRequest from "../../utils/usePostRequest";
import { createUserSuccess } from "../agent-accounts/agent-accounts-logic/profileSlice";
import SelectFromApi from "../components/SelectFromApi";
import { ErrorType } from "../../utils/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
    },

    inputStyle: {
      width: 400,
      marginBottom: theme.spacing(2),
    },
    autoComplete: {
      marginBottom: theme.spacing(1),
    },
    main: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
      zIndex: theme.zIndex.appBar - 1,
      outline: "none",
    },
    grid: {
      display: "grid",
      gridTemplateColumns: "1fr",
    },
    paper: {
      padding: theme.spacing(2),
      // textAlign: "center",
      //color: theme.palette.text.secondary,
      width: 'auto',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      color: 'black !important',
      fontFamily: "ChronicaPro,sans-serif",
      fontSize:"16px",
      fontWeight: 400,
    },
    formCustomTitle: {
      fontWeight:700,
      color: "#449afe",
      fontSize:"1.3rem",
      fontFamily: "ChronicaPro,sans-serif",
      width: 400,
    },
    CustomTitle: {
      fontWeight:700,
      color: "#022c5b",
      fontSize:"1.0rem! important",
      fontFamily: "ChronicaPro,sans-serif",
      width: 400,
    },
    inputLabel: {
      "&.focused": {
        color: "#449afe",
      },
      color: "#449afe",
    },
    errorText: {
      color: 'red',
      fontSize: 'small',
    },
      mapContainer: {
        width: '100%',
        marginTop: '10px',
      },
  })
);



type AssignmentModalProps = {
  queryString: string;
  open: boolean;
  cycleID: string;
  clientName: string;
  // eslint-disable-next-line no-unused-vars
  setOpen: (value: boolean) => void;
  // eslint-disable-next-line no-unused-vars
};

function DoctorReviewModal({
  queryString,
  open,
  setOpen,
  cycleID,
  clientName


}: AssignmentModalProps) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const [openSnack, setOpenSnack] = useState(true);
  
  const { handleSubmit, reset } = useForm();
  const [postError, setPostError] = useState<false | ErrorType>(false);
  const [invoiceError, setInvoiceError] = useState('');
  const [visitTypeErr, setVisitTypeErr] = useState<false | ErrorType>(false);
  // const [assignedTo, setassignedTo] = useState<string>("");
  const [assignedTo, setassignedToID] = useState<string>("");
  const [departmentID, setDepartmentID] = useState<string>("7");
  const [comment, setcomment] = useState<string>("");

  var UserID =sessionStorage.getItem('UserID');

  const onSuccess = ({ data }: any) => {
    queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.includes("Orders"),
    });
    dispatch(createUserSuccess(data));
    setOpen(false);
    queryClient.invalidateQueries(`${queryString}`);
    
    // Clear the input fields
    setDepartmentID("");
    setassignedToID("");
    setcomment("");
  };
  const onFailure = (err: ErrorType) => {
    setPostError(err);
  };



  const handleChange = (event:any) => {
    const { name, value } = event.target;
    switch (name) {
      case 'comment':
        setcomment(value);
        break;
        default:
          break;
      }
    };

    
  const handleAssigned = (newValue: any) => {
    setassignedToID(newValue?.userID);
  };
  const mutation = usePostRequest(
    "api/v1/checkups/tickets/doctor-review",
    {
      cycleID,
      status:1,
      comment,
      userID:UserID
    },
    onSuccess,
    onFailure
  );
  const onSubmit = () => {
    mutation.reset();
    mutation.mutate();
  };


  const handleClose = () => {
    mutation.reset();
    reset();
  };

  const actions = [
    {
      label: 'Complete Review',
      primary: true,
      onClick: handleSubmit(onSubmit),
    },
  ];

  return (
    <div>
      {mutation.isSuccess && (
        <Snackbar
          open={openSnack}
          onClose={() => setOpenSnack(false)}
          autoHideDuration={6000}
        >
          <Alert onClose={handleClose} severity="success">
              "Review Successfully completed"
          </Alert>
        </Snackbar>
      )}

      <GenericDialog
        title='Complete Review'
        showDialog={open}
        onCloseDialog={() => {
          setOpen(false);
        }}
        actions={actions}
        isLoading={mutation.isLoading}
        width="sm"
        height={400}
        verticalAlign="flex-start"
      >
       
          {mutation.isLoading ? <LoadingSpinner /> : <></>}
          {/* <div className={classes.grid}> */}
            {postError ? (
              <Alert severity="error" className="error-container">
                {postError?.message}
              </Alert>
            ) : (
              <></>
            )}
            
            <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>    
          <Grid item xs={12} sm={12} mt={2}>
          <TextField
            name="cleintName"
            label="Client Name"
            value={clientName}
            onChange={handleChange}
            disabled
            fullWidth
          />
          </Grid>

         <Grid item xs={12} sm={12} sx={{mt:2}}>
          <TextField
            name="comment"
            label="Comment"
            value={comment}
            onChange={handleChange}
            fullWidth
          />
          </Grid>
          {/* <Grid item xs={12} sm={12} sx={{mt:2}}>
          <TextField
            name="invoiceNo"
            label="Invoice Number"
            value={invoiceNo}
            required
            onChange={handleChange}
            fullWidth
          />
          {invoiceError && <p style={{ color: 'red' }}>{invoiceError}</p>}
          </Grid> */}
          </Paper>
          </Grid>
          </Grid>
          
          {/* <div className={classes.mapContainer}>
            <MapView
            setassignedToID={setassignedToID}
            clientlat={clientlat}
            clientlong={clientlong}
             />
          </div> */}
        
          {/* </div> */}
  
      </GenericDialog>

  
    </div>
  );
}

export default DoctorReviewModal;
