"use client";

import { useRef, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  IconButton,
  Link,
} from "@mui/material";
import {
  Close as CloseIcon,
  CloudUpload as CloudUploadIcon,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";

const UploadBox = styled(Box)(({ theme }) => ({
  border: "2px dashed rgba(0, 0, 0, 0.1)",
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(4),
  textAlign: "center",
  cursor: "pointer",
  "&:hover": {
    borderColor: theme.palette.primary.main,
  },
}));

interface PatientImportDialogProps {
  open: boolean;
  onClose: () => void;
  onFileSelect: (file: File) => void;
}

export default function PatientImportDialog({
  open = false,
  onClose = () => {},
  onFileSelect = () => {},
}: PatientImportDialogProps) {
  const [file, setFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    if (
      droppedFile &&
      (droppedFile.type === "text/csv" ||
        droppedFile.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
    ) {
      setFile(droppedFile);
    }
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleUpload = () => {
    if (file) {
      onFileSelect(file);
      onClose();
    }
  };

  const handleFileRemove = () => {
    setFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Reset input so same file can be re-uploaded
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: { position: "fixed", right: 0, m: 0, height: "100%" },
      }}
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" component="div">
          Import patients
        </Typography>
        <IconButton aria-label="close" onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Typography variant="body1" sx={{ mb: 3 }}>
          Enroll multiple patients by filling and uploading{" "}
          <Link href="/bulk-upload-template.xlsx" color="primary" download>
            this template
          </Link>
        </Typography>

        <input
          type="file"
          accept=".csv,.xlsx"
          id="file-upload"
          style={{ display: "none" }}
          onChange={handleFileSelect}
          ref={fileInputRef} // Add ref here
        />

        {file ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              border: "1px solid rgba(0, 0, 0, 0.2)",
              padding: 2,
              borderRadius: 1,
              mt: 2,
            }}
          >
            <Typography variant="body1">{file.name}</Typography>
            <IconButton onClick={handleFileRemove}>
              <DeleteIcon color="error" />
            </IconButton>
          </Box>
        ) : (
          <UploadBox
            onDrop={handleDrop}
            onDragOver={(e) => e.preventDefault()}
            onClick={() => fileInputRef.current?.click()}
          >
            <CloudUploadIcon
              sx={{ fontSize: 48, color: "action.active", mb: 2 }}
            />
            <Typography variant="h6" color="textSecondary" sx={{ mb: 1 }}>
              Select a file or drag and drop here
            </Typography>
            <Typography variant="body2" color="textSecondary">
              CSV or XLSX file no more than 10MB
            </Typography>
            <Button
              variant="outlined"
              sx={{ mt: 2 }}
              onClick={(e) => {
                e.stopPropagation();
                fileInputRef.current?.click();
              }}
            >
              SELECT FILE
            </Button>
          </UploadBox>
        )}
      </DialogContent>

      <DialogActions sx={{ p: 3 }}>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button variant="contained" disabled={!file} onClick={handleUpload}>
          Upload patients
        </Button>
      </DialogActions>
    </Dialog>
  );
}
