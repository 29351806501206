/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from "moment";
import { createStyles, makeStyles } from "@mui/styles";
import BadError from "../components/BadError";
import LoadingSpinner, {
  RefreshingCard,
} from "../components/LoadingSpinner";
import PageContent from "../components/pageContent";
import useFetch from "../../utils/useFetch";
import { User } from "./package-logic/packageSlice";
import { Box, Theme } from "@mui/material";
import CustomEvent from "./CustomEvent";



const useStyles = makeStyles((theme: Theme) =>
createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
    },

  searchBox: {
    padding: theme.spacing(2, 0),
    width: "99%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  calendarContainer: {
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },

}));


const links = [
  {
    pathname: "",
    label: "",
    permission: "",
  },
];


interface CalendarEvent {
  title: string;
  start: Date;
  end: Date;
  refillsData: number;
  followUpsData: number;
  bookingsData: number;
}


type Props = {
  roleName?: string;
  roleId?: string;
  userTypeCode?: string;
};
const PackagesList: React.FC<Props> = ({
  roleName,
  roleId,
  userTypeCode,
}: Props) => {
  const classes = useStyles();
  const [user, setUser] = useState<User>();

  const [value, setValue] = React.useState<string>('');
  const [inputValue, setInputValue] = React.useState('');
      const {
        data,
        isLoading,
        isError,
        error,
        isRefreshing,
        isSuccess
      } = useFetch(`api/v1/checkups/tickets/get-reminders`, ["ReminderList", ]);

    let optionsData;
    (value?.length <=0 || value ==null)? 
    optionsData=data?.data || data || [] :
    optionsData=data.filter((data:any) => data.typeOfService.includes(value));

  // const optionsData = data?.data || data || [] ;
  const singleUser = useFetch(
    `/auth/get-single-user/?usercode=${user?.userCode}`,
    ["user", `${user?.userCode}`],
    { enabled: !!user?.userCode }
  );
  // useEffect(() => {
  //   dispatch(setSelectedUser(singleUser.data));
  // }, [singleUser]);


  const localizer = momentLocalizer(moment);
  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<any>(null);
  const [creatingEvent, setCreatingEvent] = useState<boolean>(false);
  const [newEvent, setNewEvent] = useState<any>(null);
  const [selectedSlot, setSelectedSlot] = useState<{ start: Date; end: Date } | null>(null);
  // const [selectedRefills, setSelectedRefills] = useState<Refill[] | null>(null);
  // const [selectedFollowUps, setSelectedFollowUps] = useState<FollowUp[] | null>(null);
  // const [selectedBookings, setSelectedBookings] = useState<Booking[] | null>(null);

  useEffect(() => {
    // Fetch and format data for the calendar
    const eventData: CalendarEvent[] = [
      {
        title: 'Refills: 10\nFollow Ups: 20\nBookings: 40',
        start: new Date(2023, 10, 1),
        end: new Date(2023, 10, 1),
        refillsData: 10,
        followUpsData: 20,
        bookingsData: 40
      },
      {
        title: 'Refills: 10\nFollow Ups: 20\nBookings: 40',
        start: new Date(2023, 9, 15),
        end: new Date(2023, 9, 15),
        refillsData: 4,
        followUpsData: 100,
        bookingsData: 60
      },
      {
        title: 'Refills: 10\nFollow Ups: 20\nBookings: 40',
        start: new Date(2023, 11, 11),
        end: new Date(2023, 11, 11),
        refillsData: 11,
        followUpsData: 7,
        bookingsData: 14
      },
      {
        title: 'Refills: 5<br>Follow Ups: 15<br>Bookings: 30',
        start: new Date(2023, 10, 2),
        end: new Date(2023, 10, 2),
        refillsData: 15,
        followUpsData: 30,
        bookingsData: 11
      },
      // Add more data for other days
    ];

    setEvents(eventData);
  }, []);

  // const handleEventClick = (event: CalendarEvent) => {
  //   if (event.refillsData) {
  //     setSelectedRefills(event.refillsData);
  //   }
  //   if (event.followUpsData) {
  //     setSelectedFollowUps(event.followUpsData);
  //   }
  //   if (event.bookingsData) {
  //     setSelectedBookings(event.bookingsData);
  //   }
  // };
 
  const handleEventClick = (event: any) => {
    setSelectedEvent(event);
  };

  const handleSelectSlot = ({ start, end }: { start: Date; end: Date }) => {
    setSelectedSlot({ start, end });
    setCreatingEvent(true);
  };


  const handleSaveEvent = () => {
    if (selectedSlot) {
      // Create a new event based on the selected slot
      const newEmptyEvent = {
        title: "",
        start: selectedSlot.start,
        end: selectedSlot.end,
        refillsData: [],
        followUpsData: [],
        bookingsData: [],
      };
      // sampleData.events.push(newEmptyEvent);
      setCreatingEvent(false);
      setNewEvent(null);
    }
  };

  const handleCancelEvent = () => {
    setCreatingEvent(false);
    setSelectedSlot(null);
    setNewEvent(null);
  };
  return (
    <PageContent>
      <div className={classes.root}>

        {isLoading ? <LoadingSpinner /> : <></>}

      {isSuccess ? (
        <>
      <Box className={classes.searchBox}>

          </Box>
          <div className={classes.calendarContainer}>
          <Calendar
              localizer={localizer}
              events={data}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 700 }}
              onSelectEvent={handleEventClick}
              onSelectSlot={handleSelectSlot} // Handle slot selection for new event}
              components={{
                event: ({ event }) => (
                  <CustomEvent
                    event={event}
                    refillsData={event.refills}
                    followUpData={event.totalollowUps}
                    bookingsData={event.bookings}
                    reminderDate={event.start}
                  />
                ),
              }}
            />
            </div>
            {creatingEvent ? (
          <div>
            <input
              type="text"
              placeholder="Event Title"
              value={newEvent?.title || ""}
              onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })}
            />
            <button onClick={handleSaveEvent}>Save</button>
            <button onClick={handleCancelEvent}>Cancel</button>
          </div>
        ) : null}

            {/* {selectedRefills && <RefillsList refillsData={selectedRefills} />}
            {selectedFollowUps && <FollowUpsList followUpsData={selectedFollowUps} />}
            {selectedBookings && <BookingsList bookingsData={selectedBookings} />} */}
   
        </>
      ) : ('<></>')}
      </div>
    </PageContent>
  );
};

PackagesList.defaultProps = {
  roleName: undefined,
  roleId: undefined,
  userTypeCode: undefined,
};

export default PackagesList;




