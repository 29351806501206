import styled from "styled-components";
import { useState } from "react";
import { Chip, InputBase, InputBaseProps, InputLabel } from "@mui/material";
import { styled as MuiStyled } from "@mui/material/styles";
import { toast } from "react-toastify";
import SlideWrapper from "./slide-wrapper";

export type SchemeType = {
  schemeCode: string;
  insuranceAccountSchemeDesc: string;
};
type SelectDropProps = {
  items: SchemeType[] | null;
  setItems: React.Dispatch<React.SetStateAction<SchemeType[] | null>>;
};
export default function SchemeAddComponent({
  items = [],
  setItems,
}: SelectDropProps) {
  const [scheme, setScheme] = useState<SchemeType>({
    insuranceAccountSchemeDesc: "",
    schemeCode: "",
  });

  const isAddedSheme = (schemes: SchemeType[] | null, shemeCode: string) => {
    if (!schemes) return false;
    return schemes.findIndex((d) => d.schemeCode === shemeCode) < 0
      ? false
      : true;
  };

  const handleRemove = (schemeCode: string) => {
    if (items) {
      const f = items.filter((item) => item.schemeCode !== schemeCode);
      setItems(f.length > 0 ? f : null);
    }
  };

  const resetScheme = () => {
    setScheme({
      insuranceAccountSchemeDesc: "",
      schemeCode: "",
    });
  };

  const updateScheme = (
    key: "insuranceAccountSchemeDesc" | "schemeCode",
    value: string
  ) => {
    setScheme((s) => ({ ...s, [key]: value }));
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.stopPropagation();
    if (event.key === "Enter") {
      const value = event.currentTarget.value;
      if (!value || value.length < 2) return;
      if (isAddedSheme(items, value)) {
        toast.info("Scheme already added", {
          hideProgressBar: true,
          closeButton: true,
        });
        resetScheme();
        return;
      }
      setItems([...(items ? items : []), scheme]);
      resetScheme();
    }
  };
  return (
    <div style={{ width: "100%" }}>
      <InputLabel
        htmlFor={
          scheme.insuranceAccountSchemeDesc.length > 3
            ? "schemeCode"
            : "insuranceAccountSchemeDesc"
        }
        id={`scheme-label`}
      >
        Add insurance schemes
      </InputLabel>

      <SkillsWrapper $border={Boolean(items)}>
        {items &&
          items.map((val, index) => {
            return (
              <Chip
                key={index}
                label={val.insuranceAccountSchemeDesc}
                onDelete={(e) => {
                  e.preventDefault();
                  handleRemove(val.schemeCode);
                }}
              />
            );
          })}
        <StyledInputBase
          placeholder="Enter Scheme description"
          value={scheme.insuranceAccountSchemeDesc}
          onChange={(e) => {
            e.preventDefault();
            updateScheme("insuranceAccountSchemeDesc", e.target.value);
          }}
          id="insuranceAccountSchemeDesc"
        />
        {scheme.insuranceAccountSchemeDesc.length > 3 && (
          <SlideWrapper>
            <StyledInputBase
              $border
              placeholder="Enter code"
              value={scheme.schemeCode}
              onChange={(e) => {
                e.preventDefault();
                updateScheme("schemeCode", e.target.value);
              }}
              id="schemeCode"
              onKeyDown={handleKeyDown}
            />
          </SlideWrapper>
        )}
        <span
          style={{
            fontSize: "10px",
            position: "absolute",
            left: 0,
            bottom: -22,
            borderRadius: "4px",
            padding: "1px 6px",
            background: "#edf2f7",
            transform: `${
              scheme.schemeCode.length > 1
                ? "translateX(0)"
                : "translateX(-150%)"
            }`,
            color: "#4c9df8",
            transition: "transform 100ms ease-in-out",
          }}
        >
          press enter to add scheme
        </span>
      </SkillsWrapper>
    </div>
  );
}

interface StyledInputProps extends InputBaseProps {
  $border?: boolean;
}

export const StyledInputBase = MuiStyled(
  ({ $border, ...rest }: StyledInputProps) => <InputBase {...rest} />
)(({ $border, theme }) => ({
  width: "100%",

  "&.MuiInputBase-root": {
    fontSize: 16,
    width: "100%",
    display: "flex",
    padding: "0px  8px",
    alignItems: "center",
    borderRadius: "6px",
    border: $border ? "1px solid #0009321f" : "none",
    outline: "none",
    background: "rgba(255, 255, 255, 0.90)",
    height: 40,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),

    "&.MuiInputBase-root.MuiInputBase-adornedStart": {
      display: "flex",
      gap: 5,
    },

    "&.MuiInputBase-root.Mui-focused, &.MuiInputBase-root.Mui-focused.Mui-error":
      {
        border: $border ? ".6px solid #4c9df8" : "none",
        outline: "none",
      },
    "&.MuiInputBase-root.Mui-error": {
      border: "none",
      outline: "none",
    },
  },
}));

export const SkillsWrapper = styled.div<{ $border?: boolean }>`
  position: relative;
  display: flex;
  width: 100%;
  flex: 1;
  flex-wrap: wrap;
  gap: 0.3rem;
  background: #ffffff00;
  padding: ${({ $border }) => ($border ? "12px" : "0")};
  border-radius: 6px;
  background: #ffffffe5;
  border: 1px solid #0009321f;
  &:has(input:focus) {
    border: 1px solid #4c9df8;
  }
`;
