import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

import { InsuranceContext } from "../scheme-context";
type GlobalDrawerProps = {
  children?: React.ReactNode;
};
export default function GlobalDrawer({ children }: GlobalDrawerProps) {
  const { drawerState, toggleDrawer } = React.useContext(InsuranceContext);
  return (
    <DialogContainer
      open={drawerState}
      TransitionComponent={Transition}
      onClose={toggleDrawer}
      aria-describedby="alert-dialog-slide-description"
    >
      {children}
    </DialogContainer>
  );
}

const DialogContainer = styled(Dialog)(() => ({
  "& .MuiDialog-container": {
    display: "flex",
    alignItems: "start",
    justifyContent: "end",
    width: "100%",
  },
  "& .MuiDialog-container > .MuiPaper-root:first-of-type": {
    margin: "auto 24px auto 0px",
    maxWidth: 448,
    width: "100%",
  },
  "& .MuiInputLabel-root": {
    color: "#1C2024",

    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: 0,
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
