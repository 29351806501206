import { useMutation, useQuery, useQueryClient } from "react-query";
import API from "../api";
import { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { omit } from "lodash";

const URL = "/api/InsuranceEnrollment";

type CreateInsuranceType = {
  insuranceAccountDesc: string;
  code: string;
  insuranceType: string;
  phoneNumber: string;
  whatsAppToken?: string;
  whatsAppPhoneID?: string;
  imageUrl?: string;
};

export type InsuranceDataType = Omit<
  CreateInsuranceType,
  "insuranceAccountDesc" | "insuranceType"
> & {
  description: string;
  paymentAccountID: string;
  category: string;
  disabled?: boolean;

  createdDate: string;
};
export type InsuranceQueryParams = {
  pageSize: number;
  page: number;
  field?: string;
  activeTab: "active" | "inactive";
};

export type InsuranceQueryResponse = {
  data: InsuranceDataType[];
  hasNextpage: boolean;
  isLasPage: boolean;
  totalPages: number;
};
export function useGetAllInsurance({
  page,
  pageSize,
  field,
  activeTab,
}: InsuranceQueryParams) {
  return useQuery<
    AxiosResponse<InsuranceQueryResponse>,
    AxiosError<any, any>,
    InsuranceQueryResponse
  >(
    ["getInsurance", pageSize, page < 1 ? 1 : page, field],
    () =>
      API.get<{}, AxiosResponse<InsuranceQueryResponse>>(
        `${URL}/get-all-insurance?pageNumber=${
          page < 1 ? 1 : page
        }&pageSize=${pageSize}&field=${field === "" ? "all" : field}`
      ),
    {
      onError: (er) => {
        console.log(er);
        toast.error(er.message ?? "error fetching insurance", {
          hideProgressBar: true,
          closeButton: true,
        });
      },
      select: (data) => {
        return data.data;
      },
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      staleTime: Infinity,
      cacheTime: 1000 * 60 * 20,
      enabled: activeTab === "active",
    }
  );
}

export function useGetAllInactiveInsurance({
  page,
  pageSize,
  field,
  activeTab,
}: InsuranceQueryParams) {
  return useQuery<
    AxiosResponse<InsuranceQueryResponse>,
    AxiosError<any, any>,
    InsuranceQueryResponse
  >(
    ["getInactiveInsurance", pageSize, page < 1 ? 1 : page, field],
    () =>
      API.get<{}, AxiosResponse<InsuranceQueryResponse>>(
        `${URL}/get-all-inactive-insurance?pageNumber=${
          page < 1 ? 1 : page
        }&pageSize=${pageSize}&field=${field === "" ? "all" : field}`
      ),
    {
      onError: (er) => {
        console.log(er);
        toast.error(er.message ?? "error fetching inactive insurance", {
          hideProgressBar: true,
          closeButton: true,
        });
      },
      select: (data) => {
        return data.data;
      },
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      staleTime: Infinity,
      cacheTime: 1000 * 60 * 20,
      enabled: activeTab === "inactive",
    }
  );
}

export function useCreateInsurance() {
  const queryClient = useQueryClient();
  return useMutation<"success", AxiosError<any, any>, CreateInsuranceType>(
    async (data) => {
      return await API.post(`${URL}/create-insuranceprovider`, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["getInsurance"]);
      },
      onError: (er) => {
        console.log(er);
      },
    }
  );
}

export function useEditInsurance() {
  const queryClient = useQueryClient();
  return useMutation<
    "success",
    AxiosError<any, any>,
    Partial<CreateInsuranceType> & { paymentAccountID: string }
  >(
    async (data) => {
      console.log("update", data);
      return await API.post(
        `${URL}/update-insuranceprovider/${data.paymentAccountID}`,
        omit(data, ["paymentAccountID"])
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["getInsurance"]);
        toast.success("Insurance updated successfully", {
          hideProgressBar: true,
          closeButton: true,
        });
      },
      onError: (er) => {
        console.log(er);
        toast.error("Insurance update failed", {
          hideProgressBar: true,
          closeButton: true,
        });
        throw new Error("Error updating insurance");
      },
    }
  );
}

type Scheme = {
  schemeCode: string;
  insuranceAccountSchemeDesc: string;
  tags?: string;
};

export type CreateInsuranceScheme = {
  insuranceAccountId: string;
  insuranceAccountDesc: string;
  schemeListModels: Scheme[];
};
export function useEditAndCreateScheme() {
  return useMutation<"success", AxiosError<any, any>, CreateInsuranceScheme>(
    async (data) => {
      console.log("update", data);
      return await API.post(`${URL}/create-insuranceproviderscheme`, data);
    },
    {
      onSuccess: (s) => {
        console.log(s);
        toast.success("Scheme Created successfully", {
          hideProgressBar: true,
          closeButton: true,
        });
      },
      onError: (er) => {
        console.log(er);
        toast.error("Scheme creation update failed", {
          hideProgressBar: true,
          closeButton: true,
        });
        throw new Error("Error updating insurance");
      },
    }
  );
}

export function useDeleteInsurance() {
  const queryClient = useQueryClient();
  return useMutation<"success", AxiosError<any, any>, string | number>(
    async (id) => {
      console.log("update", id);
      return await API.post(`${URL}/delete-provider?insuranceId=${id}`);
    },
    {
      onSuccess: (s) => {
        queryClient.invalidateQueries(["getInsurance"]);
        queryClient.invalidateQueries(["getInactiveInsurance"]);
        toast.success("Insurance Deleted successfully", {
          hideProgressBar: true,
          closeButton: true,
        });
      },
      onError: (er) => {
        console.log(er);
        toast.error("Insurance Deletion failed", {
          hideProgressBar: true,
          closeButton: true,
        });
        throw new Error("Error deleting insurance");
      },
    }
  );
}

export function useDisableInsurance() {
  const queryClient = useQueryClient();
  return useMutation<"success", AxiosError<any, any>, string | number>(
    async (id) => {
      return await API.post(`${URL}/deactivate-provider?insuranceId=${id}`);
    },
    {
      onSuccess: (s) => {
        queryClient.invalidateQueries(["getInsurance"]);
        queryClient.invalidateQueries(["getInactiveInsurance"]);
        toast.success("Insurance Disabled successfully", {
          hideProgressBar: true,
          closeButton: true,
        });
      },
      onError: (er) => {
        console.log(er);
        toast.error("Failed to disable issurance", {
          hideProgressBar: true,
          closeButton: true,
        });
        throw new Error("Failed to disable issurance");
      },
    }
  );
}

export function useEnableInsurance() {
  const queryClient = useQueryClient();
  return useMutation<"success", AxiosError<any, any>, string | number>(
    async (id) => {
      return await API.post(`${URL}/activate-provider?insuranceId=${id}`);
    },
    {
      onSuccess: (s) => {
        queryClient.invalidateQueries(["getInsurance"]);
        queryClient.invalidateQueries(["getInactiveInsurance"]);
        toast.success("Insurance Enabled successfully", {
          hideProgressBar: true,
          closeButton: true,
        });
      },
      onError: (er) => {
        console.log(er);
        toast.error("Failed to enable issurance", {
          hideProgressBar: true,
          closeButton: true,
        });
        throw new Error("Failed to enable issurance");
      },
    }
  );
}
