import { createSlice } from "@reduxjs/toolkit";

interface SettingsState {
  sidebarCollapsed: boolean;
}

export const initialSettingsState: SettingsState = {
  sidebarCollapsed: true,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState: initialSettingsState,
  reducers: {
    toggleSidebarCollapsed: (state) => {
      state.sidebarCollapsed = !state.sidebarCollapsed;
    },
  },
});

export const { toggleSidebarCollapsed } = settingsSlice.actions;
export default settingsSlice.reducer;
