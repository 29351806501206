import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { makeStyles,createStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import {Navigate, useNavigate, Link as Links, useParams} from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast, Flip } from "react-toastify";
import { useEffect, useState } from 'react';
import useAuthFormStyles from "./authStyles";
import {strengthColor,strengthIndicator} from './strength-password'
function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://checkupsmed.com">
        Checkups Medical Hub
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
const useStyles = makeStyles((theme: Theme) => createStyles({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
}));

export default function SignIn() {
  const { id }: any = useParams();
const classes = useStyles();
const classes2 = useAuthFormStyles();
const [password, setPassword]=useState<string>("");
const [confirmPassword, setConfirmPassword]=useState<string>("");
const [confirmColor, setConfirmColor]=useState<string>("black");
const [disbale, setDisable] = useState<boolean>(true);
const [ message, setMessage]= useState<string>("");
const [ Errmessage, setErrMessage]= useState<string>("");

const handlePassword=(event:any)=>{
  setPassword(event.target.value);
  setErrMessage("");
  }
  const handleConfirmPassword=(event:any)=>{
    setConfirmPassword(event.target.value);
    const pass=event.target.value;
    if(pass==password){
      setConfirmColor("green");
    }else{
      setConfirmColor("red");
    }
    setErrMessage("");
  }
useEffect(()=>{
  password.length>=8 ? setDisable(false) : setDisable(true)
},[password])
const strength = strengthIndicator(password);
const color=strengthColor(strength);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let params = {
        token: id,
        password:password,
        confirmPassword:confirmPassword,
      };
      !disbale && 
      password==confirmPassword ?(
      axios
        .post("https://dashboardapi.checkupsmed.com:1006/api/v1/checkups/account/reset-password", params)
        .then(function (response) {
          //   IF EMAIL ALREADY EXISTS
          if (response.status !== 200) {
            toast.error("Wrong Data", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: 0,
              toastId: "my_toast",
            });
          } else {
            toast.success("Reset Successfully", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: 0,
              toastId: "my_toast",
            });
          setMessage(response.data.message)
          }
        })
  
        .catch(function (error) {
          console.log(error);
        })) :
        (
        setErrMessage("First and second password does not match ")
        );

  };


  return (
    <div className={classes2.root}>
  <Container component="main" maxWidth="md">
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
         New Password
        </Typography>
        <form className={classes.form} noValidate>
        <TextField
            margin="normal"
            sx={{
              ".css-x2l1vy-MuiInputBase-root-MuiOutlinedInput-root": {
                borderColor: "white",
              },

            }}
            required
            onChange={(event:any)=>handlePassword(event)}
            value={password}
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="off"
            InputProps={{
              sx: {
                ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                  borderColor: color,
                  borderWidth: "2px",
                  borderStyle:"solid"
                },
                "&:hover": {
                  ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    borderColor: color,
                    borderWidth: "2px",
                    borderStyle:"solid"
                  },
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: color,
                    borderWidth: "2px",
                    borderStyle:"solid" },
                  "&:hover fieldset": {borderColor: color,
                    borderWidth: "2px",
                    borderStyle:"solid" },
                  "&.Mui-focused fieldset": { borderColor: color,
                    borderWidth: "2px",
                    borderStyle:"solid" },
              },
              },
            }}
          />
                 <TextField
               variant="outlined"
               color="secondary"
            margin="normal"
            required
            onChange={(event:any)=>handleConfirmPassword(event)}
            value={confirmPassword}
            fullWidth
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            id="confirmPassword"
            disabled={disbale}
            autoComplete="off"            
            InputProps={{
              sx: {
                ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                  borderColor: confirmColor,
                  borderWidth: "2px",
                  borderStyle:"solid"
                },
                "&:hover": {
                  ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    borderColor: confirmColor,
                    borderWidth: "2px",
                    borderStyle:"solid"
                  },
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: confirmColor,
                    borderWidth: "2px",
                    borderStyle:"solid" },
                  "&:hover fieldset": {borderColor: confirmColor,
                    borderWidth: "2px",
                    borderStyle:"solid" },
                  "&.Mui-focused fieldset": { borderColor: confirmColor,
                    borderWidth: "2px",
                    borderStyle:"solid" },
              },
              },
            }}
          />
          <Button
            type="submit"
            fullWidth
            onClick={(event:any)=>handleSubmit(event)}
            variant="contained"
            color="primary"
            className={classes.submit}
          >
           Reset Password
          </Button>
          <Grid container style={{marginTop:10}}>
            <Grid item xs>
              <Links to="/" style={{color:"red"}}>
               Sign in?
              </Links>
            </Grid>
          </Grid>
        </form>
    
      </div>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
        limit={1}
        transition={Flip}
      />

    </Container>
    <Box mt={8} style={{marginTop:10}} >
    <Typography color="text.primary" align="center" sx={{ color: 'success.main' }}>
      {message}
    </Typography>
    <Typography color="text.primary" align="center" sx={{ color: 'error.main' }}>
      {Errmessage}
    </Typography>         
          </Box>
    <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
    </div>
  );
}