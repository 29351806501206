import React from "react";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const BackToDashboardButton: React.FC = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // Navigates to the previous page
  };

  return (
    <Button
      variant="contained"
      startIcon={<ArrowBackIcon />}
      onClick={handleBack}
      sx={{
        mt: 2,
        backgroundColor: "#00a6fb", 
        "&:hover": {
          backgroundColor: "#115293", 
        },
      }}
    >
      Back to Dashboard
    </Button>
  );
};

export default BackToDashboardButton;
