/**
 * A reusable component for displaying details header metadata,
 * This can be used with single items details header,
 * TOD REUSE THIS COMPONENT,
 * please check the SingleOrder.tsx file for sample usage
 */
import { Box, Divider, IconButton, Paper, Typography,Theme, Button } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { StoreOutlined } from "@mui/icons-material";
import TableLink from "../../components/TableLink";
import UploadableImageContainer from "../../components/UploadableImageContainer";
import React from "react";
import { NavLink } from "react-router-dom";
import { ItemDetails } from "./types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 0,
      width: "100%",
      padding: theme.spacing(1),
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      border: `1px solid ${theme.palette.grey[300]}`,
    },
    vertical: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    iconAndName: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      margin: theme.spacing(1, 0),
    },
    entityLabel: {
      fontWeight: 900,
      fontSize: "1.5rem",
      color: theme.palette.secondary.dark,
      textTransform: "capitalize",
    },
    shopIcon: {
      backgroundColor: theme.palette.grey[300],
      marginRight: theme.spacing(1),
      cursor: "default",
      "&:hover": {
        backgroundColor: theme.palette.grey[300],
      },
    },
    metaDataItem: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      padding: "0",
    },
    entityName: {
      color: theme.palette.text.primary,
      fontWeight: 600,
      textDecoration: "underline",
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
    metaDataContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: "0.8rem",
      marginBottom: "10px",
    },
    divider: {
      backgroundColor: theme.palette.grey[500],
      marginLeft: "10px",
      marginRight: "10px",
      padding: "0",
      height: "20px",
      width: "2px",
    },
  })
);
type Props = {
  metadata: ItemDetails;
};
/**
 *
 * @param param0 ItemDetails
 * @returns
 */

const Metadata: React.FC<Props> = ({ metadata }: Props) => {
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <div className={classes.iconAndName}>
        {metadata?.hasImageUpload ? (
          <UploadableImageContainer
            currentImages="https://checkupsmed.com/image2\.jpg"
            uploadPath={metadata?.uploadPath}
            icon={metadata?.icon}
            queryNameToRefresh={metadata?.queryNameToRefresh}
          />
        ) : (
          <IconButton className={classes.shopIcon}>
            {metadata?.icon ? (
              metadata.icon
            ) : (
              <StoreOutlined color="secondary" fontSize="large" />
            )}
          </IconButton>
        )}
        <div className={classes.vertical}>
          <Typography className={classes.entityLabel}>
          {metadata?.name}
          </Typography>
          <Box mt={0.5} mb={0.5} className={classes.metaDataContainer}>
            {metadata?.navBoxContent?.map((nav: any, key: number) => (
              <Box pl={2} title="Entity" className={classes.metaDataItem}>
                {key !== 0 && (
                  <Divider className={classes.divider} orientation="vertical" />
                )}
                {nav?.icon}
                <NavLink
                  title={nav?.navTitle}
                  className={classes.entityName}
                  to={nav?.navLink}
                >
                  {`${nav?.navName}`}
                </NavLink>
              </Box>
            ))}
          </Box>
          <div className={classes.metaDataContainer}>
            {metadata?.boxContent?.map((content: any, key: number) => (
              <Box className={classes.metaDataItem} key={content.title}>
                {key !== 0 && (
                  <Divider className={classes.divider} orientation="vertical" />
                )}
                {content.icon}
                {content?.link ? (
                  <TableLink path={content?.link}>
                    <Typography style={{ fontSize: "1rem" }}>
                      {content.title}
                    </Typography>
                  </TableLink>
                ) : (
                  <Typography style={{ fontSize: "1rem" }}>
                    {content.title}
                  </Typography>
                )}
              </Box>
            ))}
          </div>
          <div className={classes.metaDataContainer}>
            {metadata?.boxContent2?.map((content: any, key: number) => (
              <Box className={classes.metaDataItem} key={content.title}>
                {key !== 0 && (
                  <Divider className={classes.divider} orientation="vertical" />
                )}
                 <Typography style={{ fontSize: "1rem",fontWeight:"bold" }}>
                    {content.title2}
                  </Typography>
                {content?.link ? (
                  <TableLink path={content?.link}>
                    <Typography style={{ fontSize: "1rem" }}>
                      {content.title}
                    </Typography>
                  </TableLink>
                ) : (
                  <Typography style={{ fontSize: "1rem" }}>
                    {content.title}
                  </Typography>
                  
                )}
                
              </Box>
            ))}
          </div>
        </div>
      </div>
     
      <Button
            color="secondary"
            variant="outlined"
            style={{  backgroundColor:'green',color:'white'}}
          >
          {metadata?.statusContainer}
          </Button>
      {metadata?.inforBox}
    </Paper>
  );
};

export default Metadata;
