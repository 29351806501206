import * as React from "react";
import { alpha, styled } from "@mui/material/styles";
import {
  GridRowsProp,
  GridRowModesModel,
  DataGrid,
  GridColDef,
  GridEventListener,
  gridClasses,
  GridSlots,
  GridPaginationModel,
  GridCallbackDetails,
  PaginationPropsOverrides,
  GridRowIdGetter,
} from "@mui/x-data-grid";
import { TablePaginationProps, Box } from "@mui/material";

export type GridTable = {
  columns: GridColDef[];
  rows: GridRowsProp;
  pageSize?: number;
  paginationModel?: GridPaginationModel;
  rowModesModel?: GridRowModesModel;
  loading?: boolean;
  onPaginationModelChange?: (
    model: GridPaginationModel,
    details: GridCallbackDetails
  ) => void;
  onRowModesModelChange?: (
    rowModesModel: GridRowModesModel,
    details: GridCallbackDetails
  ) => void;
  onRowEditStop?: GridEventListener<"rowEditStop">;
  onCellEditStop?: GridEventListener<"cellEditStart">;
  getRowId?: GridRowIdGetter<any>;
  onProcessRowUpdateError?: (error: any) => void;
  processRowUpdate?: (newRow: any, oldRow: any) => any | Promise<any>;
  children?: React.ReactNode;
  pagination?: React.JSXElementConstructor<
    Partial<TablePaginationProps> & PaginationPropsOverrides
  > | null;
  EditToolbar?: GridSlots["toolbar"];
};
export default React.memo(function GridTable({
  children,
  rows,
  columns,
  rowModesModel,
  pageSize,
  paginationModel,
  onPaginationModelChange,
  ...props
}: GridTable) {
  return (
    <Box
      id="table-root"
      sx={{
        height: 500,
        width: "100%",
        position: "relative",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
        "& .insurance-table--header": {
          backgroundColor: "#E2E8F0",
        },
      }}
    >
      <StyledDataGrid
        columnHeaderHeight={40}
        disableColumnSelector
        rows={rows}
        columns={columns}
        editMode="row"
        getRowId={props.getRowId}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        rowModesModel={rowModesModel}
        onRowModesModelChange={props.onRowModesModelChange}
        onRowEditStop={props.onRowEditStop}
        onCellEditStart={props.onCellEditStop}
        processRowUpdate={props.processRowUpdate}
        loading={props.loading}
        onProcessRowUpdateError={props.onProcessRowUpdateError}
        autoHeight
        slots={{
          toolbar: props.EditToolbar,
          pagination: props.pagination,
        }}
        pageSizeOptions={pageSize ? ([pageSize] as const) : undefined}
      />
      {children}
    </Box>
  );
},
areEqual);

function deepCompareProps(prevProps: any, nextProps: any): boolean {
  // Create a helper function to compare objects deeply
  function isEqual(a: any, b: any): boolean {
    // Handle primitive values and null/undefined
    if (a === b) return true;
    if (
      typeof a !== "object" ||
      typeof b !== "object" ||
      a == null ||
      b == null
    )
      return false;

    // Handle arrays
    if (Array.isArray(a) && Array.isArray(b)) {
      if (a.length !== b.length) return false;
      return a.every((item, index) => isEqual(item, b[index]));
    }

    // Handle functions (check by reference)
    if (typeof a === "function" || typeof b === "function") {
      return a === b;
    }

    if ("mode" in b) {
      console.log(b, a);
      return b?.mode === a?.mode;
    }
    // Handle objects
    const keysA = Object.keys(a);
    const keysB = Object.keys(b);
    if (keysA.length !== keysB.length) return false;

    return keysA.every((key) => {
      // Skip comparing non-serializable properties like React internals or DOM nodes
      if (key.startsWith("__react")) return true; // Skip React-related props
      if (typeof a[key] === "function" || typeof b[key] === "function")
        return a[key] === b[key];
      return isEqual(a[key], b[key]);
    });
  }

  return isEqual(prevProps, nextProps);
}

function areEqual(
  prevProps: Readonly<React.PropsWithChildren<GridTable>>,
  nextProps: Readonly<React.PropsWithChildren<GridTable>>
) {
  const isEqual = deepCompareProps(prevProps, nextProps);
  return isEqual;
}

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  color: "#495057",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "20px",
  borderColor: "#E2E8F0",
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.main, 0.3),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
  [`& .${gridClasses.row}.Mui-selected`]: {
    backgroundColor: "#4a55684a",
    "&:hover": {
      backgroundColor: "#e2e8f06e",
      "@media (hover: none)": {
        backgroundColor: "#e2e8f05c",
      },
    },
  },
  "& .MuiDataGrid-cell.category": {
    display: "flex",
    alignItems: "center",
  },
  "& .MuiDataGrid-cell.actions": {
    overflow: "visible !important",
  },
  "& .MuiDataGrid-cell:hover": {
    color: "#1C2024",
  },
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#E2E8F0",
    color: "#4A5568",
    outline: "none",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "16px" /* 133.333% */,
    letterSpacing: "0.6px",
  },
  "& .MuiDataGrid-columnHeader": {
    backgroundColor: "#E2E8F0",
    color: "#4A5568",
    textTransform: "uppercase",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "16px" /* 133.333% */,
    letterSpacing: "0.6px",
    outline: "none",
  },
  "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within":
    {
      outline: "none",
    },
  "& .MuiDataGrid-columnsContainer": {
    borderBottom: "0.5px solid #E2E8F0",
    ...theme.applyStyles("light", {
      borderBottomColor: "#E2E8F0",
    }),
  },
}));
