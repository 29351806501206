/* eslint-disable @typescript-eslint/no-unused-vars */
import { createStyles, makeStyles } from "@mui/styles";
import { CreateOutlined } from "@mui/icons-material";
import LoadingSpinner from "../components/LoadingSpinner";
import PageContent from "../components/pageContent";
import Table from "../components/Table2";
import TableLink from "../components/TableLink";
import React, { useEffect, useState } from "react";
import {
  DataTableAction,
  DataTableColumn,
  DataTableRow,
} from "../../utils/types";
import useFetch from "../../utils/useFetch";
import { User } from "./dashboard-logic/dashboardSlice";
import moment from "moment";
import { Box, Link, Theme } from "@mui/material";
import SelectTypeOfService from "../components/selectTypeOfService";
import InlineSearchBar from "../components/Search/InlineSearchBar2";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
    },
    name: {
      textTransform: "capitalize",
    },
    formField: {
      marginLeft: "4px",
      marginTop: "30px",
      [theme.breakpoints.down("md")]: {
        width: "20%",
        alignContent: "center ",
      },
    },
    searchBox: {
      padding: theme.spacing(2, 0),
      width: "99%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  })
);

type Props = {
  roleName?: string;
  roleId?: string;
  userTypeCode?: string;
};
const UsersList: React.FC<Props> = ({ userTypeCode }: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [query, setQuery] = useState("");
  const [user, setUser] = useState<User>();
  const [editing, setEditing] = useState(false);
  const [value, setValue] = React.useState<string>("");
  const [inputValue, setInputValue] = React.useState("");

  // useFetch(`/${subset}`, `fetchBreeds-${subset}`);
  const { data, isLoading, isError, error, isRefreshing, isSuccess } = useFetch(
    `api/v1/checkups/patient/Patients`,
    ["user"]
  );

  const list = data?.map((item: { typeOfService: any }) => item.typeOfService);
  let service = new Set(list);
  let serviceList = [...(service as any)];
  const [updatedData, setUpdatedData] = useState([]);
  let optionsData;
  value?.length <= 0 || value == null
    ? (optionsData = data?.data || data || [])
    : (optionsData = data.filter((data: any) =>
        data.typeOfService?.includes(value)
      ));

  //const optionsData = data?.data || data || [] ;
  const singleUser = useFetch(
    `/auth/get-single-user/?usercode=${user?.userCode}`,
    ["user", `${user?.userCode}`],
    { enabled: !!user?.userCode }
  );

  const handleEditRow = (row: any) => {
    setUser(row);
    setEditing(true);
    setOpen(true);
  };

  const actions: DataTableAction[] = [
    {
      label: "Edit user",
      icon: () => <CreateOutlined />,
      onClick: (row: any) => {
        handleEditRow(row);
      },
      permission: ["ACTIVE"].includes(data?.status?.toUpperCase())
        ? "ACTION-NOT-ALLOWED"
        : "",
    },
  ];

  const columns: DataTableColumn[] = [
    {
      id: "fullName",
      label: "Client Name",
      minWidth: 150,
      format: (value: any, row: any) => (
        <TableLink path={`/clientdetails/${row.id}`}>{value}</TableLink>
      ),
    },
    {
      id: "typeOfService",
      label: "Service Type",
      format: (value: any) => value?.toLowerCase() || "-",
    },
    {
      id: "Intake",
      label: "Intake",
      format: (value: any) => value?.toLowerCase() || "-",
    },
    {
      id: "appointmentDate",
      label: "DepartureTime",
      minWidth: 50,
      format: (value: any) => moment(value)?.format("DD-MM-YYYY"),
    },
    {
      id: "appointmentDate",
      label: "ArrivalTime",
      minWidth: 150,
    },
    {
      id: "prescriptionID",
      label: "Prescription",
      format: (value: any, row: any) => (
        <>
          {value?.length > 1 ? (
            <Link
              target="_blank"
              href={`http://197.248.142.25:1005/Medication/PrescriptionPDF?PrescriptionID=${value}`}
            >
              View File
            </Link>
          ) : (
            <span>....</span>
          )}
        </>
      ),
    },
    {
      id: "claimID",
      label: "Claim",
      format: (value: any, row: any) => (
        <>
          {value?.length > 1 ? (
            <Link
              target="_blank"
              href={`http://197.248.142.25:1005/Medication/ClaimPDF?ClaimID=${value}`}
            >
              View_File
            </Link>
          ) : (
            <span>....</span>
          )}
        </>
      ),
    },
    {
      id: "userCode",
      label: "Invoice",
      minWidth: 150,
    },
    {
      id: "scheme",
      label: "Scheme",
      minWidth: 150,
    },
    {
      id: "paymentMethod",
      label: "Payment",
      minWidth: 150,
    },
    {
      id: "isSynced",
      label: "Zidi sync",
      minWidth: 150,
    },
    {
      id: "userCode",
      label: "Medical Sent",
      minWidth: 150,
    },
    {
      id: "userCode",
      label: "Follow Up Date",
      minWidth: 150,
    },
  ];

  const rows: DataTableRow[] = isSuccess
    ? userTypeCode
      ? updatedData
      : updatedData
    : [];

  const [searched, setSearched] = useState<string>("");

  useEffect(() => {
    value?.length <= 0 || value == null
      ? setUpdatedData(data?.data || data || [])
      : setUpdatedData(
          data.filter((data: any) => data.typeOfService.includes(value))
        );
  }, [data, value]);

  return (
    <PageContent>
      <div className={classes.root}>
        {/* <PageHeading
          title="Clients Accounts"
          links={links}
          setupPathname="/setup/users"
        /> */}

        {isLoading ? <LoadingSpinner /> : ""}
        {/* {isFetching ? <RefreshingCard message="Refreshing Clients" /> : <></>} */}
        <div className={classes.formField}>
          <SelectTypeOfService
            label="Type of Service"
            setInputValue={setInputValue}
            setValue={setValue}
            inputValue={inputValue}
            value={value}
            serviceList={serviceList}
          />
        </div>
        {isSuccess ? (
          <>
            {/* <TableHeader
            onClickAddNew={handleClickNewBtn}
            onSearch={setQuery}
            query={query}
            addNewLabel={roleName ? "Add user" : "create Client"}
            addNewPermission=""
          /> */}
            <Box className={classes.searchBox}>
              <Box display="flex" alignItems="center">
                <InlineSearchBar
                  onSearch={setQuery}
                  query={query}
                  setUpdatedData={setUpdatedData}
                  setSearched={setSearched}
                  updatedData={updatedData}
                  searched={searched}
                />
              </Box>
            </Box>
            <Table
              columns={columns}
              rows={rows?.map((r, index) => ({ ...r, number: index + 1 }))}
              actions={actions}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              pageCount={data?.totalPage}
              rowColor={""}
            />
          </>
        ) : (
          ""
        )}
      </div>
      {/* <CreateUser
        queryString="clients"
        open={open}
        setOpen={setOpen}
        editing={editing}
        setEditing={setEditing}
        setEditLoading={singleUser.isLoading}
        setEditError={singleUser.isError}
      /> */}
    </PageContent>
  );
};

UsersList.defaultProps = {
  roleName: undefined,
  roleId: undefined,
  userTypeCode: undefined,
};

export default UsersList;
