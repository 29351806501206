/**
 * @description Filter an array by any string
 * @param {string} query
 * @param {array} data
 * @return {array} matched rows
 */
export const filterBySearch = (query: string, data: any[] = []): any[] =>
  query
    ? data.filter((c: any) =>
        JSON.stringify(c).toLowerCase().includes(query.toLowerCase()),
      )
    : data;