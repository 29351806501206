/* eslint-disable @typescript-eslint/no-unused-vars */
import { createStyles, makeStyles } from "@mui/styles";
import React, {  useState } from "react";
import {Box, Theme} from '@mui/material'
import { filterBySearch } from "../../../utils/filters";
import { DataTableColumn } from "../../../utils/types";
import { formatAsLocalDate } from "../../../utils/formatDates";
import TableHeader from "../../components/TableHeader";
import DataTable from "../../components/Table";
import EmptyList from "../../components/EmptyList";
import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from '@material-table/exporters';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
  }),
);

type BodyCompositionDetailsProps = {
  data: [];
};

const BodyCompositionDetails = ({
  data,
}: BodyCompositionDetailsProps) => {
  const classes = useStyles();

  const [query, setQuery] = useState('');

  const rows = filterBySearch(query, data);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedRow, setSelectedRow] = useState(null);

  const [columns, setColumns] = useState([
    {
      filed: 'bodyFat',
      title: 'Body fat',
      minWidth: 100,
      type: "string" as const
    },
    {
      filed: 'bodyWater',
      labtitleel: 'Body Water',
      minWidth: 100,
      type: "string" as const
    },
    {
      filed: 'subcutaneousFat',
      title: 'S Fat',
      minWidth: 50,
      type: "string" as const
    },
    {
      filed: 'metabolicAge',
      title: 'M Age',
      type: "string" as const,
      minWidth: 50,
    },

    {
      filed: 'boneDensity',
      title: 'Body density',
      minWidth: 50,
      type: "string" as const
    },
    {
      filed: 'visceralFat',
      title: 'V Fat',
      minWidth: 50,
      type: "string" as const
    },

      {
        filed: 'skeletalMuscle',
        title: 'S Muslcle',
        minWidth: 150,
        type: "string" as const
      },
      {
        filed: 'fatFree',
        title: 'Fat free',
        minWidth: 150,
        type: "string" as const
      },
      {
        filed: 'bmr',
        title: 'BMR',
        minWidth: 150,
        type: "string" as const
      },
      {
        filed: 'protein',
        title: 'Protein',
        minWidth: 150,
        type: "string" as const
      },
      {
        filed: 'muscleMass',
        title: 'Muscle Mass',
        minWidth: 150,
        type: "string" as const
      },
      {
        filed: 'takenBy',
        title: 'Taken By',
        minWidth: 150,
        type: "string" as const
      },
    {
      filed: 'takenOn',
      title: 'Date',
      minWidth: 150,
      type: "string" as const
     // format: (date) => formatAsLocalDate(date),
    },
  ]);

  return (
    <div className={classes.root}>
      <div>
        <TableHeader onSearch={setQuery} query={query} />
        {/* <DataTable
          page={page}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setPage={setPage}
          rows={rows?.map((r, index) => ({ ...r, number: index + 1 }))}
          columns={columns}
        /> */}

 {data ? (
        <>

            <MaterialTable
            title="Visit Summary List"
            columns={columns}
            data={data}
            actions={[]}
            options={ {
              columnsButton:true,
              exportMenu: [{
                label: 'Export PDF',
                exportFunc: (cols, datas) => ExportPdf(cols, datas, 'VisitRecordsReport')
              }, {
                label: 'Export CSV',
                exportFunc: (cols, datas) => ExportCsv(cols, datas, 'VisitRecordsReport')
              }],
              exportAllData: true,
              sorting: true,
              rowStyle: rowData => ({
                backgroundColor: (rowData.pharmacyBalance<=1000) ? '#00000' : (rowData.pharmacyBalance<0) ? '#01579b' : '#00000' 
              }),
              headerStyle: {
                backgroundColor: '#01579b',
                color: '#FFF',
              },}}

             />

        </>
      ) : (<EmptyList message="You may want to refine your filters" />)}
      </div>
    </div>
  );
};

export default BodyCompositionDetails;
