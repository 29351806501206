import { Avatar, IconButton,Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { isBefore } from "date-fns";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { useQueryClient } from "react-query";
import CustomImageUploader from "./ImageUpload";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(0),
    },
    iconButton: {
      width: 100,
      height: 100,
      backgroundColor: theme.palette.grey[300],
      cursor: "default",
      "&:hover": {
        backgroundColor: theme.palette.grey[300],
      },
    },
    icon: {
      fontSize: "2rem",
      color: theme.palette.common.black,
      cursor: "pointer",
    },
  })
);

type Props = {
  uploadPath: string;
  currentImages: { [key: string]: any }[] | string | Element[];
  width?: number;
  height?: number;
  queryNameToRefresh?: string | string[];
  // eslint-disable-next-line react/require-default-props
  icon?: any;
};

const UploadableImageContainer: React.FC<Props> = ({
  uploadPath,
  currentImages,
  width,
  height,
  queryNameToRefresh,
  icon: Icon,
}: Props) => {
  const classes = useStyles();
  const [uploadImage, setUploadImage] = useState(false);
  const queryClient = useQueryClient();

  const handleUploadImage = () => {
    setUploadImage(true);
  };

  const handleCancel = () => {
    setUploadImage(false);
  };

  const onSuccess = () => {
    queryClient.invalidateQueries(queryNameToRefresh);
    setUploadImage(false);
  };

  return (
    <span title="Click to change image">
      {uploadImage ? (
        <CustomImageUploader
          onSuccess={queryNameToRefresh ? onSuccess : undefined}
          handleCancel={handleCancel}
          path={uploadPath}
        />
      ) : (
        <IconButton onClick={handleUploadImage}>
          {typeof currentImages === "string" ||
          (typeof currentImages === "object" && !isEmpty(currentImages)) ? (
            <Avatar
              src={
                typeof currentImages === "string"
                  ? currentImages
                  : typeof currentImages === "object" &&
                    currentImages?.sort((a, b) =>
                      isBefore(new Date(a.dateCreated), new Date(b.dateCreated))
                        ? 1
                        : -1
                    )[0]?.filePath
              }
              variant="circular"
              className={classes.root}
              style={{ width, height }}
            />
          ) : (
            <IconButton className={classes.iconButton}>
              {Icon ? <Icon className={classes.icon} /> : <></>}
            </IconButton>
          )}
        </IconButton>
      )}
    </span>
  );
};

UploadableImageContainer.defaultProps = {
  width: 90,
  height: 90,
  queryNameToRefresh: "",
};

export default UploadableImageContainer;
