import { useContext } from "react";
import { InsuranceContext } from "./insurance-context";

export const useInsuranceContext = () => {
  const context = useContext(InsuranceContext);
  if (context === undefined) {
    throw new Error(
      "useInsuranceContext must be used within a VirtualCardContextProvider"
    );
  }
  return context;
};
