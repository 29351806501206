import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

import TopDisplay from "./components/top-display";
import AddNewPartner from "./add-new-partner";
import InsuranceTable from "./components/table";
import GlobalDrawer from "./components/drawer";
import { InsuranceProvider } from "./scheme-context";
import ModalCard from "./components/modal-card";
import { ModalProvider } from "./modal-context";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
  })
);

const InsuranceSetup = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <InsuranceProvider>
        <ModalProvider>
          <div
            style={{
              padding: "24px",
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
            }}
          >
            <TopDisplay
              title="Insurance Scheme"
              subTitle="View all your insurance providers"
            >
              <AddNewPartner />
            </TopDisplay>

            <InsuranceTable />
            <GlobalDrawer>
              <ModalCard />
            </GlobalDrawer>
          </div>
        </ModalProvider>
      </InsuranceProvider>
    </div>
  );
};

export default InsuranceSetup;
