import { Button, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { insuranceStyles } from "./components/styles.";
import useToggleDrawer from "../../utils/useToggleDrawer";
import { useContext } from "react";
import { ModalContext } from "./modal-context";

export default function AddNewPartner() {
  const { toggleDrawer } = useToggleDrawer();
  const { setModalOpen } = useContext(ModalContext);
  const style = insuranceStyles();
  return (
    <Button
      onClick={() => {
        toggleDrawer();
        setModalOpen({ action: "create" });
      }}
      variant="contained"
      className={(style.flexRowCenter, style.partnerButton)}
    >
      <AddIcon />
      <Typography
        className={style.textMdBold}
        sx={{
          color: "#fff",
        }}
      >
        Add new partner
      </Typography>
    </Button>
  );
}
