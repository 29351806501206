import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MenuItem, { menuItemClasses } from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import Chip from "@mui/material/Chip";
import { InputBase } from "@mui/material";
import { SelectOptions } from "./types";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      background: "#fff",
      borderRadius: "8px",
      border: "1px solid #0000330F",
      boxShadow: "0px 12px  32px -16px #0000330F",
      color: "#646464",
      padding: "0px",
    },
  },
};

type StyledSelectProps = {
  id: string;
  placeholder?: string;
  options: SelectOptions;
  error?: boolean;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  setOption: (event: SelectChangeEvent<string>) => void;
  defaultValue?: string;
};
export default function StyledSelect({
  id,
  placeholder = "Click to select",
  setOption,
  options,
  error,
  defaultValue,
  onBlur,
}: StyledSelectProps) {
  const [selectValue, setSelectValue] = React.useState("");

  const handleChange = (event: SelectChangeEvent<typeof selectValue>) => {
    const {
      target: { value },
    } = event;
    setSelectValue(value);
    setOption(event);
  };

  return (
    <Select
      error={error}
      labelId={`${id}-label`}
      id={id}
      sx={{
        "& .MuiSelect-select, & .MuiSelect-select-MuiInputBase-input": {
          height: "40px !important",
        },
        "& MuiSelect-select-MuiInputBase-input.MuiSelect-select": {
          height: "40px !important",
        },
        height: 40,
        "& .MuiSelect-nativeInput ": {
          height: "100%",
        },
      }}
      onBlur={onBlur}
      IconComponent={ExpandMoreOutlinedIcon}
      value={selectValue ?? defaultValue ?? ""}
      onChange={handleChange}
      input={<StyledInputBase />}
      displayEmpty
      renderValue={(selected) => {
        const val =
          options.find((n) => n.id === selected) ??
          options.find((n) => n.id === defaultValue);

        return (!selected || selected === "") && !defaultValue ? (
          <span
            style={{
              color: "#C2C2C2",
            }}
          >
            {placeholder}
          </span>
        ) : (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            <Chip label={!val && defaultValue ? defaultValue : val?.name} />
          </Box>
        );
      }}
      MenuProps={MenuProps}
    >
      {options.map((val) => (
        <MenuItem
          key={val.id}
          value={val.id}
          sx={{
            textTransform: "uppercase",
            display: "flex",
            alignItems: "flex-start !important",

            padding: "8px 12px",
            alignSelf: "stretch",
            background: "none",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 100,
            lineHeight: "20px",
            letterSpacing: "0px",
            justifyItems: "start",
            justifyContent: "start",

            "&:hover": {
              backgroundColor: "#e2e8f040",
            },
            "&:focus, &:focus-within, &:active": {
              backgroundColor: "#E2E8F0",
            },
            "&:active": {
              backgroundColor: "#e2e8f040",
            },
            [`& ${menuItemClasses.selected}`]: {
              backgroundColor: "#E2E8F0",
            },

            [`& ${menuItemClasses.root}`]: {
              textTransform: "capitalize",
              display: "flex",
              alignItems: "flex-start !important",

              padding: "8px 12px",
            },
          }}
        >
          {val.name}
        </MenuItem>
      ))}
    </Select>
  );
}

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: "100%",
  "& .MuiSelect-select, & .MuiSelect-select-MuiInputBase-input": {
    height: 40,
    display: "flex",
    alignItems: "center",
  },
  "&.MuiInputBase-root": {
    position: "relative",
    fontSize: 16,
    width: "100%",
    display: "flex",
    padding: "0px  8px",
    alignItems: "center",
    borderRadius: "6px",
    border: "1px solid rgba(0, 9, 50, 0.12)",
    background: "rgba(255, 255, 255, 0.90)",
    height: 40,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&.MuiInputBase-root.MuiInputBase-adornedStart": {
      display: "flex",
      gap: 5,
    },

    "&.MuiInputBase-root.Mui-focused, &.MuiInputBase-root.Mui-focused.Mui-error":
      {
        borderColor: "#4C9DF8",
      },
    "&.MuiInputBase-root.Mui-error": {
      borderColor: theme.palette.error.main,
    },
    // "&:focus": {
    //   boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    //   borderColor: theme.palette.primary.main,
    // },
    // ...theme.applyStyles("dark", {
    //   backgroundColor: "#1A2027",
    //   borderColor: "#2D3843",
    // }),
  },
}));
