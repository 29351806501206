import React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField, Box, Typography } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

interface DatePickerComponentProps {
  label: string;
  value: Date | null;
  onChange: (date: Date | null) => void;
}

const DatePickerComponent: React.FC<DatePickerComponentProps> = ({
  label,
  value,
  onChange,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box>
        <Typography variant="body1">{label}</Typography>
        <DatePicker
          value={value}
          onChange={onChange}
          maxDate={new Date()}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              sx={{
                "& .MuiOutlinedInput-root": {
                  height: "40px",
                },
                "& input": {
                  fontSize: "0.875rem",
                },
              }}
            />
          )}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default DatePickerComponent;
