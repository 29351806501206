import React from 'react';

interface CustomEventProps {
  event: {
    title: string;
    start: Date;
    end: Date;
  };
  refillsData: number;
  followUpData: number;
  bookingsData: number;
  reminderDate: Date;
}

const CustomEvent: React.FC<CustomEventProps> = ({ event,refillsData,followUpData,bookingsData,reminderDate}) => {
  const eventStyle: React.CSSProperties = {
    backgroundColor: '#022c5b',
    color: 'white',
    padding: '3px',
    paddingBottom:'25px'
  };

  return (
    <div style={eventStyle}>
      <div>
        <a href={`/refills/${reminderDate}`} style={{color:'White'}}>Refills: <span style={{color:'green', fontSize:'48'}}>{refillsData}</span> / {refillsData}</a>
      </div>
      <div>
        <a href={`/followupdetails/${reminderDate}`} style={{color:'White'}}>FollowUps: <span style={{color:'green', fontSize:'48'}}>{followUpData}</span> / {followUpData}</a>
      </div>
      <div>
        <a href={`/bookings/${reminderDate}`} style={{color:'White'}}>Bookings: <span style={{color:'green', fontSize:'48'}}>{bookingsData}</span> / {bookingsData}</a>
      </div>

    </div>
  );
};

export default CustomEvent;
