import { createContext, useCallback, useState } from "react";
import useToggleDrawer from "../../utils/useToggleDrawer";
import {
  useGetAllScheme,
  SchemeQueryParams,
  SchemeData,
} from "../../utils/useSchemeQuery";

import { PAGE_SIZE } from "./components/constant";
import { useLocalStorage } from "./useLocalStorageHook";
import { useSearchParams } from "react-router-dom";
const InsuranceContext = createContext<{
  toggleDrawer: () => void;
  drawerState: boolean;
  insuranceData?: SchemeData[];
  isLoading: boolean;
  pageSize: number;
  paginationModel?: Omit<SchemeQueryParams, "field" | "activeTab" | "clientId">;
  setPagination: (page: number) => void;
  handleSearch: (query: string) => void;
  handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
  search: string;
  page: number;
  tabIndex: number;
  activeTab: SchemeTabsType;
}>({
  toggleDrawer: () => {},
  drawerState: false,
  isLoading: false,
  pageSize: PAGE_SIZE,
  insuranceData: [],
  paginationModel: {
    // not currently used in the recent implementation
    pageSize: PAGE_SIZE,
    page: 0,
  },
  page: 0, // temporary may be removed
  setPagination: () => {},
  handleSearch: () => {},
  handleTabChange: () => {},
  search: "",
  tabIndex: 0,
  activeTab: "all",
});

type ContextProps = {
  children?: React.ReactNode;
};
type SchemeTabsType = "all" | "active" | "inactive";
type SchemeTabsMap = {
  [k in SchemeTabsType]: number;
};
const schemeTabMap: SchemeTabsMap = {
  all: 0,
  active: 1,
  inactive: 2,
};

const InsuranceProvider = ({ children }: ContextProps) => {
  const { toggleDrawer, drawerState } = useToggleDrawer();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: PAGE_SIZE,
    page: 0,
  });
  let [searchParams, setSearchParams] = useSearchParams();
  const clientId = sessionStorage.getItem("clientId");
  // get tabs from query params
  const params = searchParams.get("tab") as SchemeTabsType | null;
  // check if a valid tab params
  const isValidTab =
    params === "all" || params === "active" || params === "inactive";
  //get tab index
  const i = isValidTab ? schemeTabMap[params] : 0;
  // get tabs from locastorage if any
  const [storage, updateStorage] = useLocalStorage<number>("scheme-tab", 0);
  //  tab state index
  const tabIndex = isValidTab ? i : storage;
  const activeTab = isValidTab
    ? (Object.keys(schemeTabMap)[tabIndex] as SchemeTabsType)
    : "all";

  // create search state
  const [search, setSearch] = useState("");

  const { isLoading, isFetching, data } = useGetAllScheme({
    clientId,
    activeTab,
    field: search,
    ...paginationModel,
  });

  // const {
  //   isFetching: inactiveFetching,
  //   isLoading: inactiveLoading,
  //   data: inactivedata,
  // } = useGetAllInactiveScheme({
  //   clientId,
  //   activeTab,
  //   field: search,
  //   ...paginationModel,
  // });

  // const {
  //   isFetching: activeFetching,
  //   isLoading: activeLoading,
  //   data: activedata,
  // } = useGetAllActiveScheme({
  //   clientId,
  //   activeTab,
  //   field: search,
  //   ...paginationModel,
  // });

  // const getIsLoading = useCallback(
  //   (tab: SchemeTabsType) => {
  //     return tab === "all"
  //       ? isLoading || isFetching
  //       : tab === "active"
  //       ? activeFetching || activeLoading
  //       : inactiveFetching || inactiveLoading;
  //   },
  //   [
  //     activeFetching,
  //     activeLoading,
  //     inactiveFetching,
  //     inactiveLoading,
  //     isFetching,
  //     isLoading,
  //   ]
  // );
  const handleReset = useCallback(() => {
    setPaginationModel((prev) => ({ ...prev, page: 1 }));
    setSearch("");
  }, []);

  const handleTabChange = useCallback(
    (_event: React.SyntheticEvent, newValue: number) => {
      updateStorage(newValue);
      setSearchParams(
        { tab: Object.keys(schemeTabMap)[newValue] },
        { replace: true }
      );
      handleReset();
    },
    [handleReset, updateStorage, setSearchParams]
  );

  const handleSearch = useCallback((query: string) => {
    setSearch(query);
    setPaginationModel((prev) => ({ ...prev, page: 1 }));
  }, []);

  const setPagination = useCallback((page: number) => {
    setPaginationModel((prev) => ({ ...prev, page }));
  }, []);

  return (
    <InsuranceContext.Provider
      value={{
        toggleDrawer,
        drawerState,
        isLoading: isFetching || isLoading,
        insuranceData: data?.data ?? [],
        pageSize: ((data?.totalPages ?? 1) - 1) % PAGE_SIZE,
        page: paginationModel.page,
        setPagination,
        handleSearch,
        handleTabChange,
        search,
        tabIndex,
        activeTab,
      }}
    >
      {children}
    </InsuranceContext.Provider>
  );
};

export { InsuranceContext, InsuranceProvider };
