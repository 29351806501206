import React, { useState } from "react";
import { Box, Button, TextField } from "@mui/material";
import { useSignalR } from "./SignalRContext";

interface MessageInputProps {
  selectedUser: string;
  senderPhoneNumber: string;
  senderName: string;
}

const MessageInput: React.FC<MessageInputProps> = ({ selectedUser, senderPhoneNumber, senderName }) => {
  const { connection } = useSignalR();
  const [content, setContent] = useState("");
  const phoneNumberId = sessionStorage.getItem("phoneNumberId");

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (connection) {
      try {
        await connection.invoke(
          "SendMessage",
          selectedUser,
          content,
          phoneNumberId
        );
        setContent("");
      } catch (error) {
        console.error("Failed to send message:", error);
      }
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      style={{ padding: "16px", display: "flex", alignItems: "center" }}
    >
      <TextField
        value={content}
        onChange={(e) => setContent(e.target.value)}
        placeholder="Type your message..."
        variant="outlined"
        fullWidth
        style={{ marginRight: "8px" }}
      />
      <Button type="submit" variant="contained" color="primary">
        Send
      </Button>
    </Box>
  );
};

export default MessageInput;
