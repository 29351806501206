import PhoneInputWithCountrySelect from "react-phone-number-input";
import { StyledInputBase } from "./select";
import { forwardRef } from "react";
import { Box, InputLabel } from "@mui/material";
import { styled } from "@mui/material/styles";

type PhonenputProps = {
  label: string;
  placeholder: string;
  name?: string;
  id: string;
  value: string;
  setValue: (e?: string) => void;
};
export default function PhoneInput({
  value,
  setValue,
  id,
  placeholder,
  label,
}: PhonenputProps) {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "4px",
        "& .PhoneInput": {
          position: "relative",
          fontSize: 16,
          width: "100%",
          display: "flex",
          padding: "0px  8px",
          alignItems: "center",
          borderRadius: "6px",
          border: "1px solid rgba(0, 9, 50, 0.12)",
          background: "rgba(255, 255, 255, 0.90)",
          height: 40,
        },
        "& .PhoneInputCountry": {
          borderRight: "1px solid rgba(0, 9, 50, 0.12)",
          paddingRight: "8px",
        },
        "& .PhoneInput input": {
          border: "none",
          outline: "none",
          background: "transparent",
          fontSize: 16,
          width: "100%",
          height: "100%",
        },
      }}
    >
      <InputLabel htmlFor={id} id={`${id}-label`}>
        {label}
        <span style={{ color: "#DC3E42", fontSize: "18px" }}>*</span>
      </InputLabel>
      <PhoneInputWithCountrySelect
        defaultCountry="KE"
        placeholder={placeholder}
        value={value}
        onChange={setValue}
      />
    </Box>
  );
}

type InputProps = {
  value: string;
  onChangeText: (value: string) => void;
};
const CustomPhoneInput = forwardRef(
  ({ value, onChangeText }: InputProps, ref) => {
    console.log();
    return <StyledInputBase autoComplete="tel" ref={ref}></StyledInputBase>;
  }
);
