import { createStyles, makeStyles } from "@mui/styles";
import React, {  useState } from "react";
import {Button, Paper, Table, TableBody, TableCell, TableHead, TableRow, Theme} from '@mui/material'
import { filterBySearch } from "../../utils/filters";
import { DataTableColumn } from "../../utils/types";
import { formatAsLocalDate } from "../../utils/formatDates";
import TableHeader from "../components/TableHeader";
import DataTable from "../components/Table";
import CreateSampleCollection from "./CreateSampleCollection";
import useFetch from "../../utils/useFetch";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
    tableRightBorder: {
      borderWidth: 0,
      borderRightWidth: 1,
      borderColor: 'black',
      borderStyle: 'solid',
  },
  }),
);

type SampleCollectionDetailsProps = {
  Clientdata: [];
};

const SampleCollectionDetails = ({
  Clientdata,
}: SampleCollectionDetailsProps) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { id }: any = useParams();
//  const {
//     data,
//     isLoading,
//     isError,
//     error,
//     isRefreshing,
//   } = useFetch(`api/Reports/get-Scope?NRC_Report_Id=${id}`, ["scope", id]);

  const {
    data,
    isLoading,
    isError,
    error,
    isRefreshing,
  } = useFetch(`api/Reports/get_sampleCollectionReporting?NRCReportID=${id}`, ["screensummary", id]);
  // const DATA= useFetch(`api/Reports/get-Scope?NRC_Report_Id=${id}`,["scope",id]);
  // const newData=DATA?.data;
  const [query, setQuery] = useState('');

  const rows = filterBySearch(query, data);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  

 const obj=Clientdata?.map((newDta:any)=> newDta);
 //const scope=data?.map((DATA:any)=> DATA);
  return (
    <div className={classes.root}>
      <div>
      <Paper variant="outlined" square > 
      <Button
            color="secondary"
            variant="outlined"
            onClick={handleOpen}
            style={{backgroundColor:'purple',color:'white'}}
          >
          UPDATE
          </Button>
          <CreateSampleCollection
        queryString="wellness"
        open={open}
        setOpen={setOpen}
      />
      </Paper>
       <Paper variant="outlined" square >
        <Table sx={{ minWidth: 650 }} aria-label="caption table">
        <TableHead>
        </TableHead>
        <TableBody>
            <TableRow key="index">
              <TableCell component="th" variant="body" scope="row" className={classes.tableRightBorder}>
                SAMPLE COLLECTION DATE
              </TableCell>
              <TableCell  variant="body">{formatAsLocalDate(data?.sampleCollectionDate)}</TableCell>
            </TableRow>
            <TableRow key="index">
              <TableCell component="th" scope="row" className={classes.tableRightBorder}>
                SAMPLE LABELING
              </TableCell>
              <TableCell >{data?.sampleLabelling}</TableCell>
            </TableRow>
            <TableRow key="index">
              <TableCell scope="row" className={classes.tableRightBorder}>
                STAFF INVOLED
              </TableCell>
              <TableCell >{data?.staffInvolved}</TableCell>
            </TableRow>
            <TableRow key="index">
              <TableCell component="th" scope="row" className={classes.tableRightBorder}>
                SAMPLE TRANSPORT
              </TableCell>
              <TableCell >{data?.sampleTransport}</TableCell >
            </TableRow>
            <TableRow key="index">
              <TableCell component="th" scope="row" className={classes.tableRightBorder}>
                SAMPLE ANALYSIS DATE
              </TableCell>
              <TableCell >{formatAsLocalDate(data?.sampleAnalysisDate)}</TableCell>
            </TableRow>
            <TableRow key="index">
              <TableCell component="th" scope="row" className={classes.tableRightBorder}>
                METHODOLOGY USED
              </TableCell>
              <TableCell >{data?.methodologyUsed}</TableCell>
            </TableRow>
            <TableRow key="index">
              <TableCell component="th" scope="row" className={classes.tableRightBorder}>
                SAMPLE HANDLING
              </TableCell>
              <TableCell >{data?.sampleHandling}</TableCell>
            </TableRow>
            <TableRow key="index">
              <TableCell component="th" scope="row" className={classes.tableRightBorder}>
                MACHINE(S) USED
              </TableCell>
              <TableCell >{data?.machinesUsed}</TableCell>
            </TableRow>
            <TableRow key="index">
              <TableCell component="th" scope="row" className={classes.tableRightBorder}>
                QUALITY ASSURANCE CHECK DONE ON AND BY
              </TableCell>
              <TableCell >{formatAsLocalDate(data?.assuranceCheckDoneOn)} || {data?.assuranceCheckDoneBy}</TableCell>
            </TableRow>
            <TableRow key="index">
              <TableCell component="th" scope="row" className={classes.tableRightBorder}>
                REVIEW/ANALYSIS AND RELEASE OF RESULTS TIMELINES
              </TableCell>
              <TableCell >{formatAsLocalDate(data?.resultsTimeline)}</TableCell>
            </TableRow>
        </TableBody>
      </Table>
      </Paper>
      </div>

    </div>
  );
};

export default SampleCollectionDetails;
