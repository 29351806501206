import React from "react";
import { TextField, Box, Typography } from "@mui/material";

interface LabelInputFieldProps {
  name?: string;
  label: string;
  value: string;
  placeholder?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
}

const LabelInputField: React.FC<LabelInputFieldProps> = ({
  name,
  label,
  value,
  placeholder,
  onChange,
  disabled,
  ...textFieldProps
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        mb: 2,
      }}
    >
      <Typography variant="body1">{label}</Typography>
      <TextField
        name={name}
        variant="outlined"
        fullWidth
        sx={{
          backgroundColor: "white",
          "& .MuiOutlinedInput-root": {
            backgroundColor: "transparent",
            height: "40px",
          },
        }}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        {...textFieldProps}
      />
    </Box>
  );
};

export default LabelInputField;
