import {  Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Paper,Radio,RadioGroup,Snackbar, TextField, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Alert } from "@mui/lab";
import GenericDialog from "../components/GenericDialog";
import LoadingSpinner from "../components/LoadingSpinner";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useAppDispatch } from "../../utils/hooks";
import ReactQuill from 'react-quill';
import { ErrorType } from "../../utils/types";
import usePostRequest from "../../utils/usePostRequest";
import { createUserSuccess } from "../agent-accounts/agent-accounts-logic/profileSlice";
import SelectFromApi from "../components/SelectFromApi";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
    },

    inputStyle: {
      width: 400,
      marginBottom: theme.spacing(2),
    },
    autoComplete: {
      marginBottom: theme.spacing(1),
    },
    main: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
      zIndex: theme.zIndex.appBar - 1,
      outline: "none",
    },
    grid: {
      display: "grid",
      gridTemplateColumns: "1fr",
    },
    paper: {
      padding: theme.spacing(2),
      // textAlign: "center",
      //color: theme.palette.text.secondary,
      width: 'auto',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      color: 'black !important',
      fontFamily: "ChronicaPro,sans-serif",
      fontSize:"16px",
      fontWeight: 400,
    },
    formCustomTitle: {
      fontWeight:700,
      color: "#449afe",
      fontSize:"1.3rem",
      fontFamily: "ChronicaPro,sans-serif",
      width: 400,
    },
    CustomTitle: {
      fontWeight:700,
      color: "#022c5b",
      fontSize:"1.0rem! important",
      fontFamily: "ChronicaPro,sans-serif",
      width: 400,
    },
    inputLabel: {
      "&.focused": {
        color: "#449afe",
      },
      color: "#449afe",
    },
    errorText: {
      color: 'red',
      fontSize: 'small',
    },
      mapContainer: {
        width: '100%',
        marginTop: '10px',
      },
  })
);



type SendVisitSummaryProps = {
  queryString: string;
  open: boolean;
  clientName: string;
  visitSummary: string
  cycleID: string;
  email: string;
  phoneNumber: string;
  // eslint-disable-next-line no-unused-vars
  setOpen: (value: boolean) => void;
  // eslint-disable-next-line no-unused-vars
};

function SendVisitSummary({
  queryString,
  open,
  setOpen,
  clientName,
  visitSummary,
  cycleID,
  email,
  phoneNumber,
}: SendVisitSummaryProps) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const [openSnack, setOpenSnack] = useState(true);
  
  const { handleSubmit, reset } = useForm();
  const [postError, setPostError] = useState<false | ErrorType>(false);
  const [contactNumber, setContactNumber] = useState<string>("");
  const [newEmail, setEmail] = useState<string>("");
  const [ccEmail, setCCEmail] = useState<string>("");
  const [comment, setComment] = useState<string>("");
  const [doctorInterpretation, setDoctorInterpretation] = useState<string>("");
  
  const [emailChecked, setEmailChecked] = useState(false);
  const [whatsappChecked, setWhatsappChecked] = useState(true);
  const [visitTypeErr, setVisitTypeErr] = useState<false | ErrorType>(false);
  const [assignedTo, setassignedToID] = useState<string>("");

  var UserID =sessionStorage.getItem('UserID');

  useEffect(()=>{
  setContactNumber(phoneNumber);
  },[phoneNumber]);

  useEffect(()=>{
    setEmail(email);
  },[email]);

  const onSuccess = ({ data }: any) => {
    queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.includes("Orders"),
    });
    dispatch(createUserSuccess(data));
    setOpen(false);
    queryClient.invalidateQueries(`${queryString}`);
    
  };
  const onFailure = (err: ErrorType) => {
    setPostError(err);
  };


  const handleCheckboxChange = (event:any) => {
    const { name, checked } = event.target;

    if (name === 'email') {
      setEmailChecked(checked);
    } else if (name === 'whatsapp') {
      setWhatsappChecked(checked);
    }
  };
    
  const handleAssigned = (newValue: any) => {
    setassignedToID(String(newValue?.userID));
  };

  
  const handleChange = (event:any) => {
    const { name, value } = event.target;
    switch (name) {
      case 'contactNumber':
        setContactNumber(value);
        break;
        case 'email':
          setEmail(value);
          break;
        case 'ccemail':
          setCCEmail(value);
          break;
          case 'comment':
            setComment(value);
            break;
          case 'doctorInterpretation':
            setDoctorInterpretation(value);
            break;
        default:
          break;
      }
    };
    

  const mutation = usePostRequest(
    "api/v1/checkups/tickets/send-re",
    {
      cycleID,
      userID:UserID,
      clientName,
      visitSummary,
      phoneNumber:contactNumber,
      email:newEmail,
      comment,
      ccEmail,
      isEmail:emailChecked ? 1 : 0,
      isWhatsApp: 1,
      status:2,
      isInterpretation:doctorInterpretation ? doctorInterpretation :'No',
      assignedTo: assignedTo.toString()? assignedTo: '0'
    },
    onSuccess,
    onFailure
  );
  const onSubmit = () => {
    mutation.reset();
    mutation.mutate();
  };


  const handleClose = () => {
    mutation.reset();
    reset();
  };

  const actions = [
    {
      label: 'Send Now',
      primary: true,
      onClick: handleSubmit(onSubmit),
    },
  ];

  return (
    <div>
      {mutation.isSuccess && (
        <Snackbar
          open={openSnack}
          onClose={() => setOpenSnack(false)}
          autoHideDuration={6000}
        >
          <Alert onClose={handleClose} severity="success">
              "Summary Send Successfully "
          </Alert>
        </Snackbar>
      )}

      <GenericDialog
        title='Send Visit Summary'
        showDialog={open}
        onCloseDialog={() => {
          setOpen(false);
        }}
        actions={actions}
        isLoading={mutation.isLoading}
        width="sm"
        height={400}
        verticalAlign="flex-start"
      >
       
          {mutation.isLoading ? <LoadingSpinner /> : <></>}
          {/* <div className={classes.grid}> */}
            {postError ? (
              <Alert severity="error" className="error-container">
                {postError?.message}
              </Alert>
            ) : (
              <></>
            )}
            
            <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>    

        <Grid item xs={12} sm={12}>

        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            name="clientName"
            label="Client Name"
            value={clientName}
            onChange={handleChange}
            fullWidth
            multiline
            disabled
          />
          </Grid>
         <Grid item xs={12} sm={12} sx={{mt:2}}>
          <FormLabel component="legend">Visit Summary</FormLabel>
          <TextField
            name="visitSummary"
            label="Visit Summary"
            value={visitSummary}
            onChange={handleChange}
            fullWidth
            multiline
            disabled
          />
          </Grid>
          <Grid item xs={12} sm={12}>
        <FormControl component="fieldset">
            <FormLabel component="legend">Do you want to edit visit summary?</FormLabel>
            <RadioGroup
              name="doctorInterpretation"
              value={doctorInterpretation}
              onChange={handleChange}
              row
            >
              <FormControlLabel
                value="Yes"
                control={<Radio />}
                label="Yes"
              />
              <FormControlLabel
                value="No"
                control={<Radio />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        {doctorInterpretation==="Yes" ?(
        <Grid item xs={12} sm={12} mt={2}>
          <FormLabel component="legend">Edit Visit Summary</FormLabel>
          <ReactQuill
                  value={comment}
                  onChange={handleChange}
                  modules={{
                    toolbar: [
                      [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
                      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                      ['bold', 'italic', 'underline', 'strike'],
                      [{ 'align': [] }],
                      ['link', 'image'], // image for file attachment
                      ['clean'] // remove formatting button
                    ]
                  }}
                  formats={[
                    'header', 'font', 'list', 'bold', 'italic', 'underline', 'strike', 'align', 'link', 'image'
                  ]}
                  style={{
                    height: '200px',  // Increase height
                    width: '100%'  // Keep width at 100%
                  }}
                />
        </Grid>

        
        ):(<></>)}
          <Grid item xs={12} sm={12} sx={{mt:2}}>
    <FormGroup row>
      <FormControlLabel
        control={
          <Checkbox
            checked={emailChecked}
            onChange={handleCheckboxChange}
            name="email"
            color="primary"
          />
        }
        label="Email"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={whatsappChecked}
            onChange={handleCheckboxChange}
            disabled
            name="whatsapp"
            color="primary"
          />
        }
        label="WhatsApp"
      />
    </FormGroup>
    </Grid>
    {whatsappChecked && (
          <Grid item xs={12} sm={12} sx={{mt:2}}>
          <TextField
            name="contactNumber"
            label="Phone Number"
            value={contactNumber}
            onChange={handleChange}
            required
            fullWidth
          />      
          {/* {invoiceError && <p style={{ color: 'red' }}>{invoiceError}</p>} */}
          </Grid>
          )}
         {emailChecked && (
           <>
          <Grid item xs={12} sm={12} sx={{mt:2}}>
          <TextField
            name="email"
            label="Email"
            value={newEmail}
            onChange={handleChange}
            fullWidth
          />
          {/* {invoiceError && <p style={{ color: 'red' }}>{invoiceError}</p>} */}
          </Grid>
          <Grid item xs={12} sm={12} sx={{mt:2}}>
          <TextField
            name="ccemail"
            label="CC Email"
            value={ccEmail}
            onChange={handleChange}
            fullWidth
          />
          {/* {invoiceError && <p style={{ color: 'red' }}>{invoiceError}</p>} */}
          </Grid>
          </>
         )}
     
          <Grid item xs={12} sm={12} sx={{mt:2}}>
          <TextField
            name="comment"
            label="Comment"
            value={comment}
            onChange={handleChange}
            fullWidth
          />
          </Grid>
          </Paper>
          </Grid>
          </Grid>
        
        
          {/* </div> */}
  
      </GenericDialog>

  
    </div>
  );
}

export default SendVisitSummary;
