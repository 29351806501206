import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { apiStatus } from "../../../utils/helpers";
import { ErrorType } from "../../../utils/types";


export interface CurrentUser {
    jwtToken?: string;
}

interface UserProfileState {
  user: CurrentUser;
  status: string | number;
  error: ErrorType | boolean;
  selectedUser: CurrentUser;

}

const initialState: UserProfileState = {
  user: {
    jwtToken: "",
  },

  status: apiStatus.initial,
  error: false,
  selectedUser: {
    jwtToken: "",
  },
};

export const profileSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setSelectedUser: (state, { payload }) => {
      state.selectedUser = payload;
    },
  },
});

export const {
  setSelectedUser
} = profileSlice.actions;

export default profileSlice.reducer;
