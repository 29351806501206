import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { apiStatus } from "../../../utils/helpers";
import { ErrorType } from "../../../utils/types";

export interface User {
  agentCode?: string;
}

interface UserProfileState {
  user: User;
  status: string | number;
  error: ErrorType | boolean;
  imageError: boolean | object;
  selectedUser: User;
}

const initialState: UserProfileState = {
  user: {
    agentCode: "",
  },
  status: apiStatus.initial,
  error: false,
  imageError: false,
  selectedUser: {
    agentCode: "",
  },
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    createUserSuccess: (state, { payload }: PayloadAction<object>) => {
      state.user = payload;
      state.status = apiStatus.ok;
      state.error = false;
    },
    createUserFailure: (state, { payload }: PayloadAction<object>) => {
      state.error = payload;
      state.status = apiStatus.error;
    },
    setImageError: (state, action: PayloadAction<object>) => {
      state.imageError = action.payload;
    },
    clearError: (state) => {
      state.error = false;
      state.status = apiStatus.initial;
    },
    setSelectedUser: (state, { payload }) => {
      state.selectedUser = payload;
    },
  },
});

export const {
  createUserSuccess,
  createUserFailure,
  setImageError,
  clearError,
  setSelectedUser,
} = profileSlice.actions;

export default profileSlice.reducer;
