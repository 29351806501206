import { createStyles, makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { Theme, Grid, Typography, Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "../components/LoadingSpinner";
import DataPointGrid, { YearlyData } from "../components/DataPointGrid";
import { TableColumn } from "react-data-table-component";
import PaginatedDataTable from "./components/paginated_datatable";
import SearchBar from "./components/searchbar";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
  })
);

const AdminInsurancePatients = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const classes = useStyles();
  const [query, setQuery] = useState<string>("");
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalRecords, setTotalRecords] = useState(0);
  const [yearlyData, setYearlyData] = useState<YearlyData>({
    yearlyEnrollmentCount: 0,
    yearlyOrderCount: 0,
    yearlyTransaction: 0,
    yearlyTotalSaving: 0,
    yearlyBaselineAcceptanceCount: 0,
    yearlyRefillRate: 0,
    yearlyDeclineRate: 0,
    yearlyLostFollowUpRate: 0,
  });
  const [insuranceName, setInsuranceName] = useState("");

  interface ROW_DATA {
    enrollmentID: string;
    firstName: string;
    lastName: string;
    scheme: string;
    diagnosis: string;
    item: string;
  }

  const handlePageChange = (page: number) => {
    setPageNumber(page);
  };

  const handlePerRowsChange = (newPerPage: number) => {
    setPageSize(newPerPage);
    setPageNumber(1);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_API_URL3}api/InsuranceEnrollment/insurance-by-id?insuranceId=${id}`,
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          }
        );
        if (!response.ok) throw new Error("Network response was not ok");
        const result = await response.json();
        setInsuranceName(result.description);
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const field = query || "all";

        const response = await fetch(
          `${process.env.REACT_APP_BASE_API_URL3}api/InsuranceEnrollment/paged-enrollments-byinsuranceid?pageNumber=${pageNumber}&pageSize=${pageSize}&insuranceId=${id}&field=${field}`,
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          }
        );

        if (!response.ok) throw new Error("Network response was not ok");
        const result = await response.json();
        setTotalRecords(result.totalRecords);

        const formattedData = result.data.map(
          (item: {
            insuranceEnrollmentDetails: any[];
            enrollmentDiagnosisEntities: any[];
            enrollmentID: any;
          }) => ({
            ...item,
            item: item.insuranceEnrollmentDetails
              .map((detail) => detail.itemDescription)
              .join(", "),
            diagnosis: item.enrollmentDiagnosisEntities
              .map((diagnosis) => diagnosis.diagnosisName)
              .join(", "),
          })
        );
        setData(formattedData);
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id, pageNumber, pageSize, query]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_API_URL3}api/InsuranceEnrollment/insurance-summary?insuranceId=${id}`,
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          }
        );
        if (!response.ok) throw new Error("Network response was not ok");
        const result = await response.json();
        setYearlyData(result);
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const columns: TableColumn<ROW_DATA>[] = [
    {
      name: "S/N",
      sortable: true,
      cell: (_row: ROW_DATA, index: number) => {
        return (pageNumber - 1) * pageSize + index + 1;
      },
      width: "7rem",
    },
    {
      name: "Patient ID",
      selector: (row: ROW_DATA) => row.enrollmentID,
      sortable: true,
      width: "7rem",
    },
    {
      name: "First Name",
      selector: (row: ROW_DATA) => row.firstName,
      sortable: true,
      width: "7rem",
    },
    {
      name: "Last Name",
      selector: (row: ROW_DATA) => row.lastName,
      sortable: true,
      width: "7rem",
    },
    {
      name: "Scheme",
      selector: (row: ROW_DATA) => row.scheme,
      sortable: true,
      width: "7rem",
    },
    {
      name: "Diagnosis",
      selector: (row: ROW_DATA) => row.diagnosis,
      sortable: true,
      width: "20rem",
    },
    {
      name: "Item",
      selector: (row: ROW_DATA) => row.item,
      sortable: true,
      width: "20rem",
    },
    {
      name: "Action",
      width: "7rem",
      cell: (row: ROW_DATA) => (
        <button
          onClick={() => handleViewClick(row.enrollmentID)}
          style={{
            cursor: "pointer",
            backgroundColor: "#00a6fb",
            color: "white",
            border: "none",
            padding: "5px",
          }}
        >
          View
        </button>
      ),
      sortable: true,
    },
  ];

  const handleViewClick = (id: any) => {
    navigate(`/enroll-details/${id}`);
  };

  if (isLoading) return <LoadingSpinner />;

  return (
    <div className={classes.root}>
      <div>
        <Box sx={{ flexGrow: 1, mt: 4, pl: 2, pr: 2 }}>
          <Grid container alignItems="center">
            {/* Column with texts aligned to the far left */}
            <Grid item xs={6}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  {insuranceName}
                </Typography>
                <Typography variant="body1">
                  Monitor patients from your insurance partners
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Box
            sx={{
              mt: 6,
              mb: 6,
            }}
          >
            <DataPointGrid yearlyData={yearlyData} />
          </Box>

          <Box
            sx={{
              mb: 2,
            }}
          >
            <SearchBar value={query || ""} onChange={(e) => setQuery(e)} />
          </Box>

          <PaginatedDataTable
            columns={columns}
            data={data}
            totalRecords={totalRecords}
            handlePageChange={handlePageChange}
            handlePerRowsChange={handlePerRowsChange}
          />
        </Box>
      </div>
    </div>
  );
};

export default AdminInsurancePatients;
