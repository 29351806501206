import React from 'react';
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { Box, Typography } from '@mui/material';

interface LabelPhoneInputProps {
  name?: string;
  label: string;
  value: string;
  defaultCountry?: string;
  onChange: (value: string) => void;
  disabled: boolean;
}

const LabelPhoneInput: React.FC<LabelPhoneInputProps> = ({
  name,
  label,
  value,
  defaultCountry = "ke",
  onChange,
  disabled,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        mb: 2,
      }}
    >
      <Typography variant="body1">{label}</Typography>
      <PhoneInput
        name={name}
        defaultCountry={defaultCountry}
        value={value}
        onChange={onChange}
        disabled={disabled}
        style={{
          width: "100%",
          height: "40px",
          borderRadius: "4px",
          borderColor: "rgba(0, 0, 0, 0.23)",
          padding: "3px",
          backgroundColor: disabled ? "#f5f5f5" : "white",
        }}
      />
    </Box>
  );
};

export default LabelPhoneInput;
