import { createStyles, makeStyles } from "@mui/styles";
import {
  IconButton,
  Theme,
  Grid,
  Button,
  Typography,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import LabelInputField from "../components/LabelInputField";
import { useEffect, useState } from "react";
import LabelSelectField from "../components/LabelSelectField";
import DatePickerComponent from "../components/DatePicker";
import LabelIncrementField from "../components/LabelIncrementField";
import axios from "axios";
import LabelSelectSearchField from "../components/LabelSelectSearch";
import { FaPlus, FaMinus } from "react-icons/fa";
import LabelNumberField from "../components/LabelNumberField";
import Toast, { showToast } from "../components/Toast";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from "uuid";
import BackButton from "../components/BackButton";
import "react-international-phone/style.css";
import LabelPhoneInput from "../components/LabelPhoneInput";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
  })
);

interface Diagnosis {
  diagnosis: string;
  diagnosisName: string;
}

interface EnrollmentData {
  item: string;
  description: string;
  itemType: string;
  quantity: number;
  unitSellingPrice: number;
  unitPrice: number;
  unitCost: number;
  claimAmount: number;
  checkupsPrice: number;
  savings: number;
  discount: number;
}

const EnrollPatients = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [memberId, setMemberId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [townAddress, setTownAddress] = useState("");
  const [dateOfVisit, setDateOfVisit] = useState<Date | null>(null);
  const [lastVisitLocation, setLastVisitLocation] = useState("");
  const [typeOfVisit, setTypeOfVisit] = useState("");
  const [insuranceScheme, setInsuranceScheme] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    []
  );
  const [labOptions, setLabOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [labSearchTerm, setLabSearchTerm] = useState<string | null>(null);
  const [labData, setLabData] = useState<any[]>([]);
  const [drugOptions, setDrugOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [drugSearchTerm, setDrugSearchTerm] = useState<string>("");
  const [drugData, setDrugData] = useState<any[]>([]);
  const [diagnosis, setDiagnosis] = useState([
    { id: "", diagnosis: "", diagnosisName: "" },
  ]);
  const [drugEnrollmentData, setDrugEnrollmentData] = useState<
    EnrollmentData[]
  >([
    {
      item: "",
      description: "",
      itemType: "DRUG",
      quantity: 1,
      unitPrice: 0,
      unitSellingPrice: 0,
      unitCost: 0,
      claimAmount: 0,
      checkupsPrice: 0,
      savings: 0,
      discount: 0,
    },
  ]);
  const [labEnrollmentData, setLabEnrollmentData] = useState<EnrollmentData[]>([
    {
      item: "",
      description: "",
      itemType: "LAB",
      quantity: 1,
      unitPrice: 0,
      unitSellingPrice: 0,
      unitCost: 0,
      claimAmount: 0,
      checkupsPrice: 0,
      savings: 0,
      discount: 0,
    },
  ]);
  const [insuranceSchemeOptions, setInsuranceSchemeOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [insuranceSchemeData, setInsuranceSchemeData] = useState<any[]>([]);

  // Combine drug and lab data
  const combinedEnrollmentData: EnrollmentData[] = [
    ...drugEnrollmentData,
    ...labEnrollmentData,
  ];
  const [insuranceCode, setInsuranceCode] = useState("");

  const visitOptions = [
    { value: "IP", label: "In Patient" },
    { value: "OP", label: "Out Patient" },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_API_URL3}api/InsuranceEnrollment/insurance-by-id?insuranceId=${id}`,
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          }
        );
        if (!response.ok) throw new Error("Network response was not ok");
        const result = await response.json();
        setInsuranceCode(result.code);
      } catch (error) {
        //
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchDiseases = async (query: string) => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_API_URL3}get-diseases?DiseaseName=${query}`
        );
        const data = response.data.map((disease: any) => ({
          value: disease.subDiseaseID.toString(),
          label: disease.description,
        }));
        setOptions(data);
      } catch (error) {
        console.error("Error fetching diseases:", error);
      }
    };

    if (searchTerm) {
      fetchDiseases(searchTerm);
    } else {
      setOptions([]);
    }
  }, [searchTerm]);

  useEffect(() => {
    const fetchDrugOptions = async (query: string) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_API_URL3}api/InsuranceEnrollment/drug-by-name?name=${query}`
        );
        const data = await response.json();

        const transformedOptions = data.map((item: any) => ({
          value: item.centreProductId.toString(),
          label: item.description,
        }));

        setDrugOptions(transformedOptions);
        setDrugData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (drugSearchTerm) {
      fetchDrugOptions(drugSearchTerm);
    }
  }, [drugSearchTerm]);

  useEffect(() => {
    const fetchLabOptions = async (query: string) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_API_URL3}api/InsuranceEnrollment/lab-by-name?name=${query}`
        );
        const data = await response.json();

        const transformedOptions = data.map((item: any) => ({
          value: item.labID.toString(),
          label: item.description,
        }));

        setLabOptions(transformedOptions);
        setLabData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (labSearchTerm) {
      fetchLabOptions(labSearchTerm);
    }
  }, [labSearchTerm]);

  // Fetch insurance scheme options when insuranceSearchTerm changes
  const fetchInsuranceSchemeOptions = async (accountId: string) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL3}api/InsuranceEnrollment/insurancescheme-by-accountid?accountId=${id}`
      );
      const data = await response.json();
      const transformedOptions = data.map((item: any) => ({
        value: item.code.toString(),
        label: item.description,
      }));
      setInsuranceSchemeOptions(transformedOptions);
      setInsuranceSchemeData(data);
    } catch (error) {
      console.error("Error fetching insurance scheme options:", error);
    }
  };

  // Trigger insurance scheme fetch when insurance value changes
  useEffect(() => {
    if (id) {
      fetchInsuranceSchemeOptions(id);
    }
  }, [id]);

  const validateEnrollmentData = () => {
    for (let i = 0; i < drugEnrollmentData.length; i++) {
      const enrollment = drugEnrollmentData[i];

      if (
        enrollment.unitPrice === undefined ||
        enrollment.unitPrice === 0 ||
        enrollment.unitPrice === 1
      ) {
        showToast("error", "Please enter a valid unit price");
        return false;
      }
    }
    return true;
  };

  const validateDiagnosis = () => {
    const isValid = diagnosis.some(
      (entry) => entry.id || entry.diagnosis || entry.diagnosisName
    );

    if (!isValid) {
      showToast("error", "At least one diagnosis must be filled.");
      return false;
    }

    return true;
  };

  //submit enrollment
  const handleSubmit = async () => {
    // Filter out lab items with an empty `item` field
    const filteredLabEnrollmentData = labEnrollmentData.filter(
      (entry) => entry.item.trim() !== ""
    );

    // Check if there is at least one valid drug item
    const filteredDrugEnrollmentData = drugEnrollmentData.filter(
      (entry) => entry.item.trim() !== ""
    );

    // Combine the filtered lab and drug data
    const combinedEnrollmentData = [
      ...filteredDrugEnrollmentData,
      ...filteredLabEnrollmentData,
    ];

    const requestBody = {
      firstName: firstName,
      lastName: lastName,
      memberID: memberId,
      insurance: insuranceCode,
      scheme: insuranceScheme,
      lastVisitDate: dateOfVisit,
      lastVisitLocation: lastVisitLocation,
      visitType: typeOfVisit,
      phone: phoneNumber.replace("+", ""),
      email: emailAddress,
      town: townAddress,
      clientId: id,
      enrollmentDiagnosisModels: diagnosis.map((d) => ({
        diagnosisID: d.diagnosis,
        diagnosisName: d.diagnosisName,
      })),
      insuranceEnrollmentModels: combinedEnrollmentData.map((item) => ({
        item: item.item,
        itemDescription: item.description,
        itemType: item.itemType,
        quantity: item.quantity,
        claimAmount: item.claimAmount,
        checkupsPrice: item.checkupsPrice,
        costPrice: item.unitPrice,
        savings: item.savings.toFixed(2),
      })),
    };

    //validations
    if (
      firstName === "" ||
      lastName === "" ||
      memberId === "" ||
      phoneNumber === "" ||
      townAddress === "" ||
      dateOfVisit === null ||
      lastVisitLocation === "" ||
      typeOfVisit === "" ||
      insuranceScheme === "" ||
      insuranceCode === ""
    ) {
      showToast("error", "Please fill all the fields");
      return;
    } else if (!validateEnrollmentData()) {
      return;
    } else if (!validateDiagnosis()) {
      return;
    }
    try {
      Swal.fire({
        text: "Submitting...",
        didOpen: () => {
          Swal.showLoading();
        },
        allowOutsideClick: false,
      });
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL3}api/InsuranceEnrollment/create-enrollment`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      await response.json();
      Swal.close();
      navigate(`/enroll-list`);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleLabSearchChange = (
    event: React.ChangeEvent<{}>,
    value: string
  ) => {
    setLabSearchTerm(value);
  };

  const handleDrugsChange = (
    index: number,
    event: React.ChangeEvent<{}>,
    value: string | null
  ) => {
    const updatedEnrollmentData = [...drugEnrollmentData];

    if (value) {
      const selectedDrug = drugData.find(
        (drug) => drug.centreProductId.toString() === value
      );

      if (selectedDrug) {
        // Ensure values are treated as numbers
        const quantity = updatedEnrollmentData[index].quantity;
        const unitPrice = selectedDrug.unitPrice;
        const unitSellingPrice = selectedDrug.unitSellingPrice;

        const claimAmount = unitPrice * quantity;
        const checkupsPrice = unitSellingPrice * quantity;
        const savings = claimAmount - checkupsPrice;
        const discount = (savings / claimAmount) * 100;

        updatedEnrollmentData[index] = {
          ...updatedEnrollmentData[index],
          item: value,
          unitPrice: selectedDrug.unitPrice,
          unitSellingPrice: selectedDrug.unitSellingPrice,
          unitCost: selectedDrug.unitCost,
          description: selectedDrug.description,
          claimAmount,
          checkupsPrice,
          savings,
          discount,
        };
      }
    } else {
      updatedEnrollmentData[index] = {
        ...updatedEnrollmentData[index],
        item: "",
        description: "",
        unitPrice: 0,
        unitSellingPrice: 0,
        unitCost: 0,
        claimAmount: 0,
        checkupsPrice: 0,
        savings: 0,
        discount: 0,
      };
    }
    setDrugEnrollmentData(updatedEnrollmentData);
  };

  const handleLabChange = (
    index: number,
    event: React.ChangeEvent<{}>,
    value: string | null
  ) => {
    const updatedEnrollmentData = [...labEnrollmentData];

    if (value) {
      const selectedLab = labData.find((lab) => lab.labID.toString() === value);

      if (selectedLab) {
        const quantity = updatedEnrollmentData[index].quantity;
        const unitPrice = selectedLab.unitPrice;
        const unitSellingPrice = selectedLab.unitSellingPrice;
        const unitCost = selectedLab.unitCost;

        const claimAmount = unitPrice * quantity;
        const checkupsPrice = unitSellingPrice * quantity;
        const savings = claimAmount - checkupsPrice;
        const discount = (savings / claimAmount) * 100;

        updatedEnrollmentData[index] = {
          ...updatedEnrollmentData[index],
          item: value,
          unitPrice,
          unitSellingPrice,
          unitCost,
          description: selectedLab.description,
          claimAmount,
          checkupsPrice,
          savings,
          discount,
        };
      }
    } else {
      updatedEnrollmentData[index] = {
        ...updatedEnrollmentData[index],
        item: "",
        description: "",
        unitPrice: 0,
        unitSellingPrice: 0,
        unitCost: 0,
        claimAmount: 0,
        checkupsPrice: 0,
        savings: 0,
        discount: 0,
      };
    }

    setLabEnrollmentData(updatedEnrollmentData);
  };

  const handleDiagnosisSearchChange = (
    event: React.ChangeEvent<{}>,
    value: string
  ) => {
    setSearchTerm(value);
  };

  //diagnosis
  const handleDiagnosisChange = (
    index: number,
    field: keyof Diagnosis,
    value: string
  ) => {
    const updatedDiagnosis = [...diagnosis];
    updatedDiagnosis[index][field] = value;
    setDiagnosis(updatedDiagnosis);
  };

  const createDiagnosisChangeHandler =
    (index: number) => (event: React.ChangeEvent<{}>, value: string | null) => {
      const diagnosisValue = value || "";

      // Find the selected diagnosis in the options based on the selected value
      const selectedDiagnosis = options.find(
        (diagnosis) => diagnosis.value === diagnosisValue
      );

      if (selectedDiagnosis) {
        // Update both the diagnosis and diagnosisName for the specific index
        handleDiagnosisChange(index, "diagnosis", diagnosisValue);
        handleDiagnosisChange(index, "diagnosisName", selectedDiagnosis.label);
      } else {
        // If no valid diagnosis is found, clear both fields
        handleDiagnosisChange(index, "diagnosis", "");
        handleDiagnosisChange(index, "diagnosisName", "");
      }
    };

  const addDiagnosis = (event: any) => {
    event.preventDefault();
    // Add a new diagnosis with a unique id
    setDiagnosis([
      ...diagnosis,
      { id: uuidv4(), diagnosis: "", diagnosisName: "" },
    ]);
  };

  const removeDiagnosis = (event: any, id: string) => {
    event.preventDefault();
    // Filter out the diagnosis with the specified id
    const updatedDiagnosis = diagnosis.filter((item) => item.id !== id);
    setDiagnosis(updatedDiagnosis);
  };

  //enrollment
  const addNewDrugEnrollment = (type: "DRUG") => {
    const newEnrollment = {
      item: "",
      description: "",
      itemType: type,
      quantity: 1,
      unitPrice: 0,
      unitSellingPrice: 0,
      unitCost: 0,
      claimAmount: 0,
      checkupsPrice: 0,
      savings: 0,
      discount: 0,
    };

    setDrugEnrollmentData((prev) => [...prev, newEnrollment]);
  };

  const addNewLabEnrollment = (type: "LAB") => {
    const newEnrollment = {
      item: "",
      description: "",
      itemType: type,
      quantity: 1,
      unitPrice: 0,
      unitSellingPrice: 0,
      unitCost: 0,
      claimAmount: 0,
      checkupsPrice: 0,
      savings: 0,
      discount: 0,
    };

    setLabEnrollmentData((prev) => [...prev, newEnrollment]);
  };

  const removeDrugEnrollmentItem = (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    event.preventDefault();
    const updatedEnrollmentData = drugEnrollmentData.filter(
      (_, i) => i !== index
    );
    setDrugEnrollmentData(updatedEnrollmentData);
  };

  const removeLabEnrollmentItem = (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    event.preventDefault();
    const updatedEnrollmentData = labEnrollmentData.filter(
      (_, i) => i !== index
    );
    setLabEnrollmentData(updatedEnrollmentData);
  };

  const handleDrugEnrollmentChange = (
    index: number,
    field: keyof EnrollmentData,
    value: string | number
  ) => {
    const updatedEnrollmentData = [...drugEnrollmentData];

    // Convert the input value to a number if it's a numeric field
    if (
      field === "quantity" ||
      field === "unitSellingPrice" ||
      field === "unitPrice" ||
      field === "savings" ||
      field === "discount" ||
      field === "unitCost" ||
      field === "checkupsPrice" ||
      field === "claimAmount"
    ) {
      const parsedValue =
        value === "" || value === null ? 0 : parseFloat(value as string);
      updatedEnrollmentData[index][field] = isNaN(parsedValue)
        ? 0
        : parsedValue;
    } else {
      updatedEnrollmentData[index][field] = value as string;
    }

    const quantity = updatedEnrollmentData[index].quantity || 0;
    const unitPrice = updatedEnrollmentData[index].unitPrice || 0;
    const unitSellingPrice = updatedEnrollmentData[index].unitSellingPrice || 0;
    const discount = updatedEnrollmentData[index].discount || 0;

    const claimAmount = unitPrice * quantity;
    let checkupsPrice = unitSellingPrice * quantity;

    // Apply discount to checkupsPrice
    if (discount > 0) {
      checkupsPrice = checkupsPrice - checkupsPrice * (discount / 100);
    }

    const savings = claimAmount - checkupsPrice;

    updatedEnrollmentData[index].checkupsPrice = checkupsPrice;
    updatedEnrollmentData[index].claimAmount = claimAmount;
    updatedEnrollmentData[index].savings = savings;
    updatedEnrollmentData[index].discount = discount;

    setDrugEnrollmentData(updatedEnrollmentData);
  };

  const handleLabEnrollmentChange = (
    index: number,
    field: keyof EnrollmentData,
    value: string | number
  ) => {
    const updatedEnrollmentData = [...labEnrollmentData];

    // Convert the input value to a number if it's a numeric field
    if (
      field === "quantity" ||
      field === "unitSellingPrice" ||
      field === "unitPrice" ||
      field === "savings" ||
      field === "discount" ||
      field === "unitCost" ||
      field === "checkupsPrice" ||
      field === "claimAmount"
    ) {
      const parsedValue =
        value === "" || value === null ? 0 : parseFloat(value as string);
      updatedEnrollmentData[index][field] = isNaN(parsedValue)
        ? 0
        : parsedValue;
    } else {
      updatedEnrollmentData[index][field] = value as string;
    }

    const quantity = updatedEnrollmentData[index].quantity || 0;
    const unitPrice = updatedEnrollmentData[index].unitPrice || 0;
    const unitSellingPrice = updatedEnrollmentData[index].unitSellingPrice || 0;
    const discount = updatedEnrollmentData[index].discount || 0;

    const claimAmount = unitPrice * quantity;
    let checkupsPrice = unitSellingPrice * quantity;

    // Apply discount to checkupsPrice
    if (discount > 0) {
      checkupsPrice = checkupsPrice - checkupsPrice * (discount / 100);
    }

    const savings = claimAmount - checkupsPrice;

    updatedEnrollmentData[index].checkupsPrice = checkupsPrice;
    updatedEnrollmentData[index].claimAmount = claimAmount;
    updatedEnrollmentData[index].savings = savings;
    updatedEnrollmentData[index].discount = discount;

    setLabEnrollmentData(updatedEnrollmentData);
  };

  const handleDrugSearchChange = (
    event: React.ChangeEvent<{}>,
    value: string
  ) => {
    setDrugSearchTerm(value);
  };

  //calculations
  const calculateSavings = (item: EnrollmentData) => {
    const claimAmount = parseFloat(item.claimAmount.toString());
    const checkupsPrice = parseFloat(item.checkupsPrice.toString());
    const savings = claimAmount - checkupsPrice;
    return savings.toFixed(2);
  };

  const calculateCheckupsPrice = (item: EnrollmentData) => {
    const checkupsPrice = parseFloat(item.checkupsPrice.toString());
    return checkupsPrice.toFixed(2);
  };

  const calculateUnitCost = (item: EnrollmentData) => {
    const unitCost = parseFloat(item.unitCost.toString());
    return unitCost.toFixed(2);
  };

  const totalSavings = combinedEnrollmentData
    .reduce((total, item) => {
      return total + parseFloat(calculateSavings(item));
    }, 0)
    .toFixed(2);

  const totalCheckupsPrice = combinedEnrollmentData
    .reduce((total, item) => {
      return total + parseFloat(calculateCheckupsPrice(item));
    }, 0)
    .toFixed(2);

  const totalUnitCost = combinedEnrollmentData
    .reduce((total, item) => {
      return total + parseFloat(calculateUnitCost(item));
    }, 0)
    .toFixed(2);

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div>
        <Toast />
        <Box sx={{ flexGrow: 1, mt: 4, pl: 2, pr: 2 }}>
          <Box sx={{ mt: 4, mb: 4 }}>
            <BackButton />
          </Box>

          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={6}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Enroll new patient
                </Typography>
                <Typography variant="body1">
                  Enroll new patients from your insurance partners
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Box
            sx={{
              mt: 6,
              border: "1px solid #ebebeb",
              borderRadius: "8px",
              padding: "20px",
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              Patient's Personal Data
            </Typography>
            <Grid container spacing={3}>
              {/* First Row */}
              <Grid container item spacing={3} xs={12} sx={{ mt: 0 }}>
                <Grid item xs={3}>
                  <LabelInputField
                    label="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    placeholder="John"
                    disabled={false}
                  />
                </Grid>
                <Grid item xs={3}>
                  <LabelInputField
                    label="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    placeholder="Doe"
                    disabled={false}
                  />
                </Grid>
                <Grid item xs={3}>
                  <LabelInputField
                    label="Enter Patient's Member ID"
                    value={memberId}
                    onChange={(e) => setMemberId(e.target.value)}
                    placeholder="CHSC00002235"
                    disabled={false}
                  />
                </Grid>
                <Grid item xs={3}>
                  <LabelPhoneInput
                    name="phone"
                    label="Phone Number"
                    value={phoneNumber}
                    defaultCountry="ke"
                    onChange={(phone) => setPhoneNumber(phone ?? "")}
                    disabled={false}
                  />
                </Grid>
              </Grid>

              {/* Second Row */}
              <Grid container item spacing={3} xs={12} sx={{ mt: 1 }}>
                <Grid item xs={3}>
                  <LabelInputField
                    label="Email Address"
                    value={emailAddress}
                    onChange={(e) => setEmailAddress(e.target.value)}
                    placeholder="patient@gmail.com"
                    disabled={false}
                  />
                </Grid>
                <Grid item xs={3}>
                  <LabelInputField
                    label="Town Address"
                    value={townAddress}
                    onChange={(e) => setTownAddress(e.target.value)}
                    placeholder="CHSC00002235"
                    disabled={false}
                  />
                </Grid>
                <Grid item xs={3}>
                  <DatePickerComponent
                    label="Date of Last Visit"
                    value={dateOfVisit}
                    onChange={(date) => setDateOfVisit(date)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <LabelInputField
                    label="Last visit location"
                    value={lastVisitLocation}
                    onChange={(e) => setLastVisitLocation(e.target.value)}
                    placeholder=""
                    disabled={false}
                  />
                </Grid>
              </Grid>

              {/* Third Row */}
              <Grid container item spacing={3} xs={12} sx={{ mt: 1 }}>
                <Grid item xs={3}>
                  <LabelSelectField
                    name="type_of_visit"
                    label="Type of visit"
                    options={visitOptions}
                    value={typeOfVisit}
                    onChange={(e) => setTypeOfVisit(e.target.value)}
                  />
                </Grid>

                <Grid item xs={3}>
                  <LabelSelectField
                    name="insurance_scheme"
                    label="Insurance Scheme"
                    options={insuranceSchemeOptions}
                    value={insuranceScheme ?? ""}
                    onChange={(e) => setInsuranceScheme(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <Box
            sx={{
              mt: 6,
              border: "1px solid #ebebeb",
              borderRadius: "8px",
              padding: "20px",
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: "bold", mb: 2 }}>
              Patient's Medical/Billing Data
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                flexWrap: "wrap",
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  minWidth: 0,
                }}
              >
                <Box
                  sx={{
                    mt: 2,
                  }}
                >
                  <div>
                    {diagnosis.map((diagnosisItem, index) => (
                      <div key={diagnosisItem.id} className="">
                        <LabelSelectSearchField
                          name={`diagnosis_${index}`}
                          label="Ailment/Diagnosis"
                          options={options}
                          value={diagnosisItem.diagnosis}
                          onChange={createDiagnosisChangeHandler(index)}
                          onInputChange={handleDiagnosisSearchChange}
                        />
                        {index > 0 && (
                          <Box sx={{ float: "right" }}>
                            <IconButton
                              onClick={(event) =>
                                removeDiagnosis(event, diagnosisItem.id)
                              }
                              sx={{ color: "red" }}
                            >
                              <FaMinus size={12} />
                            </IconButton>
                          </Box>
                        )}
                      </div>
                    ))}

                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <IconButton
                        onClick={addDiagnosis}
                        sx={{ color: "#00a6fb" }}
                      >
                        <FaPlus size={12} />
                      </IconButton>
                      <IconButton
                        onClick={addDiagnosis}
                        sx={{
                          color: "#00a6fb",
                          textTransform: "none",
                          p: 0,
                        }}
                      >
                        <Typography variant="body2">Add diagnosis</Typography>
                      </IconButton>
                    </Box>
                  </div>
                </Box>

                {/* Drugs box */}
                <Box
                  sx={{
                    mt: 2,
                  }}
                ></Box>

                <div>
                  {drugEnrollmentData
                    .filter((enrollment) => enrollment.itemType === "DRUG")
                    .map((enrollment, index) => (
                      <div key={index}>
                        <Box
                          sx={{
                            mt: 2,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Box sx={{ flex: "0 0 40%" }}>
                            <LabelSelectSearchField
                              name={`drug_item_${index}`}
                              label="Drugs"
                              options={drugOptions}
                              value={enrollment.item}
                              onChange={(event, value) =>
                                handleDrugsChange(index, event, value)
                              }
                              onInputChange={handleDrugSearchChange}
                            />
                          </Box>
                          <Box sx={{ flex: "0 0 25%", padding: "10px" }}>
                            <LabelNumberField
                              name={`drug_unitPrice_${index}`}
                              label="Unit price"
                              value={enrollment.unitPrice}
                              onChange={(e) =>
                                handleDrugEnrollmentChange(
                                  index,
                                  "unitPrice",
                                  parseFloat(e.target.value)
                                )
                              }
                              placeholder=""
                              disabled={false}
                            />
                          </Box>
                          <Box sx={{ flex: "0 0 15%" }}>
                            <LabelIncrementField
                              name={`drug_quantity_${index}`}
                              label="Quantity"
                              value={enrollment.quantity}
                              onChange={(value: number) =>
                                handleDrugEnrollmentChange(
                                  index,
                                  "quantity",
                                  value
                                )
                              }
                              disabled={false}
                              onIncrement={() =>
                                handleDrugEnrollmentChange(
                                  index,
                                  "quantity",
                                  enrollment.quantity + 1
                                )
                              }
                              onDecrement={() =>
                                handleDrugEnrollmentChange(
                                  index,
                                  "quantity",
                                  Math.max(0, enrollment.quantity - 1)
                                )
                              }
                            />
                          </Box>
                          <Box sx={{ flex: "0 0 20%", padding: "10px" }}>
                            <LabelNumberField
                              name={`drug_discount_${index}`}
                              label="Discount"
                              value={enrollment.discount || 0}
                              onChange={(e) =>
                                handleDrugEnrollmentChange(
                                  index,
                                  "discount",
                                  parseFloat(e.target.value) || 0
                                )
                              }
                              placeholder=""
                              disabled={false}
                            />
                          </Box>
                        </Box>

                        {index > 0 && (
                          <Box sx={{ float: "right" }}>
                            <IconButton
                              onClick={(event) =>
                                removeDrugEnrollmentItem(event, index)
                              }
                              sx={{ color: "red" }}
                            >
                              <FaMinus size={12} />
                            </IconButton>
                          </Box>
                        )}
                      </div>
                    ))}

                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <IconButton
                      onClick={(e) => addNewDrugEnrollment("DRUG")}
                      sx={{ color: "#00a6fb" }}
                    >
                      <FaPlus size={12} />
                    </IconButton>
                    <IconButton
                      onClick={(e) => addNewDrugEnrollment("DRUG")}
                      sx={{ color: "#00a6fb", textTransform: "none", p: 0 }}
                    >
                      <Typography variant="body2">Add drug</Typography>
                    </IconButton>
                  </Box>
                </div>

                <div>
                  {labEnrollmentData
                    .filter((enrollment) => enrollment.itemType === "LAB")
                    .map((enrollment, index) => (
                      <div key={index}>
                        <Box
                          sx={{
                            mt: 2,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Box sx={{ flex: "0 0 40%" }}>
                            <LabelSelectSearchField
                              name={`lab_item_${index}`}
                              label="Labs"
                              options={labOptions}
                              value={enrollment.item}
                              onChange={(event, value) =>
                                handleLabChange(index, event, value)
                              }
                              onInputChange={handleLabSearchChange}
                            />
                          </Box>
                          <Box sx={{ flex: "0 0 25%", padding: "10px" }}>
                            <LabelNumberField
                              name={`lab_unitPrice_${index}`}
                              label="Unit price"
                              value={enrollment.unitPrice}
                              onChange={(e) =>
                                handleLabEnrollmentChange(
                                  index,
                                  "unitPrice",
                                  parseFloat(e.target.value)
                                )
                              }
                              placeholder=""
                              disabled={false}
                            />
                          </Box>
                          <Box sx={{ flex: "0 0 15%" }}>
                            <LabelIncrementField
                              name={`lab_quantity_${index}`}
                              label="Quantity"
                              value={enrollment.quantity}
                              onChange={(value: number) =>
                                handleLabEnrollmentChange(
                                  index,
                                  "quantity",
                                  value
                                )
                              }
                              disabled={true}
                              onIncrement={() =>
                                handleLabEnrollmentChange(
                                  index,
                                  "quantity",
                                  enrollment.quantity + 1
                                )
                              }
                              onDecrement={() =>
                                handleLabEnrollmentChange(
                                  index,
                                  "quantity",
                                  Math.max(0, enrollment.quantity - 1)
                                )
                              }
                            />
                          </Box>
                          <Box sx={{ flex: "0 0 20%", padding: "10px" }}>
                            <LabelNumberField
                              name={`lab_discount_${index}`}
                              label="Discount"
                              value={enrollment.discount || 0}
                              onChange={(e) =>
                                handleLabEnrollmentChange(
                                  index,
                                  "discount",
                                  parseFloat(e.target.value) || 0
                                )
                              }
                              placeholder=""
                              disabled={false}
                            />
                          </Box>
                        </Box>

                        {index > 0 && (
                          <Box sx={{ float: "right" }}>
                            <IconButton
                              onClick={(event) =>
                                removeLabEnrollmentItem(event, index)
                              }
                              sx={{ color: "red" }}
                            >
                              <FaMinus size={12} />
                            </IconButton>
                          </Box>
                        )}
                      </div>
                    ))}

                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <IconButton
                      onClick={(e) => addNewLabEnrollment("LAB")}
                      sx={{ color: "#00a6fb" }}
                    >
                      <FaPlus size={12} />
                    </IconButton>
                    <IconButton
                      onClick={(e) => addNewLabEnrollment("LAB")}
                      sx={{ color: "#00a6fb", textTransform: "none", p: 0 }}
                    >
                      <Typography variant="body2">Add lab</Typography>
                    </IconButton>
                  </Box>
                </div>

                <Box
                  sx={{
                    mt: 2,
                  }}
                ></Box>
              </Box>
              <Box
                sx={{
                  flex: 1.5,
                  border: "1px solid #ebebeb",
                  borderRadius: "8px",
                  padding: "20px",
                  minWidth: 0,
                }}
              >
                {/* Content for the second box */}
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow sx={{ backgroundColor: "#f0f0f0" }}>
                        <TableCell>Item Type</TableCell>
                        <TableCell>Item</TableCell>
                        <TableCell>Unit Price</TableCell>
                        <TableCell sx={{ color: "red" }}>Cost Price</TableCell>
                        <TableCell>Qty</TableCell>
                        <TableCell>Claim</TableCell>
                        <TableCell sx={{ color: "#00a6fb" }}>
                          Checkups
                        </TableCell>
                        <TableCell>Discounts</TableCell>
                        <TableCell>Savings</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {combinedEnrollmentData.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>{row.itemType}</TableCell>
                          <TableCell>{row.description}</TableCell>
                          <TableCell>
                            {(row.unitPrice || 0).toFixed(2)}
                          </TableCell>
                          <TableCell sx={{ color: "red" }}>
                            {(row.unitCost || 0).toFixed(2)}
                          </TableCell>
                          <TableCell>{row.quantity}</TableCell>
                          <TableCell>
                            {(row.claimAmount || 0).toFixed(2)}
                          </TableCell>
                          <TableCell sx={{ color: "#00a6fb" }}>
                            {(row.checkupsPrice || 0).toFixed(2)}
                          </TableCell>
                          <TableCell>
                            {(row.discount || 0).toFixed(2)}%
                          </TableCell>
                          <TableCell>{(row.savings || 0).toFixed(2)}</TableCell>
                        </TableRow>
                      ))}

                      {/* Total Row */}
                      <TableRow
                        sx={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}
                      >
                        <TableCell>Total</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell sx={{ color: "red" }}>
                          {totalUnitCost}
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell sx={{ color: "#00a6fb" }}>
                          {totalCheckupsPrice}
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell>{totalSavings}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Box>

          <Box sx={{ mt: 6, mb: 12, float: "right" }}>
            <Button
              onClick={handleSubmit}
              variant="contained"
              sx={{
                backgroundColor: "#00a6fb",
                color: "white",
                "&:hover": { backgroundColor: "grey.800" },
              }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default EnrollPatients;
