import { combineReducers } from "@reduxjs/toolkit";
import auth from "../services/auth/auth-logic/authSlice";
import login from "../services/auth/auth-logic/login-logic";
import settings from "../services/settings/settings-logic/settingsSlice";
import profile from "../services/agent-accounts/agent-accounts-logic/profileSlice";
import user from "../services/user-accounts/user-accounts-logic/profileSlice";
import clients from "../services/dashboard/dashboard-logic/dashboardSlice";
import summary from "../services/PatientUtilization/dashboard-logic/dashboardSlice";
import subscription from "../services/subscription/subscription-logic/subscriptionSlice";
import insurance from "../services/insurance-setup/insuranceSlice";
/**
 * @description Aggregate all reducers into a root reducer
 */
const rootReducer = combineReducers({
  auth,
  profile,
  settings,
  clients,
  subscription,
  summary,
  login,
  user,
  insurance,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
