/* eslint-disable @typescript-eslint/no-unused-vars */
import { createStyles, makeStyles } from "@mui/styles";
import LoadingSpinner from "../components/LoadingSpinner";
import PageContent from "../components/pageContent";
import Table from "../components/Table";
import React, { useEffect, useState } from "react";
import {  useAppDispatch, useAppSelector } from "../../utils/hooks";
import {
  DataTableColumn,
  DataTableRow,
} from "../../utils/types";
import useFetch from "../../utils/useFetch";
import { Box, Button, Card, CircularProgress, Container, Dialog, DialogActions, DialogTitle, Fade, Grid, Link, Menu, MenuItem, TextField, Theme } from "@mui/material";
import EmptyList from "../components/EmptyList";
import MaterialTable from "@material-table/core";
import MoreVertIcon  from '@mui/icons-material/MoreVert';
import { toDate } from "date-fns/esm";
import CheckCircleOutlineIcon  from '@mui/icons-material/CheckCircleOutline';
import CreateResults from "./CreateResults";

const useStyles = makeStyles((theme: Theme) =>
createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
    },
    name: {
      textTransform: "capitalize",
    },
    formField: {
      marginLeft: '4px',
      marginTop: '30px',
      [theme.breakpoints.down('md')]: {
        width: '20%',
        alignContent: 'center ',
      },
  },
  searchBox: {
    padding: theme.spacing(2, 0),
    width: "99%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  menuItemNoIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuItemWithIcon: {
    display: 'grid',
    gridTemplateColumns: '50px 1fr',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    fontWeight: 800,
    fontSize: '0.95rem',
  },
  menuItem: {
    minWidth: 200,
    margin: theme.spacing(1, 2),
    backgroundColor: theme.palette.grey[100],
    borderRadius: '3px',
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.grey[700],
    },
  },
  button: {
    backgroundColor: 'blue',
    color: 'white',
    width: '50%',
    fontSize: '30px',
  },
  colHeader: {
    "&:hover": {
      color: "red"
    }
  },

}));
// ----------------------------------------------------------------------
type Props = {
    CycleID: string;
    setOpenModalForm?: any;
    onCancel?: any;
  };
  const TestItems: React.FC<Props> = ({
    CycleID,
    setOpenModalForm,
    onCancel,
  }: Props) => {
    const classes = useStyles();
    const [selectedRow, setSelectedRow] = useState(null);
    const[open,setOpen]=useState(false);
    const[testID,setTestID]=useState('');
    const[labTestID,setLabTestID]=useState('');
    const[sample,setSample]=useState('');
    const handleDispatchAssignment = (event: React.MouseEvent<HTMLElement>,row:any) => {

      setLabTestID(row.lab_test_id);
      setTestID(row.test_id);
      setSample(row.sample);
       setOpen(true);
    };

    const {
        data,
        isLoading,
        isError,
        error,
        isRefreshing,
        isSuccess
      } = useFetch(`api/v1/checkups/tickets/get-lab-tests?CycleID=${CycleID}`, ["TestItems", `${CycleID}`]);
// &FromDate=${}&ToDate=${}

    const [columns, setColumns] = useState([
        {
      field: "test_serial_no",
      title: "Serial No.",
      type: "string" as const,
      minWidth: 50,
    },
    {
      field: "sample",
      title: "Sample",
      minWidth: 50,
      type: "string" as const
    },
    {
      field: "lab_test_desc",
      title: "Test",
      minWidth: 250,
      type: "string" as const
    }, 
    {
      field: "sub_lab_test_description",
      title: "Sub Lab Test",
      minWidth: 150,
      type: "string" as const
    }, 
    {
      field: "value",
      title: "Value",
      minWidth: 150,
      type: "string" as const
    }, 
    {
      field: "done",
      title: "Result Status",
      minWidth: 20,
      type: "string" as const
    },     
    {
      title:<div className={classes.colHeader}>Actions</div>,
      render: (rowData: any) => (
      <>
        <Button
        variant="text"
        disabled={rowData.done==="Done" ? true : false }
        startIcon={<CheckCircleOutlineIcon  fontSize="small" />}
        onClick={(event: any) => handleDispatchAssignment(event, rowData)}
      >
        Add-Result
      </Button>
      
    </>)}

    
  ]);

  return (
    <>
      <Container maxWidth={'xl'}>
        <Card>

        {isLoading ? <LoadingSpinner /> : <></>}
        {/* {isFetching ? <RefreshingCard message="Refreshing Clients" /> : <></>} */}
        
      {isSuccess ? (
        <>

            <MaterialTable
            title="Test Lists"
            columns={columns}
            data={data}
            actions={[]}
            
            onRowClick={undefined}
            options={ {
              columnsButton:true,
              sorting: true,
              rowStyle: rowData => ({
                backgroundColor: (rowData.pharmacyBalance<=1000) ? '#00000' : (rowData.pharmacyBalance<0) ? '#01579b' : '#00000' 
              }),
              headerStyle: {
                backgroundColor: '#01579b',
                color: '#FFF',
              },}}

             />

        </>
      ) : (<EmptyList message="You may want to refine your filters" />)}
        </Card>
        <DialogActions>
        <Box sx={{ flexGrow: 1 }} />

        <Button variant="outlined" color="inherit" onClick={onCancel}>
          Close
        </Button>
      </DialogActions>
      </Container>

      <CreateResults
        queryString="clients"
        open={open}
        setOpen={setOpen}
        testID={testID}
        labTestID={labTestID}
        CycleID={CycleID}
        sample={sample}
      />

     
    </>
  );
}
TestItems.defaultProps = {
    CycleID: undefined,
    onCancel: undefined,
    setOpenModalForm: undefined,
  };
  
  export default TestItems;


