import { TextField, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Autocomplete } from "@mui/lab";
import React, { useEffect, useState } from "react";
import { ErrorType } from "../../utils/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
    },
    errorText: {
      color: 'red',
      fontSize: 'small',
    },
    formField: {
      width: '100%',
      margin: theme.spacing(0),
    },
    inputLabel: {
      "&.focused": {
        color: "#449afe",
        fontSize: "14px"
      },
      color: "#449afe",
      fontSize: "14px"
    },
  }),
);

type Props = {
  value?: any;
  setValue: any;
  //error: false | ErrorType | undefined;
  setInputValue: any;
  inputValue: any;
  serviceList: any;
  label:any;
};
const SelectTypeOfService: React.FC<Props> = ({
  value,
  setValue,
  //error: validationErr,
  setInputValue,
  inputValue,
  serviceList,
  label,
  // eslint-disable-next-line no-unused-vars
}: Props) => {
  const classes = useStyles();
  const [country,setCountry]=useState<any[]>([]);
  const getData=()=>{
    fetch('county.json'
    ,{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }
    )
    .then(function(response){
      return response.json();
    })
    .then(function(myJson) {
      setCountry(myJson);
    });
  }
  useEffect(()=>{
    getData()
  },[])
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;
  // eslint-disable-next-line no-unused-vars

  const optionsData = country || [];
  return (
    <div className={classes.root}>
        <>
        <Autocomplete
        value={value}
        onChange={(event: any, newValue: string | null) => {
          setValue(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        id="controllable-states-demo"
        options={serviceList}
        style={{ width: 400}}
        renderInput={(params) => <TextField {...params} label={label} />}
      />
        

        </>
      {/* ) : (
        <></>
      )} */}
    </div>
  );
};

export default SelectTypeOfService;
