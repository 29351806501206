/* eslint-disable @typescript-eslint/no-unused-vars */
import { createStyles, makeStyles } from "@mui/styles";
import { CreateOutlined, DeleteOutline, QrCodeTwoTone, SubtitlesTwoTone  } from "@mui/icons-material";
import BadError from "../components/BadError";
import LoadingSpinner, {
  RefreshingCard,
} from "../components/LoadingSpinner";
import PageContent from "../components/pageContent";
import PageHeading from "../components/PageHeading";
import Status from "../components/Status/Status";
import Table from "../components/Table2";
import TableHeader from "../components/TableHeader";
import TableLink from "../components/TableLink";
import React, { useEffect, useState } from "react";
import { filterBySearch } from "../../utils/filters";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import { Icon, InlineIcon } from '@iconify/react';
import listOutline from '@iconify/icons-eva/list-outline';
import {
  DataTableAction,
  DataTableColumn,
  DataTableRow,
} from "../../utils/types";
import useFetch from "../../utils/useFetch";
import CreateUser from "./CreatePackage";
import ClearPayment from "./ClearPayment";
import { setSelectedBookings, setSelectedUser, User } from "./subscription-logic/subscriptionSlice";
import moment from "moment";
import SelectFromApi from "../components/SelectFromApi";
import { RootState } from "../../store/configureStore";
import { Fade,Autocomplete, Avatar, Box, Button, Dialog, DialogTitle, Link, List, ListItem, ListItemAvatar, ListItemText, Menu, MenuItem, TextField, Theme, Grid } from "@mui/material";
import SelectTypeOfService from "../components/selectTypeOfService";
import { blue, red } from "@mui/material/colors";
import axios from "axios";
import { useQuery } from "react-query";
import InlineSearchBar from "../components/Search/InlineSearchBar2";
import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from '@material-table/exporters';
import Routes from '../nav/routeInfo';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon  from '@mui/icons-material/MoreVert';
import MemberUtilization from "./MemberUtilization";
import DependantsList from "./DependantsList";
import UpdateDetails from "./UpdateDetails";
import AddDependants from "./AddDependants";
import RenewSubscription from "./RenewSubscription";
const emails = ['username@gmail.com', 'user02@gmail.com'];
const useStyles = makeStyles((theme: Theme) =>
createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
    },
    //root:{},
    name: {
      textTransform: "capitalize",
    },
    formField: {
      marginLeft: '4px',
      marginTop: '30px',
      [theme.breakpoints.down('md')]: {
        width: '20%',
        alignContent: 'center ',
      },
  },
  searchBox: {
    padding: theme.spacing(2, 0),
    width: "99%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  colHeader: {
    "&:hover": {
      color: "red"
    }
  },


  menuItem: {
    minWidth: 200,
    margin: theme.spacing(1, 2),
    backgroundColor: theme.palette.grey[100],
    borderRadius: '3px',
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.grey[700],
    },
  },
  menuItemNoIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuItemWithIcon: {
    display: 'grid',
    gridTemplateColumns: '50px 1fr',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    fontWeight: 800,
    fontSize: '0.95rem',
  },
  unstyledButton: {
    textTransform: 'none',
    background: 'none',
    '&:hover': {
      background: 'none',
    },
  },


}));


const links = [
  {
    pathname: "",
    label: "",
    permission: "",
  },
];

type Props = {
  roleName?: string;
  roleId?: string;
  userTypeCode?: string;
};
const SubscriptionsList: React.FC<Props> = ({
  roleName,
  roleId,
  userTypeCode,
}: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [open2, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [query, setQuery] = useState("");
  const [user, setUser] = useState<User>();
  const [editing, setEditing] = useState(false);
  const [clientName, setClientName]=useState("")
  const [opePay, setOpenPay]=useState(false);
  const [add, setAdd]=useState(false);
  const [openupdate, setOpenUpdate]=useState(false);
  const [opeEdit, setOpenEdit]=useState(false);
  const [openRenewSubscription, setOpenRenewSubscription]=useState(false);
  const [showAddUserModal, setShowAddToRoleModal] = useState(false);
  const [serviceType, setServiceType] = useState<any>();
  const subset="Patients";
  const [selectedRow, setSelectedRow] = useState(null);
  const [value, setValue] = React.useState<string>('');
  const [inputValue, setInputValue] = React.useState('');
  const { selectedUser } = useAppSelector((state) => state.login);
  const [openModalForm,setOpenModalForm]= useState(false);
  const [openDependantModalForm,setOpenDependantModalForm]= useState(false);
  const[openEditMemberDetails,setOpenEditMemberDetails]=useState(false);
  const [memberNumber,setMemberNumber]= useState<string>('');
  const [phoneNumber,setPhoneNumber]= useState<string>('');
  const [fullName,setFullName]= useState<string>('');
  const [fromDate,setFromDate]= useState<string>('');
  const [toDate,setToDate]= useState<string>('');

  const makeUrl = (defaultPath: string) =>
    query && !userTypeCode
      ? `/auth/system-user-fuzzy-search-user?searchterm=${query}`
      : defaultPath;

     // useFetch(`/${subset}`, `fetchBreeds-${subset}`);
      const {
        data,
        isLoading,
        isError,
        error,
        isRefreshing,
        isSuccess
      } = useFetch(`api/v1/checkups/account/GetSubscriptionList`, ["subscriptions"]);

    const[updatedData, setUpdatedData] = useState([]);
    let optionsData;
    (value?.length <=0 || value ==null)? 
    optionsData=data?.data || data || [] :
    optionsData=data.filter((data:any) => data.typeOfService.includes(value));
//const optionsData = data?.data || data || [] ;
  const singleUser = useFetch(
    `/auth/get-single-user/?usercode=${user?.memberNumber}`,
    ["user", `${user?.memberNumber}`],
    { enabled: !!user?.memberNumber }
  );
   useEffect(() => {
     dispatch(setSelectedUser(user));
   }, [user]);

  const [allData,setAllData] = useState(data);
  const [filteredData,setFilteredData] = useState(allData);

  const handlePayment = (row: any) => {

    setOpenPay(true);
  };
  const handleRenewSubscription =(row: any)=>{
    setOpenRenewSubscription(true);
  }

  const handleEditRow = (row: any) => {
    setUser(row);
    setEditing(true);
    setOpen(true);
  };
  const handleType = (option: any) => {
    dispatch(setSelectedBookings(option));
  };
  const handleClickNewBtn = () => {
    setEditing(false);
    !roleName ? setOpen(true) : setShowAddToRoleModal(true);
  };
  const isBackgroundRed = true;
  const actions: DataTableAction[] = [
    {
      label: "Update Location",

           onClick: (row: any) => {
        handleEditRow(row);
      },
      permission: ['SuperAdmin'].includes(
        selectedUser?.role?.toUpperCase(),
      )
        ?  `(<>icon: () => <CreateOutlined /></>)`
        : 'ACTION-NOT-ALLOWED',
    },
  ];

  type IType =
    | "string"
    | "boolean"
    | "numeric"
    | "date"
    | "datetime"
    | "time"
    | "currency";
  const string: IType = "string";

  const [columns, setColumns] = useState([
    { title: <div className={classes.colHeader}>Member No.</div>, 
    field: "memberNumber", 
    type: "string" as const
   },
    {
      title:  <div className={classes.colHeader}>Client Name</div>,
      field: "fullName",
      initialEditValue: "initial edit value",
      type: "string" as const,
      render:(rowData:any)=>  <TableLink path={`/subscriptiondetails/${rowData.memberNumber}`} >{rowData.fullName}</TableLink>,
    },
    { title:<div className={classes.colHeader}>Package Name</div>, 
    field: "packageDescription", type: "string" as const },
    {
      field: "patient_id",
      title: "Family",
      minWidth: 50,
      render: (value: any, row: any) => (
      value.refferedBy ==='Dependant' ? (
        <Button disabled={true} >
           Dependant
         </Button>
      ):(
        <Button disabled={value.packagePriceName==='M + 0' ? true : false} onClick={(e:any)=>handleOpenDependantsForm(value)} >
        {value.packagePriceName}
     </Button>
      )     
      )
    },
    {
      title:<div className={classes.colHeader}>Phone Number</div>,
      field: "phoneNumber",
      type: "string" as const
    },   
    {
      title:<div className={classes.colHeader}>ID No.</div>,
      field: "idNumber",
      type: "string" as const
    },   
    {
      field:"amountPaid",
      title:<div className={classes.colHeader}>Amount Paid</div>,
      type: "string" as const
    },
    {
      title:<div className={classes.colHeader}>Pharmacy Benefits</div>,
      field: "pharmacy",
      type: "string" as const
    },
    {
      field: "patient_id",
      title: "Total Benefits",
      minWidth: 50,
      render: (value: any, row: any) => (
        <Button onClick={(e:any)=>handleOpenSubscriptionForm(value)} variant="outlined">
                <Icon icon={listOutline} />
                {row.totalBenefits}
         </Button>
         )
    },
    {
      title:<div className={classes.colHeader}>Agent Name</div>,
      field: "agentName",
      type: "string" as const
    },
  
    {
      title:<div className={classes.colHeader}>Date Created</div>,
      field: "created",
      type: "string" as const,
      render:  (value: any)=>moment(value.created)?.format("DD-MM-YYYY")
      
    },
    
  ]);

const rows: DataTableRow[] = isSuccess
? userTypeCode
  ?updatedData
  : updatedData
: [];

const [searched, setSearched] = useState<string>("");

useEffect(()=>{
  (value?.length <=0 || value ==null)? 
    setUpdatedData(data?.data || data || [])
    : setUpdatedData(data.filter((data:any) => data.typeOfService.includes(value)))

},[value || query || []])

const handleDateFilter = (term: any, rowData:any) => {
  const today = Date.now();
  return new Date(today) < new Date(rowData.created)
    ? true
    : false;
};
const[permission,setPermission]=useState<any[]>(
  [sessionStorage.getItem('role')]
)


const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
const handleOpen = (event: React.MouseEvent<HTMLElement>,row:any) => {
  setAnchorEl(event.currentTarget);
  setUser(row);
};
const handleClose = () => {
  setAnchorEl(null);
};
const open = Boolean(anchorEl);
const [slcRow, setSlcRow] = React.useState(null);
function handleMenuClick(event:any) {
  setAnchorEl(event.currentTarget);
  //setSlcRow(row);
}
const handleOpenSubscriptionForm = (row:any) => {
  setMemberNumber(row.memberNumber);
  setFromDate(row.dateRegistered);
  setToDate(row.expiryDate);
   setOpenModalForm(true);
};
const handleCloseSubscriptionForm = () => {
  setOpenModalForm(false);
};
const handleOpenDependantsForm = (row:any) => {
  setMemberNumber(row.memberNumber);
  setOpenDependantModalForm(true);
};
const handleCloseDependantsForm = () => {
  setOpenDependantModalForm(false);
};
const rowStyle = (rowData: any, index: number) => {
  if (index % 2 === 0) {
    return { backgroundColor: '#EFEFEF' };
  }
}
  return (
    <PageContent>
      <div className={classes.root}>
        {/* <PageHeading
          title="Clients Accounts"
          links={links}
          setupPathname="/setup/users"
        /> */}

        {isLoading ? <LoadingSpinner /> : <></>}

      {isSuccess ? (
        <>

          <MaterialTable
            title="Health Benefit Management Platform"
            columns={columns}
            data={data}
            actions={[
              {
                icon: () => <MoreVertIcon  />,
                tooltip: 'Actions',
                isFreeAction: false,
                onClick: (handleOpen)
              }
           ]}
            
           options={  {
            columnsButton:true,
            exportMenu: [{
              label: 'Export PDF',
              exportFunc: (cols, datas) => ExportPdf(cols, datas, 'SubscriptionReport')
            }, {
              label: 'Export CSV',
              exportFunc: (cols, datas) => ExportCsv(cols, datas, 'SubscriptionReport')
            }],
            exportAllData: true,
            sorting: true,
            rowStyle: rowData => ({
              backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
            }),
            headerStyle: {
              backgroundColor: '#01579b',
              color: '#FFF',
            },
           }}

             />

        </>
      ) : ('<></>')}
      </div>
      <CreateUser
        queryString="clients"
        open={false}
        setOpen={setOpen}
        editing={editing}
        setEditing={setEditing}
        setEditLoading={singleUser.isLoading}
        setEditError={singleUser.isError}
      />
        <ClearPayment
        queryString="clients"
        open={opePay}
        setOpen={setOpenPay}
        editing={editing}
        setEditing={setEditing}
        setEditLoading={singleUser.isLoading}
        setEditError={singleUser.isError}
      />
        <RenewSubscription
        queryString="clients"
        open={openRenewSubscription}
        setOpen={setOpenRenewSubscription}
        editing={editing}
        setEditing={setEditing}
        setEditLoading={singleUser.isLoading}
        setEditError={singleUser.isError}
      />
      <Menu
        className={classes.root}
        id="fade-menu"
        anchorEl={anchorEl}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {/* {getAllowedActions().map((b) => ( */}
          <>
            <MenuItem onClick={handlePayment}
              classes={{ root: classes.menuItem }}
              className={
                // b.icon ? classes.menuItemWithIcon : 
                classes.menuItemNoIcon
              }
              key="Menu-Key"
            >
            <span className={classes.label}>Complete Payment</span>
            </MenuItem>
            <MenuItem onClick={handleRenewSubscription}
              classes={{ root: classes.menuItem }}
              className={
                classes.menuItemWithIcon 
                //classes.menuItemNoIcon
              }
              key="Renew"
            >
            <span className={classes.label}>Renew</span>
            </MenuItem>
            </>
        {/* ))} */}
      </Menu>

      <Dialog fullWidth maxWidth="lg" open={openModalForm} onClose={handleCloseSubscriptionForm}>
        <DialogTitle>Member Utilization</DialogTitle>
        <Grid container spacing={5}>
      <Grid item xs={12} md={12}>
      <MemberUtilization
          onCancel={handleCloseSubscriptionForm}
          setOpenModalForm={setOpenModalForm}
          MemberNumber={memberNumber}
          FromDate={fromDate}
          ToDate={toDate}
        />
      </Grid>
    </Grid>
    
      </Dialog>
      <Dialog fullWidth maxWidth="lg" open={openDependantModalForm} onClose={handleCloseDependantsForm}>
        <DialogTitle>Dependants List</DialogTitle>
        <Grid container spacing={5}>
      <Grid item xs={12} md={12}>
      <DependantsList
          onCancel={handleCloseDependantsForm}
          setOpenModalForm={setOpenDependantModalForm}
          MemberNumber={memberNumber}
        />
      </Grid>
    </Grid>
    
      </Dialog> 
    </PageContent>
  );
};

SubscriptionsList.defaultProps = {
  roleName: undefined,
  roleId: undefined,
  userTypeCode: undefined,
};

export default SubscriptionsList;
