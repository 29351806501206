/* eslint-disable @typescript-eslint/no-unused-vars */
import { createStyles, makeStyles } from "@mui/styles";
import {
  Call,
  CheckCircleOutline,
  CreateOutlined,
  EmailOutlined,
  HighlightOffOutlined,
  LocalOfferTwoTone,
  LockOpenOutlined,
  OpacityOutlined,
  RotateRightOutlined,
} from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BadError from "../components/BadError";
import LoadingSpinner, {
  RefreshingCard,
} from "../components/LoadingSpinner";
import Metadata from "../components/Metadata/Metadata";
import { ItemDetails } from "../components/Metadata/types";
import NotFoundPage from "../components/NotFound";
import PageHeading from "../components/PageHeading";
import Status from "../components/Status/Status";
import TabbedView from "../components/TabbedView";
import React, { ReactChild, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate , useParams } from "react-router-dom";
import { DSA_USER_TYPE_CODE } from "../../utils/constants";
import { is404Error } from "../../utils/helpers";
import { DataTableAction, ErrorType, TabbedViewTab } from "../../utils/types";
import useFetch from "../../utils/useFetch";
import usePostRequest from "../../utils/usePostRequest";
import { setSelectedUser } from "./subscription-logic/subscriptionSlice";
import { useForm } from "react-hook-form";
import {Button, Grid, Paper, TextField, Theme} from '@mui/material'
import { RootState } from "../../store/configureStore";
import { useAppSelector } from "../../utils/hooks";
import ClientsPointSections from "./subscriptionDetails";
import ClientPaymentDetails from "./paymentDetails";
import PatientJourney from "./PatientJourney";
import MedicalHistory from "./MedicalHistory";
import GenericDialog from "../components/GenericDialog";
import Iframe from "react-iframe";
import Swal from 'sweetalert2';
import PageContent from "../components/pageContent";

const links = [
  {
    pathname: "",
    label: "",
    permission: "",
  },
];
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {width:'100%'},
    icon: {
      fontSize: 20,
      marginRight: theme.spacing(1),
    },
    paper2: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      padding: theme.spacing(1),
    },
    inputLabel: {
      "&.focused": {
        color: "#449afe",
      },
      color: "#449afe",
    },
    paper: {
      padding: theme.spacing(2),
      // textAlign: "center",
      //color: theme.palette.text.secondary,
      width: 'auto',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      color: 'black !important',
      fontFamily: "ChronicaPro,sans-serif",
      fontSize:"16px",
      fontWeight: 400,
    },
  })
);

const SingleUser: React.FC = () => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const [remarks, setRemarks] = useState<string>("");
  const [remarksErr, setRemarksErr] = useState<false | ErrorType>(false);
  const [postErr, setPostErr] = useState<false | ErrorType>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [editing, setEditing] = useState(false);
  const [confirmAction, setConfirmAction] = useState<
    false | "activate" | "deactivate" | "reset password"
  >(false);
  const { id }: any = useParams();
  const { handleSubmit, reset } = useForm();
  const [pesapal, setPesapal]= useState<any>();
  const [AmountPaid, setAmountPaid] = useState<string>('')
  const [TransactionID, setTransactionID] = useState<string>("")
  const [PhoneNumber, setPhoneNumber] = useState<string>("")
  const [lastPayment, setLastPayment] = useState<string>("")
  

  const { selectedBookings } = useAppSelector(
    (state: RootState) => state.clients,
  );
  const {
    data,
    isLoading,
    isError,
    error,
    isRefreshing,
  } = useFetch(`/api/v1/checkups/account/get-member-details?memberno=${id}`, ["user", id]);

const subscriptionData=[data];
  const singleUser = useFetch(
    `/auth/get-single-user/?usercode=${data?.userCode}`,
    ["user", `${data?.userCode}`],
    { enabled: !!data?.userCode }
  );
  const onSuccess = (result: any) => {
    console.log("result",result);
    queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.includes("subscriptions"),
    });
    setConfirmAction(false);
    setOpen(false);
    Swal.fire({
      icon: 'success',
      title: 'STK PUSH PROMPT..',
      text: 'Successfully pushed payment prompt to client',
    })
    setRemarks("");
  };
  const onFailure = (err: ErrorType) => {
    setPostErr(err);
    setOpen(true);
  };

  useEffect(()=>{
     setPhoneNumber(subscriptionData[0]?.phoneNumber);
  },[subscriptionData[0]?.phoneNumber]);

  useEffect(()=>{
    setAmountPaid(subscriptionData[0]?.amountDue);
 },[subscriptionData[0]?.amountDue]);
 useEffect(()=>{
  setLastPayment(subscriptionData[0]?.lastPaymentDate);
},[subscriptionData[0]?.lastPaymentDate]);

 //.format("DD-MM-YYYY")
  const handleAmountPaid = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAmountPaid(event.target.value);
  };
  const handlePhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(event.target.value);
  };
  const handleTransaction = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTransactionID(event.target.value);
  };

  const mutation = usePostRequest(
    "api/v1/checkups/pay/post-mpesa-stk-push",
    {
      phoneNumber: PhoneNumber,
      totalAmount: AmountPaid,
      firstName: subscriptionData[0]?.firstName,
      idNumber: subscriptionData[0]?.idNumber,

    },
    onSuccess,
    onFailure
  );
  const onSubmit = () => {
    mutation.reset();
    mutation.mutate();
  };
  const actions = [
    {
      label: 'Pay Now',
      primary: true,
      onClick: handleSubmit(onSubmit),
    },
  ];
  
  const history = useNavigate ();
  const handleOpenModal = (
    action: "activate" | "deactivate" | "reset password"
  ) => {
    setConfirmAction(action);
    setPostErr(false);
    setRemarksErr(false);
  };
  const handleEditRow = () => {
    setEditing(true);
    setOpen(true);
  };

  const handleChangeuserStatus = () => {
    if (remarks === "") {
      setRemarksErr({ message: "Reason for this change is required" });
      return;
    }
    setPostErr(false);
    mutation.reset();
    mutation.mutate();
  };

  const handleRemarksChange = (value: string) => {
    setRemarksErr(false);
    setRemarks(value);
  };

  const userActions: DataTableAction[] = [
    {
      label: "Activate user",
      permission: ['ACTIVE'].includes(data?.status?.toUpperCase())
        ? 'ACTION-NOT-ALLOWED'
        : "",
      onClick: () => handleOpenModal("activate"),
      icon: () => <CheckCircleOutline />,
    },
    {
      label: "Deactivate user",
      permission: [undefined, '', 'INACTIVE'].includes(
        data?.status?.toUpperCase(),
      )
        ? 'ACTION-NOT-ALLOWED'
        : "",
      onClick: () => handleOpenModal("deactivate"),
      icon: () => <HighlightOffOutlined />,
    },
    {
      permission: [undefined, '', 'INACTIVE'].includes(
        data?.status?.toUpperCase(),
      )
        ? 'ACTION-NOT-ALLOWED'
        : "",
      label: "Reset password",
      onClick: () => handleOpenModal("reset password"),
      icon: () => <RotateRightOutlined />,
    },
    {
      permission: [undefined, '', 'INACTIVE'].includes(
        data?.status?.toUpperCase(),
      )
        ? 'ACTION-NOT-ALLOWED'
        : "",
      label: "Assign roles",
      onClick: () => history("/roles"),
      icon: () => <LockOpenOutlined />,
    },
    {
      permission: [undefined, '', 'INACTIVE'].includes(
        data?.status?.toUpperCase(),
      )
        ? 'ACTION-NOT-ALLOWED'
        : "",
      label: "Edit user",
      onClick: () => handleEditRow(),
      icon: () => <CreateOutlined />,
    },
  ];

  const userTypeCode = data?.userTypeCode;

  const userTypeFetch = useFetch(
    `/search/usertype/${userTypeCode}`,
    ["userType", id],
    {
      enabled: !!userTypeCode,
    }
  );


  const mainTabs: TabbedViewTab[] = [
    {
    tabId: 'Client Details',
      title: 'Client Details',
      content: <ClientsPointSections data={data} />
    },

    {
      tabId: 'Payment Details',
        title: 'Payment Details',
        content: <ClientPaymentDetails data={data} />
      },
  
    {
    tabId: 'Medical History',
    title: 'Utilization Report',
    content: <MedicalHistory data={data} />
    },
    {
      tabId: 'Package Details',
      title: 'Package Details',
      content: <PatientJourney cycleID={data?.packageCode}/>
      },
  ];
  const [activeTab, setActiveTab] = useState(
    mainTabs[0].tabId
  );
  const handleChangeActiveTab = (name: string) => {
    setActiveTab(name);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError && is404Error(error)) {
    return <NotFoundPage message={`User with userId ${id} not found`} />;
  }

  if (isError) {
    return <BadError error={error} />;
  }

  const userType = userTypeFetch?.data;
  /**
   * Entity status manipulation logic
   */

  const modalActions: {
    label: string | ReactChild;
    onClick: () => void;
    primary: boolean;
  }[] = [
    {
      label: `${confirmAction}`,
      onClick: () => {
        handleChangeuserStatus();
      },
      primary: true,
    },
  ];

  /*
   * metadata needed to display profile header content
   */
  if (!data?.status) {
    data.status = "Active";
  }

  const metaData: ItemDetails = {
    imageUrl: data?.imageUrl,
    hasImageUpload: true,
    name: subscriptionData[0]?.firstName,
    statusContainer: data && ([]
      // <Status
      //   status={data?.status}
      //   editable
      //   canActOnEntityStatus={false}
      //   confirmAction={confirmAction}
      //   setConfirmAction={setConfirmAction}
      //   remarksErr={remarksErr}
      //   remarks={remarks}
      //   type="User"
      //   handleRemarksChange={handleRemarksChange}
      //   modalActions={modalActions}
      //   mutation={mutation}
      //   postErr={postErr}
      //   open={open}
      //   setOpen={setOpen}
      //   editing={editing}
      //   setEditing={setEditing}
      //   entityActions={userActions}
      //   singleUser={singleUser}
      // />
    ),
    uploadPath: `auth/upload-profile-picture?usercode=${data?.userCode}`,
    icon: AccountCircleIcon,
    queryNameToRefresh: ["user", data?.userCode],
    boxContent: [
      {
        title: subscriptionData[0]?.memberNumber,
        icon: <LocalOfferTwoTone className={classes.icon} />,
      },
      {
        title: subscriptionData[0]?.email,
        icon: <EmailOutlined className={classes.icon} />,
      },
      {
        title: subscriptionData[0]?.phoneNumber,
        icon: <Call className={classes.icon} />,
      },
    ],
  };
 
  const handleModal = (row: any) => {
    row.preventDefault();
    setOpen(true);
    setPesapal(subscriptionData[0]?.paymentURL);
  };

  return (
    <PageContent>
    <div className={classes.root}>
      <PageHeading title="User Profile" links={links} />
      {isRefreshing ? <RefreshingCard message="Refreshing user" /> : <></>}
      <Button
            color="secondary"
            variant="outlined"
            onClick={handleModal}
            style={{ position: 'absolute', right: 10, marginTop: 50,backgroundColor:'green',color:'white'}}
          >
          EXECUTE STK PUSH
          </Button>
      <Metadata metadata={metaData} />
      <TabbedView
        tabs={mainTabs}
        activeTab={activeTab}
        onChangeActiveTab={handleChangeActiveTab}
      />

<GenericDialog
        title='COMPLETE PAYMENT'
        showDialog={open}
        onCloseDialog={() => {
          setOpen(false);
        }}
        actions={actions}
        isLoading={mutation.isLoading}
        width="sm"
        height={400}
        verticalAlign="flex-start"
      >
   <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
            <Grid container spacing={3}>  

            <Grid item xs={12} sm={12}>
            <TextField
              size="small"
              type="text"
              InputLabelProps={{
                classes: {
                  root: classes.inputLabel,
                  focused: "focused",
                  shrink: "shrink"
                }}}
              name="TransactionID"
              label="Last Payment"
              color="secondary"
              variant="outlined"
              fullWidth
              disabled
              required
              defaultValue={lastPayment }
              onChange={handleTransaction}
              autoComplete="off"
            />
          </Grid>
          {/* <Grid item xs={12} sm={12}>
            <TextField
              size="small"
              type="text"
              InputLabelProps={{
                classes: {
                  root: classes.inputLabel,
                  focused: "focused",
                  shrink: "shrink"
                }}}
              name="TransactionID"
              label="Payment Plan"
              color="secondary"
              variant="outlined"
              fullWidth
              disabled
              required
              defaultValue={TransactionID}
              onChange={handleTransaction}
              autoComplete="off"
            />
          </Grid>   */}
<Grid item xs={12} sm={12}>
            <TextField
              size="small"
              type="text"
              InputLabelProps={{
                classes: {
                  root: classes.inputLabel,
                  focused: "focused",
                  shrink: "shrink"
                }}}
              name="PhooneNumber"
              label="Phone Number"
              color="secondary"
              variant="outlined"
              fullWidth
              required
              defaultValue={PhoneNumber}
              onChange={handlePhoneNumber}
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              size="small"
              type="number"
              InputLabelProps={{
                classes: {
                  root: classes.inputLabel,
                  focused: "focused",
                  shrink: "shrink"
                }}}
              name="AmountPaid"
              label="Amount to Pay"
              color="secondary"
              variant="outlined"
              fullWidth
              required
              defaultValue={AmountPaid}
              onChange={handleAmountPaid}
              autoComplete="off"
            />
          </Grid>
          {mutation.isLoading ? <LoadingSpinner /> : <></>}
</Grid>
</Paper>
</Grid>
      {/* <Grid container spacing={1} className={classes.paper2}>
                <Grid item md={12} xs={12} xl={12} lg={12}>                    
              <Iframe url={pesapal}
                width="100%"
                height="600px"
                id="myId"
                target={"_blank"}
                className="myClassname"                                    
                position="relative"/>                                                
            </Grid>
             </Grid> */}

  
      </GenericDialog>
             
    </div>
    </PageContent>
  );
};

export default SingleUser;
