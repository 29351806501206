import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link  from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { makeStyles,createStyles } from '@mui/styles';
import { Backdrop, CircularProgress, FormControl, FormLabel, Radio, RadioGroup, Stack, Theme } from '@mui/material';
import {Navigate, useNavigate, Link as Links} from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast, Flip } from "react-toastify";
import { useEffect, useState } from 'react';
import useAuthFormStyles from "./authStyles";
import {strengthColor,strengthIndicator} from './strength-password'
import { useAppDispatch } from '../../utils/hooks';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://checkupsmed.com">
        Checkups Medical Hub
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
const useStyles = makeStyles((theme: Theme) => createStyles({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    inputLabel: {
      "&.focused": {
        color: "#449afe",
      },
      color: "#449afe",
    },
}));

export default function SignIn() {
const navigate = useNavigate();
const classes = useStyles();
const classes2 = useAuthFormStyles();
const [email, setEmail]=useState<string>("");
const [password, setPassword]=useState<string>("")
const [ message, setMessage]= useState<string>("");
const [confirmPassword, setConfirmPassword]=useState<string>("");
const [confirmColor, setConfirmColor]=useState<string>("black");
const [disbale, setDisable] = useState<boolean>(true);
const [ Errmessage, setErrMessage]= useState<string>("");
const [firstName, setfirstName] = useState<String>("");
const [middleName, setmiddleName] = useState<String>("");
const [lastName, setlastName] = useState<String>("");
const [phoneNumber, setphoneNumber] = useState<String>("");
const [gender, setGender] = useState<String>("");
const [open, setOpen] = React.useState(false);

const handleFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
  setfirstName(event.target.value);
};
const handleMiddleName = (event: React.ChangeEvent<HTMLInputElement>) => {
  setmiddleName(event.target.value);
};
const handleLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
  setlastName(event.target.value);
};
const handlephoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
  setphoneNumber(event.target.value);
};
const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  setGender((event.target as HTMLInputElement).value);
};
const handlePassword=(event:any)=>{
  setPassword(event.target.value);
  setErrMessage("");
  }
  const handleEmail=(event:any)=>{
    setEmail(event.target.value);
    setMessage("");
    }

  const handleConfirmPassword=(event:any)=>{
    setConfirmPassword(event.target.value);
    const pass=event.target.value;
    if(pass==password){
      setConfirmColor("green");
    }else{
      setConfirmColor("red");
    }
    setErrMessage("");
  }
useEffect(()=>{
  password.length>=8 ? setDisable(false) : setDisable(true)
},[password])
const strength = strengthIndicator(password);
const color=strengthColor(strength);

const dispatch = useAppDispatch();
const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
  setOpen(!open);
  event.preventDefault();
  let params = {
    email,
    phoneNumber,
    middleName,
    firstName,
    lastName,
    gender,
    password:password,
    confirmPassword:confirmPassword,
    acceptTerms:true
    };
    !disbale && 
    password==confirmPassword ?(
    axios
      .post("https://dashboardapi.checkupsmed.com:1006/api/v1/checkups/account/register-user", params)
      .then(function (response) {
        //   IF EMAIL ALREADY EXISTS
        if (response.status !== 200) {
          toast.error("Wrong Data", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: 0,
            toastId: "my_toast",
          });
        } else {
          toast.success("Registration successful, Please contact IT team for account verification", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: 0,
            toastId: "my_toast",
          });
        setOpen(false);
        setMessage(response.data.message);
        setTimeout(() => {
          navigate("/");
        }, 3000);
        }
      })

      .catch(function (error) {
        setOpen(false)
        console.log(error);
      })) :
      (    
      setErrMessage("First and second password does not match ")
      
      );

};

const handleClick = (e:any) => {
  navigate("/dashboard");
}
  return (
    <div className={classes2.root}>
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <form className={classes.form} noValidate>
        <TextField
               required
              type="text"
              name="firstName"
              label="FirstName"
              color="secondary"
              variant="outlined"
              margin="normal"
              fullWidth
              defaultValue={firstName}
              onChange={handleFirstName}
              autoComplete="off"
            />
            <TextField
              required
              type="text"
              name="middleName"
              label="MiddleName"
              color="secondary"
              variant="outlined"
              margin="normal"
              fullWidth
              defaultValue={middleName}
              onChange={handleMiddleName}
              autoComplete="off"
            />
            <TextField
              type="text"
              name="lastName"
              label="LastName"
              required
              variant="outlined"
              margin="normal"
              fullWidth
              defaultValue={lastName}
              onChange={handleLastName}
              autoComplete="off"
            />
            <TextField
              required
              type="text"       
              name="phoneNumber"
              margin="normal"
              label="Phone Number"
              color="secondary"
              variant="outlined"
              fullWidth
              defaultValue={phoneNumber}
              onChange={handlephoneNumber}
              autoComplete="off"
            />
          <FormControl>
          <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={gender}
                onChange={handleChange}
              >
                <FormControlLabel value="female" control={<Radio />} label="Female" />
                <FormControlLabel value="male" control={<Radio />} label="Male" />
              </RadioGroup>
            </FormControl>

          <TextField
            variant="outlined"
            margin="normal"
            required
            onChange={(event:any)=>handleEmail(event)}
            fullWidth
            id="email"
            value={email}
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
         <TextField
            margin="normal"
            sx={{
              ".css-x2l1vy-MuiInputBase-root-MuiOutlinedInput-root": {
                borderColor: "white",
              },

            }}
            required
            onChange={(event:any)=>handlePassword(event)}
            value={password}
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="off"
            InputProps={{
              sx: {
                ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                  borderColor: color,
                  borderWidth: "2px",
                  borderStyle:"solid"
                },
                "&:hover": {
                  ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    borderColor: color,
                    borderWidth: "2px",
                    borderStyle:"solid"
                  },
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: color,
                    borderWidth: "2px",
                    borderStyle:"solid" },
                  "&:hover fieldset": {borderColor: color,
                    borderWidth: "2px",
                    borderStyle:"solid" },
                  "&.Mui-focused fieldset": { borderColor: color,
                    borderWidth: "2px",
                    borderStyle:"solid" },
              },
              },
            }}
          />
                 <TextField
               variant="outlined"
               color="secondary"
            margin="normal"
            required
            onChange={(event:any)=>handleConfirmPassword(event)}
            value={confirmPassword}
            fullWidth
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            id="confirmPassword"
            disabled={disbale}
            autoComplete="off"            
            InputProps={{
              sx: {
                ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                  borderColor: confirmColor,
                  borderWidth: "2px",
                  borderStyle:"solid"
                },
                "&:hover": {
                  ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    borderColor: confirmColor,
                    borderWidth: "2px",
                    borderStyle:"solid"
                  },
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: confirmColor,
                    borderWidth: "2px",
                    borderStyle:"solid" },
                  "&:hover fieldset": {borderColor: confirmColor,
                    borderWidth: "2px",
                    borderStyle:"solid" },
                  "&.Mui-focused fieldset": { borderColor: confirmColor,
                    borderWidth: "2px",
                    borderStyle:"solid" },
              },
              },
            }}
          />
          <Button
            type="submit"
            fullWidth
            onClick={(event:any)=>handleSubmit(event)}
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Register
          </Button>
          <Grid container>
          <Grid item xs>
              <Links to="/">
                Sighin?
              </Links>
            </Grid>
            </Grid>
          <Box mt={8} style={{marginTop:10}} >
    <Typography color="text.primary" align="center" sx={{ color: 'error.main' }}>
      {message}
    </Typography>       
          </Box>
        </form>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
        limit={1}
        transition={Flip}
      />
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>

    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <Stack gap={1} justifyContent="center" alignItems="center">
          <CircularProgress color="inherit" />
          <Typography>Registering...</Typography>
        </Stack>
      </Backdrop>
      
    </div>
  );
}

function dispatch(arg0: any) {
  throw new Error('Function not implemented.');
}
