import { TextField, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import { ErrorType } from "../../utils/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      marginBottom: theme.spacing(2),
    },
  })
);

type Props = {
  type: string;
  label: string;
  value: any;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: any) => void;
  error: false | ErrorType;
  autoFocus?: boolean;
};
const GenericFormField: React.FC<Props> = ({
  label,
  value,
  onChange,
  error,
  type,
  autoFocus,
}: Props) => {
  const classes = useStyles();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <TextField
      size="small"
      InputLabelProps={{ shrink: true }}
      color="secondary"
      variant="outlined"
      className={classes.root}
      autoFocus={!!autoFocus}
      autoComplete="off"
      label={label}
      type={type}
      fullWidth
      error={!!error}
      helperText={error ? error.message : ""}
      defaultValue={value}
      onChange={handleChange}
    />
  );
};

GenericFormField.defaultProps = {
  autoFocus: false,
};

export default GenericFormField;
