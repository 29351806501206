import React from "react";
import { Box, Typography, styled } from "@mui/material";

export interface EnrollmentData {
  isEnrolled: number;
  isMessagedSent: number;
  isMessagedReceived: number;
  hasResponded: number;
  isQuoteSent: number;
  isRefillBooked: number;
  isQuoteAccepted: number;
  quotationAcceptedCreatedDate: string;
  isTicketCreated: number;
  ticketCreatedDate: string;
  withPharmacy: number;
  pharmacyCreatedDate: string;
  withBilling: number;
  billingCreatedDate: string;
  withDispatch: number;
  dispatchCreatedDate: string;
  withRider: number;
  riderCreatedDate: string;
  isDelivered: number;
  orderArrivalDate: string;
}

interface ProgressTrackerProps {
  enrollmentData?: EnrollmentData;
}

const steps = [
  { label: "Enrolled", key: "isEnrolled" },
  { label: "Message sent", key: "isMessagedSent" },
  { label: "Message received", key: "isMessagedReceived" },
  { label: "Responded", key: "hasResponded" },
  { label: "Quote sent", key: "isQuoteSent" },
  { label: "Refill booked", key: "isRefillBooked" },
];

interface StepIconProps {
  active?: boolean;
  completed?: boolean;
  icon: React.ReactNode;
}

const ColorlibStepIconRoot = styled("div")<StepIconProps>(
  ({ theme, active, completed }) => ({
    backgroundColor: theme.palette.grey[300],
    zIndex: 1,
    color: "#fff",
    width: 32,
    height: 32,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(active && {
      backgroundColor: theme.palette.primary.main,
      boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    }),
    ...(completed && {
      backgroundColor: theme.palette.primary.main,
    }),
  })
);

function ColorlibStepIcon({ active, completed, icon }: StepIconProps) {
  return (
    <ColorlibStepIconRoot
      active={active}
      completed={completed}
      icon={undefined}
    >
      {icon}
    </ColorlibStepIconRoot>
  );
}

const StepConnector = styled(Box)(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.grey[300],
  height: 1,
  flex: 1,
}));

const ProgressTracker: React.FC<ProgressTrackerProps> = ({
  enrollmentData,
}) => {
  // Handle the case where enrollmentData is not passed
  if (!enrollmentData) {
    return (
      <Typography variant="caption">Loading enrollment data...</Typography>
    );
  }

  const updatedSteps = steps.map((step) => ({
    ...step,
    completed: enrollmentData[step.key as keyof EnrollmentData] === 1,
  }));

  return (
    <Box sx={{ width: "100%", p: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {updatedSteps.map((step, index) => (
          <React.Fragment key={step.label}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <ColorlibStepIcon
                icon={index + 1}
                active={index === 0} // Can customize active step logic
                completed={step.completed}
              />
              <Typography variant="caption" sx={{ mt: 1, textAlign: "center" }}>
                {step.label}
              </Typography>
            </Box>
            {index < updatedSteps.length - 1 && <StepConnector />}
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
};

export default ProgressTracker;
