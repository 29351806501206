import React from "react";
import {  Box, Typography } from "@mui/material";

interface LabelTextFieldProps {
  label: string;
  value: string;
}

const LabelTextField: React.FC<LabelTextFieldProps> = ({ label, value }) => {
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="body1">{label}</Typography>
      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
        {value}
      </Typography>
    </Box>
  );
};

export default LabelTextField;
