import { ButtonBase, buttonClasses, ButtonProps } from "@mui/material";
import { styled, lighten, alpha } from "@mui/material/styles";

type StytedButtonProps = {
  onClick: () => void;
  color?: string;
  bgColor?: string;
  padding?: string;
  text: string;
  disabled?: boolean;
};
interface ActionButtonProps extends ButtonProps {
  $color?: string;
  $bgColor?: string;
  $padding?: string;
}
export default function StyledButton({
  onClick,
  color,
  bgColor,
  padding,
  text,
  disabled,
}: StytedButtonProps) {
  return (
    <ButtonMui
      variant="text"
      onClick={onClick}
      $color={color}
      $bgColor={bgColor}
      $padding={padding}
      disabled={disabled}
    >
      {text}
    </ButtonMui>
  );
}

const ButtonMui = styled(
  ({ $bgColor, $color, $padding, ...rest }: ActionButtonProps) => (
    <ButtonBase {...rest} />
  )
)(({ theme, $color, $bgColor, $padding }) => ({
  [`&, .${buttonClasses.root}`]: {
    color: $color ? $color : "white",
    textTransform: "capitalize",
    display: "flex",
    alignItems: "center",
    height: 40,
    padding: `0px ${$padding ? $padding : "16px"}`,
    borderRadius: "6px",
    background: $bgColor ? $bgColor : "#4C9DF8",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "0px",
    justifyItems: "center",
    gap: "8px",
    justifyContent: "center",

    "&.Mui-disabled": {
      backgroundColor: "#EDF2F7",
      color: "#A0AEC0",
      pointerEvents: "none",
      cursor: "not-allowed",
    },
    "&:hover": {
      backgroundColor: alpha($bgColor ?? "#EDF2F7", 0.9),
    },
    "&:focus": {
      backgroundColor: "#E2E8F0",
    },
  },
  color: $color ? $color : "white",
  textTransform: "capitalize",
  display: "flex",
  alignItems: "center",
  height: 40,
  padding: `0px ${$padding ? $padding : "16px"}`,
  borderRadius: "6px",
  background: $bgColor ? $bgColor : "#4C9DF8",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "24px",
  letterSpacing: "0px",
  justifyItems: "center",
  gap: "8px",
  justifyContent: "center",
  transition: theme.transitions.create([
    "border-color",
    "background-color",
    "box-shadow",
  ]),

  [`& .${buttonClasses.root}:hover`]: {
    backgroundColor: lighten($bgColor ?? "#EDF2F7", 0.6),
  },
  "&:focus": {
    backgroundColor: "#E2E8F0",
  },
}));
