import React, { forwardRef, ReactNode } from 'react';
import { Icon } from '@iconify/react';
import { Box, BoxProps } from '@mui/material';

interface IconifyProps {
  icon: string;
  width?: number | string;
  sx?: BoxProps['sx'];
  children?: ReactNode;
}

const Iconify = forwardRef<HTMLDivElement, IconifyProps>((props, ref) => {
  const { icon, width = 20, sx, ...other } = props;

  return (
    <Box ref={ref} component={Icon} icon={icon} sx={{ width, height: width, ...sx }} {...other} />
  );
});


export default Iconify;
