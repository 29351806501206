/* eslint-disable @typescript-eslint/no-unused-vars */
import NoNavLayoutLayout from "../components/NoNavLayout";
import React from "react";
import { render } from "react-dom";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import DefaultLayout from "../components/DefaultLayout";
import NotFoundPage from "../components/NotFound";
// import useCheckPermissions from '../auth/auth-logic/useCheckPermissions';
import Register from "../auth/sighup";
import Login2 from "../auth/sighin";
import ForgotPassword from "../auth/ForgotPassword";
import ResetPassword from "../auth/ResetPassword";
import ChronicPatients from "../PatientUtilization/ChronicPatientsList";
import VisitRecords from "../PatientUtilization/VisitRecords";
import Ticketing from "../Ticketing/Ticketing";
import SingleTicketsData from "../Ticketing/SingleTicketsData";
import AppointmentList from "../Appointment/AppointmentList";
import OrderList from "../Orders/OrderList";
import VisitSummaryList from "../VisitSummary/VisitSummaryList";
import TestList from "../Tests/TestList";
import NurseAllocation from "../NurseAllocation/NurseAllocation";
import CreateTicket from "../Ticketing/CreateTicket";
import GenerateTicket from "../Ticketing/GenerateTicket";
import Clients from "../dashboard/DashboardList";
import MainDashboard from "../dashboard/DashboardList";
import ClientDetails from "../dashboard/SingleClientsData";
import SubscriptionsList from "../subscription/SubscriptionsList";
import SubscriptionsAgents from "../subscription/SubscriptionsAgents";
import SingleSubscription from "../subscription/SingleSubscriptionData";
import PackageList from "../packages/PackageList";
import Patients from "../PatientReport/PatientsWellnessReport";
import SingleFollowup from "../packages/packageDetails";
import Agents from "../agent-accounts/AgentList";
import UsersList from "../user-accounts/UsersList";
import ReportDetails from "../PatientReport/SingleReportData";
import PatientRequestList from "../subscription/PatientRequestList";
import VisitSummaryReport from "../PatientUtilization/SingleClientsData";
import InsurancePatients from "../enroll-patient/InsurancePatients";
import EnrollPatients from "../enroll-patient/EnrollPatients";
import EnrollDetails from "../enroll-patient/EnrollDetails";
import { ProtectedRoute } from "../auth/ProtectedRoute";
import ROTES, { RouteType } from "./routeInfo";
import StockReportList from "../StockReport/StockReportList";
import InsuranceSetup from "../insurance-setup/InsuranceSetup";
import { SignalRProvider } from "../messaging/SignalRContext";
import ChatPage from "../messaging/ChatPage";
import AdminInsurancePatients from "../enroll-patient/AdminInsurancePatients";
import InsuranceScheme from "../insurance-scheme/insurance-scheme";

const Rotes = () => (
  <SignalRProvider>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login2 />}></Route>
        <Route path="/register" element={<Register />}></Route>
        <Route path="/forgot-password" element={<ForgotPassword />}></Route>
        <Route path="/reset-password/:id" element={<ResetPassword />}></Route>
        <>
          <Route
            path="/dashboard"
            element={
              <DefaultLayout>
                <ProtectedRoute>
                  <MainDashboard />
                </ProtectedRoute>
              </DefaultLayout>
            }
          ></Route>
          <Route
            path="/subscription"
            element={
              <DefaultLayout>
                <ProtectedRoute>
                  <SubscriptionsList />
                </ProtectedRoute>
              </DefaultLayout>
            }
          ></Route>
          {/*    <Route path="/NurseAllocation" element={
           
           <DefaultLayout>
            <ProtectedRoute>
            <NurseAllocation/>
            </ProtectedRoute>
          </DefaultLayout>}>
      
        </Route>  */}
          <Route
            path="/StockReportList"
            element={
              <DefaultLayout>
                <ProtectedRoute>
                  <StockReportList />
                </ProtectedRoute>
              </DefaultLayout>
            }
          ></Route>
          <Route
            path="/ticketing"
            element={
              <DefaultLayout>
                <ProtectedRoute>
                  <Ticketing />
                </ProtectedRoute>
              </DefaultLayout>
            }
          ></Route>
          <Route
            path="/appointments"
            element={
              <DefaultLayout>
                <ProtectedRoute>
                  <AppointmentList />
                </ProtectedRoute>
              </DefaultLayout>
            }
          ></Route>
          <Route
            path="/orders"
            element={
              <DefaultLayout>
                <ProtectedRoute>
                  <OrderList />
                </ProtectedRoute>
              </DefaultLayout>
            }
          ></Route>

          <Route
            path="/VisitSummary"
            element={
              <DefaultLayout>
                <ProtectedRoute>
                  <VisitSummaryList />
                </ProtectedRoute>
              </DefaultLayout>
            }
          ></Route>
          <Route
            path="/new-ticket"
            element={
              <DefaultLayout>
                <ProtectedRoute>
                  <CreateTicket />
                </ProtectedRoute>
              </DefaultLayout>
            }
          ></Route>
          {/* <Route path="/agentsaccount" element={
                  <MainDashboard />
                </ProtectedRoute>
              </DefaultLayout>
            }
          ></Route>
          <Route
            path="/subscription"
            element={
              <DefaultLayout>
                <ProtectedRoute>
                  <SubscriptionsList />
                </ProtectedRoute>
              </DefaultLayout>
            }
          ></Route>
          <Route
            path="/ticketing"
            element={
              <DefaultLayout>
                <ProtectedRoute>
                  <Ticketing />
                </ProtectedRoute>
              </DefaultLayout>
            }
          ></Route>
          <Route
            path="/appointments"
            element={
              <DefaultLayout>
                <ProtectedRoute>
                  <AppointmentList />
                </ProtectedRoute>
              </DefaultLayout>
            }
          ></Route>
          <Route
            path="/orders"
            element={
              <DefaultLayout>
                <ProtectedRoute>
                  <OrderList />
                </ProtectedRoute>
              </DefaultLayout>
            }
          ></Route>
          <Route
            path="/new-ticket"
            element={
              <DefaultLayout>
                <ProtectedRoute>
                  <CreateTicket />
                </ProtectedRoute>
              </DefaultLayout>
            }
          ></Route>
          {/* <Route path="/agentsaccount" element={
           
           <DefaultLayout>
            <ProtectedRoute>
            <SubscriptionsAgents />
            </ProtectedRoute>
          </DefaultLayout>}>
      
        </Route> */}
          <Route
            path="/subscriptiondetails/:id"
            element={
              <DefaultLayout>
                <ProtectedRoute>
                  <SingleSubscription />
                </ProtectedRoute>
              </DefaultLayout>
            }
          ></Route>
          <Route
            path="/ticketdetails/:id"
            element={
              <DefaultLayout>
                <ProtectedRoute>
                  <VisitSummaryReport />
                </ProtectedRoute>
              </DefaultLayout>
            }
          ></Route>
          <Route
            path="/summaryreport/:id"
            element={
              <DefaultLayout>
                <ProtectedRoute>
                  <VisitSummaryReport />
                </ProtectedRoute>
              </DefaultLayout>
            }
          ></Route>

          <Route
            path="patient-details"
            element={
              <DefaultLayout>
                <ProtectedRoute>
                  <GenerateTicket />
                </ProtectedRoute>
              </DefaultLayout>
            }
          ></Route>
          <Route
            path="test-results"
            element={
              <DefaultLayout>
                <ProtectedRoute>
                  <TestList />
                </ProtectedRoute>
              </DefaultLayout>
            }
          ></Route>

          <Route
            path="/package"
            element={
              <DefaultLayout>
                <ProtectedRoute>
                  <PackageList />
                </ProtectedRoute>
              </DefaultLayout>
            }
          ></Route>
          <Route
            path="/followupdetails/:reminderdate"
            element={
              <DefaultLayout>
                <ProtectedRoute>
                  <SingleFollowup />
                </ProtectedRoute>
              </DefaultLayout>
            }
          ></Route>
          <Route
            path="/clientdetails/:id"
            element={
              <DefaultLayout>
                <ProtectedRoute>
                  <ClientDetails />
                </ProtectedRoute>
              </DefaultLayout>
            }
          ></Route>
          <Route
            path="patient-medical-history"
            element={
              <DefaultLayout>
                <ProtectedRoute>
                  <ChronicPatients />
                </ProtectedRoute>
              </DefaultLayout>
            }
          ></Route>
          <Route
            path="patient-utilizations"
            element={
              <DefaultLayout>
                <ProtectedRoute>
                  <VisitRecords />
                </ProtectedRoute>
              </DefaultLayout>
            }
          ></Route>

          <Route
            path="payments"
            element={
              <DefaultLayout>
                <ProtectedRoute>
                  <PatientRequestList />
                </ProtectedRoute>
              </DefaultLayout>
            }
          ></Route>

          <Route
            path="/reportdetails/:id"
            element={
              <DefaultLayout>
                <ProtectedRoute>
                  <ReportDetails />
                </ProtectedRoute>
              </DefaultLayout>
            }
          ></Route>
          <Route
            path="/agent-accounts"
            element={
              <DefaultLayout>
                <ProtectedRoute>
                  <Agents />
                </ProtectedRoute>
              </DefaultLayout>
            }
          ></Route>
          <Route
            path="/patients"
            element={
              <DefaultLayout>
                <ProtectedRoute>
                  <Patients />
                </ProtectedRoute>
              </DefaultLayout>
            }
          ></Route>
          <Route
            path="/agentsaccount"
            element={
              <DefaultLayout>
                <ProtectedRoute>
                  <SubscriptionsAgents />
                </ProtectedRoute>
              </DefaultLayout>
            }
          ></Route>
          <Route
            path="/user-accounts"
            element={
              <DefaultLayout>
                <ProtectedRoute>
                  <UsersList />
                </ProtectedRoute>
              </DefaultLayout>
            }
          ></Route>
          <Route
            path="/enroll-list"
            element={
              <DefaultLayout>
                <ProtectedRoute>
                  <InsurancePatients />
                </ProtectedRoute>
              </DefaultLayout>
            }
          ></Route>
          <Route
            path="/admin-enroll-list/:id"
            element={
              <DefaultLayout>
                <ProtectedRoute>
                  <AdminInsurancePatients />
                </ProtectedRoute>
              </DefaultLayout>
            }
          ></Route>
          <Route
            path="/enroll-patient/:id"
            element={
              <DefaultLayout>
                <ProtectedRoute>
                  <EnrollPatients />
                </ProtectedRoute>
              </DefaultLayout>
            }
          ></Route>
          <Route
            path="/enroll-details/:id"
            element={
              <DefaultLayout>
                <ProtectedRoute>
                  <EnrollDetails />
                </ProtectedRoute>
              </DefaultLayout>
            }
          ></Route>
          <Route
            path="/insurance-setup"
            element={
              <DefaultLayout>
                <ProtectedRoute>
                  <InsuranceSetup />
                </ProtectedRoute>
              </DefaultLayout>
            }
          ></Route>
          <Route
            path="/insurance-scheme"
            element={
              <DefaultLayout>
                <ProtectedRoute>
                  <InsuranceScheme />
                </ProtectedRoute>
              </DefaultLayout>
            }
          ></Route>
          {/* <Route
            path="/messaging"
            element={
              <DefaultLayout>
                <ProtectedRoute>
                  <Messaging />
                </ProtectedRoute>
              </DefaultLayout>
            }
          ></Route> */}
          <Route
            path="/messaging"
            element={
              <DefaultLayout>
                <ProtectedRoute>
                  <div>
                    <ChatPage />
                  </div>
                </ProtectedRoute>
              </DefaultLayout>
            }
          ></Route>
        </>

        {/* {ROTES.map(({ path, name, component, layout }) => (
       <RouteItem
       key={path}
       name={name}
       component={component}
       path={path}
       layout={layout}
     />
  
   ))}  */}
        <Route
          path="/"
          element={() => {
            ROTES.map(({ path, name, component, layout }) => (
              <RouteItem
                key={path}
                name={name}
                component={component}
                path={path}
                layout={layout}
              />
            ));
          }}
        >
          {" "}
        </Route>

        {/* 404 page */}
        <Route
          path="*"
          element={
            <DefaultLayout>
              <NotFoundPage />
            </DefaultLayout>
          }
        ></Route>
      </Routes>
    </BrowserRouter>
  </SignalRProvider>
);

export const AgentRotes = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Login2 />}></Route>
      <Route path="/register" element={<Register />}></Route>
      <Route path="/forgot-password" element={<ForgotPassword />}></Route>
      <Route path="/reset-password/:id" element={<ResetPassword />}></Route>
      <>
        <Route
          path="/agentsaccount"
          element={
            <DefaultLayout>
              <ProtectedRoute>
                <SubscriptionsAgents />
              </ProtectedRoute>
            </DefaultLayout>
          }
        ></Route>
      </>

      {/* 404 page */}
      <Route
        path="*"
        element={
          <DefaultLayout>
            <NotFoundPage />
          </DefaultLayout>
        }
      ></Route>
    </Routes>
  </BrowserRouter>
);
type RouteProps = Omit<RouteType, "showOnNav" | "icon">;
export const RouteItem = ({
  path,
  component: Component,
  layout: Layout,
}: RouteProps) => {
  // const { isAuthenticated, isAuthorised } = useCheckPermissions(permission);
  const Token = sessionStorage.getItem("auth");

  return (
    <Route
      path={path}
      element={
        <Layout>
          <Component />
        </Layout>
      }
      // render(
      //() => (
      // permission !== '' ? (
      //   <Redirect to={{ pathname: '/', state: { from: path } }} />
      // ) : (
      //   <Layout>
      //     <Component />
      //   </Layout>
      // )
      // <></>
      // }
    />
  );
};

export default Rotes;
