import { Grid, Typography,Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { ExpandMore, FiberManualRecord } from "@mui/icons-material";
import { Alert } from "@mui/lab";
import GenericDialog from "../../components/GenericDialog";
import GenericFormField from "../../components/GenericFormField";
import LoadingSpinner from "../../components/LoadingSpinner";
import TableActionMenu from "../../components/TableActionMenu";
import React from "react";
import CreateUser from "../../agent-accounts/CreateAgent";
import { EntityStatusOption } from "../../../utils/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 150,
      width: "fit-content",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      marginLeft: theme.spacing(-0.5),
    },
    editable: {
      marginLeft: theme.spacing(0.5),
      padding: theme.spacing(1),
      justifyContent: "space-around",
      cursor: "pointer",
      borderRadius: 4,
      border: `1px solid ${theme.palette.grey[300]}`,
    },
    text: {
      fontSize: "0.9rem",
      alignSelf: "center",
      textTransform: "capitalize",
      color: theme.palette.text.secondary,
    },
    active: {
      color: "#2e9705",
    },

    inactive: {
      color: theme.palette.error.main,
    },
    userCode: {
      margin: theme.spacing(3, 0),
      fontWeight: 900,
    },
    action: {
      fontWeight: 900,
      textTransform: "uppercase",
      padding: theme.spacing(0, 0.7),
      textDecoration: "underline",
    },
    pending: {
      color: "#ff7707",
    },
    dispatched: {
      color: "#2e9705",
    },
    pendingPayment: {
      color: "#ccd639",
    },
    complete: {
      color: "#3cd400",
    },
    batchNumber: {
      margin: theme.spacing(3, 0),
      fontWeight: 900,
    },
    ended: {
      color: "#54ff10",
    },

    paused: {
      color: theme.palette.error.main,
    },

    inProgress: {
      color: "#1e6900",
    },
  })
);

type Props = {
  status: EntityStatusOption;
  editable?: boolean;
  remarksRequired?: boolean;
  canActOnEntityStatus: boolean;
  confirmAction: any;
  setConfirmAction: any;
  remarksErr: any;
  remarks: string;
  type: string;
  handleRemarksChange: any;
  modalActions: any;
  mutation: any;
  postErr: any;
  open: boolean;
  setOpen: any;
  setEditing: any;
  editing: any;
  entityActions: any;
  singleUser: any;
};
const Status: React.FC<Props> = ({
  status,
  editable,
  canActOnEntityStatus,
  confirmAction,
  setConfirmAction,
  remarksErr,
  remarks,
  type,
  handleRemarksChange,
  modalActions,
  mutation,
  postErr,
  open,
  setOpen,
  editing,
  setEditing,
  entityActions,
  singleUser,
  remarksRequired,
}: Props) => {
  const classes = useStyles();
  const getStatusAndClassName = (): any[] => {
    if (!status) {
      status = "Active";
    }
    switch (status?.toLowerCase()) {
      case "active":
        return [status?.toLowerCase(), classes.active];
      case "Pending":
        return [status?.toLowerCase(), classes.complete];
      case "complete":
        return [status?.toLowerCase(), classes.complete];
      case "canceled":
        return [status?.toLowerCase(), classes.inactive];
      case "Inactive":
        return [status?.toLowerCase(), classes.inactive];
      case "inprogress":
        return [status.toLowerCase(), classes.inProgress];
      default:
        return [status?.toLowerCase() || "-", classes.pending];
    }
  };

  const modalContent = () => {
    switch (confirmAction) {
      default:
        return (
          <div style={{ marginTop: 10 }}>
            {remarksRequired ? (
              <GenericFormField
                label="Reason"
                type="text"
                error={remarksErr}
                autoFocus
                value={remarks}
                onChange={handleRemarksChange}
              />
            ) : (
              <></>
            )}
          </div>
        );
    }
  };

  const StatusInfo = () => (
    <Grid
      classes={{ root: classes.root }}
      className={editable ? classes.editable : ""}
      item
      style={{
        cursor: editable && canActOnEntityStatus ? "pointer" : "default",
      }}
    >
      <FiberManualRecord
        fontSize="small"
        className={getStatusAndClassName()[1]}
      />
      <Typography className={classes.text}>
        {getStatusAndClassName()[0]}
      </Typography>
      {editable && canActOnEntityStatus && <ExpandMore />}
    </Grid>
  );

  if (!editable || !canActOnEntityStatus) {
    return StatusInfo();
  }

  return (
    <>
      {confirmAction ? (
        <>
          <GenericDialog
            title={`${confirmAction}?`}
            showDialog={!!confirmAction}
            onCloseDialog={() => setConfirmAction(false)}
            actions={modalActions}
            isLoading={mutation?.isLoading}
            height={200}
            width="sm"
            verticalAlign="space-around"
          >
            {mutation?.isLoading ? <LoadingSpinner /> : <></>}
            {postErr ? (
              <Alert className="error-container" severity="error">
                {postErr.message}
              </Alert>
            ) : (
              <></>
            )}
            <p>
              <span>Are you sure you would like to</span>
              <span className={classes.action}>{confirmAction}</span>
              <span>
                {`${
                  confirmAction === "reset password" ? "for " : ""
                }this ${type}?`}
              </span>
            </p>
            {modalContent()}
          </GenericDialog>
        </>
      ) : (
        <></>
      )}
      <CreateUser
        queryString="user"
        open={open}
        setOpen={setOpen}
        editing={editing}
        setEditing={setEditing}
        setEditLoading={singleUser?.isLoading}
        setEditError={singleUser?.isError}
      />
      <TableActionMenu actions={entityActions} entryBtn={StatusInfo} />
    </>
  );
};

Status.defaultProps = { editable: false, remarksRequired: true };
export default Status;
