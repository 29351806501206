import { createStyles, makeStyles } from "@mui/styles";
import React, {  useEffect, useState } from "react";
import {Button, CircularProgress, Link, Theme} from '@mui/material'
import { filterBySearch } from "../../utils/filters";
import { DataTableColumn } from "../../utils/types";
import { formatAsLocalDate } from "../../utils/formatDates";
import TableHeader from "../components/TableHeader";
import DataTable from "../components/Table";
import { mapValues } from "lodash";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
  }),
);

type MedicalHistoryProps = {
  data: [];
};

const MedicalHistory = ({
  data,
}: MedicalHistoryProps) => {
  const classes = useStyles();

  const [query, setQuery] = useState('');

  const rows = filterBySearch(query, [data]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [message, setMessage]=useState<string>("");
  const [isSubmitting,setisSubmitting]=useState<boolean>(false);
  const handleClick=(memberNo:any)=>{ 
    setisSubmitting(true)
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ memberNumber: memberNo })
    };
    fetch('https://dashboardapi.checkupsmed.com:1006/api/v1/checkups/package/generate-member-report', requestOptions)
        .then(response => response.json())
        .then(data => setMessage(data));  
        setTimeout(() => setisSubmitting(false), 5000);    
  }
  // useEffect(()=>{
  //     setTimeout(() => setisSubmitting(false), 5000);      
  // },[message])
  const columns: DataTableColumn[] = [
    {
      id: "memberNumber",
      label: "Action",
      minWidth: 150,
      format: (value: any, row: any) => (
      <Button onClick={(e:any)=>handleClick(row.memberNumber)} disabled={isSubmitting}>
         {isSubmitting && <CircularProgress size={14} />}
      {!isSubmitting && 'Generate Report'}
       </Button>)
    },
    {
      id: "memberNumber",
      label: "Medical Report",
      format: (value: any, row: any) => (
       <>
       {value?.length > 1 ?(
       <Link target="_blank"  href={`http://197.248.142.25:1005/Medication/MemberReport?MemberNumber=${value}.pdf`}>
        View Report
       </Link>
       ):(
       <span>....</span>
       )}
       </>
       )
     //format: (value: any, row: any) => <Link href="https://google.com">Google 1</Link>
     },
      {
        id: 'HistoryDescription',
        label: 'Clinician Comment',
        minWidth: 150,
      },

    {
      id: 'created',
      label: 'Previous Update',
      minWidth: 150,
      format: (date) => formatAsLocalDate(date),
    },

  ];

  return (
    <div className={classes.root}>
      <div>
        <TableHeader onSearch={setQuery} query={query} />
        <DataTable
          page={page}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setPage={setPage}
          rows={rows?.map((r, index) => ({ ...r, number: index + 1 }))}
          columns={columns}
        />
      </div>
    </div>
  );
};

export default MedicalHistory;
