export function convertToInternationalFormat(phoneNumber: string) {
  // Check if the phone number starts with '0' and is 10 digits long
  if (phoneNumber.startsWith("0") && phoneNumber.length === 10) {
    // Replace the leading '0' with '254'
    return "254" + phoneNumber.slice(1);
  } else {
    return phoneNumber;
  }
}

export const formatTimestamp = (timestamp: string) => {
  const currentTime = new Date();
  const messageTime = new Date(timestamp);

  // Check if the message is older than 24 hours
  const timeDifference = currentTime.getTime() - messageTime.getTime();
  const oneDay = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

  if (timeDifference > oneDay) {
    // Format to dd/mm/yyyy
    const day = String(messageTime.getDate()).padStart(2, "0");
    const month = String(messageTime.getMonth() + 1).padStart(2, "0");
    const year = messageTime.getFullYear();
    return `${day}/${month}/${year}`;
  }

  // Otherwise, return the time in hh:mm format (24-hour clock)
  return messageTime.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
};
