export enum USER_ROLES {
    NONE = "None",
    SUPERADMIN = "SuperAdmin",
    ADMIN = "Admin",
    FINANCE = "Finance",
    CLINICIAN = "Clinician",
    NURSES = "Nurses",
    CUSTOMER_SERVICE = "CustomerService",
    USER = "User",
    RIDERS = "Riders",
    CLAIMS = "Claims",
    PHARMACY = "Pharmacy",
    DISPATCH = "Dispatch",
    AGENT = "Agent",
    INSURANCE_PROVIDER = "InsuranceProvider",
    BILLING = "Billing",
    RECEPTIONIST = "Receptionist"
}

export const roleEnumMapping = {
    None: 0,
    SuperAdmin: 1,
    Admin: 2,
    Finance: 3,
    Clinician: 4,
    Nurses: 5,
    CustomerService: 6,
    User: 7,
    Riders: 8,
    Claims: 9,
    Pharmacy: 10,
    Dispatch: 11,
    Agent: 12,
    InsuranceProvider: 13,
    Billing: 14,
    Receptionist: 15
} as const;