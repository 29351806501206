import {  Box, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Paper, Radio, RadioGroup, Snackbar, TextField, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Alert } from "@mui/lab";
import GenericDialog from "../components/GenericDialog";
import LoadingSpinner from "../components/LoadingSpinner";
import React, { ChangeEvent, useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import { ErrorType } from "../../utils/types";
import usePostRequest from "../../utils/usePostRequest";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { createUserSuccess } from "../agent-accounts/agent-accounts-logic/profileSlice";
import SelectFromApi from "../components/SelectFromApi";
import useFetch from "../../utils/useFetch";
import userEvent from "@testing-library/user-event";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
    },

    inputStyle: {
      width: 400,
      marginBottom: theme.spacing(2),
    },
    autoComplete: {
      marginBottom: theme.spacing(1),
    },
    main: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
      zIndex: theme.zIndex.appBar - 1,
      outline: "none",
    },
    grid: {
      display: "grid",
      gridTemplateColumns: "1fr",
    },
    paper: {
      padding: theme.spacing(2),
      // textAlign: "center",
      //color: theme.palette.text.secondary,
      width: 'auto',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      color: 'black !important',
      fontFamily: "ChronicaPro,sans-serif",
      fontSize:"16px",
      fontWeight: 400,
    },
    formCustomTitle: {
      fontWeight:700,
      color: "#449afe",
      fontSize:"1.3rem",
      fontFamily: "ChronicaPro,sans-serif",
      width: 400,
    },
    CustomTitle: {
      fontWeight:700,
      color: "#022c5b",
      fontSize:"1.0rem! important",
      fontFamily: "ChronicaPro,sans-serif",
      width: 400,
    },
    inputLabel: {
      "&.focused": {
        color: "#449afe",
      },
      color: "#449afe",
    },
    errorText: {
      color: 'red',
      fontSize: 'small',
    },
  })
);

type ClearPaymentProps = {
  queryString: string;
  open: boolean;
  ticketNo: string;
  // eslint-disable-next-line no-unused-vars
  setOpen: (value: boolean) => void;
  // eslint-disable-next-line no-unused-vars
};

function AddVisitSummary({
  queryString,
  open,
  setOpen,
  ticketNo
}: ClearPaymentProps) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const {
    data,
    isLoading,
    isError,
    error,
    isRefreshing,
  } = useFetch(`api/v1/checkups/tickets/get-single-ticket?TicketNo=${ticketNo}`, ["Ticket", ticketNo]);
  const [openSnack, setOpenSnack] = useState(true);
  const { selectedUser } = useAppSelector((state) => state.subscription);
  const [comment, setcomment] = useState<string>('');
  const [rate, setrate] = useState<string>('');
  const { handleSubmit, reset } = useForm();
  const [postError, setPostError] = useState<false | ErrorType>(false);
  const [commentError,setCommentError]=useState<string>("");

  
  var UserID =sessionStorage.getItem('UserID');
  const onSuccess = ({ data }: any) => {
    queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.includes("ViewFeedBack"),
    });
    dispatch(createUserSuccess(data));
    setOpen(false);
    queryClient.invalidateQueries(`${queryString}`);
    
    // Clear the input fields
    setcomment("");
    setrate ("");
  };
  const onFailure = (err: ErrorType) => {
    setPostError(err);
  };
  const handleRating = (event: any) => {
    setrate(event.target.value);
  };


  const handleChange = (value: string) => {
    setcomment(value); // value is the updated content of the editor
  };
  // const handleChange = (event:any) => {
  //   const { name, value } = event.target;
  //   switch (name) {
  //     case 'comment':
  //       setcomment(value);
  //       setCommentError('');
  //       break;
  //       case 'rate':
  //         setrate(value);
  //         break;
  //       default:
  //         break;
  //     }
  //   };
  const processedPayload =()=> ({
   surveyID:ticketNo,
   comment,
   rate,
  
  });
    const mutation=  usePostRequest(
        "api/v1/checkups/tickets/client-feedback",
        processedPayload(),
        onSuccess,
        onFailure
      ); 
  const onSubmit = () => {
  
    if (comment.trim() === '') {
      setCommentError('Comment is required.'); // Display a warning if the field is empty
      return;
    }
    mutation.reset();
    mutation.mutate();
  };
  const handleClose = () => {
    mutation.reset();
    reset();
  };

  const actions = [
    {
      label: 'Submit Now',
      primary: true,
      onClick: handleSubmit(onSubmit),
    },
  ];

  return (
    <div>
      {mutation.isSuccess && (
        <Snackbar
          open={openSnack}
          onClose={() => setOpenSnack(false)}
          autoHideDuration={6000}
        >
          <Alert onClose={handleClose} severity="success">
              "Visit Summary Added Successfully"
          </Alert>
        </Snackbar>
      )}
  <GenericDialog
        title='Add Visit Summary'
        showDialog={open}
        onCloseDialog={() => {
          setOpen(false);
        }}
        actions={actions}
        isLoading={mutation.isLoading}
        width="sm"
        height={300}
        verticalAlign="flex-start"
      >
          {mutation.isLoading ? <LoadingSpinner /> : <></>}
          {/* <div className={classes.grid}> */}
            {postError ? (
              <Alert severity="error" className="error-container">
                {postError?.message}
              </Alert>
            ) : (
              <></>
            )}
            <Grid container spacing={3}>
                 {/* <DialogTitle className={classes.formCustomTitle}>
                    Ticket No: {ticketNo}
                </DialogTitle>  */}
              {/* <Grid item xs={12} sm={12}>
                <TextField
                      label="Visit Summmary"
                      type="text"
                      autoFocus
                      multiline
                      fullWidth
                      name="comment"
                      value={comment}
                      onChange={handleChange}
                  />   
              </Grid> */}

              <Grid item xs={12} sm={12}>
                <ReactQuill
                  value={comment}
                  onChange={handleChange}
                  modules={{
                    toolbar: [
                      [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
                      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                      ['bold', 'italic', 'underline', 'strike'],
                      [{ 'align': [] }],
                      ['link', 'image'], // image for file attachment
                      ['clean'] // remove formatting button
                    ]
                  }}
                  formats={[
                    'header', 'font', 'list', 'bold', 'italic', 'underline', 'strike', 'align', 'link', 'image'
                  ]}
                  style={{
                    height: '350px',  // Increase height
                    width: '100%'  // Keep width at 100%
                  }}
                />
              </Grid>
              {/* <Grid item xs={12} sm={12}>
                <DialogTitle>Client Service Rating</DialogTitle>
                  <DialogContent>
                    <RadioGroup aria-label="options" name="options" value={rate} onChange={handleRating}>
                        <FormControlLabel value="5" control={<Radio />} label="★★★★★: Very Satisfied" />
                        <FormControlLabel value="4" control={<Radio />} label="★★★★☆: Satisfied" />
                        <FormControlLabel value="3" control={<Radio />} label="★★★☆☆: Neutral" />
                        <FormControlLabel value="2" control={<Radio />} label="★★☆☆☆: Dissatisfied" />
                        <FormControlLabel value="1" control={<Radio />} label="★☆☆☆☆: Very Dissatisfied" />
                    </RadioGroup>
                </DialogContent>
              </Grid> */}
             </Grid>
      </GenericDialog>
    </div>
  );
}

export default AddVisitSummary;
