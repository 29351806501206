import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import Conversation from "./Conversation";
import { useSignalR } from "./SignalRContext";
import AddCommentIcon from "@mui/icons-material/AddComment";
import Toast, { showToast } from "../components/Toast";
import Swal from "sweetalert2";
import { convertToInternationalFormat, formatTimestamp } from "../../utils";

export interface Message {
  senderName: string;
  senderPhoneNumber: string;
  receiverName: string;
  receiverPhoneNumber: string;
  content: string;
  timestamp: string;
}

const ChatPage: React.FC = () => {
  const phoneNumberId = sessionStorage.getItem("phoneNumberId");
  const clientId = sessionStorage.getItem("clientId");
  const { connection } = useSignalR();
  const [messages, setMessages] = useState<Message[]>([]);
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [content, setContent] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  // Function to handle modal open
  const handleOpen = () => {
    setOpen(true);
  };

  // Function to handle modal close
  const handleClose = () => {
    setOpen(false);
  };

  // Function to handle phone number input change
  const handlePhoneNumberChange = (e: any) => {
    const value = e.target.value;
    // Allow only numbers and limit input to 11 digits
    if (/^\d*$/.test(value)) {
      setPhoneNumber(value);
    }
  };

  // Function to handle send button click
  const handleSend = async () => {
    const messageData = {
      receiverPhoneNumber: phoneNumber,
      content: content,
      type: "send",
      insurancePhoneNumber: phoneNumberId,
    };

    try {
      handleClose();
      Swal.fire({
        text: "Sending...",
        didOpen: () => {
          Swal.showLoading();
        },
        allowOutsideClick: false,
      });
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL3}api/RealTimeMessaging/send`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(messageData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to send message");
      }

      await response.json();
      Swal.close();
      showToast("success", "Message sent");
      setContent("");
      setPhoneNumber("");
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_API_URL3}api/RealTimeMessaging/all?clientId=${clientId}`
        );
        const data: Message[] = await response.json();
        setMessages(data);
      } catch (error) {
        console.error("Failed to fetch messages:", error);
      }
    };

    fetchMessages();

    if (connection) {
      connection.on(
        "ReceiveMessage",
        (
          senderName,
          senderPhoneNumber,
          receiverName,
          receiverPhoneNumber,
          content,
          timestamp
        ) => {
          setMessages((prevMessages: Message[]) => [
            ...prevMessages,
            {
              senderName,
              senderPhoneNumber,
              receiverName,
              receiverPhoneNumber,
              content,
              timestamp,
            },
          ]);
        }
      );
    }
  }, [connection, clientId]);

  const handleUserClick = (phoneNumber: string) => {
    setSelectedUser(phoneNumber);
  };

  // Sorting messages by most recent first
  const sortedMessages = messages.filter(
    (msg) =>
      msg.senderPhoneNumber === selectedUser ||
      msg.receiverPhoneNumber === selectedUser
  );

  const getUniqueSenders = (messages: Message[]) => {
    const uniqueSenders: { [key: string]: Message } = {};
    const formattedNumber = convertToInternationalFormat(phoneNumberId ?? "");
    const excludedNumbers = [phoneNumberId, formattedNumber];

    // Iterate over the messages in reverse order to prioritize newer messages
    [...messages].reverse().forEach((msg) => {
      // Add sender if not excluded and not already added
      if (
        !uniqueSenders[msg.senderPhoneNumber] &&
        !excludedNumbers.includes(msg.senderPhoneNumber)
      ) {
        uniqueSenders[msg.senderPhoneNumber] = msg;
      }

      // Add receiver (if not the sender and not excluded)
      if (
        !uniqueSenders[msg.receiverPhoneNumber] &&
        !excludedNumbers.includes(msg.receiverPhoneNumber)
      ) {
        uniqueSenders[msg.receiverPhoneNumber] = {
          ...msg,
          senderPhoneNumber: msg.receiverPhoneNumber,
          senderName: msg.receiverName,
          receiverPhoneNumber: msg.senderPhoneNumber,
          receiverName: msg.senderName,
        };
      }
    });

    return Object.values(uniqueSenders);
  };

  // Filter unique senders by search query
  const filteredSenders = getUniqueSenders(messages)
    .filter(
      (msg) =>
        msg.senderPhoneNumber.includes(searchQuery) ||
        msg.receiverPhoneNumber.includes(searchQuery)
    )
    .sort(
      (a, b) =>
        new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
    ); // Sort by timestamp (most recent first)

  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <Toast />
      {/* Sidebar with user list */}
      <Paper
        style={{ width: "30%", padding: "16px", borderRight: "1px solid #ddd" }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          style={{ marginTop: "16px" }}
        >
          <Typography variant="h6">Messages</Typography>
          <AddCommentIcon
            style={{ cursor: "pointer", color: "#e5383b", marginTop: "5px" }}
            onClick={handleOpen}
          ></AddCommentIcon>
        </Box>
        <Typography variant="body2" color="textSecondary">
          Keep track and follow up conversations
        </Typography>
        <TextField
          label="Search by Phone Number"
          variant="outlined"
          fullWidth
          margin="normal"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <List>
          {filteredSenders.map((msg, index) => (
            <React.Fragment key={index}>
              <ListItem
                button
                selected={selectedUser === msg.senderPhoneNumber}
                onClick={() => handleUserClick(msg.senderPhoneNumber)}
                style={{ alignItems: "flex-start" }}
              >
                <Avatar
                  style={{ marginRight: "8px", backgroundColor: "#e5383b" }}
                >
                  {msg.senderName ? msg.senderName[0].toUpperCase() : "U"}
                </Avatar>
                <div style={{ flex: 1 }}>
                  <Typography variant="subtitle1">
                    {msg.senderName || msg.senderPhoneNumber}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {msg.content}
                  </Typography>
                </div>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  style={{ marginLeft: "auto" }}
                >
                  {formatTimestamp(msg.timestamp)}{" "}
                  {/* Use the formatTimestamp function */}
                </Typography>
              </ListItem>
              <Divider style={{ backgroundColor: "#ddd" }} />
            </React.Fragment>
          ))}
        </List>
      </Paper>

      {/* Modal */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle style={{ fontSize: "18px" }}>New Message</DialogTitle>
        <DialogContent>
          <TextField
            label="Phone Number"
            variant="outlined"
            fullWidth
            margin="normal"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
          />
          <TextField
            label="Message Content"
            variant="outlined"
            fullWidth
            margin="normal"
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSend} color="primary">
            Send
          </Button>
        </DialogActions>
      </Dialog>

      {/* Conversation window */}
      <Paper style={{ flex: 1, display: "flex", flexDirection: "column" }}>
        {selectedUser ? (
          <Conversation
            user={selectedUser}
            messages={sortedMessages.map((msg) => ({
              ...msg,
              // Format the timestamp conditionally, but only for this component
              formattedTimestamp: formatTimestamp(msg.timestamp),
            }))}
          />
        ) : (
          <Typography variant="h6" style={{ margin: "16px" }}>
            Select a user to start chatting
          </Typography>
        )}
      </Paper>
    </div>
  );
};

export default ChatPage;
