import {  FormControl, FormControlLabel, FormLabel, Grid, Paper, Radio, RadioGroup, Snackbar, TextField, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Alert } from "@mui/lab";
import GenericDialog from "../components/GenericDialog";
import LoadingSpinner from "../components/LoadingSpinner";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useAppDispatch } from "../../utils/hooks";
import { ErrorType } from "../../utils/types";
import usePostRequest from "../../utils/usePostRequest";
import { createUserSuccess } from "../agent-accounts/agent-accounts-logic/profileSlice";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
    },

    inputStyle: {
      width: 400,
      marginBottom: theme.spacing(2),
    },
    autoComplete: {
      marginBottom: theme.spacing(1),
    },
    main: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
      zIndex: theme.zIndex.appBar - 1,
      outline: "none",
    },
    grid: {
      display: "grid",
      gridTemplateColumns: "1fr",
    },
    paper: {
      padding: theme.spacing(2),
      // textAlign: "center",
      //color: theme.palette.text.secondary,
      width: 'auto',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      color: 'black !important',
      fontFamily: "ChronicaPro,sans-serif",
      fontSize:"16px",
      fontWeight: 400,
    },
    formCustomTitle: {
      fontWeight:700,
      color: "#449afe",
      fontSize:"1.3rem",
      fontFamily: "ChronicaPro,sans-serif",
      width: 400,
    },
    CustomTitle: {
      fontWeight:700,
      color: "#022c5b",
      fontSize:"1.0rem! important",
      fontFamily: "ChronicaPro,sans-serif",
      width: 400,
    },
    inputLabel: {
      "&.focused": {
        color: "#449afe",
      },
      color: "#449afe",
    },
    errorText: {
      color: 'red',
      fontSize: 'small',
    },
  })
);



type ClearPaymentProps = {
  queryString: string;
  open: boolean;
  ticketNo: string;
  orderID: string;
  PDepartmentID: number,
  // eslint-disable-next-line no-unused-vars
  setOpen: (value: boolean) => void;
  // eslint-disable-next-line no-unused-vars
};

function CompleteOrder({
  queryString,
  open,
  setOpen,
  ticketNo,
  orderID,
  PDepartmentID
}: ClearPaymentProps) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const [openSnack, setOpenSnack] = useState(true);
  const [isRefillDate, setISRefillDate] = useState<string>("");
  const [invoiceError, setInvoiceError] = useState('');
  const { handleSubmit, reset } = useForm();
  const [postError, setPostError] = useState<false | ErrorType>(false);
  const [commentError, setCommentError] = useState<string>("");
  // const [assignedTo, setassignedTo] = useState<string>("");
  const [refillDate, setRefillDate] = useState<string>("");
  const [comment, setcomment] = useState<string>("");

  var UserID =sessionStorage.getItem('UserID');

  const onSuccess = ({ data }: any) => {
    queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.includes("Orders"),
    });
    dispatch(createUserSuccess(data));
    setOpen(false);
    queryClient.invalidateQueries(`${queryString}`);
    
    // Clear the input fields
    setInvoiceError('');
  };
  const onFailure = (err: ErrorType) => {
    setPostError(err);
  };


  const handleChange = (event:any) => {
    const { name, value } = event.target;
    switch (name) {
      case 'comment':
        setcomment(value);
        setCommentError('');
        break;
        case 'isRefillDate':
          setISRefillDate(value);
          break;
            case 'refillDate':
              setRefillDate(value);
              break;     
        default:
          break;
      }
    };

  const mutation = usePostRequest(
    "api/v1/checkups/tickets/patient-refill",
    {
      ticketNo,
      refillDate:refillDate ? refillDate : null,
      userID: UserID,
      refillComment:comment? comment : ''
    },
    onSuccess,
    onFailure
  );

  const onSubmit = () => {
    if (refillDate.trim() === '' && isRefillDate==="Yes") {
      setInvoiceError('Refill Date is required.'); // Display a warning if the field is empty
      return;
    }
    if (comment.trim() === '' && isRefillDate==="No") {
      setCommentError('Comment is required.'); // Display a warning if the field is empty
      return;
    }
    if (isRefillDate.trim() === '') {
      setInvoiceError('Filled required.'); // Display a warning if the field is empty
      return;
    }
    mutation.reset();
    mutation.mutate();
  };


  const handleClose = () => {
    mutation.reset();
    reset();
  };

  const actions = [
    {
      label: 'Submit Now',
      primary: true,
      onClick: handleSubmit(onSubmit),
    },
  ];

  return (
    <div>
      {mutation.isSuccess && (
        <Snackbar
          open={openSnack}
          onClose={() => setOpenSnack(false)}
          autoHideDuration={6000}
        >
          <Alert onClose={handleClose} severity="success">
              "Ticket Successfully Assigned"
          </Alert>
        </Snackbar>
      )}

      <GenericDialog
        title='Complete Order'
        showDialog={open}
        onCloseDialog={() => {
          setOpen(false);
        }}
        actions={actions}
        isLoading={mutation.isLoading}
        width="sm"
        height={400}
        verticalAlign="flex-start"
      >
       
          {mutation.isLoading ? <LoadingSpinner /> : <></>}
          {/* <div className={classes.grid}> */}
            {postError ? (
              <Alert severity="error" className="error-container">
                {postError?.message}
              </Alert>
            ) : (
              <></>
            )}
            
            <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>    
            <Grid item xs={12} sm={12}>
            
          <FormControl component="fieldset">
          <FormLabel>Is Refill Date Applicable?</FormLabel>
            <RadioGroup
              name="isRefillDate"
              value={isRefillDate}
              onChange={handleChange}
              row
            >
                <FormControlLabel
                value="Yes"
                control={<Radio />}
                label="Yes"
              />
              <FormControlLabel
                value="No"
                control={<Radio />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        </Paper>
        {isRefillDate &&(
            <Paper className={classes.paper}>   
         {/* {convertTicket==="Accepted"? (
           <>
        <Grid item xs={12} sm={12} sx={{mb:2}}>
          <TextField
            name="invoiceNo"
            label="Invoice Number"
            value={invoiceNo}
            required
            onChange={handleChange}
            fullWidth
          />
          {invoiceError && <p style={{ color: 'red' }}>{invoiceError}</p>}
          </Grid>
         </>
         ):(<></>)} */}
        {isRefillDate==="Yes" ? (
         <Grid item xs={12} sm={12}>
          <TextField
            name="refillDate"
            label="Refill Date"
            type="date"
            placeholder="mm/dd/yyyy"
            value={refillDate}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
              style: { position: 'absolute', backgroundColor: '#FFF', padding: '0 4px' },
            }}
            fullWidth
            required
          />
      
        </Grid>

        ):(<></>)}
      {isRefillDate==="No" ? (
         <Grid item xs={12} sm={12}>
          <TextField
            name="comment"
            label="Comment"
            value={comment}
            onChange={handleChange}
            required
            fullWidth
          />
         {commentError && <p style={{ color: 'red' }}>{commentError}</p>}
          </Grid>
     ):(<></>)}

       {/* <Grid item xs={12} sm={12}>
          <TextField
            name="DeliveryTime"
            label="Expected Delivery Time"
            type="datetime-local"
            onChange={handleChange}
            fullWidth
            InputLabelProps={{
              shrink: true, 
            }}
          />
        </Grid> */}
        
          </Paper>
        )}
          </Grid>
          </Grid>

          {/* </div> */}
  
      </GenericDialog>
    </div>
  );
}

export default CompleteOrder;
