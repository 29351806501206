import React from "react";
import { List, ListItem, Paper, Typography } from "@mui/material";
import MessageInput from "./MessageInput";
import logo from "../../assets/checkups_logo.png";

interface Message {
  senderName: string;
  senderPhoneNumber: string;
  receiverName: string;
  receiverPhoneNumber: string;
  content: string;
  timestamp: string;
  receiverAvatar?: string;
}

interface ConversationProps {
  user: string;
  messages: Message[];
}

const Conversation: React.FC<ConversationProps> = ({ user, messages }) => {
  const senderName =
    messages.find((msg) => msg.senderPhoneNumber === user)?.senderName ||
    messages.find((msg) => msg.receiverPhoneNumber === user)?.receiverName ||
    "Unknown Sender";

  const senderPhoneNumber =
    messages.find((msg) => msg.senderPhoneNumber === user)?.senderPhoneNumber ||
    "";
  const formatTime = (timestamp: string) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  return (
    <Paper
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#f8f9fa",
      }}
    >
      {/* Header with Avatar and Sender Name */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "16px",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#fff",
        }}
      >
        <div
          style={{
            width: 40,
            height: 40,
            borderRadius: "50%",
            backgroundColor: "#e5383b",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: 12,
            color: "white",
            fontWeight: "bold",
            fontSize: 16,
          }}
        >
          {senderName.charAt(0).toUpperCase()}
        </div>
        <Typography
          variant="h6"
          style={{
            textShadow: "0px 1px 3px rgba(0, 0, 0, 0.2)",
          }}
        >
          {senderName}
        </Typography>
      </div>

      {/* Messages */}
      <List style={{ flex: 1, overflowY: "auto", padding: "16px" }}>
        {messages.map((msg, index) => (
          <ListItem
            key={index}
            style={{
              justifyContent:
                msg.senderPhoneNumber === user ? "flex-start" : "flex-end",
              marginBottom: 16, // Space between each message
              padding: 0, // Remove default padding
            }}
          >
            <div style={{ display: "flex", alignItems: "flex-end" }}>
              {/* Avatar for sender */}
              {msg.senderPhoneNumber === user && (
                <div
                  style={{
                    width: 30,
                    height: 30,
                    borderRadius: "50%",
                    backgroundColor: "#e5383b",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: 8,
                    color: "white",
                    fontWeight: "bold",
                    fontSize: 16,
                  }}
                >
                  {msg.senderName.charAt(0).toUpperCase()}
                </div>
              )}
              {/* Message bubble for sender */}
              {msg.senderPhoneNumber === user && (
                <div
                  style={{
                    maxWidth: "60%",
                    padding: "8px 16px",
                    borderRadius: "12px",
                    backgroundColor: "#fff",
                    color: "black",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography variant="body1">{msg.content}</Typography>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    style={{ marginTop: "4px" }}
                  >
                    {formatTime(msg.timestamp)}
                  </Typography>
                </div>
              )}
              {/* Message bubble for receiver */}
              {msg.senderPhoneNumber !== user && (
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  <div
                    style={{
                      width: 30,
                      height: 30,
                      borderRadius: "0%",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundImage: `url(${logo})`, // Receiver's avatar image
                      marginLeft: 8,
                    }}
                  ></div>
                  <div
                    style={{
                      maxWidth: "60%",
                      padding: "8px 16px",
                      borderRadius: "12px",
                      backgroundColor: "#1976d2",
                      color: "white",
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: 8,
                    }}
                  >
                    <Typography variant="body1">{msg.content}</Typography>
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      style={{ marginTop: "4px" }}
                    >
                      {formatTime(msg.timestamp)}
                    </Typography>
                  </div>
                </div>
              )}
            </div>
          </ListItem>
        ))}
      </List>

      {/* Message input for sending messages */}
      <MessageInput
        selectedUser={user}
        senderName={senderName}
        senderPhoneNumber={senderPhoneNumber}
      />
    </Paper>
  );
};

export default Conversation;
