/* eslint-disable @typescript-eslint/no-unused-vars */
import { createStyles, makeStyles } from "@mui/styles";
import LoadingSpinner from "../components/LoadingSpinner";
import PageContent from "../components/pageContent";
import Table from "../components/Table";
import React, { useEffect, useState } from "react";
import {  useAppSelector } from "../../utils/hooks";
import { useNavigate } from 'react-router-dom';
import {
  DataTableColumn,
  DataTableRow,
} from "../../utils/types";
import useFetch from "../../utils/useFetch";
import {  User } from "./dashboard-logic/dashboardSlice";
import { Box, Button, Card, CircularProgress, Divider, Fade, IconButton, Link, ListItemIcon, ListItemText, Menu, MenuItem, Tab, Tabs, TextField, Theme } from "@mui/material";
import EmptyList from "../components/EmptyList";
import MaterialTable from "@material-table/core";
import MoreVertIcon  from '@mui/icons-material/MoreVert';
import { ExportCsv, ExportPdf } from '@material-table/exporters';
import TableHeader from "../components/TableHeader";
import { Label } from "@mui/icons-material";
import ClearPayment from "../subscription/ClearPayment";
import AssignTicket from "./AssignAppointment";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
    },
    //root:{},
    name: {
      textTransform: "capitalize",
    },
    formField: {
      marginLeft: '4px',
      marginTop: '30px',
      [theme.breakpoints.down('md')]: {
        width: '20%',
        alignContent: 'center ',
      },
  },
  searchBox: {
    padding: theme.spacing(2, 0),
    width: "99%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  colHeader: {
    "&:hover": {
      color: "red"
    }
  },


  menuItem: {
    minWidth: 200,
    margin: theme.spacing(1, 2),
    backgroundColor: theme.palette.grey[100],
    borderRadius: '3px',
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.grey[700],
    },
  },
  menuItemNoIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuItemWithIcon: {
    display: 'grid',
    gridTemplateColumns: '50px 1fr',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    fontWeight: 800,
    fontSize: '0.95rem',
  },
  unstyledButton: {
    textTransform: 'none',
    background: 'none',
    '&:hover': {
      background: 'none',
    },
  },


}));

interface Invoice {
  status: string;
}

interface Tabz {
  value: string;
  label: string;
  color: string;
  count: number | undefined;
}

const links = [
  {
    pathname: "",
    label: "",
    permission: "",
  },
];

type Props = {
  roleName?: string;
  roleId?: string;
  userTypeCode?: string;
};
const AppointmentList: React.FC<Props> = ({
  roleName,
  roleId,
  userTypeCode,
}: Props) => {
  const classes = useStyles();
  const history = useNavigate();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [query, setQuery] = useState("");
  const [user, setUser] = useState<User>();
 const Token =sessionStorage.getItem('auth');
  const { selectedUser } = useAppSelector((state) => state.login);
 //const [data, SetnewData]=useState<string[]>([]);
  const [value, setValue] = React.useState<string>('00000');
  const [phone, setPhone] = React.useState<string>('');
  const [selectedRow, setSelectedRow] = useState(null);
  const [ticketNo, setTicketNo] = React.useState<string>('');
  const [appointmentNo, setAppointmentNo] = React.useState<string>('');
  const [editing, setEditing] = useState(false);
  const [opePay, setOpenPay]=useState(false);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value);
  };
  const handleSubmit = () => {
    setValue(phone);
  };
  const makeUrl = (defaultPath: string) =>
    query && !userTypeCode
      ? `/auth/system-user-fuzzy-search-user?searchterm=${query}`
      : defaultPath;

    // useFetch(`/${subset}`, `fetchBreeds-${subset}`);
      const {
        data,
        isLoading,
        isError,
        error,
        isRefreshing,
        isSuccess
      } = useFetch('appointments', ["Appointments"],
    { enabled: !!value });
    // useFetch(`api/v1/checkups/patient/patient-medication-cycle?PageNumber=${page}&PageSize=${rowsPerPage}`, ["PatientVisit", `${page}`,`${rowsPerPage}`],
    // { enabled: !!value });

    //const optionsData = data?.data || data || [] ;
    const [filterStatus, setFilterStatus] = useState<string>('all');

    const dataFiltered = applyFilter({
      inputData: isSuccess ? data : [],
      filterStatus,
      // filterStartDate,
      // filterEndDate,
    });
    const handleFilterStatus = (event: React.ChangeEvent<{}>, newValue: string) => {
      setFilterStatus(newValue);
    };
    
    const getLengthByStatus = (status: string): number => data && data.filter((item:any) => item.status === status).length;
  // color?: "inherit" | "action" | "disabled" | "primary" | "secondary" | "error" | "info" | "success" | "warning" | undefined;
  const TABS:Tabz[] = [
    { value: 'all', label: 'All', color: 'info', count: data?.length },
    { value: 'Pending', label: 'Pending', color: 'black', count: getLengthByStatus('Pending') },
    { value: 'Assigned', label: 'Assigned  ', color: 'secondary', count: getLengthByStatus('Assigned') },
    { value: 'Confirmed', label: 'Confirmed', color: 'warning', count: getLengthByStatus('Confirmed') },
    { value: 'Enroute', label: 'Enroute', color: 'primary', count: getLengthByStatus('Enroute') },
    { value: 'Arrived', label: 'Arrived', color: 'secondary', count: getLengthByStatus('Arrived') },
    { value: 'Completed', label: 'Completed', color: 'success', count: getLengthByStatus('Completed') },
    { value: 'Declined', label: 'Declined', color: 'error', count: getLengthByStatus('Declined') },
  ];

  const [message, setMessage]=useState<string>("");
  const [isSubmitting,setisSubmitting]=useState<boolean>(false);
  const handleClickNewBtn = () => {
    history('/new-ticket'); 
  };
  const handleClick=(patNo:any)=>{ 
    setisSubmitting(true)
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ patientNumber: patNo })
    };
    fetch('https://dashboardapi.checkupsmed.com:1006/api/v1/checkups/patient/patient-usage-report', requestOptions)
        .then(response => response.json())
        .then(data => setMessage(data));  
        setTimeout(() => setisSubmitting(false), 5000);    
  }
  const [columns, setColumns] = useState([
    {
      title:<div className={classes.colHeader}>Appointment No.</div>,
      field: "appointmentID",
      type: "string" as const,
     // minWidth: 30,
    },
    {
      title:<div className={classes.colHeader}>Client Name</div>,
      field: "clientName",
      type: "string" as const,
     // minWidth: 100,
    },
    {
      title:<div className={classes.colHeader}>Phone Number</div>,
      field: "clientPhone",
      type: "string" as const,
     // minWidth: 100,
    },
    {
      title:<div className={classes.colHeader}>Location</div>,
      field: "location",
      type: "string" as const,
      //minWidth: 150,
    },
    {
      title:<div className={classes.colHeader}>Payment Method</div>,
      field: "paymentMethod",
      type: "string" as const,
      //minWidth: 100,
    },
    {
      title:<div className={classes.colHeader}>Intake Date</div>,
      field: "intakeDate",
      type: "string" as const,
      //minWidth: 100,
    },
    {
      title:<div className={classes.colHeader}>Amount</div>,
      field: "amount",
      type: "string" as const,
      //minWidth: 150,
    },
    {
      title:<div className={classes.colHeader}>Status</div>,
      field: "status",
      type: "string" as const,
      //minWidth: 50,
    },
    {
      title:<div className={classes.colHeader}>Actions</div>,
      render: (rowData: any) => (
        <Button
        variant="text"
        startIcon={<AccountCircleIcon fontSize="small" />}
        onClick={(event: any) => handlePayment(event, rowData)}
      >
        Assign
      </Button>
        // <IconButton onClick={(event: any) => handlePayment(event, rowData)}>
        //   <MoreVertIcon />
        // </IconButton>
      ),
    },
    
  ]);

const rows: DataTableRow[] = isSuccess
? userTypeCode
  ?data && data
  : data && data
: [];

const handlePayment = (event: React.MouseEvent<HTMLElement>,row:any) => {
  setOpenPay(true);
  setTicketNo(row.ticketNumber);
  setAppointmentNo(row.appointmentID);
};

const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
const handleOpen = (event: React.MouseEvent<HTMLElement>,row:any) => {
  setAnchorEl(event.currentTarget);
  setUser(row);
  setTicketNo(row.ticketNumber);
};
const handleClose = () => {
  setAnchorEl(null);
};
const open = Boolean(anchorEl);
const [slcRow, setSlcRow] = React.useState(null);
function handleMenuClick(event:any) {
  setAnchorEl(event.currentTarget);
  //setSlcRow(row);
}


  return (
    <PageContent>
      <div className={classes.root}>

        {isLoading ? <LoadingSpinner /> : <></>}
        {/* {isFetching ? <RefreshingCard message="Refreshing Clients" /> : <></>} */}
        
      {isSuccess ? (
        <>
       <Divider sx={{mt:2}} />
       {/* <TableHeader
            onClickAddNew={handleClickNewBtn}
            onSearch={setQuery}
            query={query}
            addNewLabel="NEW TICKET"
          /> */}
     {/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Box>
          <Button
            color="secondary"
            variant="outlined"
            onClick={handleClickNewBtn}
            style={{ margin: 10, backgroundColor: 'green', color: 'white' }}
          >
            NEW TICKET
          </Button>
        </Box>
      </div> */}
      {/* <Card> */}
      <Box sx={{ bgcolor: 'background.default' }}>
        <Tabs
          value={filterStatus}
          onChange={handleFilterStatus}
          sx={{
            px: 2,
            bgcolor: 'background.paper',
            width: '100%',
          }}
          variant="fullWidth"
        >
          {TABS.map((tab) => (
            <Tab
              key={tab.value}
              value={tab.value}
              label={tab.label}
              icon={
                <Box component="span" color={tab.color} sx={{ mr: 1 }}>
                  {tab.count}
                </Box>
              }
              sx={{
                color: tab.color ? `${tab.color}.contrastText` : 'text.primary',
                bgcolor: tab.color ? `${tab.color}.main` : 'transparent',
                '&.Mui-selected': {
                  color: `${tab.color}.contrastText`,
                  bgcolor: tab.color ? `${tab.color}.dark` : 'transparent',
                },
                marginLeft: 1,
                marginRight: 1,
              }}
            />
          ))}
        </Tabs>
      </Box>

          <Divider />

          <MaterialTable
            title="Appointments List"
            columns={columns}
            data={dataFiltered}
            actions={[
              // {
              //   icon: () => <MoreVertIcon  />,
              //   tooltip: 'Actions',
              //   isFreeAction: false,
              //   onClick: (handleOpen)
              // }
           ]}
      
           // onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
            options={  {
              columnsButton:true,
              exportMenu: [{
                label: 'Export PDF',
                exportFunc: (cols, datas) => ExportPdf(cols, datas, 'SubscriptionReport')
              }, {
                label: 'Export CSV',
                exportFunc: (cols, datas) => ExportCsv(cols, datas, 'SubscriptionReport')
              }],
              exportAllData: true,
              sorting: true,
              rowStyle: rowData => ({
                backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
              }),
              headerStyle: {
                backgroundColor: '#01579b',
                color: '#FFF',
              },
             }}

             />
        {/* </Card> */}
        </>
    
      ) : (<EmptyList message="You may want to refine your filters" />)}
      </div>
      <AssignTicket
        queryString="clients"
        open={opePay}
        setOpen={setOpenPay}
        ticketNo={ticketNo}
        appointmentNo={appointmentNo}
      />
      {/* <Menu
         className={classes.root}
         id="fade-menu"
         anchorEl={anchorEl}
         transformOrigin={{
           vertical: 'top',
           horizontal: 'center',
         }}
         keepMounted
         open={open}
         onClose={handleClose}
         TransitionComponent={Fade}
       >
             {[
            <MenuItem onClick={handlePayment}
              classes={{ root: classes.menuItem }}
              className={
                // b.icon ? classes.menuItemWithIcon : 
                classes.menuItemNoIcon
              }
              key="Menu-Key"
            >
            <span className={classes.label}>Assign User</span>
            </MenuItem>
          ]}
      </Menu> */}
    </PageContent>
  );
};

AppointmentList.defaultProps = {
  roleName: undefined,
  roleId: undefined,
  userTypeCode: undefined,
};

export default AppointmentList;


const applyFilter = ({
  inputData,
  filterStatus,
}: {
  inputData: Invoice[];
  filterStatus: string;
}): Invoice[] => {
  if (filterStatus !== 'all') {
    inputData = inputData.filter((invoice) => invoice.status === filterStatus);
  }

  return inputData;
};