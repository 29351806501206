import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
// import "fontsource-roboto";
import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Provider } from "react-redux";
import { toast } from "react-toastify";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";

import theme from "./css/theme";
import reportWebVitals from "./reportWebVitals";
import configureStore from "./store/configureStore";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";
const queryClient = new QueryClient();
const { store, persistor } = configureStore();

toast.configure({
  position: "top-right",
  autoClose: 6000,
  hideProgressBar: false,
  closeOnClick: true,
  closeButton: false,
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <PersistGate loading={null} persistor={persistor}>
            <CssBaseline />
            <App />
          </PersistGate>
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        </QueryClientProvider>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
