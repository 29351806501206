import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '90vh',
    },
    header: {
      margin: 0,
      fontFamily: 'Roboto Bold',
    },
    subtitle: {
      margin: 0,
      opacity: 0.75,
    },
  }),
);

type Props = {
  message?: string;
};
const NotFoundPage: React.FC<Props> = ({ message }: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <h2 className={classes.header}>404: We looked everywhere</h2>
      <p className={classes.subtitle}>{message}</p>
    </div>
  );
};

NotFoundPage.defaultProps = {
  message: 'Resource not found or access denied',
};

export default NotFoundPage;
