import { createContext, useCallback, useState } from "react";
import { InsuranceDataType } from "../../utils/useInsuranceQuery";
import { GridRowId } from "@mui/x-data-grid";

type ModalPayLoad = {
  loading?: boolean;
  data?: InsuranceDataType | null;
  action?: "edit" | "create" | "add-scheme";
};
const ModalContext = createContext<{
  loading: boolean;
  data: InsuranceDataType | null;
  deleteWarn: ConfirmDelete;
  handleDeleteWarn: (e: ConfirmDelete) => void;
  setModalOpen: (m?: ModalPayLoad) => void;
  action: "edit" | "create" | "add-scheme";
}>({
  loading: false,
  data: null,
  deleteWarn: null,
  setModalOpen: () => {},
  handleDeleteWarn: () => {},

  action: "create",
});
type ConfirmDelete = { id: string | number; warn: boolean } | null;

type ContextProps = {
  children?: React.ReactNode;
};
const ModalProvider = ({ children }: ContextProps) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<InsuranceDataType | null>(null);
  const [deleteWarn, setDeleteWarn] = useState<ConfirmDelete>(null);
  const [action, setAction] = useState<"edit" | "create" | "add-scheme">(
    "create"
  );

  const handleDeleteWarn = useCallback((f: ConfirmDelete) => {
    setDeleteWarn(f);
  }, []);

  const setModalOpen: (m?: ModalPayLoad) => void = (d) => {
    if (d?.loading !== undefined) {
      setLoading(d.loading);
    }
    if (d?.data !== undefined) {
      setData(d.data);
    }
    if (d?.action !== undefined) {
      setAction(d.action);
    }
  };

  console.log("data", data);
  return (
    <ModalContext.Provider
      value={{
        loading,
        setModalOpen,
        data,
        action,
        deleteWarn,
        handleDeleteWarn,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export { ModalContext, ModalProvider };
