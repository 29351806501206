import { createSlice } from "@reduxjs/toolkit";
type actionState = {
  drawerStatus: boolean;
};
type actionPayload = {
  payload: boolean;
};
const initialState: actionState = {
  drawerStatus: false,
};

export const actionSlice = createSlice({
  name: "insurance",
  initialState,
  reducers: {
    getDrawerStatus: (state, { payload }: actionPayload) => {
      state.drawerStatus = payload;
    },
  },
});

export const { getDrawerStatus } = actionSlice.actions;

export default actionSlice.reducer;
