import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

export const insuranceStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
    },

    inputStyle: {
      width: 400,
      marginBottom: theme.spacing(2),
    },
    autoComplete: {
      marginBottom: theme.spacing(1),
    },
    textMdBold: {
      "&.MuiTypography-root": {
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "24px",
      },
    },
    textSmall: {
      "&.MuiButton-root, &": {
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 100,
        lineHeight: "20px",
        letterSpacing: "0px",
      },
    },
    textMdNormal: {
      "&.MuiTypography-root": {
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "24px",
      },
    },
    text2Xl: {
      "&.MuiTypography-root": {
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "32px",
      },
    },
    flexColumnStart: {
      display: "flex",
      alignItems: "start",
      flexDirection: "column",
    },
    flexRowCenter: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    actionButton: {
      "&.MuiButton-root, &": {
        color: "#646464 !important",
        textTransform: "capitalize !important",
        display: "flex",

        alignItems: "flex-start !important",
        gap: "8px",
        alignSelf: "stretch",
        background: "none",
      },
    },
    partnerButton: {
      "&.MuiButton-root": {
        height: "44px",
        padding: " 0px 16px",
        gap: "8px",
        borderRadius: "8px",
        backgroundColor: "#0090FF",
      },
      "&.MuiButton-root:hover": {
        backgroundColor: "#0090ffd1",
      },
      transition: "all 300ms ease-in",
    },
    flexBetween: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    topContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    paper: {
      padding: theme.spacing(2),
      // textAlign: "center",
      //color: theme.palette.text.secondary,
      width: "auto",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      color: "black !important",
      fontFamily: "ChronicaPro,sans-serif",
      fontSize: "16px",
      fontWeight: 400,
    },
    formCustomTitle: {
      fontWeight: 700,
      color: "#449afe",
      fontSize: "1.3rem",
      fontFamily: "ChronicaPro,sans-serif",
      width: 400,
    },
    CustomTitle: {
      fontWeight: 700,
      color: "#022c5b",
      fontSize: "1.0rem! important",
      fontFamily: "ChronicaPro,sans-serif",
      width: 400,
    },
    inputLabel: {
      "&.focused": {
        color: "#449afe",
      },
      color: "#449afe",
    },
    errorText: {
      color: "red",
      fontSize: "small",
    },
  })
);
