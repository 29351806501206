/* eslint-disable @typescript-eslint/no-unused-vars */
import { createStyles, makeStyles } from "@mui/styles";
import LoadingSpinner from "../components/LoadingSpinner";
import PageContent from "../components/pageContent";
import Table from "../components/Table";
import React, { useEffect, useState } from "react";
import {  useAppSelector } from "../../utils/hooks";
import {
  DataTableColumn,
  DataTableRow,
  ErrorType,
} from "../../utils/types";
import moment from "moment";
import useFetch from "../../utils/useFetch";
import SendIcon from "@mui/icons-material/Send";
import {  User } from "./dashboard-logic/dashboardSlice";
import { Avatar, Box, Button, CircularProgress, Link, TextField, Theme } from "@mui/material";
import EmptyList from "../components/EmptyList";
import SelectTypeOfService from "../components/selectTypeOfService";
import { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SchemeSelect from "../components/schemeAutocomplete";
import FormSelect from "../components/FormSelect";
import VisitTypeSelect from "../components/VisitTypeSelect";
import usePostRequest from "../../utils/usePostRequest";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TableLink from "../components/TableLink";

const useStyles = makeStyles((theme: Theme) =>
createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
    },
    name: {
      textTransform: "capitalize",
    },
    formField: {
      margin: '4px',
      marginTop: '30px',
      [theme.breakpoints.down('md')]: {
        width: '30%',
        alignContent: 'center ',
      },
  },
  formSelect: {
    margin: '4px',
    marginTop: '30px',
    [theme.breakpoints.down('md')]: {
      width: '30%',
      alignContent: 'center ',
    },
},
  searchBox: {
    padding: theme.spacing(2, 0),
    width: "99%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

}));


const links = [
  {
    pathname: "",
    label: "",
    permission: "",
  },
];

type Props = {
  roleName?: string;
  roleId?: string;
  userTypeCode?: string;
};
const UsersList: React.FC<Props> = ({
  roleName,
  roleId,
  userTypeCode,
}: Props) => {
  const classes = useStyles();
  const { register, handleSubmit, watch,formState, reset } = useForm();
  const queryClient = useQueryClient();
  const[isPosting, setisLoading] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [query, setQuery] = useState("");
  const [user, setUser] = useState<User>();
 const Token =sessionStorage.getItem('auth');
  const { selectedUser } = useAppSelector((state) => state.login);
  const [nationalityErr, setnationalityError] = useState<false | ErrorType>(false);
 //const [data, SetnewData]=useState<string[]>([]);
  const [dateFromErr, setDateFromErr] = React.useState<string>("");
  const [dateToErr, setDateToErr] = React.useState<string>("");
  const [visitTypeErr, setVisitTypeErr] = React.useState<string>("");
  const [stage, setStage] = React.useState<string>('All');
  const [visitType, setVisitType] = React.useState<string>("");
  const [visitTypeID, setVisitTypeID] = React.useState<string>("");
  const [scheme, setScheme] = React.useState<string>("");
  const [schemeID, setSchemeID] = React.useState<string>();
  const [InsuranceID, setInsuranceID] = React.useState<string>();
  const [insurance, setInsurance] = React.useState<string>("");
  const [status, setStatus] = React.useState<string>('Open');
  const [phone, setPhone] = React.useState<string>('');
  const [fromDate, setFromDate] = React.useState<string>("");
  const [toDate, setToDate] = React.useState<string | null>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value);
  };
  const handlefromDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    let from= new Date(event.target.value).toLocaleDateString('en-US'); 
    const DateFrom= moment(from).format('YYYY-MM-DD');
   setFromDate(DateFrom);
  };
  const handletoDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    let to= new Date(event.target.value).toLocaleDateString('en-US'); 
    const DateTo= moment(to).format('YYYY-MM-DD');
   setToDate(DateTo);
  };
    const handleSchemes = (option: any) => {
        setScheme(option && (option as any).patient_payment_account_type_desc);
        setSchemeID(option && (option as any).patient_payment_account_type_id);
  };
  const handleInsurance = (option: any) => {
    setInsuranceID(option && (option as any).payment_account_id);
    setInsurance(option && (option as any).payment_account_desc);
};
const handleVisitType = (option: any) => {
  setVisitTypeID(option && (option as any).typeID);
  setVisitType(option && (option as any).name);
};

const [value, setValue] = React.useState<Dayjs | null>(null);

const handleDateChange = (newValue: Dayjs | null) => {
  setValue(newValue);
};
  const makeUrl = (defaultPath: string) =>
    query && !userTypeCode
      ? `/auth/system-user-fuzzy-search-user?searchterm=${query}`
      : defaultPath;

      const {
        data,
        isLoading,
        isError,
        error,
        isRefreshing,
        isSuccess
      } = useFetch(`api/Reports/get_NRCReport`, ["wellnessreport"],
    { enabled: !!stage });

    //const optionsData = data?.data || data || [] ;

  const [message, setMessage]=useState<string>("");
  const [isSubmitting,setisSubmitting]=useState<boolean>(false);
  const handleClick=(patNo:any)=>{ 
    setisSubmitting(true)
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ patientNumber: patNo })
    };
    fetch('https://dashboardapi.checkupsmed.com:1006/api/v1/checkups/patient/patient-usage-report', requestOptions)
        .then(response => response.json())
        .then(data => setMessage(data));  
        setTimeout(() => setisSubmitting(false), 5000);    
  }
  
  const columns: DataTableColumn[] = [
    {
      id: "dateFrom",
      label: "DateFrom",
      minWidth: 250,
      format: (value: any)=> moment(value)?.format("DD-MM-YYYY"),
    },
    {
      id: "dateTo",
      label: "DateTo",
      minWidth: 100,
      format: (value: any)=> moment(value)?.format("DD-MM-YYYY"),
    },
    {
      id: "scheme",
      label: "Scheme",
      minWidth: 150,
    },
    {
      id: "pulledBy",
      label: "Generated By",
      minWidth: 50,
    },
    {
      id: "pulledOn",
      label: "Date Pulled",
      minWidth: 150,
      format: (value: any)=> moment(value)?.format("DD-MM-YYYY"),
    },
    {
      id: "nrC_Report_Id",
      label: "Report",
      minWidth: 150,
      format: (value: any, row: any) => (
        <>
        {value?.length > 1 ?(
        <Link target="_blank"  href={`https://dashboardapi.checkupsmed.com:1006/api/Reports/api/FileAPI/WellnessReport?ReportID=${value}`}>
         Download
        </Link>
        ):(
        <span>....</span>
        )}
        </>
        )
    },
    {
      id: "nrC_Report_Id",
      label: "Summary",
      minWidth: 150,
      format: (value: any, row: any) => (
        <TableLink path={`/reportdetails/${row.nrC_Report_Id}`} >
          Open
        </TableLink>
      ),
    },

    
  ];

const rows: DataTableRow[] = isSuccess
? userTypeCode
  ?data
  : data
: [];


const [inputValue, setInputValue] = React.useState('');
const list= data?.map((item: { typeOfService: any; }) =>item.typeOfService)
let service = new Set(list);
let serviceList=  [...(service as any)];

let DateN =new Date().toLocaleDateString('en-US');
const date =  moment(DateN).format('YYYY-MM-DD');

const processedPayload = () => ({
  dateFrom: fromDate,
  dateTo: toDate,
  visitType: visitTypeID.toString(),
  scheme:schemeID,
  insurance:InsuranceID
});
const onSuccess = (data: any) => {
  setisLoading(false);
  queryClient.invalidateQueries({
    predicate: (query) => query.queryKey.includes("wellnessreport"),
  });
};
const onFailure = (err: ErrorType) => {
  setisLoading(false);
};
const mutation= usePostRequest(
    "api/Reports/SummaryReport",
    processedPayload(),
    onSuccess,
    onFailure
  );

const onSubmit = () => {
setisLoading(true);
  mutation.mutate();

};

  return (
    <PageContent>
      <div className={classes.root}>

        {isLoading ? <LoadingSpinner /> : <></>}
        {/* {isFetching ? <RefreshingCard message="Refreshing Clients" /> : <></>} */}
          <Box display="flex" >
          <div className={classes.formField}>
          <TextField
              size="small"
              id="fromDate"
              color="secondary"
              label="DATE FROM"
              type="date"
              defaultValue={fromDate}
              className={classes.formField}
              name="fromDate"
              onChange={handlefromDate}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className={classes.formField}>
          <TextField
              size="small"
              id="toDate"
              color="secondary"
              label="DATE TO"
              type="date"
              defaultValue={toDate}
              className={classes.formField}
              name="toDate"
              onChange={handletoDate}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className={classes.formField}>
      
          <VisitTypeSelect
              primaryKey="typeID"
              displayValueKey="name"
              onSelect={handleVisitType}
              selected={visitType || ""}
              error={nationalityErr}
              label="VISIT TYPE"
              
              width="100%"
            ></VisitTypeSelect>
          </div>
          </Box>
          <Box display="flex" >

                <div className={classes.formField}>
                <FormSelect
                    itemName="Insurance"
                    primaryKey="payment_account_id"
                    displayValueKey="payment_account_desc"
                    label="Insurance"
                    onSelect={handleInsurance}
                    selected={InsuranceID || ""}
                    error={false}
                    width="100%"
                    path="api/v1/checkups/account/get-insurance"
                  />
                </div>
                {InsuranceID && (
                <div className={classes.formField}>
                <FormSelect
                itemName={`Insurance-${InsuranceID}`}
                primaryKey={
                  InsuranceID
                    ? `${InsuranceID}-patient_payment_account_type_id`
                    : "patient_payment_account_type_id"
                }
                displayValueKey="patient_payment_account_type_desc"
                label="Schemes"
                onSelect={handleSchemes}
                width="100%"
                selected={schemeID || ""}
                error={false}
                path={`api/v1/checkups/account/get-scheme?paymentAccountId=${InsuranceID}`}
              /> 

                </div>        
                )}
                  <div className={classes.formField}>
                  <Button
            type="submit"
            variant="contained"
            //className={classes.button}
            size="large"
            style={{textTransform:"none"}}
            endIcon={<SendIcon />}
            startIcon={<Avatar style={{height: "0", width:"0",display:"none;"}} src={'https://px.ads.linkedin.com/collect/?pid=4033249&conversionId=6468881&fmt=gif'} />}
            onClick={(e) => {
              handleSubmit(() => {
                  if (!fromDate) {
                      setDateFromErr("Please select Date Range");
                      return;
                  }
                  if (!toDate) {
                    setDateToErr("Please select Date Range");
                    return;
                }  
                if (!visitType) {
                  setVisitTypeErr("please Visit Type");
                  return;
                 }
                
                  handleSubmit(onSubmit)(e);
              })(e);
          }}
          //disabled={checked == false}
          >
              {isPosting ? <LoadingSpinner /> : <></>}
            PULL REPORT
          </Button>
                  </div>
          </Box>
      {isSuccess ? (
        <>
      <Box className={classes.searchBox}>
          </Box>
          <Table
            columns={columns}
            rows={rows?.map((r, index) => ({ ...r, number: index + 1 }))}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            pageCount={data?.totalPage}
          />
        </>
      ) : (<EmptyList message="You may want to refine your filters" />)}
      </div>
    </PageContent>
  );
};

UsersList.defaultProps = {
  roleName: undefined,
  roleId: undefined,
  userTypeCode: undefined,
};

export default UsersList;
