import { createStyles, makeStyles } from "@mui/styles";
import React, {  useState } from "react";
import {Theme} from '@mui/material'
import { filterBySearch } from "../../../utils/filters";
import EmptyList from "../../components/EmptyList";
import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from '@material-table/exporters';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
  }),
);

type DoctorsNoteDetailsProps = {
  data: [];
};

const DoctorsNoteDetails = ({
  data,
}: DoctorsNoteDetailsProps) => {
  const classes = useStyles();

  const [query, setQuery] = useState('');

  const rows = filterBySearch(query, data);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [columns, setColumns] = useState([
    {   
      field: 'notes',
      title: 'Notes',
        minWidth: 350,
      },
      {
        field: 'takenBy',
        title: 'Written By',
        minWidth: 200,
      },
    {
      field: 'takenOn',
      title: 'Date',
      minWidth: 200,
     // format: (date) => formatAsLocalDate(date),
    },
  ]);

  return (
    <div className={classes.root}>
      <div>
      {data ? (
        <>

            <MaterialTable
            title="Visit Summary List"
            columns={columns}
            data={data}
            actions={[]}
            options={ {
              columnsButton:true,
              exportMenu: [{
                label: 'Export PDF',
                exportFunc: (cols, datas) => ExportPdf(cols, datas, 'VisitRecordsReport')
              }, {
                label: 'Export CSV',
                exportFunc: (cols, datas) => ExportCsv(cols, datas, 'VisitRecordsReport')
              }],
              exportAllData: true,
              sorting: true,
              rowStyle: rowData => ({
                backgroundColor: (rowData.pharmacyBalance<=1000) ? '#00000' : (rowData.pharmacyBalance<0) ? '#01579b' : '#00000' 
              }),
              headerStyle: {
                backgroundColor: '#01579b',
                color: '#FFF',
              },}}

             />

        </>
      ) : (<EmptyList message="You may want to refine your filters" />)}
      </div>
    </div>
  );
};

export default DoctorsNoteDetails;
