/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Modal,
  Typography,
  TextField,
  CircularProgress,
  Grid,
  InputAdornment,
} from "@mui/material";
import { TableColumn } from "react-data-table-component";
import PaginatedDataTable from "../enroll-patient/components/paginated_datatable";
import SearchBar from "../enroll-patient/components/searchbar";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Swal from "sweetalert2";
import Toast, { showToast } from "../components/Toast";
import axios from "axios";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { roleEnumMapping } from "../../constants";

// Modal styling
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};

const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxHeight: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
};

const style2 = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

type Props = {
  roleName?: string;
  roleId?: string;
  userTypeCode?: string;
};

interface ROW_DATA {
  id: string;
  fullName: string;
  phoneNumber: string;
  departmentName: string;
  insuranceName: string;
  roleName: string;
  status: string;
  staffID: string;
}

interface StaffData {
  id: number;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  fullName: string;
  phoneNumber: string;
  verified: string;
  created: string;
  gender: string;
  locationName: string;
  isAvailable: string;
  departmentName: string;
  departmentID: number;
  latitude: number;
  longitude: number;
  latestAccess: string;
  roleName: string;
  role: number;
  staffID: number;
  status: string;
  insuranceProvider: string;
  insuranceName: string;
}

const AgentList: React.FC<Props> = ({
  roleName,
  roleId,
  userTypeCode,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openNewModal, setOpenNewModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [data, setData] = useState([]);
  const [staffData, setStaffData] = useState<StaffData | null>(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [query, setQuery] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedRowId, setSelectedRowId] = useState("");
  const open = Boolean(anchorEl);
  const [insuranceProvider, setInsuranceProvider] = useState("");
  const [insuranceName, setInsuranceName] = useState("");
  const [departmentId, setDepartmentId] = useState("");
  const [departmentName, setDepartmentName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [gender, setGender] = useState("");
  const [staffId, setStaffId] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [insuranceProviderOptions, setInsuranceProviderOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [allInsuranceProviders, setAllInsuranceProviders] = useState<
    { value: string; label: string }[]
  >([]);
  const [departmentOptions, setDepartmentOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [roleOptions, setRoleOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [genderOptions, setGenderOptions] = useState<
    { value: string; label: string }[]
  >([
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ]);
  const handleOpenEditModal = () => setOpenEditModal(true);
  const handleCloseEditModal = () => setOpenEditModal(false);
  const handleOpenNewModal = () => setOpenNewModal(true);
  const handleCloseNewModal = () => setOpenNewModal(false);
  const handleOpenViewModal = () => setOpenViewModal(true);
  const handleCloseViewModal = () => setOpenViewModal(false);
  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const [searchProvider, setSearchProvider] = useState<string | null>(null);

  const handlePageChange = (page: number) => {
    setPageNumber(page);
  };

  const handlePerRowsChange = (newPerPage: number) => {
    setPageSize(newPerPage);
    setPageNumber(1);
  };

  const handleMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    rowId: string
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowId(rowId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRowId("");
  };

  const handleViewClick = async () => {
    setAnchorEl(null);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL3}api/v1/checkups/account/get-staffs/${selectedRowId}`
      );
      setStaffData(response.data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
      handleOpenViewModal();
    }
  };

  const handleNewSubmit = async () => {
    setAnchorEl(null);
    const requestBody = {
      departmentID: parseInt(departmentId),
      departmentName: departmentName,
      insuranceProvider: insuranceProvider.toUpperCase(),
      insuranceName: insuranceName,
      email: email,
      phoneNumber: phoneNumber,
      firstName: firstName,
      lastName: lastName,
      middleName: middleName,
      gender: gender,
      acceptTerms: true,
      password: password,
      confirmPassword: confirmPassword,
      role: roleEnumMapping[role as keyof typeof roleEnumMapping],
    };

    //validations
    if (
      email === "" ||
      phoneNumber === "" ||
      firstName === "" ||
      lastName === "" ||
      gender === "" ||
      password === "" ||
      confirmPassword === "" ||
      password !== confirmPassword ||
      role === ""
    ) {
      showToast("error", "Please fill all the fields");
      return;
    }
    try {
      handleCloseNewModal();
      Swal.fire({
        text: "Creating user...",
        didOpen: () => {
          Swal.showLoading();
        },
        allowOutsideClick: false,
      });
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL3}api/v1/checkups/account/register-user`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const responseData = await response.json();
      if (!response.ok) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${responseData}`,
        });
      }

      if (responseData === "success") {
        Swal.close();
        setDepartmentId("");
        setDepartmentName("");
        setInsuranceName("");
        setInsuranceProvider("");
        setRole("");
        Swal.fire("Success!", "User created successfully", "success").then(
          () => {
            window.location.reload();
          }
        );
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleUpdateSubmit = async () => {
    setAnchorEl(null);
    const requestBody = {
      status: 0,
      addedBy: parseInt(selectedRowId),
      staffID: staffId,
      departmentID: parseInt(departmentId),
      departmentName: departmentName,
      insuranceProvider: insuranceProvider.toUpperCase(),
      insuranceName: insuranceName,
      role: roleEnumMapping[role as keyof typeof roleEnumMapping],
    };

    //validations
    if (staffId === "" || role === "") {
      showToast("error", "Please fill all the fields");
      return;
    }
    try {
      handleCloseEditModal();
      Swal.fire({
        text: "Updating...",
        didOpen: () => {
          Swal.showLoading();
        },
        allowOutsideClick: false,
      });
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL3}api/v1/checkups/account/update-staff-details`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      await response.json();
      Swal.close();
      setDepartmentId("");
      setDepartmentName("");
      setInsuranceName("");
      setInsuranceProvider("");
      setRole("");
      Swal.fire("Success!", "User updated successfully", "success").then(() => {
        window.location.reload();
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Handle input change for filtering the options
  const handleInputChange = (
    event: React.ChangeEvent<{}>,
    newValue: string | null
  ) => {
    setSearchProvider(newValue || "");

    // Filter the options based on user input
    if (newValue) {
      const filteredOptions = allInsuranceProviders.filter((provider) =>
        provider.label.toLowerCase().includes(newValue.toLowerCase())
      );
      setInsuranceProviderOptions(filteredOptions);
    } else {
      setInsuranceProviderOptions(allInsuranceProviders);
    }
  };

  // Handle selection of an option
  const handleSelectChange = (
    event: React.SyntheticEvent,
    value: { value: string; label: string } | null
  ) => {
    setInsuranceProvider(value?.value || "");
    setInsuranceName(value?.label || "");
  };

  const handleDepartmentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = e.target.value;
    const selected = departmentOptions.find(
      (option) => option.value === selectedValue
    );

    if (selected) {
      setDepartmentId(selected.value);
      setDepartmentName(selected.label);
    }
  };

  //fetch departments
  const fetchDepartments = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL3}api/v1/checkups/tickets/departments`
      );
      const data = response.data.map((department: any) => ({
        value: department.departmentID,
        label: department.departmentName,
      }));
      setDepartmentOptions(data);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  //fetch roles
  const fetchRoles = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL3}api/v1/checkups/account/roles`
      );
      const data = response.data.map((department: any) => ({
        value: department.name,
        label: department.name,
      }));
      setRoleOptions(data);
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };

  // Fetch all insurance providers
  const fetchInsuranceProviders = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL3}api/InsuranceEnrollment/get-all-insurance?pageNumber=1&pageSize=200`
      );
      const data = response.data.data.map((insuranceProvider: any) => ({
        value: insuranceProvider.paymentAccountID,
        label: insuranceProvider.description,
      }));

      setAllInsuranceProviders(data);
      setInsuranceProviderOptions(data);
    } catch (error) {
      console.error("Error fetching insurance providers:", error);
    }
  };

  useEffect(() => {
    fetchInsuranceProviders();
    fetchDepartments();
    fetchRoles();
  }, []);

  //fetch all users
  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_API_URL3}api/v1/checkups/account/get-staffs?pageNumber=${pageNumber}&pageSize=${pageSize}&field=${query}`,
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          }
        );
        if (!response.ok) throw new Error("Network response was not ok");
        const result = await response.json();
        setData(result.data);
        setTotalRecords(result.totalRecords);
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [pageNumber, pageSize, query]);

  const columns: TableColumn<ROW_DATA>[] = [
    {
      name: "S/N",
      sortable: true,
      cell: (_row: ROW_DATA, index: number) => {
        return (pageNumber - 1) * pageSize + index + 1;
      },
      width: "5rem",
    },
    {
      name: "Full Name",
      selector: (row: ROW_DATA) => row.fullName,
      sortable: true,
      width: "15rem",
    },
    {
      name: "Phone Number",
      selector: (row: ROW_DATA) => row.phoneNumber,
      sortable: true,
    },
    {
      name: "Insurance Provider",
      selector: (row: ROW_DATA) => row.insuranceName,
      sortable: true,
    },
    {
      name: "Department",
      selector: (row: ROW_DATA) => row.departmentName,
      sortable: true,
    },
    {
      name: "Role",
      selector: (row: ROW_DATA) => row.roleName,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row: ROW_DATA) => row.status,
      sortable: true,
    },
    {
      name: "",
      cell: (row: ROW_DATA) => (
        <>
          <IconButton
            aria-label="more"
            aria-controls={`menu-${row.id}`}
            aria-haspopup="true"
            onClick={(event) => handleMenuClick(event, row.id)}
          >
            <MoreVertIcon />
          </IconButton>

          <Menu
            id={`menu-${row.id}`}
            anchorEl={anchorEl}
            open={open && selectedRowId === row.id}
            onClose={handleMenuClose}
            MenuListProps={{
              "aria-labelledby": `button-${row.id}`,
            }}
          >
            <MenuItem onClick={handleViewClick}>View</MenuItem>
            <MenuItem onClick={handleOpenEditModal}>Update</MenuItem>
          </Menu>
        </>
      ),
      sortable: true,
    },
  ];

  return (
    <div>
      <Toast />

      <Box sx={{ flexGrow: 1, mt: 4, pl: 2, pr: 2 }}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={6}>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                User Management
              </Typography>
              <Typography variant="body1">
                Add new users and update existing users
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} display="flex" justifyContent="flex-end">
            <Button
              onClick={handleOpenNewModal}
              variant="contained"
              sx={{
                backgroundColor: "#00a6fb",
                color: "white",
                "&:hover": { backgroundColor: "grey.800" },
              }}
            >
              Add New User
            </Button>
          </Grid>
        </Grid>

        <Box
          sx={{
            mb: 2,
            mt: 4,
          }}
        >
          <SearchBar value={query || ""} onChange={(e) => setQuery(e)} />
        </Box>

        {/* view user modal */}
        <Modal open={openViewModal} onClose={handleCloseViewModal}>
          <Box sx={style2}>
            <Typography variant="h6" component="h2" mb={2}>
              USER DETAILS
            </Typography>

            {/* Loader */}
            {loading && (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            )}

            {/* Staff Data */}
            {staffData && !loading && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    <strong>Full Name:</strong>{" "}
                    {staffData.fullName.toUpperCase()}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    <strong>Email:</strong> {staffData.email}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    <strong>Phone:</strong> {staffData.phoneNumber}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    <strong>Staff ID:</strong> {staffData.staffID}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    <strong>Gender:</strong> {staffData.gender.toUpperCase()}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    <strong>Verified:</strong>{" "}
                    {new Date(staffData.verified).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    <strong>Department:</strong> {staffData.departmentName}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    <strong>Status:</strong> {staffData.status.toUpperCase()}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    <strong>Location:</strong> {staffData.locationName || "N/A"}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    <strong>Is Available:</strong> {staffData.isAvailable}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    <strong>Role Name:</strong> {staffData.roleName || "N/A"}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    <strong>Insurance Provider:</strong>{" "}
                    {staffData.insuranceName}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    <strong>Created:</strong>{" "}
                    {new Date(staffData.created).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    <strong>Last Access:</strong>{" "}
                    {new Date(staffData.latestAccess).toLocaleString()}
                  </Typography>
                </Grid>
              </Grid>
            )}

            <Box mt={2}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleCloseViewModal}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Modal>

        {/* edit user modal */}
        <Modal
          open={openEditModal}
          onClose={handleCloseEditModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box sx={style}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Typography id="modal-title" variant="h6" component="h4">
                Enter Details
              </Typography>
              <Button onClick={handleCloseEditModal} sx={{ minWidth: 0 }}>
                <span style={{ fontSize: "1.5rem", color: "gray" }}>✖</span>
              </Button>
            </Box>

            {/* Insurance Provider Input */}
            <Autocomplete
              options={insuranceProviderOptions}
              getOptionLabel={(option) => option.label}
              onInputChange={handleInputChange}
              onChange={handleSelectChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Insurance Provider"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
              )}
            />

            {/* Department Name Input */}
            <Box sx={{ mt: 2 }}>
              <TextField
                select
                fullWidth
                label="Department Name"
                name="departmentName"
                value={departmentId ?? ""}
                onChange={handleDepartmentChange}
                variant="outlined"
              >
                {departmentOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>

            {/* Role Input */}
            <Box sx={{ mt: 2 }}>
              <TextField
                select
                fullWidth
                label="Role"
                name="role"
                value={role ?? ""}
                onChange={(e) => setRole(e.target.value)}
                variant="outlined"
              >
                {roleOptions.map((option) => (
                  <MenuItem key={option.label} value={option.label}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>

            {/* Staff ID Input */}
            <TextField
              fullWidth
              label="Staff ID"
              name="staffId"
              variant="outlined"
              value={staffId}
              onChange={(e) => setStaffId(e.target.value)}
              margin="normal"
            />

            {/* Submit Button */}
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdateSubmit}
              fullWidth
              sx={{ mt: 2 }}
            >
              Submit
            </Button>
          </Box>
        </Modal>

        {/* add new user modal */}
        <Modal
          open={openNewModal}
          onClose={handleCloseNewModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box sx={style1}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Typography id="modal-title" variant="h6" component="h4">
                Add New User
              </Typography>
              <Button onClick={handleCloseNewModal} sx={{ minWidth: 0 }}>
                <span style={{ fontSize: "1.5rem", color: "gray" }}>✖</span>
              </Button>
            </Box>

            {/* Two-column grid for input fields */}
            <Grid container spacing={2}>
              {/* First row */}
              <Grid item xs={12} sm={6}>
                {/* Email Input */}
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* Phone number Input */}
                <TextField
                  fullWidth
                  label="Phone Number"
                  name="phoneNumber"
                  variant="outlined"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  margin="normal"
                />
              </Grid>

              {/* Second row */}
              <Grid item xs={12} sm={6}>
                {/* First Name Input */}
                <TextField
                  fullWidth
                  label="First Name"
                  name="firstName"
                  variant="outlined"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* Middle Name Input */}
                <TextField
                  fullWidth
                  label="Middle Name"
                  name="middleName"
                  variant="outlined"
                  value={middleName}
                  onChange={(e) => setMiddleName(e.target.value)}
                  margin="normal"
                />
              </Grid>

              {/* Third row */}
              <Grid item xs={12} sm={6}>
                {/* Last Name Input */}
                <TextField
                  fullWidth
                  label="Last Name"
                  name="lastName"
                  variant="outlined"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* Gender Input */}
                <Box sx={{ mt: 2 }}>
                  <TextField
                    select
                    fullWidth
                    label="Gender"
                    name="gender"
                    value={gender ?? ""}
                    onChange={(e) => setGender(e.target.value)}
                    variant="outlined"
                  >
                    {genderOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </Grid>

              {/* Fourth row */}
              <Grid item xs={12} sm={6}>
                {/* Insurance Provider Input */}
                <Autocomplete
                  options={insuranceProviderOptions}
                  getOptionLabel={(option) => option.label}
                  onInputChange={handleInputChange}
                  onChange={handleSelectChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Insurance Provider"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* Department Name Input */}
                <Box sx={{ mt: 2 }}>
                  <TextField
                    select
                    fullWidth
                    label="Department Name"
                    name="departmentName"
                    value={departmentId ?? ""}
                    onChange={handleDepartmentChange}
                    variant="outlined"
                  >
                    {departmentOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </Grid>

              {/* Fifth row */}
              <Grid item xs={12} sm={6}>
                {/* Staff ID Input */}
                <TextField
                  fullWidth
                  label="Staff ID"
                  name="staffId"
                  variant="outlined"
                  value={staffId}
                  onChange={(e) => setStaffId(e.target.value)}
                  margin="normal"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                {/* Role Input */}
                <Box sx={{ mt: 2 }}>
                  <TextField
                    select
                    fullWidth
                    label="Role"
                    name="role"
                    value={role ?? ""}
                    onChange={(e) => setRole(e.target.value)}
                    variant="outlined"
                  >
                    {roleOptions.map((option) => (
                      <MenuItem key={option.label} value={option.label}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  variant="outlined"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={togglePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              {/* Confirm Password Input */}
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Confirm Password"
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  variant="outlined"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={toggleConfirmPasswordVisibility}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>

            {/* Submit Button */}
            <Button
              variant="contained"
              color="primary"
              onClick={handleNewSubmit}
              fullWidth
              sx={{ mt: 2 }}
            >
              Submit
            </Button>
          </Box>
        </Modal>

        <PaginatedDataTable
          columns={columns}
          data={data}
          totalRecords={totalRecords}
          handlePageChange={handlePageChange}
          handlePerRowsChange={handlePerRowsChange}
        />
      </Box>
    </div>
  );
};

AgentList.defaultProps = {
  roleName: undefined,
  roleId: undefined,
  userTypeCode: undefined,
};

export default AgentList;
