import {  Box, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Paper, Radio, RadioGroup, Snackbar, TextField, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Alert } from "@mui/lab";
import GenericDialog from "../components/GenericDialog";
import LoadingSpinner from "../components/LoadingSpinner";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ErrorType } from "../../utils/types";
import usePostRequest from "../../utils/usePostRequest";
import { useLocation } from 'react-router-dom';
import LocationHome from "../components/LocationHome";
import {useEffect} from "react";
import LocationOffice from "../components/LocationOffice";
import { createUserSuccess } from "../agent-accounts/agent-accounts-logic/profileSlice";
import SelectFromApi from "../components/SelectFromApi";
import MapView from "./AssignTicket3";
import DeliveryLocation from "../components/DeliveryLocation";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
    },

    inputStyle: {
      width: 400,
      marginBottom: theme.spacing(2),
    },
    autoComplete: {
      marginBottom: theme.spacing(1),
    },
    main: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
      zIndex: theme.zIndex.appBar - 1,
      outline: "none",
    },
    grid: {
      display: "grid",
      gridTemplateColumns: "1fr",
    },
    paper: {
      padding: theme.spacing(2),
      // textAlign: "center",
      //color: theme.palette.text.secondary,
      width: 'auto',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      color: 'black !important',
      fontFamily: "ChronicaPro,sans-serif",
      fontSize:"16px",
      fontWeight: 400,
    },
    formCustomTitle: {
      fontWeight:700,
      color: "#449afe",
      fontSize:"1.3rem",
      fontFamily: "ChronicaPro,sans-serif",
      width: 400,
    },
    CustomTitle: {
      fontWeight:700,
      color: "#022c5b",
      fontSize:"1.0rem! important",
      fontFamily: "ChronicaPro,sans-serif",
      width: 400,
    },
    inputLabel: {
      "&.focused": {
        color: "#449afe",
      },
      color: "#449afe",
    },
    errorText: {
      color: 'red',
      fontSize: 'small',
    },
      mapContainer: {
        width: '100%',
        marginTop: '10px',
      },
  })
);
interface LocationData {
  locationName: string;
  latitude: string;
  longitude: string;
  type: string;
}
type ClearPaymentProps = {
  queryString: string;
  open: boolean;
  ticketNo: string;
  orderNo: string;
  clientlat: number;
  clientlong: number;
  clientLocationName:string;
  setClientLocationName: any;
  setClientLatitude:any;
  setClientLongitude:any;
  // eslint-disable-next-line no-unused-vars
  setOpen: (value: boolean) => void;
  // eslint-disable-next-line no-unused-vars
};

function AssignTicket({
  queryString,
  open,
  setOpen,
  ticketNo,
  orderNo,
  clientlat,
  clientlong,
  clientLocationName,
  setClientLocationName,
  setClientLatitude,
  setClientLongitude

}: ClearPaymentProps) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const [openMap, setOpenMap]=useState(true);
  const [openSnack, setOpenSnack] = useState(true);
  const { selectedUser } = useAppSelector((state) => state.subscription);
  const [PaymentMethod, setPaymentMethod] = useState<string>('')
  const [AmountPaid, setAmountPaid] = useState<string>('')
  const [TransactionID, setTransactionID] = useState<string>("")
  const [TransactionIDErr, setTransactionIDErr] = useState<false | ErrorType>(false);
  const { handleSubmit, reset } = useForm();
  const [postError, setPostError] = useState<false | ErrorType>(false);
  const [invoiceNo, setInvoiceNo] = useState<string>('');
  const [invoiceError, setInvoiceError] = useState('');
  const [visitTypeErr, setVisitTypeErr] = useState<false | ErrorType>(false);
  // const [assignedTo, setassignedTo] = useState<string>("");
  const [department, setdepartment] = useState<string>("");
  const [assignedTo, setassignedToID] = useState<string>("");
  const [locationName, setLocationName] = useState<string>("");
  const [DeliveryTime, setDeliveryTime] = useState('');
  const [latitude, setLatitude] = useState<string>("");
  const [longitude, setLongitude] = useState<string>("");
  const [deliveryPoint, setDeliveryPoint] = useState<string>("");
  const [officeLocation, setofficeLocation] = useState<string>("");
  const [officeLat, setofficeLat] = useState<string>("0.00");
  const [officeLong, setofficeLong] = useState<string>("0.00");
  const [homeLocation, setHomeLocation] = useState<string>('');
  const [homeLat, setHomeLat] = useState<string>('0.00');
  const [homeLong, setHomeLong] = useState<string>('0.00');
  const [isEdit,setIsEdit]= useState(false);
  const [facilityErr, setFacilityErr] = useState<false | ErrorType>(false);
  const location = useLocation();
  const rowData = (location.state as { rowData: any })?.rowData;
  const [combinedLocations, setCombinedLocations] = useState<LocationData[]>([]);
  const [assignments, setAssignments] = useState<Array<{ assignedTo: string; department: string }>>([
    { assignedTo: "", department: "" }
  ]);
  const [departmentID, setDepartmentID] = useState<string>("7");
  const [comment, setcomment] = useState<string>("");

  var UserID =sessionStorage.getItem('UserID');

  useEffect(()=>{
    setofficeLocation(isEdit ? rowData.officeLocation : '');
    setofficeLat(isEdit ? rowData.officeLatitude : '');
    setofficeLong(isEdit ? rowData.officeLongitude : '');
    setHomeLocation(isEdit ? rowData.homeLocation : '');
    setHomeLat(isEdit ? rowData.homeLatitude : '');
    setHomeLong(isEdit ? rowData.homeLongitude : '');
   
    //  isEdit ? rowData?.insurance(setpaymentMethod("Insurance")):(<></>);
    setCombinedLocations([
      {
          locationName: isEdit ? rowData.officeLocation : '',
          latitude: isEdit ? rowData.officeLatitude : '',
          longitude: isEdit ? rowData.officeLongitude : '',
          type: "office"
      },
      {
        locationName: isEdit ? rowData.homeLocation : '',
        latitude: isEdit ? rowData.homeLatitude : '',
        longitude: isEdit ? rowData.homeLongitude : '',
        type: "home"
    },
    
    ]);
    },[isEdit])
    
    
    useEffect(()=>{
      setCombinedLocations([]);
    },[])


  const onSuccess = ({ data }: any) => {
    queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.includes("Orders"),
    });
    dispatch(createUserSuccess(data));
    setOpen(false);
    queryClient.invalidateQueries(`${queryString}`);
    
    // Clear the input fields
    setPaymentMethod("");
    setAmountPaid("");
    setTransactionID("");
    setTransactionIDErr(false);
    setDepartmentID("");
    setassignedToID("");
    setcomment("");
  };
  const onFailure = (err: ErrorType) => {
    setPostError(err);
  };




  const handleDepartment = (newValue: any) => {
    setDepartmentID(newValue?.departmentID);
  };
  const handleChange = (event:any) => {
    const { name, value } = event.target;
    switch (name) {
      case 'comment':
        setcomment(value);
        break;
        case 'DeliveryTime':
        setDeliveryTime(value);
        break;
        case 'clientLocationName':
        setClientLocationName(value);
        break;
  
        case 'combinedLocations':
        setCombinedLocations(value);
        break;
        default:
          break;
      }
    };
    const handleDelivery = (newValue: any) => {
      setDeliveryPoint(newValue.locationName);
      setLocationName(newValue.location);
      setLatitude(newValue.latitude);
      setLongitude(newValue.longitude);
      setClientLatitude(newValue.latitude);
      setClientLongitude(newValue.longitude);
      setClientLocationName(newValue.locationName);
    
 
    };
    
  const handleAssigned = (newValue: any) => {
    setassignedToID(newValue?.userID);
  };
  const mutation = usePostRequest(
    "order-assignment",
    {
      ticketNumber: ticketNo,
      assignedTo,
      orderID:orderNo,
      assignedBy: UserID,
      latitude:officeLat?officeLat:0,
      longitude: officeLong?officeLong:0,
      locationName:officeLocation,
      status:2,
      notes:comment,
      departmentID
    },
    onSuccess,
    onFailure
  );
  const onSubmit = () => {
    mutation.reset();
    mutation.mutate();
  };


  const handleClose = () => {
    mutation.reset();
    reset();
  };

  const actions = [
    {
      label: 'Assign Now',
      primary: true,
      onClick: handleSubmit(onSubmit),
    },
  ];

  return (
    <div>
      {mutation.isSuccess && (
        <Snackbar
          open={openSnack}
          onClose={() => setOpenSnack(false)}
          autoHideDuration={6000}
        >
          <Alert onClose={handleClose} severity="success">
              "Order Successfully Assigned"
          </Alert>
        </Snackbar>
      )}

      <GenericDialog
        title='Rider Assignment'
        showDialog={open}
        onCloseDialog={() => {
          setOpen(false);
        }}
        actions={actions}
        isLoading={mutation.isLoading}
        width="sm"
        height={400}
        verticalAlign="flex-start"
      >
       
          {mutation.isLoading ? <LoadingSpinner /> : <></>}
          {/* <div className={classes.grid}> */}
            {postError ? (
              <Alert severity="error" className="error-container">
                {postError?.message}
              </Alert>
            ) : (
              <></>
            )}
            
            <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>    
            
            {/* <Grid item xs={12} sm={12}>
        <Box className={classes.autoComplete}>
              <SelectFromApi
                itemName="Department"
                primaryKey="departmentID"
                displayValueKey="departnmentName"
                onSelect={handleDepartment}
                selected={department}
                error={visitTypeErr}
                path="api/v1/checkups/tickets/departments"
                label="Select Department"
                width="100%"
                alertWidth={500}
              />
            </Box>
        </Grid> */}
        <Grid item xs={12} sm={12}>
        <Box className={classes.autoComplete}>
              <SelectFromApi
                itemName={`userID-${departmentID}-${ticketNo}`}
                primaryKey="userID"
                displayValueKey="fullName"
                onSelect={handleAssigned}
                selected={assignedTo || ""}
                error={visitTypeErr}
                path={`api/v1/checkups/tickets/users-bydepartment?DepartmentID=${departmentID}`}
                label="Update User (Picks nearest user by default)"
                width="100%"
                alertWidth={500}
              />
            </Box>
        </Grid>
         {/* <Grid item xs={12} sm={12}>
        <Box className={classes.autoComplete}>
              <SelectFromApi
                itemName={`ticketNo-${ticketNo}`}
                primaryKey="locationName"
                displayValueKey="location"
                onSelect={handleDelivery}
                selected={clientLocationName || ""}
                error={visitTypeErr}
                path={`get-order-locations?TickeNumber=${ticketNo}`}
                label="Update Delivery Location (Default home location)"
                width="100%"
                alertWidth={500}
              />
            </Box>
        </Grid>  */}
        <Grid item xs={12} sm={12}>
        <Box className={classes.autoComplete}>
        <FormControl component="fieldset">
            <FormLabel component="legend">Delivery Location</FormLabel>
            <RadioGroup
              name="clientLocationName"
              value={clientLocationName}
              onChange={handleChange}
              row
            >
              <FormControlLabel
                value="homeLocation"
                control={<Radio />}
                label="Home"
              />
             
              <FormControlLabel
                value="officeLocation"
                control={<Radio />}
                label="Office"
              />
            </RadioGroup>
          </FormControl>

       
        {(clientLocationName === "officeLocation" || clientLocationName === "homeLocation") && (
            <DeliveryLocation
            location={officeLocation}
            setLocation={setofficeLocation}
            label="Delivery Location"
            setLatitude={setofficeLat}
            setLongitude={setofficeLong}
          />
        )}
         </Box>
        </Grid>
        {/* <Grid item xs={12} sm={12}>
        <Box className={classes.autoComplete}>
        <TextField
          name="DeliveryTime"
          label="Expected Delivery Time"
          type="datetime-local"
          value={DeliveryTime}
          onChange={handleChange}
          fullWidth
          required
          InputLabelProps={{
            shrink: true, 
          }}
        />
        </Box>
      </Grid> */}


         <Grid item xs={12} sm={12}>
          <TextField
            name="comment"
            label="Comment"
            value={comment}
            onChange={handleChange}
            fullWidth
          />
          </Grid>
          {/* <Grid item xs={12} sm={12} sx={{mt:2}}>
          <TextField
            name="invoiceNo"
            label="Invoice Number"
            value={invoiceNo}
            required
            onChange={handleChange}
            fullWidth
          />
          {invoiceError && <p style={{ color: 'red' }}>{invoiceError}</p>}
          </Grid> */}
          </Paper>
          </Grid>
          </Grid>
          
          {/* <div className={classes.mapContainer}>
            <MapView
            setassignedToID={setassignedToID}
            clientlat={clientlat}
            clientlong={clientlong}
             />
          </div> */}
        
          {/* </div> */}
  
      </GenericDialog>

  
    </div>
  );
}

export default AssignTicket;
