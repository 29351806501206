
import { Box, Divider, IconButton, Paper, Typography,Theme, Grid } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { StoreOutlined } from "@mui/icons-material";
import TableLink from "../TableLink";
import UploadableImageContainer from "../UploadableImageContainer";
import React from "react";
import { NavLink } from "react-router-dom";
import { ItemDetails } from "./types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 0,
      width: "100%",
      padding: theme.spacing(1),
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      border: `1px solid ${theme.palette.grey[300]}`,
    },
    vertical: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    iconAndName: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      margin: theme.spacing(1, 0),
    },
    entityLabel: {
      fontWeight: 900,
      fontSize: "1.5rem",
      color: theme.palette.secondary.dark,
      textTransform: "capitalize",
    },
    shopIcon: {
      backgroundColor: theme.palette.grey[300],
      marginRight: theme.spacing(1),
      cursor: "default",
      "&:hover": {
        backgroundColor: theme.palette.grey[300],
      },
    },
    metaDataItem: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      padding: "0",
    },
    entityName: {
      color: theme.palette.text.primary,
      fontWeight: 600,
      textDecoration: "underline",
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
    metaDataContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: "0.8rem",
    },
    divider: {
      backgroundColor: theme.palette.grey[500],
      marginLeft: "10px",
      marginRight: "10px",
      padding: "0",
      height: "20px",
      width: "2px",
    },
  })
);
type Props = {
  metadata: ItemDetails;
};
/**
 *
 * @param param0 ItemDetails
 * @returns
 */

const Metadata: React.FC<Props> = ({ metadata }: Props) => {
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <div className={classes.iconAndName}>
        
        <div className={classes.vertical}>
          <Typography className={classes.entityLabel}>
           TOTAL CLIENTS SERVED: {metadata?.total}
          </Typography>
          <Divider  flexItem style={{marginTop:'10px', marginBottom:'10px'}}/>
          <Grid container>
            <Grid >
          <Typography className={classes.entityLabel}>
           TOTAL MALE: {metadata?.male} ({metadata?.percentmale}%)
          </Typography>
          </Grid>
          <Divider orientation="vertical" flexItem style={{marginLeft:'10px', marginRight:'10px'}}/>
          <Grid>
          <Typography className={classes.entityLabel}>
            TOTAL FEMALE: {metadata?.female} ({metadata?.percentfemale}%)
          </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
      {/* {metadata?.statusContainer}
      {metadata?.inforBox} */}
    </Paper>
  );
};

export default Metadata;
