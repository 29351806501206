import { createStyles, makeStyles } from "@mui/styles";
import React, {  useState } from "react";
import {Theme} from '@mui/material'
import { filterBySearch } from "../../utils/filters";
import { DataTableColumn } from "../../utils/types";
import { formatAsLocalDate } from "../../utils/formatDates";
import TableHeader from "../components/TableHeader";
import DataTable from "../components/Table";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
  }),
);

type ClientsSectionsProps = {
  data: [];
};

const ClientsPointSections = ({
  data,
}: ClientsSectionsProps) => {
  const classes = useStyles();

  const [query, setQuery] = useState('');

  const rows = filterBySearch(query, data);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const columns: DataTableColumn[] = [
    {
      id: 'idNumber',
      label: 'ID Number',
      align: 'center',
      minWidth: 200,
    },
    {
        id: 'gender',
        label: 'Gender',
        minWidth: 150,
      },
      {
        id: 'nationality',
        label: 'Nationality',
        minWidth: 150,
      },
    {
      id: 'dob',
      label: 'DateOfBirth',
      minWidth: 50,
      format: (date) => formatAsLocalDate(date),
    },
    {
      id: 'location',
      label: 'Service Location',
      minWidth: 150,
    },
    {
      id: 'dateAdded',
      label: 'Date Created',
      minWidth: 150,
      format: (date) => formatAsLocalDate(date),
    },
  ];

  return (
    <div className={classes.root}>
      <div>
        <TableHeader onSearch={setQuery} query={query} />
        <DataTable
          page={page}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setPage={setPage}
          rows={rows?.map((r, index) => ({ ...r, number: index + 1 }))}
          columns={columns}
        />
      </div>
    </div>
  );
};

export default ClientsPointSections;
