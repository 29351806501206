import React from "react";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";

interface LabelSelectSearchFieldProps {
  name: string;
  label: string;
  options: { value: string; label: string }[];
  value: string | null;
  onChange: (event: React.ChangeEvent<{}>, value: string | null) => void;
  onInputChange?: (event: React.ChangeEvent<{}>, value: string) => void;
}

const LabelSelectSearchField: React.FC<LabelSelectSearchFieldProps> = ({
  name,
  label,
  options,
  value,
  onChange,
  onInputChange,
  ...autocompleteProps
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        mb: 2,
      }}
    >
      <Typography variant="body1">{label}</Typography>
      <Autocomplete
        freeSolo={true}
        forcePopupIcon={true}
        options={options.map((option) => option.value)}
        getOptionLabel={(option) =>
          options.find((opt) => opt.value === option)?.label || ""
        }
        value={value || ""}
        onChange={(event, newValue) => onChange(event, newValue)}
        onInputChange={onInputChange}
        isOptionEqualToValue={(option, selected) => option === selected}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" fullWidth name={name} />
        )}
        sx={{
          backgroundColor: "white",
          "& .MuiOutlinedInput-root": {
            backgroundColor: "transparent",
            height: "40px",
            padding: "0px",
          },
          "& .MuiSelect-select": {
            padding: "8px 16px",
            fontSize: "0.875rem",
          },
        }}
        {...autocompleteProps}
      />
    </Box>
  );
};

export default LabelSelectSearchField;
