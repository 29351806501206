/* eslint-disable no-nested-ternary */
import { Box, Button, Snackbar } from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { Alert } from "@mui/lab";
import { AxiosError } from "axios";
import React, { useState } from "react";
import { setImageError } from "../agent-accounts/agent-accounts-logic/profileSlice";
import { useAppDispatch } from "../../utils/hooks";
import { useUploadRequest } from "../../utils/usePostRequest";
import styled from "styled-components";

type BoxProps = {
  readonly preview: boolean;
};

const StyledForm = styled.div`
  margin-left: 10px;
  position: relative;
  padding: 0.9em;
`;

const StyledImageWrapper = styled.div<BoxProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: ${(props) => (props.preview ? "none" : "1px dashed #707070")};
  border-radius: 50%;
  height: 90px;
  width: 90px;
  position: relative;
`;

const StyledImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
`;

const StyledInput = styled.input`
  width: 100%;
`;

type CustomImageUploaderProps = {
  path: string;
  handleCancel: any;
  onSuccess?: Function;
};

const CustomImageUploader = ({
  path,
  handleCancel,
  onSuccess,
}: CustomImageUploaderProps) => {
  const dispatch = useAppDispatch();
  const [preview, setImagePreview] = useState("");
  const [file, setFile] = useState({});
  const [uploadSuccess, setUploadImageSuccess] = useState("");
  const [uploadFailure, setUploadImageFailure] = useState("");
  const [openSnack, setOpenSnack] = useState(true);

  const uploadSingleFile = (event: any) => {
    const bodyFormData = new FormData();
    bodyFormData.append("imageUrl", event.target.files[0]);
    setFile(bodyFormData);
    setImagePreview(URL.createObjectURL(event.target.files[0]));
  };

  const uploadImageSuccess = () => {
    setUploadImageSuccess("Successfully Uploaded Image");
    onSuccess ? onSuccess() : handleCancel();
  };

  const uploadImageFailure = (err: any) => {
    dispatch(
      setImageError({
        message:
          (err as AxiosError)?.message ||
          "Something went wrong when uploading image",
        statusCode: (err as AxiosError)?.response?.status || 500,
      })
    );
    setUploadImageFailure(
      (err as AxiosError)?.message ||
        "Something went wrong when uploading image"
    );
  };

  const uploadImage = useUploadRequest(
    path,
    file,
    uploadImageSuccess,
    uploadImageFailure
  );

  const handleUpload = () => {
    uploadImage.mutate();
  };

  const handleRemove = () => {
    uploadImage.reset();
    setImagePreview("");
    setFile("");
    handleCancel(false);
  };

  return (
    <StyledForm>
      <Cancel
        titleAccess="Cancel upload"
        style={{
          cursor: "pointer",
          position: "absolute",
          top: -10,
          left: -20,
          fontSize: "2.5em",
          color: "red",
        }}
        onClick={handleRemove}
      />
      <StyledImageWrapper preview={Boolean(preview.length > 0)}>
        {preview ? (
          <>
            <StyledImage src={preview} alt="" />
          </>
        ) : (
          <div className="form-group">
            <StyledInput
              type="file"
              className="form-control"
              onChange={uploadSingleFile}
            />
          </div>
        )}
      </StyledImageWrapper>

      {preview && (
        <Box ml={-2} mt={1}>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleUpload}
            disabled={uploadImage.isLoading}
          >
            {uploadImage.isLoading
              ? "Loading..."
              : uploadImage.isSuccess
              ? ""
              : "Save image"}
          </Button>
        </Box>
      )}
      {uploadImage.isSuccess && (
        <Snackbar
          open={openSnack}
          onClose={() => setOpenSnack(false)}
          autoHideDuration={6000}
        >
          <Alert onClose={() => setOpenSnack(false)} severity="success">
            {uploadSuccess}
          </Alert>
        </Snackbar>
      )}
      {uploadImage.isError && (
        <Snackbar
          open={openSnack}
          onClose={() => setOpenSnack(false)}
          autoHideDuration={6000}
        >
          <Alert onClose={() => setOpenSnack(false)} severity="error">
            {uploadFailure}
          </Alert>
        </Snackbar>
      )}
    </StyledForm>
  );
};

CustomImageUploader.defaultProps = {
  onSuccess: undefined,
};
export default CustomImageUploader;
