import * as React from "react";
import Button from "@mui/material/Button";
import DialogContentText from "@mui/material/DialogContentText";
import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";

import {
  DialogActions,
  dialogContentClasses,
  Modal,
  Paper,
  typographyClasses,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import StyledButton from "./button";

type DialogButtonProps = {
  onClick: () => void;
};

type SlideInDialogProps = {
  open: boolean;
  handleClose: () => void;
  handleCancel: () => void;
  text?: string; // text to show the user in the confirm modal
  DialogButton?: string | React.ComponentType<DialogButtonProps>;
  className?: string;
};
export default function SlideInDialog({
  handleClose,
  handleCancel,
  text,
  DialogButton,
  open,
}: SlideInDialogProps) {
  const rootRef = React.useRef<HTMLDivElement>(null);

  return (
    <React.Fragment>
      <Box
        sx={{
          flexGrow: 1,
          minWidth: 300,
          transform: `${open ? "translateY(0)" : "translateY(0)"}`,
          position: "absolute",
          ...(open ? { top: 0, right: 0, left: 0, bottom: 0 } : {}),
        }}
        ref={rootRef}
      >
        <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          sx={{
            position: "fixed",
            zIndex: 1300,
            inset: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backdropFilter: "blur(2px)",
            backgroundColor: "rgba(0 0 0 / 0.)",
            "&.base-Modal-hidden": {
              visibility: "hidden",
            },
          }}
          container={() => rootRef.current!}
          open={open}
          closeAfterTransition
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
          aria-describedby="alert-dialog-slide-description"
        >
          <Slide in={open} direction="up">
            <PaperContainer className=" p-4 flex flex-col justify-center gap-2">
              <DialogContentText id="alert-dialog-slide-description">
                {text ? text : "Are you sure you want to close"}
              </DialogContentText>
              <DialogActions sx={{ alignSelf: "end" }}>
                <StyledButton
                  color="#1A202C"
                  bgColor="#EDF2F7"
                  text="cancel"
                  onClick={handleCancel}
                />
                {DialogButton && typeof DialogButton !== "string" ? (
                  <DialogButton onClick={handleClose} />
                ) : (
                  <StyledButton
                    text={DialogButton ? DialogButton : "Close"}
                    bgColor="#4C9DF8"
                    onClick={handleClose}
                  />
                )}
              </DialogActions>
            </PaperContainer>
          </Slide>
        </Modal>
      </Box>
    </React.Fragment>
  );
}

const PaperContainer = styled(Paper)(({ theme }) => ({
  padding: "16px",
  display: "flex",
  flexDirection: "column",
  gap: 16,

  [`& + p.MuiTypography-root.MuiDialogContentText-root ${typographyClasses.root}${dialogContentClasses.root}`]:
    {
      maxWidth: "90px",
      margin: "30px",
    },
}));
