import { useTheme } from "@mui/material";
import React, { forwardRef } from "react";
import Slide from "@mui/material/Slide";

type WrapperProps = {
  children: React.ReactNode;
};
const Wrapper = forwardRef<HTMLDivElement, WrapperProps>(
  ({ children }, ref) => {
    return (
      <div style={{ width: "100%" }} ref={ref}>
        {children}
      </div>
    );
  }
);

export default function SlideWrapper({
  children,
}: {
  children: React.ReactElement;
}) {
  const theme = useTheme();
  return (
    <Slide
      appear
      in
      mountOnEnter
      unmountOnExit
      direction="right"
      easing={{
        enter: theme.transitions.easing.easeIn,
        exit: theme.transitions.easing.easeOut,
      }}
      timeout={{
        enter: 500,
        exit: 500,
      }}
    >
      <Wrapper>{children}</Wrapper>
    </Slide>
  );
}
