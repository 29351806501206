import { createStyles, makeStyles } from "@mui/styles";
import React, {  useState } from "react";
import {Box, Divider, List, ListItem, ListItemText, Paper, Table, TableBody, TableCell, TableHead, TableRow, Theme, Typography} from '@mui/material'
import { filterBySearch } from "../../utils/filters";
import { DataTableColumn } from "../../utils/types";
import { formatAsLocalDate } from "../../utils/formatDates";
import TableHeader from "../components/TableHeader";
import DataTable from "../components/Table";
import useFetch from "../../utils/useFetch";
import { useParams } from "react-router-dom";

const style = {
  width: '100%',
  maxWidth: 660,
  bgcolor: 'background.paper',
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
    title: {
      fontWeight: 700,
      color: theme.palette.common.black,
      fontSize: "1.75rem",
      textTransform: "capitalize",
    },
    tableRightBorder: {
      borderWidth: 0,
      borderRightWidth: 1,
      borderColor: 'black',
      borderStyle: 'solid',
  },
  }),
);

type PaymentDetailsProps = {
  Clientdata: [];
};

const ClientPaymentDetails = ({
  Clientdata,
}: PaymentDetailsProps) => {
  const classes = useStyles();

  const [query, setQuery] = useState('');
  const { id }: any = useParams();
  const rows = filterBySearch(query, Clientdata);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const {
    data,
    isLoading,
    isError,
    error,
    isRefreshing,
  } = useFetch(`api/Reports/get-Scope?NRC_Report_Id=${id}`, ["scope", id]);
  const obj=Clientdata?.map((newDta:any)=> newDta);
  const scope=data?.map((newDta:any)=> newDta.packagePrice);
  return (
    <div className={classes.root}>
      <div>
        <TableHeader onSearch={setQuery} query={query} />
        <Paper variant="outlined" square >
        <Table sx={{ minWidth: 650 }} aria-label="caption table">
        <TableHead>
          <TableRow>
            <TableCell>GENDER</TableCell>
            <TableCell >RECORD</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            <TableRow key="index">
              <TableCell component="th" variant="body" scope="row" className={classes.tableRightBorder}>
                Male
              </TableCell>
              <TableCell  variant="body">{obj[0]?.femaleCount} ({obj[0]?.femalePercentage}%)</TableCell>
            </TableRow>
            <TableRow key="index">
              <TableCell component="th" variant="head" scope="row" className={classes.tableRightBorder}>
               Female
              </TableCell>
              <TableCell variant="head">
              {obj[0]?.maleCount} ({obj[0]?.malePercentage}%)
              </TableCell>
            </TableRow>
            
        </TableBody>
      </Table>
      </Paper>

      </div>
      <div  style={{marginTop:"30px"}}>
      <Typography className={classes.title}>SCOPE OF WORK</Typography>
      <Paper variant="outlined" square>
      {data && data.map((value:any)=>(
      <List sx={style} >
      <ListItem  divider>
        <ListItemText primary={value.test} />
      </ListItem>
      </List>
      ))}
      </Paper>
      </div>
    </div>
  );
};

export default ClientPaymentDetails;
