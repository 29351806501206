import * as React from "react";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import { GridColDef, GridRowId, GridRowIdGetter } from "@mui/x-data-grid";

import ActionButton from "./action-button";

import { DropDownItems } from "./types";
import GridTable from "./grid-table";
import {
  useDeleteScheme,
  useEnableScheme,
  useDisableScheme,
  SchemeData,
} from "../../../utils/useSchemeQuery";
import SlideInDialog from "./slideindrawer";
import { Box, Chip, ChipProps } from "@mui/material";
import InlineSearchBar from "../../components/Search/InlineSearchBar";
import { ModalContext } from "../modal-context";

import { styled } from "@mui/material/styles";
import { InsuranceContext } from "../scheme-context";

import { PAGE_SIZE } from "./constant";
import InsuranceTabs from "./insurance-scheme-tabs";
import { useSchemeContext } from "../useSchemeContext";

export default function InsuranceTable() {
  const clientId = sessionStorage.getItem("clientId");
  console.log("cliend", clientId);
  const {
    isLoading,
    insuranceData,
    search,
    activeTab,
    handleSearch,
    toggleDrawer,
  } = useSchemeContext();
  const { setModalOpen, deleteWarn, handleDeleteWarn } =
    React.useContext(ModalContext);
  const { mutateAsync } = useDeleteScheme();
  const { mutateAsync: disableInsurance } = useDisableScheme();
  const { mutateAsync: enableInsurance } = useEnableScheme();

  const handleEditClick = React.useCallback(
    (row: SchemeData) => {
      toggleDrawer();
      setModalOpen({ data: row, action: "edit" });
    },
    [setModalOpen, toggleDrawer]
  );

  // handele delete, warn on delete
  const handleDeleteClick = React.useCallback(
    (id: GridRowId, warn: boolean) => {
      if (warn) {
        handleDeleteWarn({ id, warn });
      }
    },
    [handleDeleteWarn]
  );

  // MOCK handle delete
  const handleDelete = React.useCallback(async () => {
    if (deleteWarn === null) return;
    try {
      await mutateAsync(deleteWarn.id);
      handleDeleteWarn(null);
    } catch (error) {
      console.log(error, "ERROR");
    }
  }, [deleteWarn, mutateAsync, handleDeleteWarn]);

  // handle edit cancel on rows

  // cancel modal cancel for delete warning
  const handleCancel = React.useCallback(() => {
    if (deleteWarn === null) return;
    handleDeleteWarn(null);
  }, [deleteWarn, handleDeleteWarn]);

  const handleSchemeDisable = React.useCallback(
    async (id: GridRowId, tab: "all" | "active" | "inactive") => {
      try {
        if (tab === "active" || tab === "all") {
          await disableInsurance(id);
        } else {
          await enableInsurance(id);
        }
      } catch (error) {
        console.log(error, "ERROR");
      }
    },
    [disableInsurance, enableInsurance]
  );
  // action items for the the table actionable
  const actionItems = React.useCallback(
    (row: SchemeData): DropDownItems[] => [
      {
        onClick: () => {
          handleEditClick(row);
        },
        label: "Edit Scheme",
        divider: true,
        disabled: activeTab === "inactive",
      },
      {
        onClick: () => {
          handleSchemeDisable(row.paymentAccountID, activeTab);
        },
        label: `${
          activeTab === "inactive" ? "Enable Scheme" : "Disable Scheme"
        }`,
        // color: `${activeTab === "inactive" ? "#228BE6" : "#DC3E42"}`,
      },
      {
        onClick: () => {
          handleDeleteClick(row.paymentAccountID, true);
        },
        label: "Delete Scheme",
        color: "#DC3E42",
        disabled: activeTab === "inactive",
      },
    ],
    [handleDeleteClick, handleEditClick, handleSchemeDisable, activeTab]
  );

  // table column information
  const columns: GridColDef<SchemeData>[] = React.useMemo(
    () => [
      {
        field: "dateCreated",
        headerName: "Date Created",
        type: "string",
        resizable: false,
        editable: false,
        width: 150,
      },
      {
        field: "description",
        headerName: "Patient Group",
        type: "string",
        resizable: false,
        editable: false,
        flex: 1,
      },
      {
        field: "code",
        headerName: "Scheme Code",
        resizable: false,
        editable: true,
        type: "number",
        align: "left",
        headerAlign: "left",
        flex: 0.3,
      },
      {
        field: "status",
        headerName: "Status",
        type: "string",
        resizable: false,
        editable: true,
        flex: 0.4,
        cellClassName: "category",
        renderCell: (params) => {
          return (
            <StyledChip
              $category={params.row.active === 0 ? "INACTIVE" : "ACTIVE"}
              label={params.row.active === 0 ? "INACTIVE" : "ACTIVE"}
            />
          );
        },
      },
      {
        field: "actions",
        type: "actions",
        resizable: false,
        width: 100,
        align: "center",
        cellClassName: "actions",
        getActions: ({ row }) => {
          return [
            <ActionButton
              actions={{
                items: actionItems(row),
                boundId: "table-root",
              }}
            />,
          ];
        },
      },
    ],
    [actionItems]
  );

  // get each row id
  const getRowId: GridRowIdGetter<SchemeData> = React.useCallback((param) => {
    return param.paymentAccountID;
  }, []);

  return (
    <Box
      id="table-root"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        width: "100%",
        "& .MuiInputBase-root": {
          margin: 0,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <InsuranceTabs />
        <InlineSearchBar
          value={search}
          onSearch={handleSearch}
          id="insurance-search"
        />
      </Box>

      <GridTable
        loading={isLoading}
        rows={insuranceData ?? []}
        columns={columns}
        getRowId={getRowId}
        pagination={CustomPagination}
        pageSize={PAGE_SIZE}
      >
        <SlideInDialog
          handleCancel={handleCancel}
          handleClose={handleDelete}
          open={deleteWarn !== null}
          DialogButton={"continue"}
          text="This action is irriversible, do want to continue"
        />
      </GridTable>
    </Box>
  );
}

const CustomPagination = React.memo(() => {
  const { pageSize, setPagination, page } = React.useContext(InsuranceContext);

  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={page < 1 ? 1 : page}
      count={pageSize}
      renderItem={(props2) => <PaginationItem {...props2} />}
      onChange={(_event: React.ChangeEvent<unknown>, value: number) => {
        setPagination(value);
      }}
    />
  );
});

interface StyledChipProps extends ChipProps {
  $category?: string;
}

const StyledChip = styled(({ $category, ...rest }: StyledChipProps) => (
  <Chip {...rest} />
))(({ $category, theme }) => ({
  color: $category === "ACTIVE" ? "#00713FDE" : "#CC4E00C5",
  backgroundColor: $category === "ACTIVE" ? "#00A43319" : "#FF9C0029",
  borderRadius: "2px",
  padding: "3px 10px",
  fontSize: "11px",
  fontFamily: "Inter",
  fontStyle: "normal",

  fontWeight: 700,
  textTransform: "uppercase",
  height: "20px",
  letterSpacing: "0.4px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  gap: "4px",
  "& .MuiChip-icon": {
    color: theme.palette.primary.main,
  },
  "& .MuiChip-label": {
    padding: "0px",
    color: $category === "ACTIVE" ? "#00713FDE" : "#CC4E00C5",
    height: "fit-content",
    display: "flex",
    alignItems: "center",
  },
}));
