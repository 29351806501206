import { Popover } from "@mui/material";
import DropDown from "./dropdown";
import { ActionProp } from "./types";

export default function ActionCard({
  dropdownProps,
  onClickOutside,
  anchorEl,
  open,
}: ActionProp) {
  return (
    <Popover
      onClose={(e) => {
        const n = e as unknown as MouseEvent;
        n?.stopPropagation();
        onClickOutside();
      }}
      anchorEl={anchorEl}
      open={open}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      container={() => {
        return document.querySelector("#table-root");
      }}
      elevation={4}
    >
      <DropDown {...dropdownProps} />
    </Popover>
  );
}
