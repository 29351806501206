import React from "react";
import {
  Box,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";

interface LabelIncrementFieldProps {
  name?: string;
  label: string;
  value: number;
  placeholder?: string;
  onChange: (value: number) => void;
  onIncrement?: () => void;
  onDecrement?: () => void;
  disabled: boolean;
}

const LabelIncrementField: React.FC<LabelIncrementFieldProps> = ({
  name,
  label,
  value,
  placeholder,
  onChange,
  onIncrement,
  onDecrement,
  disabled,
  ...textFieldProps
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        mb: 2,
      }}
    >
      <Typography variant="body1">{label}</Typography>
      <Box display="flex" alignItems="center">
        <TextField
          name={name}
          variant="outlined"
          fullWidth
          sx={{
            backgroundColor: "white",
            "& .MuiOutlinedInput-root": {
              backgroundColor: "transparent",
              height: "40px",
            },
            "& .MuiInputAdornment-root": {
              display: "none",
            },
          }}
          value={value}
          onChange={(e) => onChange(parseInt(e.target.value, 10))}
          placeholder={placeholder}
          type="number"
          disabled={disabled}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Box display="flex" flexDirection="column">
                  <IconButton onClick={onIncrement} disabled={value >= 100}>
                    <ArrowUpward />
                  </IconButton>
                  <IconButton onClick={onDecrement} disabled={value <= 0}>
                    <ArrowDownward />
                  </IconButton>
                </Box>
              </InputAdornment>
            ),
          }}
          {...textFieldProps}
        />
      </Box>
    </Box>
  );
};

export default LabelIncrementField;
