/* eslint-disable @typescript-eslint/no-unused-vars */
import { createStyles, makeStyles } from "@mui/styles";
import LoadingSpinner from "../components/LoadingSpinner";
import PageContent from "../components/pageContent";
import Table from "../components/Table";
import React, { useEffect, useState } from "react";
import {  useAppDispatch, useAppSelector } from "../../utils/hooks";
import {
  DataTableColumn,
  DataTableRow,
} from "../../utils/types";
import useFetch from "../../utils/useFetch";
import {  User,setSummary } from "./dashboard-logic/dashboardSlice";
import { Box, Button, CircularProgress, Dialog, DialogTitle, Fade, Grid, Link, Menu, MenuItem, TextField, Theme } from "@mui/material";
import EmptyList from "../components/EmptyList";
import MaterialTable from "@material-table/core";
import MoreVertIcon  from '@mui/icons-material/MoreVert';
import UploadFile from "./UploadFile";
import MemberDetails from "./SubscriptionDetails";
import { Icon, InlineIcon } from '@iconify/react';
import listOutline from '@iconify/icons-eva/list-outline';
import CreateTicket from "./CreateTicket";
import { useNavigate } from "react-router";

const useStyles = makeStyles((theme: Theme) =>
createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
    },
    name: {
      textTransform: "capitalize",
    },
    formField: {
      marginLeft: '4px',
      marginTop: '30px',
      [theme.breakpoints.down('md')]: {
        width: '20%',
        alignContent: 'center ',
      },
  },
  searchBox: {
    padding: theme.spacing(2, 0),
    width: "99%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  menuItemNoIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuItemWithIcon: {
    display: 'grid',
    gridTemplateColumns: '50px 1fr',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    fontWeight: 800,
    fontSize: '0.95rem',
  },
  menuItem: {
    minWidth: 200,
    margin: theme.spacing(1, 2),
    backgroundColor: theme.palette.grey[100],
    borderRadius: '3px',
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.grey[700],
    },
  },
  button: {
    backgroundColor: 'blue',
    color: 'white',
    width: '50%',
    fontSize: '30px',
  },

}));

const links = [
  {
    pathname: "",
    label: "",
    permission: "",
  },
];

type Props = {
  roleName?: string;
  roleId?: string;
  userTypeCode?: string;
};
const GenerateTicket: React.FC<Props> = ({
  roleName,
  roleId,
  userTypeCode,
}: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [query, setQuery] = useState("");
  const navigate = useNavigate();
  const [user, setUser] = useState<User>();
 const Token =sessionStorage.getItem('auth');
 //const [data, SetnewData]=useState<string[]>([]);
  const [value, setValue] = React.useState<string>('00000');
  const [phone, setPhone] = React.useState<string>('');
  const [InvoiceNo,setInvoiceNo] = React.useState<string>('');
  const [editing, setEditing] = useState(false);
  const [open2, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openModalForm,setOpenModalForm]= useState(false);
  const [memberNumber,setMemberNumber]= useState<string>('');
  const [newData, SetnewData]=useState<string[]>([]);

  useEffect(() => {
    dispatch(setSummary(user));
  }, [user]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value);
  };

  const handleSubmit = () => {
    setValue(phone);
  };

      const {
        data,
        isLoading,
        isError,
        error,
        isRefreshing,
        isSuccess
      } = useFetch(`api/v1/checkups/tickets/get-patirnt_details?search=${value}`, ["PatientsList", `${value}`]);

    //const optionsData = data?.data || data || [] ;

  const handleClick=(row:any,value:any)=>{ 
    navigate(`/new-ticket`, { state: { rowData: value,isEdit:true } });
  } 
  const handleOpenSubscriptionForm = (patNo:any) => {
    setOpenModalForm(true);
    setMemberNumber(patNo);
  };

  const handleCloseSubscriptionForm = () => {
    setOpenModalForm(false);
  };

  const [columns, setColumns] = useState([
        {
      field: "firstName",
      title: "First Name.",
      type: "string" as const,
      minWidth: 250,
    },
    {
      field: "middleName",
      title: "Middle Name",
      minWidth: 250,
      type: "string" as const
    },
    {
      field: "lastName",
      title: "Last Name",
      minWidth: 150,
      type: "string" as const
    },
    {
        field: "phoneNumber",
        title: " Phone Number",
        minWidth: 150,
        type: "string" as const
      },
    {
      field: "gender",
      title: "Gender",
      minWidth: 150,
      type: "string" as const
    },
    {
        field: "email",
        title: "Email",
        minWidth: 150,
        type: "string" as const
      },
    {
      field: "patient_id",
      title: "Subscription Details",
      minWidth: 50,
      render: (value: any, row: any) => (
        <Button onClick={(e:any)=>handleOpenSubscriptionForm(value.memberNumber)} variant="outlined">
                <Icon icon={listOutline} />
         </Button>
         )
    },


    {
      field: "patient_id",
      title: "Action",
      minWidth: 250,
      render: (value: any, row: any) => (
        <Button onClick={(e:any)=>handleClick(row,value)}>
        Create Ticket
         </Button>
         )
    },
    
  ]);

const rows: DataTableRow[] = isSuccess
? userTypeCode
  ?data
  : data
: [];

const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
const handleOpen = (event: React.MouseEvent<HTMLElement>,row:any) => {
  setAnchorEl(event.currentTarget);
  setUser(row);
};
const handleClose = () => {
  setAnchorEl(null);
};
const open = Boolean(anchorEl);
const [slcRow, setSlcRow] = React.useState(null);
function handleMenuClick(event:any) {
  setAnchorEl(event.currentTarget);
  //setSlcRow(row);
}

const handleFileModal = (row: any) => {
  setOpen(true);
};
const handleClickNewBtn = () => {
    navigate('/new-ticket'); 
  };

  return (
    <PageContent>
      <div className={classes.root}>

        {isLoading ? <LoadingSpinner /> : <></>}
        {/* {isFetching ? <RefreshingCard message="Refreshing Clients" /> : <></>} */}
        <div className={classes.formField}>
        <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <TextField
          id="outlined-multiline-flexible"
          label="Search Existing Patient"
          multiline
          maxRows={3}
          placeholder="Search by Phone number or ID Number or Member Number"
          style={{ width: "100%"}}
          value={phone}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
            style: { position: 'absolute', backgroundColor: '#FFF', padding: '0 4px' },
          }}
        />
        </Grid>
        <Grid item xs={12} md={2}>
        <Button
          onClick={handleSubmit}
          variant="contained" size="large"
          className={classes.button}
        >
          Search
        </Button>
      </Grid>
      <Grid item xs={12} md={2}>
      <Button
            color="secondary"
            variant="outlined" size="large"
            onClick={handleClickNewBtn}
            style={{ margin: 10, backgroundColor: 'green', color: 'white' }}
          >
           ADD NEW CLIENT
          </Button>
      </Grid>
     
      </Grid>
      </div>
    
      {isSuccess ? (
        <>
      <Box className={classes.searchBox}>
          </Box>
            <MaterialTable
            title="Patients List"
            columns={columns}
            data={data}
            actions={[]}
            
            onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
            options={ {
              columnsButton:true,
              sorting: true,
              rowStyle: rowData => ({
                backgroundColor: (rowData.pharmacyBalance<=1000) ? '#00000' : (rowData.pharmacyBalance<0) ? '#01579b' : '#00000' 
              }),
              headerStyle: {
                backgroundColor: '#01579b',
                color: '#FFF',
              },}}

             />

        </>
      ) : (<EmptyList message="You may want to refine your filters" />)}
      </div>

      <UploadFile
        queryString="clients"
        open={open2}
        setOpen={setOpen}
        editing={editing}
        setEditing={setEditing}
        setEditLoading={isLoading}
        setEditError={isError}
      />
        <Dialog fullWidth maxWidth="lg" open={openModalForm} onClose={handleCloseSubscriptionForm}>
        <DialogTitle>Member Details</DialogTitle>
        <Grid container spacing={5}>
      <Grid item xs={12} md={12}>
      <MemberDetails
          onCancel={handleCloseSubscriptionForm}
          setOpenModalForm={setOpenModalForm}
          MemberNumber={memberNumber}
          FromDate="2022-01-01 13:06:17.000"
          ToDate="2023-09-22 13:06:17.000"
        />
      </Grid>
    </Grid>
    
      </Dialog>
      <Menu
        className={classes.root}
        id="fade-menu"
        anchorEl={anchorEl}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {/* {getAllowedActions().map((b) => ( */}
          <>
            <MenuItem onClick={handleFileModal}
              classes={{ root: classes.menuItem }}
              className={
                // b.icon ? classes.menuItemWithIcon : 
                classes.menuItemNoIcon
              }
              key="Menu-Key"
            >
            <span className={classes.label}>Upload Prescription</span>
            </MenuItem>
            </>
        {/* ))} */}
      </Menu>
    </PageContent>
  );
};

GenerateTicket.defaultProps = {
  roleName: undefined,
  roleId: undefined,
  userTypeCode: undefined,
};

export default GenerateTicket;
