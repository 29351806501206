import React from "react";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
import { Typography } from "@mui/material";
import {
  LocalShipping,
  Inventory,
  ReceiptLong,
  CheckCircleOutline,
  CalendarToday,
} from "@mui/icons-material";
import { EnrollmentData } from "./ProgressTracker";

interface ActivityHistoryProps {
  enrollmentData?: EnrollmentData;
}

const formatDate = (dateString: string | null) => {
  if (!dateString) return null;

  const date = new Date(dateString);

  // Format the date to "9:24 AM - 10th October 2024"
  const formattedDate = new Intl.DateTimeFormat("en-GB", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    day: "numeric",
    month: "long",
    year: "numeric",
  }).format(date);

  return formattedDate;
};

const ActivityHistory: React.FC<ActivityHistoryProps> = ({
  enrollmentData,
}) => {
  // Map the API data to the timeline structure
  const activities = [
    {
      icon: <CheckCircleOutline />,
      title: "Customer accepted quotation",
      date: enrollmentData!.quotationAcceptedCreatedDate,
      condition: enrollmentData!.isQuoteAccepted === 1,
    },
    {
      icon: <ReceiptLong />,
      title: "Ticket created",
      date: enrollmentData!.ticketCreatedDate,
      condition: enrollmentData!.isTicketCreated === 1,
    },
    {
      icon: <ReceiptLong />,
      title: "Order moved to pharmacy",
      date: enrollmentData!.pharmacyCreatedDate,
      condition: enrollmentData!.withPharmacy === 1,
    },
    {
      icon: <ReceiptLong />,
      title: "Order moved to billing",
      date: enrollmentData!.billingCreatedDate,
      condition: enrollmentData!.withBilling === 1,
    },
    {
      icon: <CalendarToday />,
      title: "Order assigned to rider",
      date: enrollmentData!.dispatchCreatedDate,
      condition: enrollmentData!.withDispatch === 1,
    },
    {
      icon: <LocalShipping />,
      title: "Rider is on the way",
      date: enrollmentData!.riderCreatedDate,
      condition: enrollmentData!.withRider === 1,
    },
    {
      icon: <Inventory />,
      title: "Order has arrived",
      date: enrollmentData!.orderArrivalDate,
      condition: enrollmentData!.isDelivered === 1,
    },
  ];

  return (
    <Timeline sx={{ textAlign: "left", paddingLeft: "0" }}>
      {activities.map((activity, index) => (
        <TimelineItem key={index}>
          <TimelineSeparator>
            <TimelineDot color={activity.condition ? "primary" : "grey"}>
              {activity.icon}
            </TimelineDot>
            {index !== activities.length - 1 && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="body1">{activity.title}</Typography>
            {activity.date && (
              <Typography variant="body2" color="textSecondary">
                {formatDate(activity.date)}
              </Typography>
            )}
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export default ActivityHistory;
