import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField } from '@mui/material';

interface LabelNumberFieldProps {
  name?: string;
  label: string;
  value: number;
  placeholder?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
}

const LabelNumberField: React.FC<LabelNumberFieldProps> = ({
  name,
  label,
  value,
  placeholder,
  onChange,
  disabled,
  ...textFieldProps
}) => {
  const [internalValue, setInternalValue] = useState<string>(value?.toString() || '');

  // Update internal value if the props value changes
  useEffect(() => {
    setInternalValue(value?.toString() || '');
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;

    // Update internal string value for rendering
    setInternalValue(newValue);

    // Call the external onChange handler with a parsed float if valid
    if (onChange && !isNaN(parseFloat(newValue))) {
      onChange(e);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        mb: 2,
      }}
    >
      <Typography variant="body1">{label}</Typography>
      <TextField
        name={name}
        variant="outlined"
        fullWidth
        type="number"
        inputProps={{
          step: '0.01', // Allows decimals
        }}
        sx={{
          backgroundColor: "white",
          "& .MuiOutlinedInput-root": {
            backgroundColor: "transparent",
            height: "40px",
          },
        }}
        value={internalValue} // Use internal string value for better control
        onChange={handleChange}
        placeholder={placeholder}
        disabled={disabled}
        {...textFieldProps}
      />
    </Box>
  );
};

export default LabelNumberField;
