import {
  Box,
  FormHelperText,
  InputLabel,
  SelectChangeEvent,
} from "@mui/material";
import StyledSelect, { StyledInputBase } from "./select";
import { SelectOptions } from "./types";
import LinkIcon from "@mui/icons-material/Link";
import { useState } from "react";

const URLREGEX = /^https?:\/\/.*\.(?:png|jpg|jpeg|gif|bmp|webp|svg)$/;

type StyledInputProps<T extends "select" | "input"> = {
  type?: T;
  label: string;
  disabled?: boolean;
  placeholder: string;
  name?: string;
  id: string;
  errorText: string;
  defaultValue?: string;
  value: T extends "input" ? string : SelectOptions;
  setValue: T extends "input"
    ? React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    : (e: SelectChangeEvent<string>) => void;
  setError: (s: string, e: boolean) => void;
};

export default function StyledInput<T extends "select" | "input">({
  type = "input" as T,
  value,
  label,
  id,
  name,
  placeholder,
  errorText,
  setValue,
  disabled,
  defaultValue,
  setError,
}: StyledInputProps<T>) {
  const [error, setPostError] = useState(false);
  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target.value === "") {
      setPostError(true);
      setError(e.target.name, true);
    } else if (id === "group-url") {
      if (!new RegExp(URLREGEX).test(e.target.value)) {
        setPostError(true);
        setError(e.target.name, true);
      } else {
        setPostError(false);
        setError(e.target.name, false);
      }
    } else {
      setPostError(false);
      setError(e.target.name, false);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "4px",
      }}
    >
      <InputLabel htmlFor={id} id={`${id}-label`}>
        {label}
        <span style={{ color: "#DC3E42", fontSize: "18px" }}>*</span>
      </InputLabel>
      {type === "input" ? (
        <StyledInputBase
          disabled={disabled}
          onBlur={onBlur}
          error={error}
          startAdornment={
            id === "group-url" ? (
              <LinkIcon
                sx={{
                  color: !error && value ? "#4C9DF8" : "#C2C2C2",
                }}
              />
            ) : undefined
          }
          placeholder={placeholder}
          name={name}
          value={value as string}
          defaultValue={value as string}
          onChange={
            setValue as React.ChangeEventHandler<
              HTMLInputElement | HTMLTextAreaElement
            >
          }
          id={id}
          key={type}
        />
      ) : (
        <StyledSelect
          error={error}
          onBlur={onBlur}
          options={value as SelectOptions}
          setOption={setValue as (e: SelectChangeEvent<string>) => void}
          defaultValue={defaultValue}
          id={id}
          key={type}
        />
      )}
      {error && <FormHelperText error>{errorText}</FormHelperText>}
    </Box>
  );
}
