import { CircularProgress, TextField, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Alert, Autocomplete } from "@mui/lab";
import LoadingSpinner, { RefreshingCard } from '../components/LoadingSpinner';
import React, { useEffect, useState } from 'react';
import { ErrorType } from '../../utils/types';
import useFetch from '../../utils/useFetch';
import { makeErrorMessage } from '../../utils/useGracefulAPIErrorHandling';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
    },
    errorText: {
      color: 'red',
      fontSize: 'small',
    },
    formField: {
      width: '100%',
      margin: theme.spacing(0),
    },
    inputLabel: {
      "&.focused": {
        color: "#449afe",
        fontSize: "14px"
      },
      color: "#449afe",
      fontSize: "14px"
    },
  }),
);

type Props = {
  onSelect?: Function;
  label: string;
  primaryKey: string;
  displayValueKey: string | number;
  error: false | ErrorType | undefined;
  helperText?: string;
  selected: string;
  width?: string | number;
  alertWidth?: string | number;
};
const SelectFacility: React.FC<Props> = ({
  onSelect,
  label,
  primaryKey,
  displayValueKey,
  error: validationErr,
  helperText,
  selected,
  // eslint-disable-next-line no-unused-vars
  width,
  alertWidth,
}: Props) => {
  const classes = useStyles();
  const [country,setCountry]=useState<any[]>([]);
  const getData=()=>{
    fetch('location.json'
    ,{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }
    )
    .then(function(response){
      return response.json();
    })
    .then(function(myJson) {
      setCountry(myJson);
    });
  }
  useEffect(()=>{
    getData()
  },[])
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;
  // eslint-disable-next-line no-unused-vars
  const handleSelection = (e: any, option: any) => {
    onSelect && onSelect(option);
  };

  const optionsData = country || [];

  return (
    <div className={classes.root}>
        <>
          <Autocomplete
            color="secondary"
            onChange={(e, value) => {
              handleSelection(e, value);
            }}
            disableClearable
            //loading={isLoading}
            id="autocomplete"
            size="small"
            className={classes.formField}
            options={optionsData}
            style={{ width: width || 400}}
            // defaultValue={data.find((d: any) => d[primaryKey] === selected)}
            defaultValue={optionsData?.find((d: any) =>
              primaryKey ? d[primaryKey] === selected : d === selected,
            )}
            getOptionLabel={(option: any) => option[displayValueKey] || ''}
            renderInput={(params) => (
              <TextField
                color="secondary"
                {...params}
                className={classes.formField}
                size="small"
                label={label}
                fullWidth
                variant="outlined"
                style={{ width: 400}}
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabel,
                    focused: "focused",
                    shrink: "shrink"
                  }}}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
                helperText={helperText}
              />
            )}
          />
          {validationErr ? (
            <span className={classes.errorText}>{validationErr.message}</span>
          ) : (
            <></>
          )}
        </>
      {/* ) : (
        <></>
      )} */}
    </div>
  );
};

SelectFacility.defaultProps = {
  onSelect: () => {},
  width: '100%',
  alertWidth: '100%',
  helperText: '',
};
export default SelectFacility;
