/* eslint-disable @typescript-eslint/no-unused-vars */
import { createStyles, makeStyles } from "@mui/styles";
import LoadingSpinner from "../components/LoadingSpinner";
import PageContent from "../components/pageContent";
import Table from "../components/Table";
import React, { useEffect, useState } from "react";
import {  useAppDispatch, useAppSelector } from "../../utils/hooks";
import {
  DataTableColumn,
  DataTableRow,
} from "../../utils/types";
import useFetch from "../../utils/useFetch";
import { Box, Button, Card, CircularProgress, Container, Dialog, DialogActions, DialogTitle, Fade, Grid, Link, Menu, MenuItem, TextField, Theme } from "@mui/material";
import EmptyList from "../components/EmptyList";
import MaterialTable from "@material-table/core";
import MoreVertIcon  from '@mui/icons-material/MoreVert';
import { toDate } from "date-fns/esm";

const useStyles = makeStyles((theme: Theme) =>
createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
    },
    name: {
      textTransform: "capitalize",
    },
    formField: {
      marginLeft: '4px',
      marginTop: '30px',
      [theme.breakpoints.down('md')]: {
        width: '20%',
        alignContent: 'center ',
      },
  },
  searchBox: {
    padding: theme.spacing(2, 0),
    width: "99%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  menuItemNoIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuItemWithIcon: {
    display: 'grid',
    gridTemplateColumns: '50px 1fr',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    fontWeight: 800,
    fontSize: '0.95rem',
  },
  menuItem: {
    minWidth: 200,
    margin: theme.spacing(1, 2),
    backgroundColor: theme.palette.grey[100],
    borderRadius: '3px',
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.grey[700],
    },
  },
  button: {
    backgroundColor: 'blue',
    color: 'white',
    width: '50%',
    fontSize: '30px',
  },

}));
// ----------------------------------------------------------------------
type Props = {
    PatientNumber?: any;
    setOpenModalForm?: any;
    onCancel?: any;
  };
  const VisitsList: React.FC<Props> = ({
    PatientNumber,
    setOpenModalForm,
    onCancel,
  }: Props) => {
    const classes = useStyles();
    const [selectedRow, setSelectedRow] = useState(null);
    const {
        data,
        isLoading,
        isError,
        error,
        isRefreshing,
        isSuccess
      } = useFetch(`api/v1/checkups/visit-summary/get-patient-visits?patientId=${PatientNumber}`, ["PatientVisits", `${PatientNumber}`]);
// &FromDate=${}&ToDate=${}
    const [columns, setColumns] = useState([
        {
      field: "fullName",
      title: "FullName.",
      type: "string" as const,
      minWidth: 150,
    },
    {
      field: "invoice_number",
      title: "Invoice Number",
      minWidth: 150,
      type: "string" as const,
    },
    {
      field: "visitDate",
      title: "Visit Date",
      minWidth: 150,
      type: "string" as const
    },
    {
      field: "patient_id",
      title: "Action",
      minWidth: 250,
      render: (value: any, row: any) => (
        <Button onClick={(e:any)=>handleClick(row,value)}>
        Generate Summary
         </Button>
         )
    },
    {
      field: "patient_id",
      title: "Action",
      minWidth: 250,
      render: (value: any, row: any) => (
        <Button onClick={(e:any)=>handleClick(row,value)}>
        View Summary
         </Button>
         )
    },
  ]);

  const handleClick=(row:any,value:any)=>{ 
    // navigate(`/new-ticket`, { state: { rowData: value,isEdit:true } });
   } 

  return (
    <>
      <Container maxWidth={'lg'}>
        <Card>

        {isLoading ? <LoadingSpinner /> : <></>}
        {/* {isFetching ? <RefreshingCard message="Refreshing Clients" /> : <></>} */}
        
      {isSuccess ? (
        <>

            <MaterialTable
            title=""
            columns={columns}
            data={data}
            actions={[]}
            
            onRowClick={undefined}
            options={ {
              columnsButton:true,
              sorting: true,
              rowStyle: rowData => ({
                backgroundColor: (rowData.pharmacyBalance<=1000) ? '#00000' : (rowData.pharmacyBalance<0) ? '#01579b' : '#00000' 
              }),
              headerStyle: {
                backgroundColor: '#01579b',
                color: '#FFF',
              },}}

             />

        </>
      ) : (<EmptyList message="You may want to refine your filters" />)}
        </Card>
        <DialogActions>
        <Box sx={{ flexGrow: 1 }} />

        <Button variant="outlined" color="inherit" onClick={onCancel}>
          Close
        </Button>
      </DialogActions>
      </Container>


     
    </>
  );
}
VisitsList.defaultProps = {
    PatientNumber: undefined,
    onCancel: undefined,
    setOpenModalForm: undefined,
  };
  
  export default VisitsList;


