/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, Fade, IconButton, Menu , Theme} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { AssignmentTurnedIn, ListAlt, Today } from "@mui/icons-material";
import FilterList from "@mui/icons-material/FilterList";
import { isEmpty } from "lodash";
import React, { ReactChild, useState } from "react";
import { RootState } from "../../store/configureStore";
import { useAppSelector } from "../../utils/hooks";
import { DataTableFilter } from "../../utils/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 900,
      marginBottom: theme.spacing(1),
      fontSize: "0.9rem",
    },
    button: {
      backgroundColor: theme.palette.grey[200],
    },
    btnFiltersActive: {
      backgroundColor: theme.palette.secondary.main,
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    icon: {
      color: theme.palette.grey[800],
    },
    iconFiltersActive: {
      color: theme.palette.common.white,
    },

    menuItem: {
      padding: theme.spacing(0.75, 1.5),
      marginBottom: theme.spacing(1),
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      textTransform: "none",
      backgroundColor: theme.palette.grey[100],
      borderRadius: 0,
      "&:hover": {
        backgroundColor: theme.palette.grey[300],
      },
    },

    optionsTypeIcon: {
      fontSize: "1.2rem",
      color: theme.palette.grey[900],
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginRight: theme.spacing(0.5),
      padding: theme.spacing(0.1),
    },

    appliedFilterIcon: {
      color: theme.palette.secondary.main,
    },

    actions: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(2, 0, 0, 0),
      borderTop: `1px solid ${theme.palette.grey[300]}`,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  })
);

type Props = {
  filterableColumns: DataTableFilter[];
  filterId: string;
  onApplyFilters: Function;
  onClearFilters: Function;
};
const TableFilters: React.FC<Props> = ({
  filterId,
  filterableColumns,
  onApplyFilters,
  onClearFilters,
}: Props) => {
  const classes = useStyles();
  //const filters = useAppSelector((state: RootState) => state.filters);
  // const domainSpecificFilters = filters[filterId] || {};

  const [showOptions, setShowOptions] = useState<false | DataTableFilter>(
    false
  );
  const [showColumnList, setShowColumnList] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setShowColumnList(true);
  };

  const handleClose = () => {
    setShowOptions(false);
    setAnchorEl(null);
    setShowColumnList(false);
  };

  const handleApplyFilters = () => {
    onApplyFilters();
    handleClose();
  };

  const handleClearAll = () => {
    onClearFilters();
  };

  const handleChangeColumn = () => {
    setShowColumnList(true);
    setShowOptions(false);
  };

  const getOptionType = (
    option: "checkbox" | "range" | "boolean" | "date"
  ): ReactChild => {
    switch (option) {
      case "date":
        return <Today className={classes.optionsTypeIcon} />;
      case "checkbox":
        return <ListAlt fontSize="large" className={classes.optionsTypeIcon} />;
      default:
        return "";
    }
  };

  // const isSpecificFilterActive = (id: string) =>
  //   Array.isArray(domainSpecificFilters[id])
  //     ? !isEmpty(domainSpecificFilters[id])
  //     : typeof domainSpecificFilters[id] === 'object' &&
  //       (filters.appliedFilters as string[]).includes(filterId);

  const isAnyFiltersActive = () =>
    ([]);

  if (!filterableColumns.length) {
    return <></>;
  }

  return (
    <>
      <Box display="flex" alignItems="center">
        <IconButton
          onClick={handleOpen}
          classes={{
            root: isAnyFiltersActive() ? classes.btnFiltersActive : "",
          }}
          className={classes.button}
        >
          <FilterList
            className={
              isAnyFiltersActive() ? classes.iconFiltersActive : classes.icon
            }
          />
        </IconButton>
        {isAnyFiltersActive() ? (
          <Box ml={2}>
            <Button
              onClick={() => handleClearAll()}
              variant="outlined"
              color="secondary"
            >
              Clear all filters
            </Button>
          </Box>
        ) : (
          <></>
        )}
      </Box>
      <Menu
        id="filters-menu"
        anchorEl={anchorEl}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        variant="menu"
        keepMounted
        open={showColumnList}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <Box minWidth={400} p={1}>
          {showOptions ? (
            <>
              <ColumnFilterOptions column={showOptions} />
              <Box className={classes.actions}>
                <Button
                  color="secondary"
                  onClick={() => handleChangeColumn()}
                  variant="text"
                >
                  Back
                </Button>
                <Button
                  color="secondary"
                  onClick={() => handleApplyFilters()}
                  variant="contained"
                >
                  Apply changes
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Box className={classes.title}>Select column to filter by</Box>

              {filterableColumns.map((c) => (
                <Button
                  onClick={() => setShowOptions(c)}
                  variant="text"
                  className={classes.menuItem}
                  key={c.label}
                >
                  <Box display="flex">
                    <Box className={classes.optionsTypeIcon}>
                      {getOptionType(c.optionsType)}
                    </Box>
                    {c.label}
                  </Box>
                  {/* {isSpecificFilterActive(c.storeId) ? ( */}
                  <AssignmentTurnedIn className={classes.appliedFilterIcon} />
                  {/* ) : (
                    <></>
                  )} */}
                </Button>
              ))}
            </>
          )}
        </Box>
      </Menu>
    </>
  );
};

export default TableFilters;

const useColumnFilterStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { padding: theme.spacing(1) },
  })
);

type ColumnFilterProps = {
  column: DataTableFilter;
};

export const ColumnFilterOptions: React.FC<ColumnFilterProps> = ({
  column,
}: ColumnFilterProps) => {
  const classes = useColumnFilterStyles();

  return (
    <Box className={classes.root}>
      <>{column.component}</>
    </Box>
  );
};
