import { useMutation, useQuery, useQueryClient } from "react-query";
import API from "../api";
import { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

import dayjs from "dayjs";
import { CreateInsuranceScheme } from "./useInsuranceQuery";

const URL = "/api/InsuranceEnrollment";

export type SchemeStatusType = "Active" | "Disabled";

export interface SchemeData {
  paymentAccountID: string;
  active: number;
  description: string;
  dateCreated: string;
  patientGroup: string;
  tags?: string | null;
  totalPatients: number;
}
function promiseWrapper(
  callback: () => { data: SchemeData[]; totalPages: number }
): Promise<{ data: SchemeData[]; totalPages: number }> {
  return new Promise((resolve, reject) => {
    try {
      const result = callback();
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
}

function filterByStatus(
  data: SchemeData[],
  status: number,
  pageSize: number,
  page: number
): { data: SchemeData[]; totalPages: number } {
  const from = (page === 0 ? page : page - 1) * pageSize;
  const to = (page === 0 ? 1 : page) * pageSize;
  const filteredData = data
    .filter((item) => item.active === status)
    .slice(from, to);
  return {
    data: filteredData,
    totalPages: filteredData.length,
  };
}
function generateRandomDate(): dayjs.Dayjs {
  const start = dayjs("2018-01-01");
  const end = dayjs();
  const randomTimestamp =
    start.valueOf() + Math.random() * (end.valueOf() - start.valueOf());
  return dayjs(randomTimestamp);
}

const patientGroups = [
  "Insurance scheme One",
  "The second Insurance scheme",
  "MLS Therapy",
  "SM Insurance",
  "COVA",
  "Checkups",
  "Group A",
  "Group B",
  "Group C",
  "Group D",
  "Group E",
  "Group F",
  "Group G",
  "Group H",
  "Group I",
  "Group J",
  "Group K",
  "Group L",
  "Group M",
  "Group N",
];

const statuses: SchemeStatusType[] = ["Active", "Disabled"];

const data: SchemeData[] = Array.from({ length: 20 }, (_, index) => ({
  paymentAccountID: uuidv4(),
  active: Math.random() * statuses.length,
  dateCreated: generateRandomDate().format("DD/MM/YY"),
  description: patientGroups[index % patientGroups.length],
  patientGroup: patientGroups[index % patientGroups.length],
  totalPatients: Math.floor(Math.random() * 1500) + 1,
}));

type CreateInsuranceType = {
  insuranceAccountDesc: string;
  code: string;
  insuranceType: string;
  phoneNumber: string;
  whatsAppToken?: string;
  whatsAppPhoneID?: string;
  imageUrl?: string;
};

export type InsuranceQueryResponse = {
  data: InsuranceDataType[];
  hasNextpage: boolean;
  isLasPage: boolean;
  totalPages: number;
};

export type InsuranceDataType = Omit<
  CreateInsuranceType,
  "insuranceAccountDesc" | "insuranceType"
> & {
  description: string;
  paymentAccountID: string;
  category: string;
  disabled?: boolean;

  createdDate: string;
};

export type InsuranceSchemeType = {
  schemeName: string;
  schemeTag: string;
};

export type SchemeQueryParams = {
  clientId: string | null;
  pageSize: number;
  page: number;
  field?: string;
  activeTab: "all" | "active" | "inactive";
};

export function useDeleteScheme() {
  const queryClient = useQueryClient();
  return useMutation<"success", AxiosError<any, any>, string | number>(
    async (id) => {
      return await API.post(
        `${URL}/delete-providerscheme?insurancechemeId=${id}`
      );
    },
    {
      onSuccess: (s) => {
        queryClient.invalidateQueries(["getInsuranceScheme"]);
        queryClient.invalidateQueries(["getActiveInsuranceScheme"]);
        queryClient.invalidateQueries(["getInactiveInsuranceScheme"]);
        toast.success("Scheme Deleted successfully", {
          hideProgressBar: true,
          closeButton: true,
        });
      },
      onError: (er) => {
        console.log(er);
        toast.error("Scheme Deletion failed", {
          hideProgressBar: true,
          closeButton: true,
        });
        throw new Error("Error deleting scheme");
      },
    }
  );
}

export function useEnableScheme() {
  const queryClient = useQueryClient();
  return useMutation<
    "success",
    AxiosError<{ message: string }, any>,
    string | number
  >(
    async (id) => {
      return await API.post(
        `${URL}/activate-providerscheme?insurancechemeId=${id}`
      );
    },
    {
      onSuccess: (s) => {
        queryClient.invalidateQueries(["getInsuranceScheme"]);
        queryClient.invalidateQueries(["getActiveInsuranceScheme"]);
        queryClient.invalidateQueries(["getInactiveInsuranceScheme"]);
        toast.success("Scheme Enabled successfully", {
          hideProgressBar: true,
          closeButton: true,
        });
      },
      onError: (er) => {
        console.log(er);
        toast.error(
          "Failed to enable scheme" + (er.response?.data.message ?? ""),
          {
            hideProgressBar: true,
            closeButton: true,
          }
        );
      },
    }
  );
}

export function useDisableScheme() {
  const queryClient = useQueryClient();
  return useMutation<"success", AxiosError<any, any>, string | number>(
    async (id) => {
      return await API.post(
        `${URL}/deactivate-providerscheme?insurancechemeId=${id}`
      );
    },
    {
      onSuccess: (s) => {
        queryClient.invalidateQueries(["getInsuranceScheme"]);
        queryClient.invalidateQueries(["getActiveInsuranceScheme"]);
        queryClient.invalidateQueries(["getInactiveInsuranceScheme"]);
        toast.success("Scheme Disabled successfully", {
          hideProgressBar: true,
          closeButton: true,
        });
      },
      onError: (er) => {
        console.log(er);
        toast.error("Failed to disable scheme", {
          hideProgressBar: true,
          closeButton: true,
        });
      },
    }
  );
}
export function useEditScheme() {
  const queryClient = useQueryClient();
  return useMutation<"success", AxiosError<any, any>, InsuranceSchemeType>(
    async (data) => {
      console.log("update", data);
      return await API.post(`${URL}/update-scheme`, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["getInsuranceScheme"]);
        toast.success("scheme edited successfully", {
          hideProgressBar: true,
          closeButton: true,
        });
      },
      onError: (er) => {
        console.log(er);
        toast.error("Scheme update failed", {
          hideProgressBar: true,
          closeButton: true,
        });
        throw new Error("Error updating scheme");
      },
    }
  );
}
export function useCreateScheme(clientId: string | null) {
  const queryClient = useQueryClient();
  return useMutation<"success", AxiosError<any, any>, CreateInsuranceScheme>(
    async (data) => {
      // get the insurance account description
      const m = await API.get<InsuranceDataType>(
        `${URL}/insurance-by-id?insuranceId=${clientId}`
      );
      // update the data with the insurance account description
      data.insuranceAccountDesc = m.data.description;
      console.log("update", data);
      return await API.post(`${URL}/create-insuranceproviderscheme`, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["getInsuranceScheme"]);
      },
      onError: (er) => {
        console.log(er);
      },
    }
  );
}
export function useGetAllInactiveScheme({
  page,
  pageSize,
  field,
  activeTab,
  clientId,
}: SchemeQueryParams) {
  return useQuery<
    { data: SchemeData[]; totalPages: number },
    AxiosError<any, any>
  >(
    [
      "getInactiveInsuranceScheme",
      pageSize,
      page < 1 ? 1 : page,
      field,
      activeTab,
    ],
    () => promiseWrapper(() => filterByStatus(data, 0, pageSize, page)),
    // API.get<{}, AxiosResponse<InsuranceQueryResponse>>(
    //   `${URL}/get-all-inactive-scheme?pageNumber=${
    //     page < 1 ? 1 : page
    //   }&pageSize=${pageSize}&field=${field === "" ? "all" : field}`
    // ),
    {
      onError: (er) => {
        console.log(er);
        toast.error(er.message ?? "error fetching inactive scheme", {
          hideProgressBar: true,
          closeButton: true,
        });
      },
      // select: (data) => {
      //   return data.data;
      // },
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      staleTime: Infinity,
      cacheTime: 1000 * 60 * 20,
      enabled: activeTab === "inactive" && !!clientId,
    }
  );
}
export function useGetAllActiveScheme({
  page,
  pageSize,
  field,
  activeTab,
  clientId,
}: SchemeQueryParams) {
  return useQuery<
    { data: SchemeData[]; totalPages: number },
    // AxiosResponse<InsuranceQueryResponse>,
    AxiosError<any, any>
    // InsuranceQueryResponse
  >(
    [
      "getActiveInsuranceScheme",
      pageSize,
      page < 1 ? 1 : page,
      field,
      activeTab,
    ],
    () => promiseWrapper(() => filterByStatus(data, 1, pageSize, page)),
    // API.get<{}, AxiosResponse<InsuranceQueryResponse>>(
    //   `${URL}/get-all-active-insurance?pageNumber=${
    //     page < 1 ? 1 : page
    //   }&pageSize=${pageSize}&field=${field === "" ? "all" : field}`
    // ),
    {
      onError: (er) => {
        console.log(er);
        toast.error(er.message ?? "error fetching active insurance", {
          hideProgressBar: true,
          closeButton: true,
        });
      },
      // select: (data) => {
      //   return data.data;
      // },
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      staleTime: Infinity,
      cacheTime: 1000 * 60 * 20,
      enabled: activeTab === "active" && !!clientId,
    }
  );
}

export function useGetAllScheme({
  clientId,
  page,
  pageSize,
  field,
  activeTab,
}: SchemeQueryParams) {
  return useQuery<
    AxiosResponse<{ data: SchemeData[]; totalPages: number }>,
    // AxiosResponse<InsuranceQueryResponse>,
    AxiosError<any, any>,
    { data: SchemeData[]; totalPages: number }
  >(
    ["getInsuranceScheme", pageSize, page < 1 ? 1 : page, field, activeTab],
    () =>
      // promiseWrapper(() => {
      //   console.log(clientId, "cl");
      //   const from = (page === 0 ? page : page - 1) * pageSize;
      //   const to = (page === 0 ? 1 : page) * pageSize;

      //   return { data: data.slice(from, to), totalPages: data.length };
      // }),
      API.get<{}, AxiosResponse<{ data: SchemeData[]; totalPages: number }>>(
        `${URL}/pagedinsurancescheme-by-accountid?accountId=${clientId}&pageNumber=${
          page < 1 ? 1 : page
        }&pageSize=${pageSize}&field=all&${
          activeTab !== "all" ? `active=${activeTab === "active" ? 1 : 0}` : ""
        }`
      ),
    {
      onError: (er) => {
        console.log(er);
        toast.error(er.message ?? "error fetching insurance", {
          hideProgressBar: true,
          closeButton: true,
        });
      },
      select: (data) => {
        console.log(data, "all");
        return data.data;
      },
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      staleTime: Infinity,
      cacheTime: 1000 * 60 * 20,
      enabled: !!clientId,
    }
  );
}
