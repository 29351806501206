import React from "react";
import { Grid, Paper, Typography, Box } from "@mui/material";

// Define the props interface for DataPointGrid
export interface YearlyData {
  yearlyEnrollmentCount: number;
  yearlyOrderCount: number;
  yearlyTransaction: number;
  yearlyTotalSaving: number;
  yearlyBaselineAcceptanceCount: number;
  yearlyRefillRate: number;
  yearlyDeclineRate: number;
  yearlyLostFollowUpRate: number;
}

interface DataPointGridProps {
  yearlyData: YearlyData;
}

const DataPointGrid: React.FC<DataPointGridProps> = ({ yearlyData }) => {
  const dataPoints = [
    {
      id: 1,
      label: "Enrollment Count",
      value: yearlyData.yearlyEnrollmentCount,
    },
    { id: 2, label: "Order Count", value: yearlyData.yearlyOrderCount },
    { id: 3, label: "Transaction", value: yearlyData.yearlyTransaction },
    { id: 4, label: "Total Savings", value: yearlyData.yearlyTotalSaving },
    {
      id: 5,
      label: "Baseline Acceptance",
      value: yearlyData.yearlyBaselineAcceptanceCount,
    },
    { id: 6, label: "Refill Rate", value: yearlyData.yearlyRefillRate },
    { id: 7, label: "Decline Rate", value: yearlyData.yearlyDeclineRate },
    {
      id: 8,
      label: "Lost Follow-up Rate",
      value: yearlyData.yearlyLostFollowUpRate,
    },
  ];

  return (
    <Grid container spacing={2}>
      {dataPoints.map((point) => (
        <Grid item xs={12} sm={6} md={4} lg={2} key={point.id}>
          <Paper
            elevation={1}
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "100%",
              borderRadius: 2,
              minWidth: "150px", // Ensure a minimum width for each card
            }}
          >
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              {point.label}
            </Typography>
            <Box
              sx={{ display: "flex", flexDirection: "column", mt: 1, mb: 1 }}
            >
              <Typography component="h2" variant="h4" fontWeight="medium">
                {point.value.toLocaleString()}
              </Typography>
            </Box>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default DataPointGrid;
