import { createStyles, makeStyles } from "@mui/styles";
import React, {  useState } from "react";
import {Box, Button, Grid, IconButton, Link, Paper, TextField, Theme} from '@mui/material'
import { filterBySearch } from "../../utils/filters";
import { DataTableColumn, ErrorType } from "../../utils/types";
import { formatAsLocalDate } from "../../utils/formatDates";
import TableHeader from "../components/TableHeader";
import DataTable from "../components/Table3";
import usePostRequest from "../../utils/usePostRequest";
import { useParams } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import useFetch from "../../utils/useFetch";
import { useQueryClient } from "react-query";
import { useForm } from "react-hook-form";
import GenericDialog from "../components/GenericDialog";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
  }),
);

type FinalPageProps = {
  Clientdata: [];
};

const FinalPage = ({
  Clientdata,
}: FinalPageProps) => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { handleSubmit, reset } = useForm();
  const [query, setQuery] = useState('');
  const [remarks, SetRemarks] = useState<String>("")
  const [testID,setTestID]= useState<String>("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [postError, setPostError] = useState<false | ErrorType>(false);
  const { id }: any = useParams();
  const columns: DataTableColumn[] = [
    {
      id: 'actionPlan',
      label: 'Action Plan/Recommendation',
      minWidth: 650,
    },
  ];

  const {
    data,
    isLoading,
    isError,
    error,
    isRefreshing,
  } = useFetch(`api/Reports/get-actionPlanRecommendation?NRC_Report_Id=${id}`,["recommends",id]);
  const rows = filterBySearch(query, data);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => 
  {
    setOpen(true)
  };
  const handleChange =
  (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setTestID(panel);
  };
  
  const handleRemarks = (event: React.ChangeEvent<HTMLInputElement>) => {
    SetRemarks(event.target.value);
  };
  const onSuccess = ({ data }: any) => {
    queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.includes("recommends"),
    });
    setOpen(false);
    SetRemarks("");
  };
  const onFailure = (err: ErrorType) => {
    setPostError(err);
  };
  var userId =sessionStorage.getItem('UserID');
  const mutation = usePostRequest(
    "api/Reports/actionPlanRecommendation",
    {
      nrC_Report_Id:id,
      createdBy:userId,
      actionPlan:remarks
    },
    onSuccess,
    onFailure
  );
  const onSubmit = () => {
    mutation.reset();
    mutation.mutate();
  };

  const actions = [
    {
      label: 'ADD REMARK',
      primary: true,
      onClick: handleSubmit(onSubmit),
    },
  ];
  return (
    <div className={classes.root}>
      <div>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
        <Paper > 
        <Box
        m={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
      <Link target="_blank"  href={`https://dashboardapi.checkupsmed.com:1006/api/Reports/generatePDF?NRC_Report_Id=${id}`}>
      <Button
            color="secondary"
            variant="outlined"
            style={{backgroundColor:'purple',color:'white'}}
          >
          GENERATE WELLNESS SUMMARY REPORT
          </Button>
        </Link>

        <Button
            color="primary"
            variant="outlined"  
            sx={ { borderRadius: 28 } }
            onClick={handleOpen} 
            style={{backgroundColor:'blue',color:'white'}}           
          >
        ADD ACTION PLAN<AddIcon />
      </Button>
      </Box>
        <DataTable
          rows={rows?.map((r, index) => ({ ...r, number: index + 1 }))}
          columns={columns}
        />
         </Paper>
        </Grid>
      </Grid>
      <GenericDialog
        title=''
        showDialog={open}
        onCloseDialog={() => {
          setOpen(false);
        }}
        actions={actions}
        isLoading={mutation.isLoading}
        width="sm"
        height={150}
        verticalAlign="flex-start"
      >
        <TextField
              size="small"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              name="remarks"
              label="ACTION PLAN/ RECOMMENDATION"
              color="secondary"
              variant="outlined"
              fullWidth
              defaultValue={remarks}
              onChange={handleRemarks}
              autoComplete="off"
              rows="4"
              multiline={true}
              minRows={4}
            />
        </GenericDialog>
      </div>
    </div>
  );
};

export default FinalPage;
