import { createStyles, makeStyles } from "@mui/styles";
import React, {  useState } from "react";
import {Theme} from '@mui/material'
import { filterBySearch } from "../../utils/filters";
import { DataTableColumn } from "../../utils/types";
import { formatAsLocalDate } from "../../utils/formatDates";
import TableHeader from "../components/TableHeader";
import DataTable from "../components/Table";
import { useParams } from "react-router-dom";
import useFetch from "../../utils/useFetch";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
  }),
);


const ClientsPointSections = () => {
  const { reminderdate }: any = useParams();
  const {
    data,
    isLoading,
    isError,
    error,
    isRefreshing,
    isSuccess
  } = useFetch(`api/v1/checkups/tickets/get-followup-list?ReminderDate=${reminderdate}`, ["FollowUpList", ]);

  const classes = useStyles();

  const [query, setQuery] = useState('');
  const rows = filterBySearch(query, data);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const columns: DataTableColumn[] = [
    {
      id: 'patientName',
      label: 'Patient Name',
      minWidth: 200,
    },
    
    {
        id: 'phoneNumber',
        label: 'Phone Number',
        minWidth: 150,
      },
      {
        id: 'email',
        label: 'Email',
        minWidth: 150,
      },
    {
      id: 'dob',
      label: 'DateOfBirth',
      minWidth: 50,
      format: (date) => formatAsLocalDate(date),
    },
    {
      id: 'description',
      label: 'Type',
      minWidth: 150,
    },
    {
      id: 'comment',
      label: 'Comment',
      minWidth: 150,
    },

    {
      id: 'solution',
      label: 'Solution',
      minWidth: 150,
    },
    {
      id: 'reminderDate',
      label: 'Reminder Date',
      minWidth: 150,
    },
   
  ];

  return (
    <div className={classes.root}>
      <div>
        <TableHeader onSearch={setQuery} query={query} />
        <DataTable
          page={page}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setPage={setPage}
          rows={rows?.map((r, index) => ({ ...r, number: index + 1 }))}
          columns={columns}
        />
      </div>
    </div>
  );
};

export default ClientsPointSections;
