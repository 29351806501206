import * as React from "react";
import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";

import { CircularProgress, Modal } from "@mui/material";
import { ModalContext } from "../modal-context";

export default function LoadingModal() {
  const { loading } = React.useContext(ModalContext);
  const rootRef = React.useRef<HTMLDivElement>(null);

  return (
    <React.Fragment>
      <Box
        sx={{
          flexGrow: 1,
          minWidth: 300,
          transform: `${loading ? "translateY(0)" : "translateY(0)"}`,
          position: "absolute",
          ...(loading ? { top: 0, right: 0, left: 0, bottom: 0 } : {}),
        }}
        ref={rootRef}
      >
        <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          sx={{
            position: "fixed",
            zIndex: 1300,
            inset: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backdropFilter: "blur(2px)",
            backgroundColor: "rgba(0 0 0 / 0.)",
            "&.base-Modal-hidden": {
              visibility: "hidden",
            },
          }}
          container={() => rootRef.current!}
          open={loading}
          closeAfterTransition
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
          aria-describedby="alert-dialog-slide-description"
        >
          <Slide in={loading} direction="up">
            <CircularProgress sx={{ background: "none", color: "#0a487f" }} />
          </Slide>
        </Modal>
      </Box>
    </React.Fragment>
  );
}
