import { createStyles, makeStyles } from "@mui/styles";
import React, {  useState } from "react";
import {Box, Button, Modal, Paper, Table, TableBody, TableCell, TableHead, TableRow, Theme, Typography} from '@mui/material'
import { filterBySearch } from "../../utils/filters";
import { DataTableColumn } from "../../utils/types";
import { formatAsLocalDate } from "../../utils/formatDates";
import TableHeader from "../components/TableHeader";
import DataTable from "../components/Table";
import useFetch from "../../utils/useFetch";
import { useParams } from "react-router-dom";
import CreateCollectionDetails from "./CreateCollectionDetails";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
    Modalstyle: {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      //bgcolor: 'background.paper',
      border: '2px solid #000',
    },
    tableRightBorder: {
      borderWidth: 0,
      borderRightWidth: 1,
      borderColor: 'black',
      borderStyle: 'solid',
  },
  }),
);

type ClientsSectionsProps = {
  Clientdata: [];
};

const ClientsPointSections = ({
  Clientdata,
}: ClientsSectionsProps) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { id }: any = useParams();
 const {
    data,
    isLoading,
    isError,
    error,
    isRefreshing,
  } = useFetch(`api/Reports/get-Scope?NRC_Report_Id=${id}`, ["scope", id]);
const DATA= useFetch(`api/Reports/get_FoodScreeningSummaryRep?NRCReportID=${id}`,["locationsdetails",id])
  const [query, setQuery] = useState('');

  const rows = filterBySearch(query, Clientdata);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

const newData=DATA.data;

 const obj=Clientdata?.map((newDta:any)=> newDta);
 const scope=data?.map((newDta:any)=> newDta.packagePrice);
  return (
    <div className={classes.root}>
      <div>
      <Paper variant="outlined" square >     
      <Button
            color="secondary"
            variant="outlined"
            onClick={handleOpen}
            style={{  backgroundColor:'purple',color:'white'}}
          >
          UPDATE
          </Button>
          <CreateCollectionDetails
        queryString="wellness"
        open={open}
        setOpen={setOpen}
        // editing={editing}
        // setEditing={setEditing}
        // setEditLoading={singleUser.isLoading}
        // setEditError={singleUser.isError}
      />
      </Paper>
      <Paper variant="outlined" square >
        <Table sx={{ minWidth: 650 }} aria-label="caption table">
        <TableHead>
          {/* <TableRow>
            <TableCell>CATEGORIES</TableCell>
            <TableCell align="right">ACTIONS</TableCell>
          </TableRow> */}
        </TableHead>
        <TableBody >
            <TableRow key="index" >
              <TableCell component="th" variant="body" scope="row" className={classes.tableRightBorder}>
                DATE OF SERVICE
              </TableCell>
              <TableCell  variant="body">{formatAsLocalDate(obj[0]?.visitDate)}</TableCell>
            </TableRow>
            <TableRow key="index">
              <TableCell component="th" variant="head" scope="row" className={classes.tableRightBorder}>
               LOCATION
              </TableCell>
              <TableCell variant="head">{newData?.location}</TableCell>
            </TableRow>
            <TableRow key="index">
              <TableCell component="th" scope="row" className={classes.tableRightBorder}>
                CONTACT PERSON(S) CLIENT
              </TableCell>
              <TableCell >{newData?.contactPersonClient}</TableCell>
            </TableRow>
            <TableRow key="index">
              <TableCell scope="row" className={classes.tableRightBorder}>
                CONTACT PERSON(S) CHECKUPS
              </TableCell>
              <TableCell >{newData?.contactPersonCheckupsTeam}</TableCell>
            </TableRow>
            <TableRow key="index">
              <TableCell component="th" scope="row" className={classes.tableRightBorder}>
                EXPECTED POPULATION
              </TableCell>
              <TableCell >{newData?.populationExpcted}</TableCell>
            </TableRow>
            <TableRow key="index">
              <TableCell component="th" scope="row" className={classes.tableRightBorder}>
                POPULATION SERVED
              </TableCell>
              <TableCell >{obj[0]?.totalCount} People</TableCell>
            </TableRow>
            <TableRow key="index">
              <TableCell component="th" scope="row" className={classes.tableRightBorder}>
                COST PER PERSON
              </TableCell>
              <TableCell >{}</TableCell>
            </TableRow>
        </TableBody>
      </Table>
      </Paper>
       
      </div>
    </div>
  );
};

export default ClientsPointSections;
