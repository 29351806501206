import { Typography,Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { ErrorOutline } from "@mui/icons-material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ErrorType } from "../../utils/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "50vh",
    },

    errIcon: {
      color: theme.palette.error.dark,
      fontSize: "10rem",
    },

    header: {
      fontWeight: 900,
      fontSize: "2rem",
      padding: theme.spacing(1),
    },
    subtitle: {
      color: theme.palette.grey[500],
    },
    error: {
      color: "red",
    },
  })
);

type Props = {
  error?: false | ErrorType;
};

const BadError: React.FC<Props> = ({ error }: Props) => {
  const classes = useStyles();
const Redirect = useNavigate();
  if (
    error &&
    (error?.statusCode === 403 ||
      error?.message?.toLowerCase().includes("status code 403"))
  ) {
    return null;
   // return Redirect("/access-denied");
  }
  return (
    <div className={classes.root}>
      <ErrorOutline className={classes.errIcon} />
      <Typography className={classes.header}>Oops. Broken link</Typography>
      <Typography className={classes.subtitle}>
        You should never see this. Something went horribly wrong.
      </Typography>
      {error && (
        <Typography className={classes.error}>
          {(error as any)?.message}
        </Typography>
      )}
    </div>
  );
};

BadError.defaultProps = {
  error: false,
};

export default BadError;
