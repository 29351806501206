import { createStyles, makeStyles } from "@mui/styles";
import React, {  useEffect, useState } from "react";
import {Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, IconButton, Link, Modal, Paper, TextField, Theme, Typography} from '@mui/material'
import { filterBySearch } from "../../utils/filters";
import { DataTableColumn, ErrorType } from "../../utils/types";
import { formatAsLocalDate } from "../../utils/formatDates";
import TableHeader from "../components/TableHeader";
import DataTable from "../components/Table3";
import usePostRequest from "../../utils/usePostRequest";
import { useParams } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import useFetch from "../../utils/useFetch";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GenericDialog from "../components/GenericDialog";
import { useQueryClient } from "react-query";
import { useForm } from "react-hook-form";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
  }),
);

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

type DiagnosisProps = {
  Clientdata: [];
};

const Diagnosis = ({
  Clientdata,
}: DiagnosisProps) => {
  const classes = useStyles();

  const [query, setQuery] = useState('');
  const queryClient = useQueryClient();
  const { handleSubmit, reset } = useForm();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [remarks, SetRemarks] = useState<String>("")
  const [testID,setTestID]= useState<String>("");
  const [testID2,setTestID2]= useState<String>("");
  const[comments,setComments]=useState<any>();
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [postError, setPostError] = useState<false | ErrorType>(false);
  const { id }: any = useParams();
  const columns: DataTableColumn[] = [
    {
      id: 'remarks',
      label: 'Remarks',
      minWidth: 650,
    },
  ];
  const {
    data,
    isLoading,
    isError,
    error,
    isRefreshing,
  } = useFetch(`api/Reports/get-doctorsRemarks?NRC_Report_Id=${id}&TestID=${testID}`,["comments",id,testID]);
  
  const rows = filterBySearch(query, data);

  const scope= useFetch(`api/Reports/get-Scope?NRC_Report_Id=${id}`, ["scope", id]);

  const tests= useFetch(`api/Reports/TestRecords?NRC_Report_Id=${id}&TestID=${testID}`, ["tests", id,testID]);
  const rows2 = filterBySearch(query, tests.data);
  const [open, setOpen] = React.useState(false);
  const handleOpen = (value:any) => 
  {
    setOpen(true)
    setTestID(value);
  };

  const handleChange =
  (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
    setTestID(panel);
  };
  
  const handleRemarks = (event: React.ChangeEvent<HTMLInputElement>) => {
    SetRemarks(event.target.value);
  };
  const onSuccess = ({ data }: any) => {
    queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.includes("comments"),
    });
    setOpen(false);
    SetRemarks("");
  };
  const onFailure = (err: ErrorType) => {
    setPostError(err);
  };
  var userId =sessionStorage.getItem('UserID');
  const mutation = usePostRequest(
    "api/Reports/doctors-remarks",
    {
      nrC_Report_Id:id,
      createdBy:userId,
      remarks,
      testID
    },
    onSuccess,
    onFailure
  );
  const onSubmit = () => {
    mutation.reset();
    mutation.mutate();
  };

  const actions = [
    {
      label: 'ADD REMARK',
      primary: true,
      onClick: handleSubmit(onSubmit),
    },
  ];

  const columns2: DataTableColumn[] = [
    {
      id: 'result',
      label: 'RESULT',
      minWidth: 150,
    },
    {
      id: 'normal',
      label: 'NORMAL',
      minWidth: 150,
    },
    {
      id: 'higher',
      label: 'HIGH',
      minWidth: 150,
    },
    {
      id: 'lower',
      label: 'LOW',
      minWidth: 150,
    },
    {
      id: 'total',
      label: 'TOTAL',
      minWidth: 150,
    },
  ];
  return (
    <div className={classes.root}>
    {/* {data.map((value:any)=>( */}
      <div>
      {scope.data.map((value:any, index:any) => (
      <>
      <Accordion expanded={expanded === value.testID} onChange={handleChange(value.testID)}
      // onChange = {(e,expanded) => {
      //   if(expanded){
      //     setTestID(value.testID);
      //   }
      // }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography> {value.test}</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Box> 
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} key={index}>
     
        <Grid item xs={6}>
           <Paper > 
        <Box
        m={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Button
            color="primary"
            variant="outlined"  
            sx={ { borderRadius: 28 } }
            onClick={e=>handleOpen(value.testID)}         
          >
        <AddIcon />
      </Button>
      </Box>
        <DataTable
          rows={testID ? rows?.map((r, index) => ({ ...r, number: index + 1 })) : []}
          columns={columns}
        />
         </Paper>
          </Grid>
          <Grid item xs={6}>
           <Paper > 
           <DataTable
          rows={testID ? rows2?.map((r, index) => ({ ...r, number: index + 1 })) : []}
          columns={columns2}
        />
             </Paper>
             </Grid>
           
             </Grid>
             </Box>
        </AccordionDetails>
      </Accordion>

      
      </>
      
      ))}
     <GenericDialog
        title=''
        showDialog={open}
        onCloseDialog={() => {
          setOpen(false);
        }}
        actions={actions}
        isLoading={mutation.isLoading}
        width="sm"
        height={150}
        verticalAlign="flex-start"
      >
        <TextField
              size="small"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              name="remarks"
              label="Doctor's Remarks"
              color="secondary"
              variant="outlined"
              fullWidth
              defaultValue={remarks}
              onChange={handleRemarks}
              autoComplete="off"
              rows="4"
              multiline={true}
              minRows={4}
            />
        </GenericDialog>
      </div>
    </div>
  );
};

export default Diagnosis;
