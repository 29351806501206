export const SELECTVALUES = [
  {
    id: "NCD",
    name: "NCD Insurance",
  },
  {
    id: "Checkups",
    name: "Checkups Insurance",
  },
];

export const PAGE_SIZE = 8;
