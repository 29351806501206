import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { addMinutes } from "date-fns";

export interface AuthState {
  isAuthenticated: boolean;
  currentUser?: CurrentUser;
  isCodeReceived: boolean;
  isCodeVerified: boolean;
  phone: string;
  loggedInUserPerms: any[];
  tokenExpires: string;
}

export interface CurrentUser {
  userId: string;
  userCode?: string;
  email: string;
  fullName: string;
  imageUrl: string;
}

export const initialState: AuthState = {
  isAuthenticated: false,
  isCodeReceived: false,
  isCodeVerified: false,
  phone: "",
  loggedInUserPerms: [],
  tokenExpires: `${addMinutes(new Date(), 30)}`,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthSuccess: (state: any, action: PayloadAction<CurrentUser>) => {
      state.currentUser = action.payload;
      state.isAuthenticated = true;
      state.tokenExpires = `${addMinutes(new Date(), 5)}`;
    },

    updateUserDetails: (
      state: any,
      action: PayloadAction<Partial<CurrentUser>>
    ) => {
      state.currentUser = { ...state.currentUser, ...action.payload };
    },

    updateOTPPhoneNumber: (state, { payload }: PayloadAction<string>) => {
      state.phone = payload;
    },

    resetAuthState: (state) => {
      state.isAuthenticated = false;
      state.isCodeVerified = false;
      state.phone = "";
      state.currentUser = undefined;
      state.loggedInUserPerms = [];
    },
    generateCodeSuccess: (state) => {
      state.isCodeReceived = true;
    },

    // Verify OTP Code success
    verifyCodeSuccess: (state) => {
      state.isCodeVerified = true;
    },

    updateLoggedInUserPerms: (state, { payload }: PayloadAction<string[]>) => {
      state.loggedInUserPerms = payload;
    },

    refreshToken: (state) => {
      state.tokenExpires = `${addMinutes(new Date(), 30)}`;
    },
  },
});

export const {
  setAuthSuccess,
  resetAuthState,
  verifyCodeSuccess,
  generateCodeSuccess,
  updateLoggedInUserPerms,
  updateOTPPhoneNumber,
  updateUserDetails,
  refreshToken,
} = authSlice.actions;

export default authSlice.reducer;
