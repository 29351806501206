import DataTable, { TableColumn } from "react-data-table-component";

interface PaginatedDataTableProps<T> {
  columns: TableColumn<T>[];
  data: T[];
  totalRecords: number;
  handlePageChange: (page: number) => void;
  handlePerRowsChange: (newPerPage: number, page: number) => void;
}

const customStyles = {
  headCells: {
    style: {
      backgroundColor: "#adb5bd",
      color: "white",
    },
  },
};

const PaginatedDataTable = <T,>({
  columns,
  data,
  totalRecords,
  handlePageChange,
  handlePerRowsChange,
}: PaginatedDataTableProps<T>): JSX.Element => {
  return (
    <DataTable
      pagination
      columns={columns}
      data={data}
      highlightOnHover
      paginationRowsPerPageOptions={[20, 50, 100, 200]}
      paginationTotalRows={totalRecords}
      paginationServer
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
      customStyles={customStyles}
    />
  );
};

export default PaginatedDataTable;