import * as React from "react";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import {
  GridRowModesModel,
  GridRowModes,
  GridColDef,
  GridRowId,
  GridRowIdGetter,
} from "@mui/x-data-grid";

import ActionButton from "./action-button";

import { DropDownItems } from "./types";
import GridTable from "./grid-table";
import {
  InsuranceDataType,
  useDeleteInsurance,
  useDisableInsurance,
  useEnableInsurance,
} from "../../../utils/useInsuranceQuery";
import SlideInDialog from "./slideindrawer";
import { Box, Chip, ChipProps } from "@mui/material";
import InlineSearchBar from "../../components/Search/InlineSearchBar";

import { useNavigate } from "react-router-dom";
import { ModalContext } from "../modal-context";

import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import { InsuranceContext } from "../insurance-context";

import { PAGE_SIZE } from "./constant";
import InsuranceTabs from "./insurance-tabs";
import { useInsuranceContext } from "../useInsuranceContext";

export default function InsuranceTable() {
  const navigate = useNavigate();
  const {
    isFetching,
    isLoading,
    insuranceData,
    search,
    activeTab,
    handleSearch,
    toggleDrawer,
  } = useInsuranceContext();
  const { setModalOpen, deleteWarn, handleDeleteWarn } =
    React.useContext(ModalContext);
  const [rows, setRows] = React.useState<
    (InsuranceDataType & { isNew?: boolean })[]
  >([]);

  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );
  const { mutateAsync } = useDeleteInsurance();
  const { mutateAsync: disableInsurance } = useDisableInsurance();
  const { mutateAsync: enableInsurance } = useEnableInsurance();

  const handleAddScheme = React.useCallback(
    (row: InsuranceDataType) => {
      toggleDrawer();
      setModalOpen({ data: row, action: "add-scheme" });
    },
    [setModalOpen, toggleDrawer]
  );
  const handleEditClick = React.useCallback(
    (row: InsuranceDataType) => {
      toggleDrawer();
      setModalOpen({ data: row, action: "edit" });
    },
    [setModalOpen, toggleDrawer]
  );

  // handele delete, warn on delete
  const handleDeleteClick = React.useCallback(
    (id: GridRowId, warn: boolean) => {
      if (warn) {
        handleDeleteWarn({ id, warn });
      }
    },
    [handleDeleteWarn]
  );

  const handleViewClick = React.useCallback(
    (id: GridRowId) => {
      navigate(`/admin-enroll-list/${id}`);
    },
    [navigate]
  );

  // MOCK handle delete
  const handleDelete = React.useCallback(async () => {
    if (deleteWarn === null) return;
    try {
      await mutateAsync(deleteWarn.id);
      handleDeleteWarn(null);
    } catch (error) {
      console.log(error, "ERROR");
    }
  }, [deleteWarn, mutateAsync, handleDeleteWarn]);

  // handle edit cancel on rows
  const handleCancelClick = React.useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });

      const editedRow = rows.find((row) => row.paymentAccountID === id);
      if (editedRow!?.isNew) {
        setRows(rows.filter((row) => row.paymentAccountID !== id));
      }
    },
    [rowModesModel, rows]
  );

  // cancel modal cancel for delete warning
  const handleCancel = React.useCallback(() => {
    if (deleteWarn === null) return;
    handleCancelClick(deleteWarn.id)();
    handleDeleteWarn(null);
  }, [deleteWarn, handleCancelClick, handleDeleteWarn]);

  const handleInsuranceDisable = React.useCallback(
    async (id: GridRowId, tab: "active" | "inactive") => {
      if (tab === "active") {
        await disableInsurance(id);
      } else {
        await enableInsurance(id);
      }
    },
    [disableInsurance, enableInsurance]
  );
  // action items for the the table actionable
  const actionItems = React.useCallback(
    (row: InsuranceDataType): DropDownItems[] => [
      {
        onClick: () => {
          handleEditClick(row);
        },
        label: "Edit",
        divider: true,
        disabled: activeTab === "inactive",
      },
      {
        onClick: () => {
          handleViewClick(row.paymentAccountID);
        },
        label: "View",
        divider: true,
        disabled: activeTab === "inactive",
      },
      {
        onClick: () => {
          handleAddScheme(row);
        },
        label: "Add Scheme",
        disabled: activeTab === "inactive",
      },
      {
        onClick: () => {
          handleInsuranceDisable(row.paymentAccountID, activeTab);
        },
        label: `${activeTab === "inactive" ? "Enable" : "Disable"}`,
        color: `${activeTab === "inactive" ? "#228BE6" : "#DC3E42"}`,
      },
      {
        onClick: () => {
          handleDeleteClick(row.paymentAccountID, true);
        },
        label: "Delete",
        color: "#DC3E42",
        disabled: activeTab === "inactive",
      },
    ],
    [
      handleAddScheme,
      handleDeleteClick,
      handleEditClick,
      handleInsuranceDisable,
      handleViewClick,
      activeTab,
    ]
  );

  // table column information
  const columns: GridColDef<InsuranceDataType>[] = React.useMemo(
    () => [
      {
        field: "createdDate",
        headerName: "Date Created",
        type: "string",
        resizable: false,
        editable: false,
        flex: 1,
        valueGetter: (e) => {
          if (e) {
            return dayjs(e).format("DD/MM/YY");
          }
          return dayjs().format("DD/MM/YY");
        },
      },
      {
        field: "code",
        headerName: "Code",
        type: "string",
        resizable: false,
        editable: false,
        flex: 1,
      },
      {
        field: "category",
        headerName: "Category",
        type: "string",
        resizable: false,
        editable: true,
        flex: 1,
        cellClassName: "category",
        renderCell: (params) => {
          return (
            <StyledChip
              $category={params.row.category?.toUpperCase() ?? "null"}
              label={params.row.category ?? "null"}
            />
          );
        },
      },
      {
        field: "description",
        headerName: "Description",
        type: "string",
        resizable: false,
        editable: true,
        flex: 1.5,
      },
      {
        field: "phoneNumber",
        headerName: "Phone Number",
        flex: 1,
        type: "string",
        editable: true,
        resizable: false,
      },
      {
        field: "whatsAppPhoneID",
        headerName: "WhatsApp Phone ID",
        resizable: false,
        flex: 1,
        editable: true,
        type: "number",
        align: "left",
        headerAlign: "left",
      },
      {
        field: "actions",
        type: "actions",
        resizable: false,
        width: 100,
        align: "center",
        cellClassName: "actions",
        getActions: ({ row }) => {
          return [
            <ActionButton
              actions={{
                items: actionItems(row),
                boundId: "table-root",
              }}
            />,
          ];
        },
      },
    ],
    [actionItems]
  );

  // get each row id
  const getRowId: GridRowIdGetter<InsuranceDataType> = React.useCallback(
    (param) => {
      return param.paymentAccountID;
    },
    []
  );

  return (
    <Box
      id="table-root"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        width: "100%",
        "& .MuiInputBase-root": {
          margin: 0,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <InsuranceTabs />
        <InlineSearchBar
          value={search}
          onSearch={handleSearch}
          id="insurance-search"
        />
      </Box>

      <GridTable
        loading={isFetching || isLoading}
        rows={insuranceData?.data ?? []}
        columns={columns}
        setRows={setRows}
        getRowId={getRowId}
        rowModesModel={rowModesModel}
        setRowModesModel={setRowModesModel}
        pagination={CustomPagination}
        pageSize={PAGE_SIZE}
      >
        <SlideInDialog
          handleCancel={handleCancel}
          handleClose={handleDelete}
          open={deleteWarn !== null}
          DialogButton={"continue"}
          text="This action is irriversible, do want to continue"
        />
      </GridTable>
    </Box>
  );
}

const CustomPagination = React.memo(() => {
  const { pageSize, setPagination, page } = React.useContext(InsuranceContext);

  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={page < 1 ? 1 : page}
      count={pageSize}
      renderItem={(props2) => <PaginationItem {...props2} />}
      onChange={(_event: React.ChangeEvent<unknown>, value: number) => {
        setPagination(value);
      }}
    />
  );
});

interface StyledChipProps extends ChipProps {
  $category?: string;
}

const StyledChip = styled(({ $category, ...rest }: StyledChipProps) => (
  <Chip {...rest} />
))(({ $category, theme }) => ({
  color:
    $category === "NCID" || $category === "NCD"
      ? "#228BE6"
      : $category === "null"
      ? ""
      : "#FD7E14",
  backgroundColor:
    $category === "NCID" || $category === "NCD"
      ? "#E7F5FF"
      : $category === "null"
      ? "transparent"
      : "#FFF4E6",
  borderRadius: "2px",
  padding: "1px 10px",
  fontSize: "11px",
  fontFamily: "Inter",
  fontStyle: "normal",

  fontWeight: 700,
  textTransform: "uppercase",
  lineHeight: "18px",
  letterSpacing: "0.4px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  gap: "4px",
  "& .MuiChip-icon": {
    color: theme.palette.primary.main,
  },
  "& .MuiChip-label": {
    color:
      $category === "NCID" || $category === "NCD"
        ? "#228BE6"
        : $category === "null"
        ? ""
        : "#FD7E14",
  },
}));
