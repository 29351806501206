import { createStyles, makeStyles } from "@mui/styles";
import React, {  useState } from "react";
import {Theme} from '@mui/material'
import { filterBySearch } from "../../utils/filters";
import { DataTableColumn } from "../../utils/types";
import { formatAsLocalDate } from "../../utils/formatDates";
import TableHeader from "../components/TableHeader";
import DataTable from "../components/Table";
import useFetch from "../../utils/useFetch";
import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
    colHeader: {
      "&:hover": {
        color: "red"
      }
    },
  
  }),
);

type PatientJourneyProps = {
  cycleID: "";
};

const PatientJourney = ({
  cycleID,
}: PatientJourneyProps) => {
  const classes = useStyles();

  const {
    data,
    isLoading,
    isError,
    error,
    isRefreshing,
    isSuccess
  } = useFetch(`api/v1/checkups/patient/patient-package-list`, ["packageitems"]);

  const [query, setQuery] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);
  
  const newData=data?.filter((data:any) => data?.subscriptionPackageId.includes(cycleID));

  const url = "https://registrationapi.checkupsmed.com:8002/api/v1/checkups/register/patients-journey?cycleId=";
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
    body: JSON.stringify({
      cycleID
    }),
  };

  const[permission,setPermission]=useState<any[]>(
    [sessionStorage.getItem('role')]
  )
  const rows = filterBySearch(query, newData);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  
  const [columns, setColumns] = useState([
    { title: <div className={classes.colHeader}>Code</div>, 
    field: "subscriptionPackageItemId", 
    type: "string" as const
   },
    {
      title:  <div className={classes.colHeader}>Item Description</div>,
      field: "itemDescription",
      initialEditValue: "initial edit value",
      type: "string" as const,
    },
    {
      title:  <div className={classes.colHeader}>Type</div>,
      field: "zidiItemType",
      initialEditValue: "initial edit value",
      type: "string" as const,
    }
  ])

  return (
    <div className={classes.root}>
      <div>
      <MaterialTable
            title="Eligible Lab Tests/Services List"
            columns={columns}
            data={newData}
            //onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
            options={  ["SUPERADMIN","ADMIN"].includes(permission[0]?.toUpperCase())? {
              columnsButton:true,
              exportMenu: [{
                label: 'Export PDF',
                exportFunc: (cols, datas) => ExportPdf(cols, datas, 'SubscriptionReport')
              }, {
                label: 'Export CSV',
                exportFunc: (cols, datas) => ExportCsv(cols, datas, 'SubscriptionReport')
              }],
              exportAllData: true,
              sorting: true,
              rowStyle: rowData => ({
                backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
              }),
              headerStyle: {
                backgroundColor: '#01579b',
                color: '#FFF',
              },
            }:{
              columnsButton:true,
              sorting: true,
              rowStyle: rowData => ({
                backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
              }),
              headerStyle: {
                backgroundColor: '#01579b',
                color: '#FFF',
              },}}

             />
      </div>
    </div>
  );
};

export default PatientJourney;
