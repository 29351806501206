import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Theme,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Alert } from "@mui/lab";
import GenericDialog from "../components/GenericDialog";
import LoadingSpinner from "../components/LoadingSpinner";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";

import { ErrorType } from "../../utils/types";
import usePostRequest from "../../utils/usePostRequest";
import { createUserSuccess } from "./subscription-logic/subscriptionSlice";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
    },

    inputStyle: {
      width: 400,
      marginBottom: theme.spacing(2),
    },
    autoComplete: {
      marginBottom: theme.spacing(1),
    },
    main: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
      zIndex: theme.zIndex.appBar - 1,
      outline: "none",
    },
    grid: {
      display: "grid",
      gridTemplateColumns: "1fr",
    },
    paper: {
      padding: theme.spacing(2),
      // textAlign: "center",
      //color: theme.palette.text.secondary,
      width: "auto",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      color: "black !important",
      fontFamily: "ChronicaPro,sans-serif",
      fontSize: "16px",
      fontWeight: 400,
    },
    formCustomTitle: {
      fontWeight: 700,
      color: "#449afe",
      fontSize: "1.3rem",
      fontFamily: "ChronicaPro,sans-serif",
      width: 400,
    },
    CustomTitle: {
      fontWeight: 700,
      color: "#022c5b",
      fontSize: "1.0rem! important",
      fontFamily: "ChronicaPro,sans-serif",
      width: 400,
    },
    inputLabel: {
      "&.focused": {
        color: "#449afe",
      },
      color: "#449afe",
    },
    errorText: {
      color: "red",
      fontSize: "small",
    },
  })
);

type CreateUserProps = {
  queryString: string;
  open: boolean;
  // eslint-disable-next-line no-unused-vars
  setOpen: (value: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  setEditing: (value: boolean) => void;
  editing: boolean;
  setEditLoading?: boolean;
  setEditError?: boolean;
};

function CreateUser({
  queryString,
  open,
  setOpen,
  editing,
  setEditing,
  setEditLoading,
  setEditError,
}: CreateUserProps) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const [openSnack, setOpenSnack] = useState(true);

  const { selectedUser } = useAppSelector((state) => state.subscription);
  //console.log("selectedUser",selectedUser.homeAddress);
  //const homeAddress=selectedUser?.homeAddress;
  const [CardLocation, setCardLocation] = useState<String>("");
  const [cardLocationErr, setCardLocationErr] = useState<false | ErrorType>(
    false
  );
  const [otherAddress, setOtherAddress] = useState<String>("");
  const { handleSubmit, reset } = useForm();
  const [postError, setPostError] = useState<false | ErrorType>(false);

  const onSuccess = ({ data }: any) => {
    queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.includes("users"),
    });
    dispatch(createUserSuccess(data));
    setOpen(false);
    setEditing(false);
    queryClient.invalidateQueries(`${queryString}`);
  };
  const onFailure = (err: ErrorType) => {
    setPostError(err);
  };
  const handleLocation = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCardLocation(event.target.value);
    setCardLocationErr(false);
  };
  const handleOtherAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOtherAddress(event.target.value);
  };
  const mutation = usePostRequest(
    "api/v1/checkups/agent/create-delivery-location",
    {
      memberNumber: selectedUser?.memberNumber,
      DeliveryLocation: CardLocation,
    },
    onSuccess,
    onFailure
  );
  const onSubmit = () => {
    mutation.reset();
    if (!CardLocation) {
      setCardLocationErr({ message: "The field is required" });
      return;
    }
    mutation.mutate();
  };

  const handleClose = () => {
    mutation.reset();
    reset();
  };

  const actions = [
    {
      label: "Update Location",
      primary: true,
      onClick: handleSubmit(onSubmit),
    },
  ];

  return (
    <div>
      {mutation.isSuccess && (
        <Snackbar
          open={openSnack}
          onClose={() => setOpenSnack(false)}
          autoHideDuration={6000}
        >
          <Alert onClose={handleClose} severity="success">
            {editing
              ? "Location created Successfully"
              : "Location updated successfully"}
          </Alert>
        </Snackbar>
      )}
      {editing && setEditError && (
        <Snackbar open={openSnack} onClose={() => setOpenSnack(false)}>
          <Alert onClose={handleClose} severity="error">
            'Something wrong happened, could not edit user'
          </Alert>
        </Snackbar>
      )}

      <GenericDialog
        title="Member Card Pickup Point Location"
        showDialog={open}
        onCloseDialog={() => {
          setOpen(false);
        }}
        actions={actions}
        isLoading={mutation.isLoading}
        width="sm"
        height={400}
        verticalAlign="flex-start"
      >
        {mutation.isLoading || setEditLoading ? <LoadingSpinner /> : <></>}
        <div className={classes.grid}>
          {postError ? (
            <Alert severity="error" className="error-container">
              {postError?.message}
            </Alert>
          ) : (
            <></>
          )}

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  container
                  justifyContent="flex-start"
                  direction="row"
                  alignItems="center"
                >
                  <FormControl component="fieldset">
                    <FormLabel
                      component="legend"
                      className={classes.formCustomTitle}
                    >
                      Please provide location for card collection
                    </FormLabel>
                    {/* <Box display="flex"> */}
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      container
                      justifyContent="flex-start"
                      direction="row"
                      alignItems="center"
                    >
                      <RadioGroup
                        aria-label="CardLocation"
                        name="CardLocation"
                        value={CardLocation || ""}
                        onChange={handleLocation}
                        className={classes.CustomTitle}
                        style={{ textDecoration: "none;" }}
                      >
                        <FormControlLabel
                          value="General Accident House"
                          control={<Radio />}
                          label="General Accident Housei"
                        />
                        <FormControlLabel
                          value="JKIA Airport"
                          control={<Radio />}
                          label="JKIA Airport"
                        />
                        <FormControlLabel
                          value="Lusaka Office"
                          control={<Radio />}
                          label="Lusaka Office"
                        />
                        <FormControlLabel
                          value={selectedUser?.homeAddress}
                          control={<Radio />}
                          label={selectedUser?.homeAddress}
                        />

                        <FormControlLabel
                          value={selectedUser?.workAddress}
                          control={<Radio />}
                          label={selectedUser?.workAddress}
                        />
                      </RadioGroup>
                    </Grid>
                    {/* </Box> */}
                    {!cardLocationErr ? (
                      ""
                    ) : (
                      <span className={classes.errorText}>
                        Please select pickup location
                      </span>
                    )}
                  </FormControl>
                </Grid>
                {CardLocation === "Other" ? (
                  <>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        size="small"
                        type="text"
                        InputLabelProps={{
                          classes: {
                            root: classes.inputLabel,
                            focused: "focused",
                            shrink: "shrink",
                          },
                        }}
                        name="PhysicalLocation"
                        label="Physical Location Address"
                        color="secondary"
                        variant="outlined"
                        fullWidth
                        defaultValue={otherAddress}
                        onChange={handleOtherAddress}
                        autoComplete="off"
                      />
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
              </Grid>
            </Paper>
          </Grid>
        </div>
      </GenericDialog>
    </div>
  );
}

CreateUser.defaultProps = {
  setEditError: false,
  setEditLoading: false,
};
export default CreateUser;
