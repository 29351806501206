import { useAppDispatch, useAppSelector } from "./hooks";
import { getDrawerStatus } from "../services/insurance-setup/insuranceSlice";

export default function useToggleDrawer() {
  const drawerState = useAppSelector((state) => state.insurance.drawerStatus);
  const dispatch = useAppDispatch();

  function toggleDrawer() {
    dispatch(getDrawerStatus(!drawerState));
  }

  return { toggleDrawer, drawerState };
}
