import { Box, Tab, Tabs } from "@mui/material";
import { styled } from "@mui/material/styles";

import { useInsuranceContext } from "../useInsuranceContext";

export default function InsuranceTabs() {
  const { handleTabChange, tabIndex } = useInsuranceContext();

  return (
    <Box>
      <StyledTabs
        value={tabIndex}
        onChange={handleTabChange}
        aria-label="basic tabs example"
      >
        <Tab label="Active" />
        <Tab label="Inactive" />
      </StyledTabs>
    </Box>
  );
}

const StyledTabs = styled(Tabs)(({ theme }) => ({
  "& .MuiTabs-indicator": {
    backgroundColor: "#fff",
    border: "1px solid #00002D17",
    height: "100%",
    borderRadius: "6px",
    zIndex: 0,
  },

  "& .MuiButtonBase-root.MuiTab-root": {
    zIndex: 1,
    backgroundColor: "transparent",
    letterSpacing: ".3px",
    border: "0px",
    outline: "0px",
    boxShadow: "0px tranparent",
    fontWeight: 300,
    color: "#000509E3",
    fontSize: "20px",
    transition: "all 0.3s ease-in-out 0.1s",
  },
  "& .MuiButtonBase-root.MuiTab-root.Mui-selected": {
    color: "#000",
    fontWeight: 500,
  },
  "& .MuiTabs-flexContainer": {
    backgroundColor: "#0000330F",
  },
  "& .MuiTab-root": {
    textTransform: "capitalize",
    backgroundColor: "#0000330F",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "0px",
    justifyItems: "center",
    gap: "8px",
    justifyContent: "center",
  },
}));
