/* eslint-disable @typescript-eslint/no-unused-vars */
import { createStyles, makeStyles } from "@mui/styles";
import { CreateOutlined, DeleteOutline, SubtitlesTwoTone } from "@mui/icons-material";
import LoadingSpinner, {
  RefreshingCard,
} from "../components/LoadingSpinner";
import PageContent from "../components/pageContent";
import PageHeading from "../components/PageHeading";
import Status from "../components/Status/Status";
import Table from "../components/Table2";
import TableHeader from "../components/TableHeader";
import TableLink from "../components/TableLink";
import React, { useEffect, useState } from "react";
import { filterBySearch } from "../../utils/filters";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import {
  DataTableAction,
  DataTableColumn,
  DataTableRow,
} from "../../utils/types";
import useFetch from "../../utils/useFetch";
import moment from "moment";
import SelectFromApi from "../components/SelectFromApi";
import { RootState } from "../../store/configureStore";
import { Autocomplete, Box, Link, TextField, Theme } from "@mui/material";
import { setSelectedUser, User } from "./agent-accounts-logic/profileSlice";
import SelectTypeOfService from "../components/selectTypeOfService";
import { red } from "@mui/material/colors";
import axios from "axios";
import { useQuery } from "react-query";
import CreateUser from "./CreateAgent";
import InlineSearchBar from "../components/Search/InlineSearchBar2";


const useStyles = makeStyles((theme: Theme) =>
createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
    },
    name: {
      textTransform: "capitalize",
    },
    formField: {
      marginLeft: '4px',
      marginTop: '30px',
      [theme.breakpoints.down('md')]: {
        width: '20%',
        alignContent: 'center ',
      },
  },
  searchBox: {
    padding: theme.spacing(2, 0),
    width: "99%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

}));


const links = [
  {
    pathname: "",
    label: "",
    permission: "",
  },
];

type Props = {
  roleName?: string;
  roleId?: string;
  userTypeCode?: string;
};
const AgentList: React.FC<Props> = ({
  roleName,
  roleId,
  userTypeCode,
}: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [query, setQuery] = useState("");
  const [editing, setEditing] = useState(false);
  const [showAddUserModal, setShowAddToRoleModal] = useState(false);
  const [serviceType, setServiceType] = useState<any>();
  const [user, setUser] = useState<User>();
  const subset="Patients";
  const { selectedBookings } = useAppSelector(
    (state: RootState) => state.clients,
  );
  const [value, setValue] = React.useState<string>('');
  const [inputValue, setInputValue] = React.useState('');
  const singleUser = useFetch(
    `/auth/get-single-user/?usercode=${user?.agentCode}`,
    ["user", `${user?.agentCode}`],
    { enabled: !!user?.agentCode }
  );
  const makeUrl = (defaultPath: string) =>
    query && !userTypeCode
      ? `/auth/system-user-fuzzy-search-user?searchterm=${query}`
      : defaultPath;

     // useFetch(`/${subset}`, `fetchBreeds-${subset}`);
      const {
        data,
        isLoading,
        isError,
        error,
        isRefreshing,
        isSuccess
      } = useFetch(`api/v1/checkups/agent/agents`, ["agents", ]);

      useEffect(() => {
        dispatch(setSelectedUser(user));
      }, [user]);
   
    const[updatedData, setUpdatedData] = useState([]);
    let optionsData;
    (value?.length <=0 || value ==null)? 
    optionsData=data?.data || data || [] :
    optionsData=data.filter((data:any) => data.typeOfService.includes(value));

  const [allData,setAllData] = useState(data);
  const [filteredData,setFilteredData] = useState(allData);
//  console.log("hello",filteredData);

const handleEditRow = (row: any) => {
  setUser(row.agentCode);
  setEditing(true);
  setOpen(true);
};
const[permission,setPermission]=useState<any[]>(
  [sessionStorage.getItem('role')]
)
  const isBackgroundRed = true;
  const actions: DataTableAction[] = 
    ["SUPERADMIN","FINANCE","ADMIN"].includes(permission[0]?.toUpperCase())? [
    {
      label: "Commission",
      icon: () => <CreateOutlined />,
      onClick: (row: any) => {
        handleEditRow(row);
      },
      permission: [undefined, '', 'INACTIVE'].includes(
        data?.status?.toUpperCase(),
      )
        ? 'ACTION-NOT-ALLOWED'
        : "",
    },
  ] : [];

  const columns: DataTableColumn[] = [
    {
      id: "agentCode",
      label: "Code",
      minWidth: 50,
    },
    {
      id: "agencyName",
      label: "Agent Name",
      minWidth: 100,
    },
    {
      id: "classicCare",
      label: "ClassicCare",
      minWidth: 30,
    },
    {
      id: "smartCare",
      label: "SmartCare",
      minWidth: 30,
    },
    {
      id: "deluxCare",
      label: "DeluxCare",
      minWidth: 30,
    },
    {
      id: "totalCount",
      label: "Total",
      minWidth: 20,
    },
    {
      id: "comission",
      label: "Total Commission(K)",
      minWidth: 30,
    },
    {
      id: "amountPaid",
      label: "AmountPaid (Kshs)",
      minWidth: 30,
    },
    {
      id: "balance",
      label: "Balance (Kshs)",
      minWidth: 30,
    },
    {
      id: "agentPhoneNumber",
      label: "Phone Number",
      format: (value: any) =>value?.toLowerCase() || "-"
    },
   
  ];

const rows: DataTableRow[] = isSuccess
? userTypeCode
  ?updatedData
  : updatedData
: [];


const [searched, setSearched] = useState<string>("");

useEffect(()=>{
  (value?.length <=0 || value ==null)? 
    setUpdatedData(data?.data || data || [])
    : setUpdatedData(data.filter((data:any) => data.typeOfService.includes(value)))

},[value || query || []])


  return (
    <PageContent>
      <div className={classes.root}>

        {isLoading ? <LoadingSpinner /> : <></>}
        {/* {isFetching ? <RefreshingCard message="Refreshing Clients" /> : <></>} */}
        <div className={classes.formField}>
        
      </div>
      {isSuccess ? (
        <>
      <Box className={classes.searchBox}>
        <Box display="flex" alignItems="center">
          <InlineSearchBar onSearch={setQuery} query={query} setUpdatedData={setUpdatedData} setSearched={setSearched} updatedData={updatedData} searched={searched} />
          </Box>
          </Box>
          <Table
            columns={columns}
            rows={rows?.map((r, index) => ({ ...r, number: index + 1 }))}
            actions={actions}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            pageCount={data?.totalPage}
            rowColor={''}
          />
        </>
      ) : ('<></>')}
      </div>
      <CreateUser
        queryString="clients"
        open={open}
        setOpen={setOpen}
        editing={editing}
        setEditing={setEditing}
        setEditLoading={singleUser.isLoading}
        setEditError={singleUser.isError}
      />
    </PageContent>
  );
};

AgentList.defaultProps = {
  roleName: undefined,
  roleId: undefined,
  userTypeCode: undefined,
};

export default AgentList;
