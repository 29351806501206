import { Box, Button,Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
// import useCheckPermissions from 'services/auth/auth-logic/useCheckPermissions';
import { DataTableFilter } from "../../utils/types";
import InlineSearchBar from "./Search/InlineSearchBar";
import TableFilters from "./TableFilters";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2, 0),
      width: "99%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    searchAndsuch: {},
    header: {
      fontWeight: 700,
    },
  })
);

type Props = {
  onSearch?: null | Function;
  query?: string;
  onClickAddNew?: null | Function;
  addNewLabel?: string;
  // addNewPermission?: string;
  filterId?: string;
  filterableColumns?: DataTableFilter[];
  onApplyFilters?: Function;
  onClearFilters?: Function;
};
const TableHeader: React.FC<Props> = ({
  onSearch,
  query,
  onClickAddNew,
  addNewLabel: ctaLabel,
  // addNewPermission,
  filterId,
  filterableColumns,
  onApplyFilters,
  onClearFilters,
}: Props) => {
  const classes = useStyles();

  const handleAddNewClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    typeof onClickAddNew === "function" && onClickAddNew();
  };

  const applyFilters = () => {
    onApplyFilters && onApplyFilters();
  };
  const clearFilters = () => {
    onClearFilters && onClearFilters();
  };

  return (
    <>
      <Box className={classes.root}>
        <Box display="flex" alignItems="center">
          {onSearch ? (
            <InlineSearchBar value={query || ""} onSearch={onSearch} />
          ) : (
            <></>
          )}
          <Box ml={3}>
            <TableFilters
              filterId={filterId || ""}
              onApplyFilters={applyFilters}
              onClearFilters={clearFilters}
              filterableColumns={filterableColumns || []}
            />
          </Box>
        </Box>

        {/* <>
          {onClickAddNew ? (
            // && addNewPermission
            <Button
              onClick={handleAddNewClick}
              variant="contained"
              color="secondary"
            >
              {ctaLabel || "Add New"}
            </Button>
          ) : (
            <></>
          )}
        </> */}
      </Box>
    </>
  );
};

TableHeader.defaultProps = {
  onSearch: null,
  query: undefined,
  onClickAddNew: null,
  addNewLabel: "",
  // addNewPermission: '',
  filterId: "",
  filterableColumns: [],
  onApplyFilters: undefined,
  onClearFilters: undefined,
};

export default TableHeader;
