/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Avatar,
  Button,
  Grid,
  IconButton,
  Typography, Theme
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Menu, Notifications, NotificationsOutlined } from "@mui/icons-material";
import React from "react";
// eslint-disable-next-line
import { Link, useNavigate } from "react-router-dom";
import { toggleSidebarCollapsed } from "../../services/settings/settings-logic/settingsSlice";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import DropDownMenu from "./DropDownMenu";
import Logo from "./Logo";
import UploadableImageContainer from "./UploadableImageContainer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "sticky",
      top: 0,
      left: 0,
      width: "100%",
      height: "8vh",
      padding: theme.spacing(1),
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      boxShadow: theme.shadows[3],
      zIndex: theme.zIndex.appBar,
      backgroundColor: theme.palette.common.white,
    },

    menuButton: {
      "&:hover": {
        backgroundColor: "#f0f0f0",
      },
    },

    icon: {
      padding: theme.spacing(1),
    },
    link: {
      margin: "auto",
    },
    center: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    },

    start: {
      justifyContent: "flex-start",
    },

    end: {
      justifyContent: "flex-end",
    },

    avatar: {
      fontSize: "4em",
      color: theme.palette.common.black,
    },

    name: {
      fontSize: "1.2em",
      fontWeight: 700,
      color: theme.palette.common.black,
      textTransform: "capitalize",
    },
    email: {
      fontSize: "1em",
      color: theme.palette.grey[700],
    },
    button: {
      marginTop: theme.spacing(1),
      width: "80%",
    },
    imageContainer: {
      display: "inline-block",
      position: "relative",
      width: 50,
      height: 50,
      overflow: "hidden",
      borderRadius: "50%",
    },
    userAvatar: {
      width: "auto",
      height: "100%",
      marginLeft: -5,
    },
    profileInfo: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: theme.spacing(2, 0),
    },
    dropdownBtn: {
      color: theme.palette.secondary.dark,
    },
  })
);

const Header: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector(
    (state) => state.auth
  );
  const isAuthenticated = sessionStorage.getItem('auth');
  const email = sessionStorage.getItem('email');
  const fullName = sessionStorage.getItem('firstName');
  const { imageUrl } = currentUser || {};
  const history = useNavigate();

  const onClickMenu = () => {
    dispatch(toggleSidebarCollapsed());
  };

  const handleLogout = () => {
    sessionStorage.clear();
    history("/");
  };

  const UserAvatar = () => <Avatar src={imageUrl} variant="circular" />;

  const NotificationsIcon = () => (
    <IconButton color="secondary" className={classes.icon}>
      <NotificationsOutlined fontSize="large" />
    </IconButton>
  );

  return (
    <div className={classes.root}>
      {isAuthenticated ? (
        <>
          <Grid
            item
            className={classes.start}
            classes={{ root: classes.center }}
          >
            <IconButton
              className={classes.menuButton}
              onClick={onClickMenu}
              color="inherit"
              size="medium"
              role="menu"
            >
              <Menu />
            </IconButton>
            <Logo />
          </Grid>

          <Grid item className={classes.end} classes={{ root: classes.center }}>
          {/* <DropDownMenu name={NotificationsIcon()} mainButtonClasses="">
              <Notifications />
                <Link className={classes.link} to="/tickets">
                  View All Tickets
                </Link>
            </DropDownMenu> */}
          <IconButton color="secondary" className={classes.icon}>
              <NotificationsOutlined fontSize="large" />
            </IconButton>
            <DropDownMenu
              name={UserAvatar()}
              mainButtonClasses={classes.dropdownBtn}
            >
              {/* <UploadableImageContainer
                uploadPath="/auth/upload-my-profile-picture"
                currentImages={imageUrl}
                queryNameToRefresh={["details", "mine"]}
              /> */}
              <div className={classes.profileInfo}>
                <Typography className={classes.name}>
                  {fullName || "-"}
                </Typography>
                <Typography className={classes.email}>
                  {!fullName && !email ? "" : email || "-"}
                </Typography>
              </div>
              <Button
                className={classes.button}
                variant="contained"
                color="secondary"
                onClick={handleLogout}
              >
                Log out
              </Button>
            </DropDownMenu>
          </Grid>
        </>
      ) : (
        <Logo />
      )}
    </div>
  );
};

export default Header;
