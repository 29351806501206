/* eslint-disable @typescript-eslint/no-unused-vars */
import { createStyles, makeStyles } from "@mui/styles";
import {
  Call,
  CheckCircleOutline,
  CreateOutlined,
  EmailOutlined,
  HighlightOffOutlined,
  LocalOfferTwoTone,
  LockOpenOutlined,
  OpacityOutlined,
  RotateRightOutlined,
} from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BadError from "../components/BadError";
import LoadingSpinner, {
  RefreshingCard,
} from "../components/LoadingSpinner";
import Metadata from "../components/Metadata/Metadata";
import { ItemDetails } from "../components/Metadata/types";
import NotFoundPage from "../components/NotFound";
import PageHeading from "../components/PageHeading";
import Status from "../components/Status/Status";
import TabbedView from "../components/TabbedView";
import React, { ReactChild, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate , useParams } from "react-router-dom";
import { DSA_USER_TYPE_CODE } from "../../utils/constants";
import { is404Error } from "../../utils/helpers";
import { DataTableAction, ErrorType, TabbedViewTab } from "../../utils/types";
import useFetch from "../../utils/useFetch";
import usePostRequest from "../../utils/usePostRequest";
import {Button, Grid, Theme} from '@mui/material'
import { RootState } from "../../store/configureStore";
import { useAppSelector } from "../../utils/hooks";
import ClientPaymentDetails from "./paymentDetails";
import PatientJourney from "./PatientJourney";
import MedicalHistory from "./MedicalHistory";
import GenericDialog from "../components/GenericDialog";
import Iframe from "react-iframe";
import PageContent from "../components/pageContent";
import { valuesIn } from "lodash";

const links = [
  {
    pathname: "",
    label: "",
    permission: "",
  },
];
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {width:'100%'},
    icon: {
      fontSize: 20,
      marginRight: theme.spacing(1),
    },
    paper2: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      padding: theme.spacing(1),
    },
  })
);

const SingleTicketsData: React.FC = () => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const [remarks, setRemarks] = useState<string>("");
  const [remarksErr, setRemarksErr] = useState<false | ErrorType>(false);
  const [postErr, setPostErr] = useState<false | ErrorType>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [editing, setEditing] = useState(false);
  const [confirmAction, setConfirmAction] = useState<
    false | "activate" | "deactivate" | "reset password"
  >(false);
  const { id }: any = useParams();
  const [pesapal, setPesapal]= useState<any>();
  const { selectedBookings } = useAppSelector(
    (state: RootState) => state.clients,
  );
  const {
    data,
    isLoading,
    isError,
    error,
    isRefreshing,
  } = useFetch(`api/v1/checkups/visit-summary/get-visit-Summary-Report?patientId=${id}`, ["VisitSummary", id]);

const subscriptionData=[data] || [];
 const singleUser = useFetch(
    `/auth/get-single-user/?usercode=${data?.userCode}`,
    ["user", `${data?.userCode}`],
    { enabled: !!data?.userCode }
  );
  const onSuccess = () => {
    queryClient.invalidateQueries(["user", data?.userCode]);
    setConfirmAction(false);
    setRemarks("");
  };
  const onFailure = (err: ErrorType) => {
    setPostErr(err);
  };

  const mutation = usePostRequest(
    "/auth/reset-user-password",
    { userCode: data?.userCode },
    onSuccess,
    onFailure
  );

  const history = useNavigate ();
  const handleOpenModal = (
    action: "activate" | "deactivate" | "reset password"
  ) => {
    setConfirmAction(action);
    setPostErr(false);
    setRemarksErr(false);
  };
  const handleEditRow = () => {
    setEditing(true);
    setOpen(true);
  };

  const handleChangeuserStatus = () => {
    if (remarks === "") {
      setRemarksErr({ message: "Reason for this change is required" });
      return;
    }
    setPostErr(false);
    mutation.reset();
    mutation.mutate();
  };

  const handleRemarksChange = (value: string) => {
    setRemarksErr(false);
    setRemarks(value);
  };

  const userActions: DataTableAction[] = [
    {
      label: "Activate user",
      permission: ['ACTIVE'].includes(data?.status?.toUpperCase())
        ? 'ACTION-NOT-ALLOWED'
        : "",
      onClick: () => handleOpenModal("activate"),
      icon: () => <CheckCircleOutline />,
    },
    {
      label: "Deactivate user",
      permission: [undefined, '', 'INACTIVE'].includes(
        data?.status?.toUpperCase(),
      )
        ? 'ACTION-NOT-ALLOWED'
        : "",
      onClick: () => handleOpenModal("deactivate"),
      icon: () => <HighlightOffOutlined />,
    },
    {
      permission: [undefined, '', 'INACTIVE'].includes(
        data?.status?.toUpperCase(),
      )
        ? 'ACTION-NOT-ALLOWED'
        : "",
      label: "Reset password",
      onClick: () => handleOpenModal("reset password"),
      icon: () => <RotateRightOutlined />,
    },
    {
      permission: [undefined, '', 'INACTIVE'].includes(
        data?.status?.toUpperCase(),
      )
        ? 'ACTION-NOT-ALLOWED'
        : "",
      label: "Assign roles",
      onClick: () => history("/roles"),
      icon: () => <LockOpenOutlined />,
    },
    {
      permission: [undefined, '', 'INACTIVE'].includes(
        data?.status?.toUpperCase(),
      )
        ? 'ACTION-NOT-ALLOWED'
        : "",
      label: "Edit user",
      onClick: () => handleEditRow(),
      icon: () => <CreateOutlined />,
    },
  ];

  const userTypeCode = data?.userTypeCode;

  const userTypeFetch = useFetch(
    `/search/usertype/${userTypeCode}`,
    ["userType", id],
    {
      enabled: !!userTypeCode,
    }
  );


  const mainTabs: TabbedViewTab[] = [
    {
    tabId: 'Medical Details',
      title: 'Medical Details',
      content: <MedicalHistory data={subscriptionData} />
    },
  
    // {
    // tabId: 'Medical History',
    // title: 'Utilization Report',
    // content: <MedicalHistory data={data} />
    // },
    // {
    //   tabId: 'Package Details',
    //   title: 'Package Details',
    //   content: <PatientJourney cycleID={data?.packageCode}/>
    //   },
  ];
  const [activeTab, setActiveTab] = useState(
    mainTabs[0].tabId
  );
  const handleChangeActiveTab = (name: string) => {
    setActiveTab(name);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError && is404Error(error)) {
    return <NotFoundPage message={`User with userId ${id} not found`} />;
  }

  if (isError) {
    return <BadError error={error} />;
  }

  const userType = userTypeFetch?.data;
  /**
   * Entity status manipulation logic
   */

  const modalActions: {
    label: string | ReactChild;
    onClick: () => void;
    primary: boolean;
  }[] = [
    {
      label: `${confirmAction}`,
      onClick: () => {
        handleChangeuserStatus();
      },
      primary: true,
    },
  ];

  /*
   * metadata needed to display profile header content
   */
  // if (!data?.status) {
  //   data.status = "Active";
  // }
const newData= subscriptionData[0]?.map((value:any)=>{
  return value;
});
  const metaData: ItemDetails = {
    imageUrl: data?.imageUrl,
    hasImageUpload: true,
    name: newData[10]?.fullName,
    statusContainer: data && ([]
    ),
    uploadPath: `auth/upload-profile-picture?usercode=${data?.userCode}`,
    icon: AccountCircleIcon,
    queryNameToRefresh: ["user", newData[10]?.patientNo],
    boxContent: [
      {
        title: newData[10]?.patientNo,
        icon: <LocalOfferTwoTone className={classes.icon} />,
      },
      {
        title: newData[10]?.email,
        icon: <EmailOutlined className={classes.icon} />,
      },
      {
        title: newData[10]?.phonenUmber,
        icon: <Call className={classes.icon} />,
      },
    ],
  };

  const handleModal = (row: any) => {
    row.preventDefault();
    setOpen(true);
    setPesapal(subscriptionData[0]?.paymentURL);
  };
  return (
    <PageContent>
    <div className={classes.root}>
      <PageHeading title="User Profile" links={links} />
      {isRefreshing ? <RefreshingCard message="Refreshing user" /> : <></>}
      <Button
            color="secondary"
            variant="outlined"
            onClick={handleModal}
            style={{ position: 'absolute', right:2, marginTop: 50,backgroundColor:'green',color:'white'}}
          >
          {data.status}
          </Button>
      <Metadata metadata={metaData} />
      {/* <TabbedView
        tabs={mainTabs}
        activeTab={activeTab}
        onChangeActiveTab={handleChangeActiveTab}
      /> */}
       <MedicalHistory data={subscriptionData} />

<GenericDialog
        title='COMPLETE PAYMENT'
        showDialog={open}
        onCloseDialog={() => {
          setOpen(false);
        }}
        actions={[]}
        isLoading={mutation.isLoading}
        width="sm"
        height={400}
        verticalAlign="flex-start"
      >
      <Grid container spacing={1} className={classes.paper2}>
                <Grid item md={12} xs={12} xl={12} lg={12}>                    
              <Iframe url={pesapal}
                width="100%"
                height="600px"
                id="myId"
                target={"_blank"}
                className="myClassname"                                    
                position="relative"/>                                                
            </Grid>
             </Grid>

  
      </GenericDialog>
             
    </div>
    </PageContent>
  );
};

export default SingleTicketsData;
