import {
  Button,

  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Theme,
  Typography,
} from '@mui/material';
import { createStyles,makeStyles,} from "@mui/styles"
import { Close } from '@mui/icons-material';
import { uniqueId } from 'lodash';
import React, { ReactChild } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: '100%',
    },
    header: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(2),
    },
    title: {
      fontWeight: 800,
      textTransform: 'capitalize',
    },
    closeBtn: {
      padding: 0,
    },
    content: {
      padding: theme.spacing(3, 0),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'column',
    },
    submitBtn: {
      minWidth: 165,
      width: 'fit-content',
    },
  }),
);

type DialogProps = {
  showDialog: boolean;
  onCloseDialog: () => void;
  children: ReactChild | ReactChild[];
  actions: {
    label: string | ReactChild;
    onClick: () => void;
    primary: boolean;
  }[];
  title: string;
  isLoading: boolean;
  width?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  height?: number;
  verticalAlign?: 'flex-start' | 'space-around' | 'space-evenly' | 'center';
};
const GenericDialog: React.FC<DialogProps> = ({
  showDialog,
  onCloseDialog,
  children,
  actions,
  title,
  isLoading,
  width,
  height,
  verticalAlign,
}: DialogProps) => {
  const classes = useStyles();

  return (
    <Dialog
      className={classes.root}
      fullWidth
      open={showDialog}
      aria-labelledby="dialog-modal"
      maxWidth={width}
    >
      <div id="dialog-modal" className={classes.header}>
        <Typography className={classes.title}>{title}</Typography>
        <IconButton className={classes.closeBtn} onClick={onCloseDialog}>
          <Close />
        </IconButton>
      </div>
      <DialogContent
        className={classes.content}
        dividers
        style={{
          minHeight: height,
          minWidth: width,
          justifyContent: verticalAlign,
        }}
      >
        {children}
      </DialogContent>
      <DialogActions>
        {actions.map(({ label, onClick, primary }) => (
          <Button
            key={uniqueId()}
            className={classes.submitBtn}
            color={primary ? 'secondary' : 'inherit'}
            variant={primary ? 'contained' : undefined}
            onClick={onClick}
            disabled={isLoading}
          >
            {label}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};

GenericDialog.defaultProps = {
  width: 'md',
  height: 900,
  verticalAlign: 'flex-start',
};
export default GenericDialog;
