import { CircularProgress, TextField, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Alert, Autocomplete } from "@mui/lab";
import LoadingSpinner, { RefreshingCard } from "./LoadingSpinner";
import React from "react";
import { ErrorType } from "../../utils/types";
import useFetch from "../../utils/useFetch";
import { makeErrorMessage } from "../../utils/useGracefulAPIErrorHandling";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
    },
    errorText: {
      color: "red",
      fontSize: "small",
    },
    formField: {
      width: "100%",
      marginTop: theme.spacing(0),
    },
  })
);

type Props = {
  onSelect?: Function;
  itemName: string;
  label: string;
  path: string;
  primaryKey: string;
  displayValueKey: string | number;
  error: false | ErrorType | undefined;
  helperText?: string;
  selected: string;
  width?: string | number;
  alertWidth?: string | number;
};
const FormSelectInsurance: React.FC<Props> = ({
  onSelect,
  itemName,
  label,
  path,
  primaryKey,
  displayValueKey,
  error: validationErr,
  helperText,
  selected,
  // eslint-disable-next-line no-unused-vars
  width,
  alertWidth,
}: Props) => {
  const classes = useStyles();
  const { isLoading, isRefreshing, isSuccess, isError, data, error } = useFetch(
    path,
    itemName
  );

  // eslint-disable-next-line no-unused-vars
  const handleSelection = (e: any, option: any) => {
    onSelect && onSelect(option);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }
  if (isError) {
    return (
      <Alert
        className="error-container"
        severity="error"
        style={{ width: alertWidth || "auto" }}
      >
        {makeErrorMessage(error as any) || "Something went wrong"}
      </Alert>
    );
  }
  const optionsData = data?.Data || data?.data || data || [];

  return (
    <div className={classes.root}>
      {isRefreshing ? (
        <RefreshingCard message={`Refreshing ${itemName}`} />
      ) : (
        <></>
      )}
      {isSuccess ? (
        <>
          <Autocomplete
            color="secondary"
            onChange={(e, value) => {
              handleSelection(e, value);
            }}
            disableClearable
            loading={isLoading}
            id="autocomplete"
            className={classes.formField}
            options={optionsData}
            // defaultValue={data.find((d: any) => d[primaryKey] === selected)}
            defaultValue={optionsData?.find((d: any) =>
              primaryKey ? d[primaryKey] === selected : d === selected
            )}
            getOptionLabel={(option: any) => option[displayValueKey] || ""}
            renderInput={(params) => (
              <TextField
                color="secondary"
                {...params}
                className={classes.formField}
                label={label}
                fullWidth
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : (
                        <></>
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
                helperText={helperText}
              />
            )}
          />
          {validationErr ? (
            <span className={classes.errorText}>{validationErr.message}</span>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

FormSelectInsurance.defaultProps = {
  onSelect: () => {},
  width: "100%",
  alertWidth: "100%",
  helperText: "",
};
export default FormSelectInsurance;
