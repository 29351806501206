import {  Box, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Paper, Radio, RadioGroup, Snackbar, TextField, Theme, Button } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Alert } from "@mui/lab";
import GenericDialog from "../components/GenericDialog";
import LoadingSpinner from "../components/LoadingSpinner";
import React, { ChangeEvent, useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ErrorType } from "../../utils/types";
import usePostRequest from "../../utils/usePostRequest";
import { createUserSuccess } from "../agent-accounts/agent-accounts-logic/profileSlice";
import SelectFromApi from "../components/SelectFromApi";
import useFetch from "../../utils/useFetch";
import axios from 'axios';  // Import axios to handle file uploads
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
    },

    inputStyle: {
      width: 400,
      marginBottom: theme.spacing(2),
    },
    autoComplete: {
      marginBottom: theme.spacing(1),
    },
    main: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
      zIndex: theme.zIndex.appBar - 1,
      outline: "none",
    },
    grid: {
      display: "grid",
      gridTemplateColumns: "1fr",
    },
    paper: {
      padding: theme.spacing(2),
      // textAlign: "center",
      //color: theme.palette.text.secondary,
      width: 'auto',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      color: 'black !important',
      fontFamily: "ChronicaPro,sans-serif",
      fontSize:"16px",
      fontWeight: 400,
    },
    formCustomTitle: {
      fontWeight:700,
      color: "#449afe",
      fontSize:"1.3rem",
      fontFamily: "ChronicaPro,sans-serif",
      width: 400,
    },
    CustomTitle: {
      fontWeight:700,
      color: "#022c5b",
      fontSize:"1.0rem! important",
      fontFamily: "ChronicaPro,sans-serif",
      width: 400,
    },
    inputLabel: {
      "&.focused": {
        color: "#449afe",
      },
      color: "#449afe",
    },
    errorText: {
      color: 'red',
      fontSize: 'small',
    },
  })
);

type AssignProcurementProps = {
  queryString: string;
  open: boolean;
  ticketNo: string;
  orderID: string;
  PDepartmentID: number,
  // eslint-disable-next-line no-unused-vars
  setOpen: (value: boolean) => void;
  // eslint-disable-next-line no-unused-vars
};

function AssignProcurement({
  queryString,
  open,
  setOpen,
  ticketNo,
  orderID,
  PDepartmentID
}: AssignProcurementProps) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const {
    data,
    isLoading,
    isError,
    error,
    isRefreshing,
  } = useFetch(`api/v1/checkups/tickets/get-single-ticket?TicketNo=${ticketNo}`, ["Ticket", ticketNo]);
  
  const [openSnack, setOpenSnack] = useState(true);
  const { selectedUser } = useAppSelector((state) => state.subscription);
  const [PaymentMethod, setPaymentMethod] = useState<string>('');
  const [AmountPaid, setAmountPaid] = useState<string>('');
  const [TransactionID, setTransactionID] = useState<string>("");
  const [convertTicket, setConvertTicket] = useState<string>("");
  const [invoiceNo, setInvoiceNo] = useState<string>('');
  const [assignTicket, setAssignTicket] = useState<string>('Department');
  const [invoiceError, setInvoiceError] = useState('');
  const [TransactionIDErr, setTransactionIDErr] = useState<false | ErrorType>(false);
  const { handleSubmit, reset } = useForm();
  const [postError, setPostError] = useState<false | ErrorType>(false);
  const [visitTypeErr, setVisitTypeErr] = useState<false | ErrorType>(false);
  const [department, setdepartment] = useState<string>("");
  const [assignedTo, setassignedToID] = useState<string>("");
  const [assignments, setAssignments] = useState<Array<{ assignedTo: string; department: string }>>([
    { assignedTo: "", department: "" }
  ]);
  const [departmentID, setDepartmentID] = useState<number>(0);
  const [comment, setcomment] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [commentError, setCommentError] = useState<string>("");
  const [uploadedFileUrl, setUploadedFileUrl] = useState<string | null>(null);  // Store the uploaded file URL
  var UserID =sessionStorage.getItem('UserID');

  const onSuccess = ({ data }: any) => {
    queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.includes("Orders"),
    });
    dispatch(createUserSuccess(data));
    setOpen(false);
    queryClient.invalidateQueries(`${queryString}`);
    
    // Clear the input fields
    setPaymentMethod("");
    setAmountPaid("");
    setTransactionID("");
    setTransactionIDErr(false);
    setDepartmentID(0);
    setassignedToID("");
    setcomment("");
    setCommentError('');
    setInvoiceNo('');
    setInvoiceError('');
    setSelectedFile(null);
    setUploadedFileUrl(null);
  };
  const onFailure = (err: ErrorType) => {
    setPostError(err);
  };

/*   const handleFileChange = (event:any) => {
    const file = event.target.files[0];
   
      setSelectedFile(file.name);
   
  }; */

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      // Reset uploaded file URL if a new file is selected
      setUploadedFileUrl(null);
    }
  };

  const handleFileUpload = async () => {
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const response = await axios.post('/api/FileUpload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setUploadedFileUrl(response.data.fileUrl);  // Assuming the API returns the file URL
    } catch (error) {
      setPostError({ message: 'Failed to upload file.' });
    }
  };

  const handleViewFile = () => {
    if (uploadedFileUrl) {
      window.open(uploadedFileUrl, '_blank');
    }
  };

  const handleDepartment = (newValue: any) => {
    setDepartmentID(newValue?.departmentID);
  };
  const handleChange = (event:any) => {
    const { name, value } = event.target;
    switch (name) {
      case 'comment':
        setcomment(value);
        setCommentError('');
        break;
        case 'convertTicket':
          setConvertTicket(value);
          break;
          case 'assignTicket':
            setAssignTicket(value);
            break;  
          case 'department':
            setdepartment(value);
            break;      
          case 'invoiceNo':
            setInvoiceNo(value);
            setInvoiceError('');
            break;
        default:
          break;
      }
    };

  const handleAssigned = (newValue: any) => {
    setassignedToID(newValue?.userID);
  };
  const mutation = usePostRequest(
    "order-assignment",
    {
      ticketNumber: ticketNo,
      assignedTo,
      departmentID: 22,
      orderID:orderID,
      assignedBy: UserID,
      latitude:0,
      longitude: 0,
      locationName:"",
      status:0,
      notes:comment,
      department
    },
    onSuccess,
    onFailure
  );

  const onSubmit = () => {
    // if (invoiceNo.trim() === '' && convertTicket==="Accepted") {
    //   setInvoiceError('Invoice Number is required.'); // Display a warning if the field is empty
    //   return;
    // }
    if (comment.trim() === '') {
      setCommentError('Comment is required.'); // Display a warning if the field is empty
      return;
    }
    mutation.reset();
    mutation.mutate();
  };

  const handleClose = () => {
    mutation.reset();
    reset();
  };

  const actions = [
    {
      label: 'Submit Now',
      primary: true,
      onClick: handleSubmit(onSubmit),
    },
  ];

  return (
    <div>
      {mutation.isSuccess && (
        <Snackbar
          open={openSnack}
          onClose={() => setOpenSnack(false)}
          autoHideDuration={6000}
        >
          <Alert onClose={handleClose} severity="success">
              "Ticket Successfully Assigned"
          </Alert>
        </Snackbar>
      )}

      <GenericDialog
        title='Ticket Assignment'
        showDialog={open}
        onCloseDialog={() => {
          setOpen(false);
        }}
        actions={actions}
        isLoading={mutation.isLoading}
        width="sm"
        height={400}
        verticalAlign="flex-start"
      >
       
          {mutation.isLoading ? <LoadingSpinner /> : <></>}
          {/* <div className={classes.grid}> */}
            {postError ? (
              <Alert severity="error" className="error-container">
                {postError?.message}
              </Alert>
            ) : (
              <></>
            )}
            
            <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>    
            <Grid item xs={12} sm={12}>
          <FormControl component="fieldset">
            <RadioGroup
              name="convertTicket"
              value={convertTicket}
              onChange={handleChange}
              row
            >
                <FormControlLabel
                value="Accepted"
                control={<Radio />}
                label="Accept"
              />
              <FormControlLabel
                value="Declined"
                control={<Radio />}
                label="Decline"
              />
              <FormControlLabel
                value="Re-Assigned"
                control={<Radio />}
                label="Re-Assign"
              />
             
            </RadioGroup>
          </FormControl>
        </Grid>
        </Paper>
        {/* {convertTicket==="Accepted" ? (
        <Paper className={classes.paper}> 
        <Grid item xs={12} sm={12}>
            
            <FormControl component="fieldset">
            <FormLabel>Department?</FormLabel>
              <RadioGroup
                name="department"
                value={department}
                onChange={handleChange}
                row
              >
                  <FormControlLabel
                  value="Dispatch"
                  control={<Radio />}
                  label="Dispatch"
                />
                <FormControlLabel
                  value="Claim"
                  control={<Radio />}
                  label="Claim"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          </Paper>
        ):(<></>)} */}
{convertTicket && (
            <Paper className={classes.paper}>   
         {/* {convertTicket==="Accepted"? (
           <>
        <Grid item xs={12} sm={12} sx={{mb:2}}>
          <TextField
            name="invoiceNo"
            label="Invoice Number"
            value={invoiceNo}
            required
            onChange={handleChange}
            fullWidth
          />
          {invoiceError && <p style={{ color: 'red' }}>{invoiceError}</p>}
          </Grid>
         </>
         ):(<></>)} */}
           {convertTicket==="Accepted" ? (
            <>
        {/* {department==="Dispatch" ? (
        <Grid item xs={12} sm={12}>
        <Box className={classes.autoComplete}>
              <SelectFromApi
                itemName={`userID-${department}`}
                primaryKey="userID"
                displayValueKey="fullName"
                onSelect={handleAssigned}
                selected={assignedTo || ""}
                error={visitTypeErr}
                path={`api/v1/checkups/tickets/users-bydepartment?DepartmentID=17`}
                label="Select Staff Assign To"
                width="100%"
                alertWidth={500}
              />
            </Box>
        </Grid>
        ):(<></>)} */}
       {/* {department==="Claim" ? ( */}
        <Grid item xs={12} sm={12}>
        <Box className={classes.autoComplete}>
              <SelectFromApi
                itemName={`userID-${department}`}
                primaryKey="userID"
                displayValueKey="fullName"
                onSelect={handleAssigned}
                selected={assignedTo || ""}
                error={visitTypeErr}
                path={`api/v1/checkups/tickets/users-bydepartment?DepartmentID=22`}
                label="Select Staff Assign To"
                width="100%"
                alertWidth={500}
              />
            </Box>
        </Grid>
        {convertTicket &&(    
         <Grid item xs={12} sm={12} style={{lineHeight:'2.5'}}>
          <label>Attach drug prescriptions</label>
          <br></br>
          <input
            name="file"
            type="file"
            multiple
            onChange={handleFileChange}
            style={{ width: '100%' , boxSizing:'border-box'}} 
          />
         {commentError && <p style={{ color: 'red' }}>{commentError}</p>}

         <Button
                    variant="text"
                    onClick={handleViewFile}
                  >
                    View File
                  </Button>
          </Grid>
       )}

         {/* ):(<></>)} */}

       </> 
           ):(<></>)}
          {convertTicket==="Re-Assigned" ? (
            <>
        <Grid item xs={12} sm={12}>
        <Box className={classes.autoComplete}>
              <SelectFromApi
                itemName={`userID-${PDepartmentID}`}
                primaryKey="userID"
                displayValueKey="fullName"
                onSelect={handleAssigned}
                selected={assignedTo || ""}
                error={visitTypeErr}
                path={`api/v1/checkups/tickets/users-bydepartment?DepartmentID=${PDepartmentID}`}
                label="Select Staff Assign To"
                width="100%"
                alertWidth={500}
              />
            </Box>
        </Grid>
        {convertTicket &&(    
         <Grid item xs={12} sm={12} style={{lineHeight:'2.5'}}>
          <label>Attach drug prescriptions</label>
          <br></br>
          <input
            name="file"
            type="file"
            multiple
            onChange={handleFileChange}
            style={{ width: '100%' , boxSizing:'border-box'}} 
          />
         {commentError && <p style={{ color: 'red' }}>{commentError}</p>}
          </Grid>
       )}

       </> 
           ):(<></>)}
          {convertTicket &&(    
                  <Grid item xs={12} sm={12}>
                    <TextField
                      name="comment"
                      label="Comment"
                      value={comment}
                      onChange={handleChange}
                      required
                      fullWidth
                    />
                  {commentError && <p style={{ color: 'red' }}>{commentError}</p>}
                    </Grid>
                )}
          </Paper>
        )}
          </Grid>
          </Grid>

          {/* </div> */}
  
      </GenericDialog>
    </div>
  );
}

export default AssignProcurement;
