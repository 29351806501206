import {  Box, Checkbox, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Paper, Radio, RadioGroup, Snackbar, TextField, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Alert } from "@mui/lab";
import GenericDialog from "../components/GenericDialog";
import LoadingSpinner from "../components/LoadingSpinner";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import { ErrorType } from "../../utils/types";
import usePostRequest from "../../utils/usePostRequest";
import { createUserSuccess } from "../agent-accounts/agent-accounts-logic/profileSlice";
import useFetch from "../../utils/useFetch";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
    },

    inputStyle: {
      width: 400,
      marginBottom: theme.spacing(2),
    },
    autoComplete: {
      marginBottom: theme.spacing(1),
    },
    main: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
      zIndex: theme.zIndex.appBar - 1,
      outline: "none",
    },
    grid: {
      display: "grid",
      gridTemplateColumns: "1fr",
    },
    paper: {
      padding: theme.spacing(2),
      // textAlign: "center",
      //color: theme.palette.text.secondary,
      width: 'auto',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      color: 'black !important',
      fontFamily: "ChronicaPro,sans-serif",
      fontSize:"16px",
      fontWeight: 400,
    },
    formCustomTitle: {
      fontWeight:700,
      color: "#449afe",
      fontSize:"1.3rem",
      fontFamily: "ChronicaPro,sans-serif",
      width: 400,
    },
    CustomTitle: {
      fontWeight:700,
      color: "#022c5b",
      fontSize:"1.0rem! important",
      fontFamily: "ChronicaPro,sans-serif",
      width: 400,
    },
    inputLabel: {
      "&.focused": {
        color: "#449afe",
      },
      color: "#449afe",
    },
    errorText: {
      color: 'red',
      fontSize: 'small',
    },
  })
);


interface SubLabType {
  subLabTestID: number;
  description: string;
  comment: string;
  isChecked: number;
}

type CreateResultsProps = {
  queryString: string;
  open: boolean;
  testID: string;
  labTestID:string;
  CycleID:string;
  sample:string;
  // eslint-disable-next-line no-unused-vars
  setOpen: (value: boolean) => void;
  // eslint-disable-next-line no-unused-vars
};

function CreateResults({
  queryString,
  open,
  setOpen,
  testID,
  labTestID,
  CycleID,
  sample
}: CreateResultsProps) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const {
    data,
    isLoading,
    isError,
    error,
    isRefreshing,
  } = useFetch(`api/v1/checkups/tickets/get-sub-lab-tests?LabTestID=${labTestID}`, ["SubLabTestLists", labTestID]);


  const [tests, setTests] = useState<SubLabType[]>([]);
  useEffect(()=>{
    setTests(data);
  },[data]);
  
  const [comments, setComments] = useState<{ [key: number]: string }>({});

  const handleCheckboxChange = (event: any, testId: number) => {
    setComments((prevComments) => {
      const updatedComments = { ...prevComments };
      if (event.target.checked) {
        updatedComments[testId] = '';
      } else {
        delete updatedComments[testId];
      }

      return updatedComments;
    });
    setTests((prevTests) =>
    prevTests.map((test) =>
      test.subLabTestID === testId ? { ...test, comment: event.target.checked ? '' : test.comment,
      isChecked: event.target.checked ? 1 : 0,
    } : test,
    )
  );

  };
  const handleCommentChange = (testId: number, comment: string) => {

    setTests((prevTests) =>
      prevTests.map((test) =>
        test.subLabTestID === testId ? { ...test, comment } : test
      )
    );
  };


  useEffect(() => {
    const payload = tests && tests
      .filter((test) => test.isChecked === 1)
      .map((test) => ({
        subLabTestID: test.subLabTestID,
        description: test.description,
        comment: test.comment,
      }));
    // You can replace this with the logic to send the payload wherever needed
  },[tests && tests]);

  const [openSnack, setOpenSnack] = useState(true);
  
  const { selectedUser } = useAppSelector((state) => state.subscription);

  const { handleSubmit, reset } = useForm();
  const [postError, setPostError] = useState<false | ErrorType>(false);
  var UserID =sessionStorage.getItem('UserID');

  const onSuccess = ({ data }: any) => {
    queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.includes("SubLabTestLists"),
    });
    dispatch(createUserSuccess(data));
    setOpen(false);
    queryClient.invalidateQueries(`${queryString}`);
    
    // Clear the input fields
  };
  const onFailure = (err: ErrorType) => {
    setPostError(err);
  };



  const payload = tests && tests
      .filter((test) => test.isChecked === 1)
      .map((test) => ({
        subLabTestID: test.subLabTestID,
        description: test.description,
        comment: test.comment,
      }));

  const mutation = usePostRequest(
    "api/v1/checkups/tickets/CreateResults",
    {
      tests:payload,
      lab_test_id:labTestID,
      test_id:testID,
      done:1,
      cycle_id:CycleID,
      center_id:"",
      done_by:"",
      labcomment:"",
      facility_id:"",
      created_by:"",
      UserID,
      sample

    },
    onSuccess,
    onFailure
  );

  const onSubmit = () => {
    mutation.reset();
    mutation.mutate();
  };


  const handleClose = () => {
    mutation.reset();
    reset();
  };

  const actions = [
    {
      label: 'Submit Now',
      primary: true,
      onClick: handleSubmit(onSubmit),
    },
  ];

  return (
    <div>
      {mutation.isSuccess && (
        <Snackbar
          open={openSnack}
          onClose={() => setOpenSnack(false)}
          autoHideDuration={6000}
        >
          <Alert onClose={handleClose} severity="success">
              "Results Successfully Assigned"
          </Alert>
        </Snackbar>
      )}

      <GenericDialog
        title='Enter Patient Results'
        showDialog={open}
        onCloseDialog={() => {
          setOpen(false);
        }}
        actions={actions}
        isLoading={mutation.isLoading}
        width="sm"
        height={400}
        verticalAlign="flex-start"
      >
       
          {mutation.isLoading ? <LoadingSpinner /> : <></>}
          {/* <div className={classes.grid}> */}
            {postError ? (
              <Alert severity="error" className="error-container">
                {postError?.message}
              </Alert>
            ) : (
              <></>
            )}
            
            <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>


            <Paper className={classes.paper}>   

            {tests && tests.map((test:SubLabType) => (
        <Grid item key={test.subLabTestID}>
          <FormControlLabel
            control={
              <Checkbox
          
                onChange={(event) => handleCheckboxChange(event,test.subLabTestID)}
                value={test.subLabTestID.toString()}
              />
            }
            label={test.description}
          />
          {comments[test.subLabTestID] !== undefined && (
            <TextField
              label="Value"
              variant="outlined"
              focused
              size="small"
              fullWidth
              value={test.comment}
              onChange={(e) => handleCommentChange(test.subLabTestID, e.target.value)}
            />
          )}
        </Grid>
      ))}
      
           
        
     
          
        
    
          </Paper>
       
          </Grid>
          </Grid>

          {/* </div> */}
  
      </GenericDialog>
    </div>
  );
}

export default CreateResults;
