import {  Box, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Paper, Radio, RadioGroup, Snackbar, TextField, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Alert } from "@mui/lab";
import GenericDialog from "../components/GenericDialog";
import LoadingSpinner from "../components/LoadingSpinner";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ErrorType } from "../../utils/types";
import usePostRequest from "../../utils/usePostRequest";
import { createUserSuccess } from "../agent-accounts/agent-accounts-logic/profileSlice";
import SelectFromApi from "../components/SelectFromApi";
import useFetch from "../../utils/useFetch";
import userEvent from "@testing-library/user-event";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
    },

    inputStyle: {
      width: 400,
      marginBottom: theme.spacing(2),
    },
    autoComplete: {
      marginBottom: theme.spacing(1),
    },
    main: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
      zIndex: theme.zIndex.appBar - 1,
      outline: "none",
    },
    grid: {
      display: "grid",
      gridTemplateColumns: "1fr",
    },
    paper: {
      padding: theme.spacing(2),
      // textAlign: "center",
      //color: theme.palette.text.secondary,
      width: 'auto',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      color: 'black !important',
      fontFamily: "ChronicaPro,sans-serif",
      fontSize:"16px",
      fontWeight: 400,
    },
    formCustomTitle: {
      fontWeight:700,
      color: "#449afe",
      fontSize:"1.3rem",
      fontFamily: "ChronicaPro,sans-serif",
      width: 400,
    },
    CustomTitle: {
      fontWeight:700,
      color: "#022c5b",
      fontSize:"1.0rem! important",
      fontFamily: "ChronicaPro,sans-serif",
      width: 400,
    },
    inputLabel: {
      "&.focused": {
        color: "#449afe",
      },
      color: "#449afe",
    },
    errorText: {
      color: 'red',
      fontSize: 'small',
    },
  })
);



type CancelOrderProps = {
  queryString: string;
  open: boolean;
  ticketNo: string;
  orderID: string;
  PDepartmentID: number,
  // eslint-disable-next-line no-unused-vars
  setOpen: (value: boolean) => void;
  // eslint-disable-next-line no-unused-vars
};

function CancelOrder({
  queryString,
  open,
  setOpen,
  ticketNo,
  orderID,
  PDepartmentID
}: CancelOrderProps) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const {
    data,
    isLoading,
    isError,
    error,
    isRefreshing,
  } = useFetch(`api/v1/checkups/tickets/get-single-ticket?TicketNo=${ticketNo}`, ["Ticket", ticketNo]);
  
  const [openSnack, setOpenSnack] = useState(true);
  
  const { selectedUser } = useAppSelector((state) => state.subscription);

  const [PaymentMethod, setPaymentMethod] = useState<string>('');
  const [AmountPaid, setAmountPaid] = useState<string>('');
  const [TransactionID, setTransactionID] = useState<string>("");
  const [convertTicket, setConvertTicket] = useState<string>("");
  const [invoiceNo, setInvoiceNo] = useState<string>('');
  const [assignTicket, setAssignTicket] = useState<string>('Department');
  const [invoiceError, setInvoiceError] = useState('');
  const [TransactionIDErr, setTransactionIDErr] = useState<false | ErrorType>(false);
  const { handleSubmit, reset } = useForm();
  const [postError, setPostError] = useState<false | ErrorType>(false);
  const [visitTypeErr, setVisitTypeErr] = useState<false | ErrorType>(false);
  // const [assignedTo, setassignedTo] = useState<string>("");
  const [department, setdepartment] = useState<string>("");
  const [assignedTo, setassignedToID] = useState<string>("");
  const [assignments, setAssignments] = useState<Array<{ assignedTo: string; department: string }>>([
    { assignedTo: "", department: "" }
  ]);
  const [departmentID, setDepartmentID] = useState<number>(0);
  const [comment, setcomment] = useState<string>("");
  const [commentError,setCommentError]=useState<string>("");

  var UserID =sessionStorage.getItem('UserID');

  const onSuccess = ({ data }: any) => {
    queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.includes("Orders"),
    });
    dispatch(createUserSuccess(data));
    setOpen(false);
    queryClient.invalidateQueries(`${queryString}`);
    
    // Clear the input fields
    setPaymentMethod("");
    setAmountPaid("");
    setTransactionID("");
    setTransactionIDErr(false);
    setDepartmentID(0);
    setassignedToID("");
    setcomment("");
    setCommentError('');
    setInvoiceNo('');
    setInvoiceError('');
  };
  const onFailure = (err: ErrorType) => {
    setPostError(err);
  };

  const handleDepartment = (newValue: any) => {
    setDepartmentID(newValue?.departmentID);
  };
  const handleChange = (event:any) => {
    const { name, value } = event.target;
    switch (name) {
      case 'comment':
        setcomment(value);
        setCommentError('');
        break;
        case 'convertTicket':
          setConvertTicket(value);
          break;
          case 'assignTicket':
            setAssignTicket(value);
            break;  
          case 'department':
            setdepartment(value);
            break;      
          case 'invoiceNo':
            setInvoiceNo(value);
            setInvoiceError('');
            break;
        default:
          break;
      }
    };

  const handleAssigned = (newValue: any) => {
    setassignedToID(newValue?.userID);
  };
  const url =   convertTicket==='Cancelled' ?  "api/v1/checkups/tickets/AssignTicket" : 
  "order-assigment";
  const mutation = usePostRequest(
    url,
    convertTicket==="Cancelled"? {
    ticketNumber: ticketNo,
    orderID:orderID,
    assignedTo :0,
    departmentID ,
    comment,
    ticketStatus:8,
    status:8,
    addedBy: UserID,
    } :
    {
      ticketNumber: ticketNo,
      assignedTo,
      departmentID,
      orderID:orderID,
      assignedBy: UserID,
      latitude:0,
      longitude: 0,
      locationName:"",
      status:8,
      notes:comment,
      department
    },
    onSuccess,
    onFailure
  );

  const onSubmit = () => {
   
    if (comment.trim() === '') {
      setCommentError('Comment is required.'); // Display a warning if the field is empty
      return;
    }
    mutation.reset();
    mutation.mutate();
  };


  const handleClose = () => {
    mutation.reset();
    reset();
  };

  const actions = [
    {
      label: 'Submit Now',
      primary: true,
      onClick: handleSubmit(onSubmit),
    },
  ];

  return (
    <div>
      {mutation.isSuccess && (
        <Snackbar
          open={openSnack}
          onClose={() => setOpenSnack(false)}
          autoHideDuration={6000}
        >
          <Alert onClose={handleClose} severity="success">
              "Ticket Successfully Cancelled"
          </Alert>
        </Snackbar>
      )}

      <GenericDialog
        title='Ticket Assignment'
        showDialog={open}
        onCloseDialog={() => {
          setOpen(false);
        }}
        actions={actions}
        isLoading={mutation.isLoading}
        width="sm"
        height={400}
        verticalAlign="flex-start"
      >
       
          {mutation.isLoading ? <LoadingSpinner /> : <></>}
          {/* <div className={classes.grid}> */}
            {postError ? (
              <Alert severity="error" className="error-container">
                {postError?.message}
              </Alert>
            ) : (
              <></>
            )}
            
            <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>    
            <Grid item xs={12} sm={12}>
          <FormControl component="fieldset">
            <RadioGroup
              name="convertTicket"
              value={convertTicket}
              onChange={handleChange}
              row
            >
                <FormControlLabel
                value="Cancelled"
                control={<Radio />}
                label="Cancel"
              />
              
            </RadioGroup>
          </FormControl>
        </Grid>
        </Paper>
        {/* {convertTicket==="Accepted" ? (
        <Paper className={classes.paper}> 
        <Grid item xs={12} sm={12}>
            
            <FormControl component="fieldset">
            <FormLabel>Department?</FormLabel>
              <RadioGroup
                name="department"
                value={department}
                onChange={handleChange}
                row
              >
                  <FormControlLabel
                  value="Dispatch"
                  control={<Radio />}
                  label="Dispatch"
                />
                <FormControlLabel
                  value="Claim"
                  control={<Radio />}
                  label="Claim"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          </Paper>
        ):(<></>)} */}
{convertTicket && (
            <Paper className={classes.paper}>   
         {/* {convertTicket==="Accepted"? (
           <>
        <Grid item xs={12} sm={12} sx={{mb:2}}>
          <TextField
            name="invoiceNo"
            label="Invoice Number"
            value={invoiceNo}
            required
            onChange={handleChange}
            fullWidth
          />
          {invoiceError && <p style={{ color: 'red' }}>{invoiceError}</p>}
          </Grid>
         </>
         ):(<></>)} */}
           {convertTicket==="Accepted" ? (
            <>
     
        
         {/* ):(<></>)} */}

       </> 
           ):(<></>)}
        
       {convertTicket &&(    
         <Grid item xs={12} sm={12}>
          <TextField
            name="comment"
            label="Reason For Cancelling Order"
            value={comment}
            onChange={handleChange}
            required
            fullWidth
          />
         {commentError && <p style={{ color: 'red' }}>{commentError}</p>}
          </Grid>
       )}
          </Paper>
        )}
          </Grid>
          </Grid>

          {/* </div> */}
  
      </GenericDialog>
    </div>
  );
}

export default CancelOrder;
