import { createStyles, makeStyles } from "@mui/styles";
import React, {  useState } from "react";
import {Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Paper, Theme, Typography} from '@mui/material'
import { filterBySearch } from "../../utils/filters";
import { DataTableColumn } from "../../utils/types";
import { formatAsLocalDate } from "../../utils/formatDates";
import TableHeader from "../components/TableHeader";
import DataTable from "../components/Table";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VitalsDetails from "./Summaries/VitalsDetails";
import BodyCompositionDetails from "./Summaries/BodyCompositionDetails";
import CompaintDetails from "./Summaries/CompaintDetails";
import DiagnosisDetails from "./Summaries/DiagnosisDetails";
import DoctorsNoteDetails from "./Summaries/DoctorsNoteDetails";
import DrugDetails from "./Summaries/DrugDetails";
import LabTestDetails from "./Summaries/LabTestDetails";
import PatientHistoryDetails from "./Summaries/PatientHistoryDetails";
import PhysicalExaminationDetails from "./Summaries/PhysicalExaminationDetails";
import ServicesDetails from "./Summaries/ServicesDetails";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
  }),
);

type MedicalHistoryProps = {
  data:any;
};

const MedicalHistory = ({
  data,
}: MedicalHistoryProps) => {
  const classes = useStyles();

  const [query, setQuery] = useState('');

  const rows = filterBySearch(query, data);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handleChange =
  (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
    // setTestID(panel);
  };

  const columns: DataTableColumn[] = [
    {
      id: 'MedicalHistory',
      label: 'Medical History',
      align: 'left',
      minWidth: 200,
    },
    {
        id: 'HistoryType',
        label: 'History Type',
        minWidth: 150,
      },
      {
        id: 'HistoryDescription',
        label: 'History Description',
        minWidth: 150,
      },
    {
      id: 'Comment',
      label: 'Comment',
      minWidth: 50,
      format: (date) => formatAsLocalDate(date),
    },
    {
      id: 'DateVisited',
      label: 'Date Visted',
      minWidth: 150,
      format: (date) => formatAsLocalDate(date),
    },
  ];

  return (
    <div className={classes.root}>
      <div>
   {data[0].map((value:any, index:any) => (
      <>
      <Accordion expanded={expanded === value.summaryID} onChange={handleChange(value.summaryID)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography> {value.summaryName}</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Box> 
      <Grid  rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} key={index}>
        {value.summaryID ===1 &&(
        <VitalsDetails data={value.vitalsLists}/>
        )}
          {value.summaryID ===2 &&(
        <CompaintDetails data={value.listEntities}/>
        )}
        {value.summaryID ===3 &&(
        <PhysicalExaminationDetails data={value.physicalExaminationsList}/>
        )}
        {value.summaryID ===4 &&(
        <PatientHistoryDetails data={value.patientHistoryLists}/>
        )}
        {value.summaryID ===5 &&(
        <DiagnosisDetails data={value.diagnosisLists}/>
        )}
        {value.summaryID ===6 &&(
        <BodyCompositionDetails data={value.bodyCompositionLists}/>
        )}
        {value.summaryID ===7 &&(
        <LabTestDetails data={value.labtestlist}/>
        )}
        {value.summaryID ===8 &&(
        <ServicesDetails data={value.servicesLists}/>
        )}
        {value.summaryID ===9 &&(
        <DrugDetails data={value.drugsLists}/>
        )}
        {value.summaryID ===10 &&(
        <DoctorsNoteDetails data={value.doctorsNoteLists}/> 
        )}   
             </Grid>
             </Box>
        </AccordionDetails>
      </Accordion>

      
      </>
      
      ))}

      </div>
    </div>
  );
};

export default MedicalHistory;
