import {  FormControl, FormControlLabel, FormLabel, Grid, Input, InputLabel, MenuItem, Paper, Radio, RadioGroup, Snackbar, TextField, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Alert } from "@mui/lab";
import GenericDialog from "../components/GenericDialog";
import LoadingSpinner from "../components/LoadingSpinner";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ErrorType } from "../../utils/types";
import usePostRequest from "../../utils/usePostRequest";
import { createUserSuccess } from "../agent-accounts/agent-accounts-logic/profileSlice";
import axios from "axios";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
    },

    inputStyle: {
      width: 400,
      marginBottom: theme.spacing(2),
    },
    autoComplete: {
      marginBottom: theme.spacing(1),
    },
    main: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
      zIndex: theme.zIndex.appBar - 1,
      outline: "none",
    },
    grid: {
      display: "grid",
      gridTemplateColumns: "1fr",
    },
    paper: {
      padding: theme.spacing(2),
      // textAlign: "center",
      //color: theme.palette.text.secondary,
      width: 'auto',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      color: 'black !important',
      fontFamily: "ChronicaPro,sans-serif",
      fontSize:"16px",
      fontWeight: 400,
    },
    formCustomTitle: {
      fontWeight:700,
      color: "#449afe",
      fontSize:"1.3rem",
      fontFamily: "ChronicaPro,sans-serif",
      width: 400,
    },
    CustomTitle: {
      fontWeight:700,
      color: "#022c5b",
      fontSize:"1.0rem! important",
      fontFamily: "ChronicaPro,sans-serif",
      width: 400,
    },
    inputLabel: {
      "&.focused": {
        color: "#449afe",
      },
      color: "#449afe",
    },
    errorText: {
      color: 'red',
      fontSize: 'small',
    },
  })
);



type UploadFilesProps = {
  queryString: string;
  open: boolean;
  // eslint-disable-next-line no-unused-vars
  setOpen: (value: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  setEditing: (value: boolean) => void;
  editing: boolean;
  setEditLoading?: boolean;
  setEditError?: boolean;
};

function UploadFile({
  queryString,
  open,
  setOpen,
  editing,
  setEditing,
  setEditLoading,
  setEditError,
}: UploadFilesProps) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const [openSnack, setOpenSnack] = useState(true);
  
  const { selectedSummary } = useAppSelector((state) => state.summary);

  const [PaymentMethod, setPaymentMethod] = useState<string>('')
  const [AmountPaid, setAmountPaid] = useState<string>('')
  const [TransactionID, setTransactionID] = useState<string>("")
  const [TransactionIDErr, setTransactionIDErr] = useState<false | ErrorType>(false);
  const { handleSubmit, reset } = useForm();
  const [postError, setPostError] = useState<false | ErrorType>(false);

  var UserID =sessionStorage.getItem('UserID');

  const onSuccess = ({ data }: any) => {
    queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.includes("subscriptions"),
    });
    dispatch(createUserSuccess(data));
    setOpen(false);
    setEditing(false);
    queryClient.invalidateQueries(`${queryString}`);
  };
  

  const [prescriptionFiles, setPrescriptionFiles] = useState({
    filepath: "",
    filetype: "",
});
  const [files, setFiles] = useState<string | any>('');
  //state for checking file size
  const [fileSize, setFileSize] = useState(true);
  // for file upload progress message
  const [fileUploadProgress, setFileUploadProgress] = useState(false);
  //for displaying response message
  const [fileUploadResponse, setFileUploadResponse] = useState('');
  //base end point url
  const FILE_UPLOAD_BASE_ENDPOINT = "https://dashboardapi.checkupsmed.com:1006/api/FileUpload";
  //const { SelectedBookingDetails } = useAppSelector((state) => state.bookings);
  // const [claimFiles, setClaimFiles] = useState({
  //     filepath: "",
  //     filetype: "",
  // });
  const uploadFileHandler = (event: any) => {
      setFiles(event.target.files);
     };

    const fileSubmitHandler = (event: any) => {
     event.preventDefault();
     setFileSize(true);
     setFileUploadProgress(true);
     setFileUploadResponse('');

      const formData = new FormData();

      for (let i = 0; i < files.length; i++) {
          // if (files[i].size > 50000){
          //     setFileSize(false);
          //     setFileUploadProgress(false);
          //     setFileUploadResponse(null);
          //     return;
          // }

          formData.append(`files`, files[i])
          formData.append(`FileType`, 'prescription')
          formData.append(`InvoiceNo`, selectedSummary.invoiceNo)
      }
      const requestOptions = {
          method: 'POST',
          body: formData
      };

      return axios.post(FILE_UPLOAD_BASE_ENDPOINT+'FileUpload', formData, {
          headers: { "content-type": "application/json" },
        }).then(response => {
          const data = response.data;
          const fileURL = data.secure_url // You should store this URL for future references in your app
          if (data.status !=200) {

              setFileUploadResponse("Failed to upload File");
          }
         setFileUploadResponse("File Successfully Uploaded");
         setFileUploadProgress(false);
         setPrescriptionFiles({
            filepath: data.filepath,
            filetype: "prescription",
          
          })
        })
  
    };
  
  return (
    <div>
      {/* {mutation.isSuccess && (
        <Snackbar
          open={openSnack}
          onClose={() => setOpenSnack(false)}
          autoHideDuration={6000}
        >
          <Alert onClose={handleClose} severity="success">
            {editing
              ? "Payment Completed Successfully"
              : "Payment Completedsuccessfully"}
          </Alert>
        </Snackbar>
      )}
      {editing && setEditError && (
        <Snackbar open={openSnack} onClose={() => setOpenSnack(false)}>
          <Alert onClose={handleClose} severity="error">
            'Something wrong happened, could not edit user'
          </Alert>
        </Snackbar>
      )} */}

      <GenericDialog
        title='Upload Prescription '
        showDialog={open}
        onCloseDialog={() => {
          setOpen(false);
        }}
        actions={[]}
        isLoading={false}
        width="sm"
        height={200}
        verticalAlign="flex-start"
      >
       
          {/* {mutation.isLoading || setEditLoading ? <LoadingSpinner /> : <></>} */}
          <div className={classes.grid}>
            {postError ? (
              <Alert severity="error" className="error-container">
                {postError?.message}
              </Alert>
            ) : (
              <></>
            )}
            
            <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
            <Grid container spacing={3}>       
            
            <Grid item xs={12} sm={12}>
            <form onSubmit={fileSubmitHandler}>
            <input type="file"  multiple onChange={uploadFileHandler}/>
            <button type='submit'>Upload</button>
            {!fileSize && <p style={{color:'red'}}>File size exceeded!!</p>}
            {fileUploadProgress && <p style={{color:'red'}}>Uploading File(s)</p>}
            {fileUploadResponse!='' && <p style={{color:'green'}}>{fileUploadResponse}</p>}
            </form>
           </Grid>

            

          </Grid>
          </Paper>
          </Grid>

          </div>
  
      </GenericDialog>
    </div>
  );
}

UploadFile.defaultProps = {
  setEditError: false,
  setEditLoading: false,
};
export default UploadFile;

