/* eslint-disable @typescript-eslint/no-unused-vars */
import { createStyles, makeStyles } from "@mui/styles";
import LoadingSpinner from "../components/LoadingSpinner";
import PageContent from "../components/pageContent";
import Table from "../components/Table";
import React, { useEffect, useState } from "react";
import {  useAppDispatch, useAppSelector } from "../../utils/hooks";
import {
  DataTableColumn,
  DataTableRow,
} from "../../utils/types";
import useFetch from "../../utils/useFetch";
import {  User,setSummary } from "./dashboard-logic/dashboardSlice";
import { Box, Button, CircularProgress, Fade, Grid, Link, Menu, MenuItem, TextField, Theme } from "@mui/material";
import EmptyList from "../components/EmptyList";
import MaterialTable from "@material-table/core";
import MoreVertIcon  from '@mui/icons-material/MoreVert';
import UploadFile from "./UploadFile";

const useStyles = makeStyles((theme: Theme) =>
createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
    },
    name: {
      textTransform: "capitalize",
    },
    formField: {
      marginLeft: '4px',
      marginTop: '30px',
      [theme.breakpoints.down('md')]: {
        width: '20%',
        alignContent: 'center ',
      },
  },
  searchBox: {
    padding: theme.spacing(2, 0),
    width: "99%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  menuItemNoIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuItemWithIcon: {
    display: 'grid',
    gridTemplateColumns: '50px 1fr',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    fontWeight: 800,
    fontSize: '0.95rem',
  },
  menuItem: {
    minWidth: 200,
    margin: theme.spacing(1, 2),
    backgroundColor: theme.palette.grey[100],
    borderRadius: '3px',
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.grey[700],
    },
  },

}));


const links = [
  {
    pathname: "",
    label: "",
    permission: "",
  },
];

type Props = {
  roleName?: string;
  roleId?: string;
  userTypeCode?: string;
};
const UsersList: React.FC<Props> = ({
  roleName,
  roleId,
  userTypeCode,
}: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [query, setQuery] = useState("");
  const [user, setUser] = useState<User>();
 const Token =sessionStorage.getItem('auth');
 //const [data, SetnewData]=useState<string[]>([]);
  const [value, setValue] = React.useState<string>('00000');
  const [invoice, setInvoice] = React.useState<string>('00000');
  const [phone, setPhone] = React.useState<string>('');
  const [InvoiceNo,setInvoiceNo] = React.useState<string>('');
  const [editing, setEditing] = useState(false);
  const [open2, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    dispatch(setSummary(user));
  }, [user]);



  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value);
  };
  const handleInvoiceno = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInvoiceNo(event.target.value);
  };
  const handleSubmit = () => {
    setValue(phone);
    setInvoice(InvoiceNo);
  };
  const makeUrl = (defaultPath: string) =>
    query && !userTypeCode
      ? `/auth/system-user-fuzzy-search-user?searchterm=${query}`
      : defaultPath;

    // useFetch(`/${subset}`, `fetchBreeds-${subset}`);
      const {
        data,
        isLoading,
        isError,
        error,
        isRefreshing,
        isSuccess
      } = useFetch(`api/v1/checkups/patient/search-patients?phoneNumber=${value}&invoiceNo=${invoice}`, ["chronic", `${value},${invoice}`]);

    //const optionsData = data?.data || data || [] ;

  const [message, setMessage]=useState<string>("");
  const [isSubmitting,setisSubmitting]=useState<boolean>(false);
  const handleClick=(patNo:any)=>{ 
    setisSubmitting(true)
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ patientNumber: patNo })
    };
    fetch('https://dashboardapi.checkupsmed.com:1006/api/v1/checkups/patient/patient-usage-report', requestOptions)
        .then(response => response.json())
        .then(data => setMessage(data));  
        setTimeout(() => setisSubmitting(false), 5000);    
  }
  
  

  const [columns, setColumns] = useState([
        {
      field: "patNo",
      title: "Patient No.",
      type: "string" as const,
      minWidth: 250,
    },
    {
      field: "name",
      title: "Client Name",
      minWidth: 250,
      type: "string" as const
    },
    {
      field: "phoneNumber",
      title: "Phone Number",
      minWidth: 150,
      type: "string" as const
    },
    {
      field: "invoiceNo",
      title: "InvoiceNo.",
      minWidth: 50,
      type: "string" as const
    },
    {
      field: "visitDate",
      title: "Visit Date",
      minWidth: 150,
      type: "string" as const
    },
    {
      field: "cancelled",
      title: "Status",
      minWidth: 50,
      type: "string" as const
    },
    {
      field: "filePath",
      title: "Prescription",
      render: (value: any, row: any) => (
       <>
       {value?.filePath.length>1 ?(
       <Link target="_blank"  href={`https://dashboardapi.checkupsmed.com:1006/api/FileUpload/Prescription?InvoiceNo=${value.filePath}`}>
        View File
       </Link>
       ):(
       <span>....</span>
       )}
       </>
       )
     //format: (value: any, row: any) => <Link href="https://google.com">Google 1</Link>
     },
    {
      field: "patNo",
      title: "Action",
      minWidth: 250,
      render: (value: any, row: any) => (
        <Button onClick={(e:any)=>handleClick(row.patNo)} disabled={isSubmitting}>
           {isSubmitting && <CircularProgress size={14} />}
        {!isSubmitting && 'Generate Report'}
         </Button>
         )
    },
    {
      field: "patNo",
      title: "Medical Report",
      minWidth: 250,
      render: (value: any, row: any) => (
       <>
       {value?.length > 1 ?(
       <Link target="_blank"  href={`http://197.248.142.25:1005/Medication/UtilizationReport?patNo=${value}.pdf`}>
        View Report
       </Link>
       ):(
       <span>....</span>
       )}
       </>
       )
     },
    
  ]);

const rows: DataTableRow[] = isSuccess
? userTypeCode
  ?data
  : data
: [];

const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
const handleOpen = (event: React.MouseEvent<HTMLElement>,row:any) => {
  setAnchorEl(event.currentTarget);
  setUser(row);
};
const handleClose = () => {
  setAnchorEl(null);
};
const open = Boolean(anchorEl);
const [slcRow, setSlcRow] = React.useState(null);
function handleMenuClick(event:any) {
  setAnchorEl(event.currentTarget);
  //setSlcRow(row);
}

const handleFileModal = (row: any) => {
  setOpen(true);
};


  return (
    <PageContent>
      <div className={classes.root}>

        {isLoading ? <LoadingSpinner /> : <></>}
        {/* {isFetching ? <RefreshingCard message="Refreshing Clients" /> : <></>} */}
        <div className={classes.formField}>
        <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <TextField
          id="outlined-multiline-flexible"
          label="Enter Phone Number"
          multiline
          maxRows={4}
          
          style={{ width: 300}}
          value={phone}
          onChange={handleChange}
        />
        </Grid>
        <Grid item xs={12} md={3}>
        <TextField
          id="outlined-multiline-flexible"
          label="Invoice Number"
          multiline
          maxRows={4}
          style={{ width: 300}}
          value={InvoiceNo}
          onChange={handleInvoiceno}
        />
        </Grid>
        <Grid item xs={12} md={2}>
        <Button onClick={ handleSubmit} variant="outlined">Search</Button>
        </Grid>
        </Grid>
      </div>
      {isSuccess ? (
        <>
      <Box className={classes.searchBox}>
          </Box>
            <MaterialTable
            title="Subscription List"
            columns={columns}
            data={data}
            actions={[
              {
                icon: () => <MoreVertIcon  />,
                tooltip: 'Actions',
                isFreeAction: false,
                onClick: (handleOpen)
              }
           ]}
            
            onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
            options={ {
              columnsButton:true,
              sorting: true,
              rowStyle: rowData => ({
                backgroundColor: (rowData.pharmacyBalance<=1000) ? '#00000' : (rowData.pharmacyBalance<0) ? '#01579b' : '#00000' 
              }),
              headerStyle: {
                backgroundColor: '#01579b',
                color: '#FFF',
              },}}

             />

        </>
      ) : (<EmptyList message="You may want to refine your filters" />)}
      </div>

      <UploadFile
        queryString="clients"
        open={open2}
        setOpen={setOpen}
        editing={editing}
        setEditing={setEditing}
        setEditLoading={isLoading}
        setEditError={isError}
      />
      <Menu
        className={classes.root}
        id="fade-menu"
        anchorEl={anchorEl}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {/* {getAllowedActions().map((b) => ( */}
          <>
            <MenuItem onClick={handleFileModal}
              classes={{ root: classes.menuItem }}
              className={
                // b.icon ? classes.menuItemWithIcon : 
                classes.menuItemNoIcon
              }
              key="Menu-Key"
            >
            <span className={classes.label}>Upload Prescription</span>
            </MenuItem>
            </>
        {/* ))} */}
      </Menu>
    </PageContent>
  );
};

UsersList.defaultProps = {
  roleName: undefined,
  roleId: undefined,
  userTypeCode: undefined,
};

export default UsersList;
