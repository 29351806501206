import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Theme,
  Button,
  Stack,
  Card,
  InputAdornment,
  Box,
  Typography,
  InputLabel,
  Autocomplete,
  CardHeader
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Alert } from "@mui/lab";
import LoadingSpinner from "../components/LoadingSpinner";

import React, { ChangeEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import LocationHome from "../components/LocationHome";
import LocationNextKin from "../components/LocationNextKin";
import LocationOffice from "../components/LocationOffice";
import { ErrorType } from "../../utils/types";
import usePostRequest from "../../utils/usePostRequest";
import { createUserSuccess } from "./dashboard-logic/dashboardSlice";
import { AnyStyledComponent } from "styled-components";
import SelectFromApi from "../components/SelectFromApi";
import FormSelectInsurance from "../components/FormSelectInsurance";
import { useNavigate } from "react-router";
import { useLocation } from 'react-router-dom';
import FormSelectScheme from "../components/FormSelectScheme";
import axios from 'axios';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
    },
    inputStyle: {
      width: 400,
      marginBottom: theme.spacing(2),
    },
    autoComplete: {
      marginBottom: theme.spacing(1),
    },
    main: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
      zIndex: theme.zIndex.appBar - 1,
      outline: "none",
    },
    grid: {
      display: "grid",
      gridTemplateColumns: "1fr",
    },
    paper: {
      padding: theme.spacing(2),
      width: "60%",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      color: "black !important",
      fontFamily: "ChronicaPro, sans-serif",
      fontSize: "16px",
      fontWeight: 400,
    },
    formCustomTitle: {
      fontWeight: 700,
      color: "#449afe",
      fontSize: "1.3rem",
      fontFamily: "ChronicaPro, sans-serif",
      width: 400,
    },
    CustomTitle: {
      fontWeight: 700,
      color: "#022c5b",
      fontSize: "1.0rem!important",
      fontFamily: "ChronicaPro, sans-serif",
      width: 400,
    },
    inputLabel: {
      "&.focused": {
        color: "#449afe",
      },
      color: "#449afe",
    },
    errorText: {
      color: "red",
      fontSize: "small",
    },
    underlinedTitle: {
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      paddingBottom: theme.spacing(0),
      marginBottom: theme.spacing(0),
    },
  })
);
const options = ['National ID', 'Passport', 'Birth Certificate'];
const payments = ['Mpesa', 'Cash', 'Insurance','Subscription'];
const payments2 = ['Mpesa', 'Cash', 'Insurance'];
const relations = ['Mother','Father','Son','Guardian', 'Daughter', 'Sister','Brother','Aunt','Uncle'];
interface AssignmentsState {
  assignedToID: string;
  department: string;
}
interface LocationData {
  locationName: string;
  latitude: string;
  longitude: string;
  type: string;
}
type CreateUserProps = {
  row?: object | null | undefined;
};
//   queryString: string;
//   open: boolean;
//   // eslint-disable-next-line no-unused-vars
//   setOpen: (value: boolean) => void;
//   // eslint-disable-next-line no-unused-vars
//   setEditing: (value: boolean) => void;
//   editing: boolean;
//   setEditLoading?: boolean;
//   setEditError?: boolean;
// };

function CreateTicket({
  // queryString,
  // open,
  // setOpen,
  // editing,
  // setEditing,
  // setEditLoading,
  // setEditError,
  row
}: CreateUserProps) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  var UserID =sessionStorage.getItem('UserID');
  const [openSnack, setOpenSnack] = useState(true);

  const [value, setValue] = React.useState<string | null>(options[0]);
  const [kinValue, setKinValue] = React.useState<string | null>(relations[0]);
  const [inputValue, setInputValue] = React.useState('');

  const { selectedUser } = useAppSelector((state) => state.subscription);
  const [CardLocation, setCardLocation] = useState<String>("");
  const [cardLocationErr, setCardLocationErr] = useState<false | ErrorType>(
    false
  );
  const [serviceLocation, setServiceLocation] = useState<string>("");
  const [serviceLocationID, setServiceLocationID] = useState<any>(null);
  const [firstName, setfirstName] = useState<string>("");
  const [middleName, setmiddleName] = useState<string>("");
  const [lastName, setlastName] = useState<string>("");
  const [gender, setgender] = useState<string>("");
  const [dob, setdob] = useState<string>("");
  const [age, setAge] = useState<number>(0);
  const [phoneNumber, setphoneNumber] = useState<string>("");
  const [email, setemail] = useState<string>("");
  const [targetTime, settargetTime] = useState<string>("");
  const [paymentMethod, setpaymentMethod] = useState<string>("");
  const [payment, setPayment] = React.useState<string | null>();
  const [kinFullName, setkinFullName] = useState<string>("");
  const [kinRelationship, setkinRelationship] = useState<string>("");
  const [kinTelephone, setkinTelephone] = useState<string>("");
  const [kinNationalID, setkinNationalID] = useState<string>("");
  const [kinLocation, setkinLocation] = useState<string>("");
  const [kinLocationLat, setkinLocationLat] = useState<string>("0.00");
  const [kinLocationLong, setkinLocationLong] = useState<string>("0.00");
  const [ticketType, setticketType] = useState<string>("");
  const [clientType, setclientType] = useState<string>("");
  const [visitType, setvisitType] = useState<string>("");
  const [memberNumber, setmemberNumber] = useState<string>("");
  const [scheme, setScheme] = React.useState<string>("");
  const [schemeID, setSchemeID] = React.useState<string>("");
  const [InsuranceID, setInsuranceID] = React.useState<string>("");
  const [packageID, setPackageID] = React.useState<string>("");
  const [packageName, setPackageName] = React.useState<string>("");
  const [packageAmount, setPackageAmount] = React.useState<any>();
  const [insurance, setInsurance] = React.useState<string>("");
  const [idType, setidType] = useState<string>("");
  const [idNumber, setidNumber] = useState<string>("");
  const [nationality, setnationality] = useState<string>("");
  const [facility, setfacility] = useState<string>("");
  const [comment, setcomment] = useState<string>("");
  const [assignedTo, setassignedTo] = useState<string>("");
  const [department, setdepartment] = useState<string>("");
  const [assignedToID, setassignedToID] = useState<string>("");
  const [assignments, setAssignments] = useState<Array<{ assignedTo: string; department: string }>>([
    { assignedTo: "1174", department: "4" }
  ]);
  const [departmentID, setDepartmentID] = useState<string>("");
  const [officeLocation, setofficeLocation] = useState<string>("");
  const [invoiceNo, setinvoiceNo] = useState<string>("");
  const [officeLat, setofficeLat] = useState<string>("0.00");
  const [officeLong, setofficeLong] = useState<string>("0.00");
  const [homeLocation, setHomeLocation] = useState<string>('');
  const [homeLat, setHomeLat] = useState<string>('0.00');
  const [homeLong, setHomeLong] = useState<string>('0.00');
  const [facilityErr, setFacilityErr] = useState<false | ErrorType>(false);
  const [visitTypeErr, setVisitTypeErr] = useState<false | ErrorType>(false);
  const [selectedUserType, setSelectedUserType] = useState<string>("");
  const { handleSubmit, reset } = useForm();
  const navigate = useNavigate();
  const [postError, setPostError] = useState<false | ErrorType>(false);
  const [combinedLocations, setCombinedLocations] = useState<LocationData[]>([]);
  const [departments,setDepartments]= useState<string>("");
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [patientID,setPatientID] = useState<string>("");
  const [isEdit,setIsEdit]= useState(false);
  const location = useLocation();
  const rowData = (location.state as { rowData: any })?.rowData;
  const edit = (location.state as { isEdit: any })?.isEdit;
  
  const [idNumberErr, setIDNumberErr] = useState<boolean>(false);
  const [idErrorMessage,setIDErrorMessage]=useState<string>("");

  const getDefaultTargetTime = () => {
    const currentDate = new Date();
    const additionalHours = 4;
    const eastAfricaTimeOffset = 3 * 60; // East Africa Time offset in minutes
    const adjustedDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      currentDate.getHours() + additionalHours,
      currentDate.getMinutes(),
      currentDate.getSeconds(),
      currentDate.getMilliseconds()
    );
    
    // Add the East Africa Time offset
    adjustedDate.setMinutes(adjustedDate.getMinutes() + eastAfricaTimeOffset);
    const formattedDate = adjustedDate.toISOString().slice(0, 19);
    return formattedDate;
  };
//DEPARTMET CHANGE
useEffect(()=>{
  if(serviceLocationID===3){
  setAssignments(prevAssignments => [
    {
      ...prevAssignments[0],
      department: "20"
    }
  ]);
  setDepartmentID("20");
}
},[serviceLocationID])

//END DEPARTMENT CHANGE
  useEffect(() => {
    settargetTime(getDefaultTargetTime());
  }, []);
 useEffect(()=>{
  setIsEdit(edit ? edit : false)
 },[edit,location])

useEffect(()=>{
setfirstName(isEdit ? rowData.firstName : '');
setmiddleName(isEdit ? rowData.middleName : '');
setlastName(isEdit ? rowData.lastName : '');
setemail(isEdit ? rowData.email : '');
setdob(isEdit ? rowData.dob : '');
setphoneNumber(isEdit ? rowData.phoneNumber : '');
setgender(isEdit ? rowData.gender : '');
setofficeLocation(isEdit ? rowData.officeLocation : '');
setofficeLat(isEdit ? rowData.officeLatitude : '');
setofficeLong(isEdit ? rowData.officeLongitude : '');
setHomeLocation(isEdit ? rowData.homeLocation : '');
setHomeLat(isEdit ? rowData.homeLatitude : '');
setHomeLong(isEdit ? rowData.homeLongitude : '');
setidType(isEdit ? rowData.idType : '');
setidNumber(isEdit ? rowData.idNumber : '');
setkinNationalID(isEdit ? rowData?.kinNationalID : '');
setkinRelationship(isEdit ? rowData?.kinRelationship : '');
setkinTelephone(isEdit ? rowData?.kinTelephone : '');
setInsurance(isEdit ? rowData?.insurance : '');
setInsuranceID(isEdit ? rowData?.insuranceID : '');
setScheme(isEdit ? rowData?.scheme : '');
setSchemeID(isEdit ? rowData?.schemeID : '');
setmemberNumber(isEdit ? rowData?.memberNumber : '');
setPatientID(isEdit ? rowData?.patient_id : '');
//  isEdit ? rowData?.insurance(setpaymentMethod("Insurance")):(<></>);
setCombinedLocations([
  {
      locationName: isEdit ? rowData.officeLocation : '',
      latitude: isEdit ? rowData.officeLatitude : '',
      longitude: isEdit ? rowData.officeLongitude : '',
      type: "office"
  },
  {
    locationName: isEdit ? rowData.homeLocation : '',
    latitude: isEdit ? rowData.homeLatitude : '',
    longitude: isEdit ? rowData.homeLongitude : '',
    type: "home"
},

]);
},[isEdit])

console.log("schemeID",schemeID);
console.log("InsuranceID",InsuranceID);

useEffect(()=>{
  setCombinedLocations([]);
},[])

  const calculateAge = (dateOfBirth: string) => {
    const currentDate = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = currentDate.getFullYear() - birthDate.getFullYear();
  
    setAge(age);
  };
  useEffect(() => {
    calculateAge(dob);
  }, [dob]);
  
  const onSuccess = ({ data }: any) => {
    queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.includes("Tickets"),
      
    });
    dispatch(createUserSuccess(data));
    navigate("/ticketing");
    // setOpen(false);
    // setEditing(false);
    // queryClient.invalidateQueries(`${queryString}`);
  };

  const onFailure = (err: ErrorType) => {
    setPostError(err);
  };

  const handleSchemes = (option: any) => {
    setScheme(option && (option as any).patient_payment_account_type_desc);
    setSchemeID(option && (option as any).patient_payment_account_type_id);
  };
  const handleInsurance = (option: any) => {
  setInsuranceID(option && (option as any).payment_account_id);
  setInsurance(option && (option as any).payment_account_desc);
  };
  const handleVisitType = (newValue: any) => {
    setvisitType(newValue?.visitID);
    setDepartments(newValue?.departments);
    setVisitTypeErr(false);
  };
  const handleServiceLocation = (newValue: any) => {
    setServiceLocationID(newValue?.locationID);
    setServiceLocation(newValue?.locationName);
  };
  const handlPackageChange = (newValue: any) => {
    setPackageID(newValue?.packageID);
    setPackageName(newValue?.packageName);
    setPackageAmount(newValue?.amount)
  };
  const handleFacility = (newValue: any) => {
    setfacility(newValue?.facilityIID);
    setFacilityErr(false);
  };
  const handleDepartment = (newValue: any) => {
    setAssignments(prevAssignments => [
      {
        ...prevAssignments[0],
        department: newValue?.departmentID
      }
    ]);
    setDepartmentID(newValue?.departmentID);
    setFacilityErr(false);
  };
  
  const handleAssigned = (newValue: any) => {
    setAssignments(prevAssignments => [
      {
        ...prevAssignments[0],
        assignedTo: newValue?.userID
      }
    ]);
    setassignedToID(newValue?.userID);
    setFacilityErr(false);
    setDepartmentID(newValue?.departmentID);
  };

  const handleIDNumberChange=(event: any)=>{
   setidNumber(event.target.value);
   setIDNumberErr(false);
  }
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const filesArray = Array.from(event.target.files); // Convert FileList to an array
      setSelectedFiles(filesArray); // Update to handle multiple files
    } else {
      setSelectedFiles([]);
    }
  };
  const handleViewFile = () => {
    const fileUrl = "api/FileUpload/Prescription"; 
    window.open(fileUrl, '_blank');
  };

  const handleChange = (event:any) => {
    const { name, value } = event.target;
    switch (name) {
      case 'firstName':
        setfirstName(value);
        break;
      case 'middleName':
        setmiddleName(value);
        break;
      case 'lastName':
        setlastName(value);
        break;
      case 'gender':
        setgender(value);
        break;
       case 'ticketType':
        setticketType(value);
        break;
      case 'dob':
        setdob(value);
        break;
      case 'phoneNumber':
        setphoneNumber(value);
        break;
      case 'email':
        setemail(value);
        break;
      case 'targetTime':
        settargetTime(value);
        break;
      case 'kinFullName':
        setkinFullName(value);
        break;
      case 'kinTelephone':
        setkinTelephone(value);
        break;
      case 'kinNationalID':
        setkinNationalID(value);
        break;
      case 'visitType':
        setvisitType(value);
        break;
      case 'memberNumber':
        setmemberNumber(value);
        break;
      case 'nationality':
        setnationality(value);
        break;
        case 'clientType':
        setclientType(value);
        break;
      case 'comment':
        setcomment(value);
        break;
        case 'invoiceNo':
        setinvoiceNo(value);
        break;
        case 'selectedFiles':
        setSelectedFiles(value);
        break;
      default:
        break;
    }
  };

  const mutation = usePostRequest(
    "api/v1/checkups/tickets/CreateTicket",
    {
      firstName,
      middleName,
      lastName,
      ticketNumber: "",
      serviceLocation,
      serviceLocationID,
      gender,
      dob,
      phoneNumber,
      email,
      targetTime,
      paymentMethod,
      kinFullName,
      kinRelationship,
      kinTelephone,
      kinNationalID,
      ticketType,
      visitType,
      insuranceID: InsuranceID,
      schemeID,
      memberNumber,
      insurance,
      scheme,
      idType,
      idNumber,
      departmentID,
      assignedTo:assignedToID,
      nationality:nationality ? nationality : "Kenyan",
      facility,
      comment,
      assignments,
      locations:combinedLocations,
      deliveryLocation:'',
      createdBy:UserID,
      patientID,
      packageID,
      packageName,
      packageAmount,
      clientType,
      invoiceNo :"",
      selectedFiles
    },
    onSuccess,
    onFailure
  );

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    setIDNumberErr(false);
    setIDErrorMessage('');

    // Validate ID Number
    if (idNumber.length < 6) {
        setIDNumberErr(true);
        setIDErrorMessage("The ID Number/Passport is Invalid");
        return; // Exit if the ID number is invalid
    }

    // Prepare the form data for submission
    const formData = new FormData();

    if (selectedFiles && selectedFiles.length > 0) {
        selectedFiles.forEach(file => {
            formData.append('file[]', file); // Append each file under the same key
        });
       
    } else {
        console.log('No files selected, proceeding without files');
    }

    // Optionally, append other form data
    formData.append('fileType', "file");
    formData.append('invoiceNo', "invoiceNo");

    try {
        mutation.reset();
        mutation.mutate();

        // Submit the form data to the API
        const response = await axios.post('https://dashboardapi.checkupsmed.com:1006/api/FileUpload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        console.log('Request successful:', response.data);
    } catch (error) {
        console.error('Error during submission:', error);
    }
};

  const handleClose = () => {
    mutation.reset();
    reset();
  };
 
  return (
    <div>
      {mutation.isSuccess && (
        <Snackbar
          open={openSnack}
          onClose={() => setOpenSnack(false)}
          autoHideDuration={6000}
        >
          <Alert onClose={handleClose} severity="success">
             Location updated successfully
          </Alert>
        </Snackbar>
      )}

      {/* {editing && setEditError && (
        <Snackbar open={openSnack} onClose={() => setOpenSnack(false)}>
          <Alert onClose={handleClose} severity="error">
            'Something wrong happened, could not edit user'
          </Alert>
        </Snackbar>
      )} */}

      <div className={classes.grid}>
        {postError ? (
          <Alert severity="error" className="error-container">
            {postError?.message}
          </Alert>
        ) : (
          <></>
        )}
<Grid container spacing={3}>
        <Grid item xs={12} md={4}>
        <Stack spacing={3}>
          <Card sx={{ p: 3, mt:2,ml:5 }}>
          <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
        <Typography variant="h6" className={classes.underlinedTitle}>
           Patient Registration Details
          </Typography>
          </Grid>
          </Grid>
          
          <Grid container spacing={3} sx={{mt:2}}>
            <Grid item xs={12} sm={12}>
          <TextField
            name="firstName"
            label="First Name"
            value={firstName}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
              style: { position: 'absolute', backgroundColor: '#FFF', padding: '0 4px' },
            }}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            name="middleName"
            label="Middle Name"
            value={middleName}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
              style: { position: 'absolute', backgroundColor: '#FFF', padding: '0 4px' },
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            name="lastName"
            label="Last Name"
            value={lastName}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
              style: { position: 'absolute', backgroundColor: '#FFF', padding: '0 4px' },
            }}
            fullWidth
            required
          />
        </Grid>
        {isEdit ? (<></>):(
        <Grid item xs={12} sm={12}>
          <TextField
            name="dob"
            label="Date of Birth"
            type="date"
            placeholder="mm/dd/yyyy"
            value={dob}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
              style: { position: 'absolute', backgroundColor: '#FFF', padding: '0 4px' },
            }}
            fullWidth
            required
          />
        </Grid>)}
        <Grid item xs={12} sm={12}>
          <TextField
            name="phoneNumber"
            label="Phone Number"
            value={phoneNumber}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
              style: { position: 'absolute', backgroundColor: '#FFF', padding: '0 4px' },
            }}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            name="email"
            label="Email"
            type="email"
            value={email}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
              style: { position: 'absolute', backgroundColor: '#FFF', padding: '0 4px' },
            }}
            fullWidth
            required
          />
        </Grid>   
        {/* {isEdit ? (<></>):(<> */}
        <Grid item xs={12} sm={12}>
        <Box className={classes.autoComplete}>
        <Autocomplete
        value={value}
        onChange={(event: any, newValue: string | null) => {
          setValue(newValue);
        }}
        inputValue={idType}
        onInputChange={(event, newInputValue) => {
          setidType(newInputValue);
        }}
        id="controllable-states-demo"
        options={options}
        renderInput={(params) => <TextField {...params} label="ID Type" />}
      />
      </Box>
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            name="idNumber"
            label={`${idType =='National ID' ? 'ID Number' : idType =='Passport' ? 'Passport Number' : 'Certificate Number'}`}
            value={idNumber}
            onChange={handleIDNumberChange}
            fullWidth
            required
            inputProps={{ pattern: "(?=.*[0-9].*[0-9].*[0-9].*[0-9].*[0-9].*[0-9]).{6,}", title: "The ID Number/Passport Number entered is invalid" }}
          />
          <span style={{color:'red'}}>{idNumberErr ? idErrorMessage : ""}</span>
        </Grid>
      {idType =='Passport' ? (
        <Grid item xs={12} sm={12}>
          <TextField
            name="nationality"
            label="Nationality"
            value={nationality}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
       
      ):(<></>)}
          {/* </>)} */}

          {/* <Grid item xs={12} sm={12}>
            <label>   View Attached Prescriptions</label>
          <Button
                variant="contained"
                color="primary"
                onClick={handleViewFile} 
                fullWidth
                style={{
                  position: 'relative',
                  backgroundColor: '#FFF',
                  padding: '10px 16px',
                  textTransform: 'none', 
                }}
              >
                View Previous Attached Prescriptions
              </Button>
        </Grid>   */}

            </Grid>
          </Card>
          </Stack>
        </Grid>

       {/* START OF VISIT SECTION */}

       <Grid item xs={12} md={4}>
        <Stack spacing={3}>
          <Card sx={{ p: 3, mt:2,ml:5 }}>
          <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
        <Typography variant="h6" className={classes.underlinedTitle}>
           Patient Visit Details
          </Typography>
          </Grid>
          </Grid>
          <Grid container spacing={3} sx={{mt:2}}>
            
        <Grid item xs={12} sm={12}>
          <TextField
            name="targetTime"
            label="Target Time"
            type="datetime-local"
            value={targetTime}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
              style: { position: 'absolute', backgroundColor: '#FFF', padding: '0 4px' },
            }}
            fullWidth
            required
          />
        </Grid>   
        <Grid item xs={12} sm={12}>
        <Box className={classes.autoComplete}>
              <SelectFromApi
                itemName="Visit Types"
                primaryKey="visitID"
                displayValueKey="visitType"
                onSelect={handleVisitType}
                selected={visitType}
                error={visitTypeErr}
                path="api/v1/checkups/tickets/get-visittypes"
                label="Select visit type"
                width="100%"
                alertWidth={500}
              />
            </Box>           
          
        </Grid>

        <Grid item xs={12} sm={12}>
        <LocationOffice
              location={officeLocation}
              setLocation={setofficeLocation}
              label="Office Location"
              setLatitude={setofficeLat}
              setLongitude={setofficeLong}
              setCombinedLocations={setCombinedLocations}
            />
        </Grid>
        <Grid item xs={12} sm={12}>
            <LocationHome
              location={homeLocation}
              setLocation={setHomeLocation}
              label="Home Location"
              setLatitude={setHomeLat}
              setLongitude={setHomeLong}
              setCombinedLocations={setCombinedLocations}
            />
        </Grid>
        <Grid item xs={12} sm={12}>
        <FormControl component="fieldset">
            <FormLabel component="legend">Ticket Type</FormLabel>
            <RadioGroup
              name="ticketType"
              value={ticketType}
              onChange={handleChange}
              row
            >
              <FormControlLabel
                value="Enquiry"
                control={<Radio />}
                label="Enquiry"
              />
              <FormControlLabel
                value="Visit"
                control={<Radio />}
                label="Visit"
              />
              <FormControlLabel
                value="Billed"
                control={<Radio />}
                label="Billed"
              />
            </RadioGroup>
          </FormControl>

          {ticketType === "Billed" && (
    <TextField
      label="Enter Invoice Number"
      name="invoiceNo"
      value={invoiceNo}
      onChange={handleChange}
      fullWidth
      required
      margin="normal"
    />
  )}
        </Grid>

        <Grid item xs={12} sm={12}>
        <FormControl component="fieldset">
            <FormLabel component="legend">Client Type</FormLabel>
            <RadioGroup
              name="clientType"
              value={clientType}
              onChange={handleChange}
              row
              
            >
              <FormControlLabel
                value="CMD"
                control={<Radio />}
                label="CDM Client"
              />
              <FormControlLabel
                value="other"
                control={<Radio />}
                label="Other"
              />
            </RadioGroup>
          </FormControl>
        </Grid>

         <Grid item xs={12} sm={12} style={{lineHeight:'2.5'}}>
          {/* <label>Attach drug prescriptions</label>
          <br></br>
          <input
            name="file"
            type="file"
            onChange={handleFileChange}
            style={{ width: '100%' , boxSizing:'border-box'}} 
            multiple
            
          /> */}
       {/*   {commentError && <p style={{ color: 'red' }}>{commentError}</p>} */}
        </Grid>
       
            </Grid>
          </Card>
          </Stack>
        </Grid>

       {/* END OF VISIT SECTION */}



        <Grid item xs={12} md={4}>
          <Stack spacing={3}>
            <Card sx={{ p: 3, mt:2,mr:5 }}>
            <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
        <Typography variant="h6" className={classes.underlinedTitle}>
           Other Details
          </Typography>
          </Grid>
          </Grid>
            <Grid container spacing={3} sx={{mt:2}}>
            <Grid item xs={12} sm={12}>
            <Box className={classes.autoComplete}>
            <FormLabel component="legend">Service Location</FormLabel>
              <SelectFromApi
                itemName="locationName"
                primaryKey="locationID"
                displayValueKey="locationName"
                onSelect={handleServiceLocation}
                selected={serviceLocation}
                error={visitTypeErr}
                path="api/v1/checkups/tickets/get-service-locations"
                label="select location"
                width="100%"
                alertWidth={500}
              />
            </Box>    
        {/* <FormControl component="fieldset">
            <FormLabel component="legend">Service Location</FormLabel>
            <RadioGroup
              name="serviceLocation"
              value={serviceLocation}
              onChange={handleChange}
              row
            >
              <FormControlLabel
                value="Online"
                control={<Radio />}
                label="Online"
              />
              <FormControlLabel
                value="Walkin"
                control={<Radio />}
                label="Walkin"
              />
              <FormControlLabel
                value="Onsite"
                control={<Radio />}
                label="Onsite"
              />
            </RadioGroup>
          </FormControl> */}
        </Grid>
        <Grid item xs={12} sm={12}>
        <Box className={classes.autoComplete}>
              <SelectFromApi
                itemName="Facility"
                primaryKey="facilityIID"
                displayValueKey="facility"
                onSelect={handleFacility}
                selected={facility}
                error={facilityErr}
                path="api/v1/checkups/tickets/get-facilities"
                label="Select Facility"
                width="100%"
                alertWidth={500}
              />
            </Box>
        </Grid>
        {(visitType=="2" || visitType=="7" || visitType=="20") ? (
        <Grid item xs={12} sm={12}>
        <Box className={classes.autoComplete}>
              <SelectFromApi
                itemName="PackageName"
                primaryKey="packageID"
                displayValueKey="description"
                onSelect={handlPackageChange}
                selected={packageName}
                error={false}
                path="api/v1/checkups/package/GetWllnessPackages"
                label="Select Wellness Package"
                width="100%"
                alertWidth={500}
              />
            </Box>    
        </Grid>
        ):(<></>)}

            <Grid item xs={12} sm={12}>
            <Box className={classes.autoComplete}>
        <Autocomplete
        value={payment}
        onChange={(event: any, newValue: string | null) => {
          setPayment(newValue);
        }}
        inputValue={paymentMethod}
        onInputChange={(event, newInputValue) => {
          setpaymentMethod(newInputValue);
        }}
        id="controllable-states-demo"
        options={isEdit ? payments : payments2}
        renderInput={(params) => <TextField {...params} label="Payment Method" />}
      />
      </Box>
        </Grid> 
       


        {(paymentMethod=="Insurance")? (<>
        <Grid item xs={12} sm={12}>
        <FormSelectInsurance
              itemName="Insurance"
              primaryKey="payment_account_id"
              displayValueKey="payment_account_desc"
              label="Insurance"
              onSelect={handleInsurance}
              selected={InsuranceID || ""}
              error={false}
              width="100%"
              path="api/v1/checkups/account/get-insurance"
            />
        </Grid>
        {InsuranceID && (
        <Grid item xs={12} sm={12}>
        <FormSelectScheme
                itemName={`Insurance-${InsuranceID}`}
                primaryKey={
                  InsuranceID
                    ? `${InsuranceID}-patient_payment_account_type_id`
                    : "patient_payment_account_type_id"
                }
                displayValueKey="patient_payment_account_type_desc"
                label="Schemes"
                onSelect={handleSchemes}
                width="100%"
                selected={schemeID || ""}
                error={false}
                path={`api/v1/checkups/account/get-scheme?paymentAccountId=${InsuranceID}`}
              /> 
        </Grid>
        )}
        </>):(<></>)}
        {(paymentMethod=="Subscription"  || paymentMethod=="Insurance") ? (<>     
        <Grid item xs={12} sm={12}>
          <TextField
            name="memberNumber"
            label="Member Number"
            value={memberNumber}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        </>):(<></>)}

       {isEdit ? (<></>):(<>
        {age<18 ? (<>  
        <Grid item xs={12} sm={12}>
          <TextField
            name="kinFullName"
            label="Kin Full Name"
            value={kinFullName}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12}>
        <Box className={classes.autoComplete}>
        <Autocomplete
        value={kinValue}
        onChange={(event: any, newValue: string | null) => {
          setKinValue(newValue);
        }}
        inputValue={kinRelationship}
        onInputChange={(event, newInputValue) => {
          setkinRelationship(newInputValue);
        }}
        id="controllable-states-demo"
        options={relations}
        renderInput={(params) => <TextField {...params} label="Kin Relationship" />}
      />
      </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="kinTelephone"
            label="Kin Telephone"
            value={kinTelephone}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            name="kinNationalID"
            label="Kin National ID"
            value={kinNationalID}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12}>
        <LocationNextKin
              location={kinLocation}
              setLocation={setkinLocation}
              label="Kin Location"
              setLatitude={setkinLocationLat}
              setLongitude={setkinLocationLong}
              setCombinedLocations={setCombinedLocations}
            />
        </Grid>
        </>):(<></>)}
        </>)}
              </Grid>
            </Card>
      {serviceLocationID && (
      <>
      {visitType && (
            <Card sx={{ p: 3, mt:5,mr:5 }}>
            <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
        <Typography variant="h6" className={classes.underlinedTitle}>
           Assignment section
          </Typography>
          </Grid>
          </Grid>
            <Grid container spacing={3} sx={{mt:2}}>
            {serviceLocationID===3 ? (
             <></>
            ) : (
        <Grid item xs={12} sm={12}>
        <Box className={classes.autoComplete}>
              <SelectFromApi
                itemName="Department"
                primaryKey="departmentID"
                displayValueKey="departmentName"
                onSelect={handleDepartment}
                selected={department}
                error={visitTypeErr}
                path="api/v1/checkups/tickets/departments"
                label="Select Department"
                width="100%"
                alertWidth={500}
              />
            </Box>
        </Grid>)}
        {departmentID && (
          <>
      {serviceLocationID===3 ? (
        <Grid item xs={12} sm={12}>
        <Box className={classes.autoComplete}>
              <SelectFromApi
                itemName={`userID-${departmentID}-${serviceLocationID}`}
                primaryKey="userID"
                displayValueKey="fullName"
                onSelect={handleAssigned}
                selected={assignedTo || ""}
                error={visitTypeErr}
                path={`api/v1/checkups/tickets/users-bydepartment?DepartmentID=20`}
                label="Select User To Assign"
                width="100%"
                alertWidth={500}
              />
            </Box>
        </Grid>
        ):(
        <Grid item xs={12} sm={12}>
        <Box className={classes.autoComplete}>
              <SelectFromApi
                itemName={`userID-${departmentID}-${serviceLocationID}`}
                primaryKey="userID"
                displayValueKey="fullName"
                onSelect={handleAssigned}
                selected={assignedTo || ""}
                error={visitTypeErr}
                path={`api/v1/checkups/tickets/users-bydepartment?DepartmentID=${departmentID}`}
                label="Select User To Assign"
                width="100%"
                alertWidth={500}
              />
            </Box>
        </Grid>
        )}
        <Grid item xs={12} sm={12}>
          <TextField
            name="comment"
            label="Comment"
            value={comment}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
              
              </>
          )}
          </Grid>
           </Card>
           )}
           </>
           )}
           <Button variant="contained" onClick={onSubmit} color="primary" type="submit">
            Submit
          </Button>
            {/* <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
            {!isEdit ? 'Create Supplier' : 'Save Changes'}
            </LoadingButton> */}
          </Stack>
          {/* <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar
              closeOnClick
              rtl={false}
              pauseOnFocusLoss={false}
              draggable={false}
              pauseOnHover
              limit={1}
              transition={Flip}
             /> */}
        </Grid>
      </Grid>

      </div>
    </div>
  );
}

CreateTicket.defaultProps = {
  setEditError: false,
  setEditLoading: false,
};

export default CreateTicket;
