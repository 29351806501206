import { createContext, useCallback, useState } from "react";
import useToggleDrawer from "../../utils/useToggleDrawer";
import {
  useGetAllInsurance,
  InsuranceQueryResponse,
  InsuranceQueryParams,
  useGetAllInactiveInsurance,
} from "../../utils/useInsuranceQuery";

import { PAGE_SIZE } from "./components/constant";
import { useLocalStorage } from "./useLocalStorageHook";
import { useSearchParams } from "react-router-dom";
const InsuranceContext = createContext<{
  toggleDrawer: () => void;
  drawerState: boolean;
  insuranceData?: InsuranceQueryResponse;
  isLoading: boolean;
  isFetching: boolean;
  pageSize: number;
  paginationModel?: Omit<InsuranceQueryParams, "field" | "activeTab">;
  setPagination: (page: number) => void;
  handleSearch: (query: string) => void;
  handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
  search: string;
  page: number;
  tabIndex: number;
  activeTab: "active" | "inactive";
}>({
  toggleDrawer: () => {},
  drawerState: false,
  isFetching: false,
  isLoading: false,
  pageSize: PAGE_SIZE,
  insuranceData: {
    data: [],
    hasNextpage: false,
    isLasPage: false,
    totalPages: 1,
  },
  paginationModel: {
    // not currently used in the recent implementation
    pageSize: PAGE_SIZE,
    page: 0,
  },
  page: 0, // temporary may be removed
  setPagination: () => {},
  handleSearch: () => {},
  handleTabChange: () => {},
  search: "",
  tabIndex: 0,
  activeTab: "active",
});

type ContextProps = {
  children?: React.ReactNode;
};
const InsuranceProvider = ({ children }: ContextProps) => {
  const { toggleDrawer, drawerState } = useToggleDrawer();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: PAGE_SIZE,
    page: 0,
  });
  let [searchParams, setSearchParams] = useSearchParams();
  // get tabs from query params
  const params = searchParams.get("tab");
  // check if a valid tab params
  const isValidTab = params === "active" || params === "inactive";
  //get tab index
  const i = params ? (params === "active" ? 0 : 1) : 0;
  // get tabs from locastorage if any
  const [storage, updateStorage] = useLocalStorage<number>("tab", 0);
  //  tab state index
  const tabIndex = isValidTab ? i : storage;
  const activeTab = isValidTab
    ? params
    : tabIndex === 0
    ? "active"
    : "inactive";

  // create search state
  const [search, setSearch] = useState("");

  const { isLoading, isFetching, data } = useGetAllInsurance({
    ...paginationModel,
    activeTab,
    field: search,
  });

  const {
    isFetching: inactiveFetching,
    isLoading: inactiveLoading,
    data: inactivedata,
  } = useGetAllInactiveInsurance({
    ...paginationModel,
    activeTab,
    field: search,
  });

  const handleReset = useCallback(() => {
    setPaginationModel((prev) => ({ ...prev, page: 1 }));
    setSearch("");
  }, []);

  const handleTabChange = useCallback(
    (_event: React.SyntheticEvent, newValue: number) => {
      updateStorage(newValue);
      setSearchParams(
        { tab: newValue === 0 ? "active" : "inactive" },
        { replace: true }
      );
      handleReset();
    },
    [handleReset, updateStorage, setSearchParams]
  );

  const handleSearch = useCallback((query: string) => {
    setSearch(query);
    setPaginationModel((prev) => ({ ...prev, page: 1 }));
  }, []);

  const setPagination = useCallback((page: number) => {
    setPaginationModel((prev) => ({ ...prev, page }));
  }, []);

  return (
    <InsuranceContext.Provider
      value={{
        toggleDrawer,
        drawerState,
        isLoading: activeTab === "active" ? isLoading : inactiveLoading,
        isFetching: activeTab === "active" ? isFetching : inactiveFetching,
        insuranceData: activeTab === "active" ? data : inactivedata,
        pageSize:
          activeTab === "active"
            ? data?.totalPages ?? 1
            : inactivedata?.totalPages ?? 1,
        page: paginationModel.page,
        setPagination,
        handleSearch,
        handleTabChange,
        search,
        tabIndex,
        activeTab,
      }}
    >
      {children}
    </InsuranceContext.Provider>
  );
};

export { InsuranceContext, InsuranceProvider };
