import { Divider, Typography, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Settings } from "@mui/icons-material";
import React from "react";
import { NavLink } from "react-router-dom";
import { PageHeaderLink } from "../../utils/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      padding: theme.spacing(1, 2),
    },
    title: {
      fontWeight: 700,
      color: theme.palette.common.black,
      fontSize: "1.75rem",
      textTransform: "capitalize",
    },
    userLinks: {
      display: "flex",
      alignItems: "center",
    },
    link: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontWeight: 600,
      color: theme.palette.secondary.main,
      borderBottom: `2px solid ${theme.palette.common.white}`,
      marginRight: 20,
      textDecoration: "none",
      fontSize: "14px",
      "&:last-child": {
        marginRight: 10,
      },
    },
    selectedLink: {
      color: theme.palette.primary.main,
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
  })
);

type PageHeadingProps = {
  links: PageHeaderLink[];
  title: string;
  setupPathname?: string;
  // setupPermission?: string;
  displayDivider?: boolean;
};

const PageHeading: React.FC<PageHeadingProps> = ({
  links,
  title,
  setupPathname,
  // setupPermission,
  displayDivider,
}: PageHeadingProps) => {
  const classes = useStyles();

  const allTheLinks = setupPathname
    ? [
        ...links,
        {
          pathname: setupPathname,
          label: "Setup",
          icon: <Settings fontSize="small" />,
          // permission: setupPermission || '',
        },
      ]
    : links;
  return (
    <>
      <div className={classes.root}>
        <Typography className={classes.title}>{title}</Typography>
        <span className={classes.userLinks}>
          {allTheLinks.map(({ pathname, label, icon: Icon }) => (
            <NavLink
              key={pathname}
              to={pathname}
              className={classes.link}
            >
              {Icon || <></>}
              {label}
            </NavLink>
          ))}
        </span>
      </div>
      {displayDivider ? <Divider variant="fullWidth" /> : <></>}
    </>
  );
};

PageHeading.defaultProps = {
  setupPathname: "",
  // setupPermission: '',
  displayDivider: true,
};
export default PageHeading;
