import * as React from "react";
import Box from "@mui/material/Box";

import CloseIcon from "@mui/icons-material/Close";

import { styled } from "@mui/material/styles";
import DialogActions from "@mui/material/DialogActions";

// import useMediaQuery from "@mui/material/useMediaQuery";
import StyledInput from "./input";
import StyledButton from "./button";
// import { SelectOption } from "./types";
import { ModalContext } from "../modal-context";

import {
  InsuranceSchemeType,
  useEditScheme,
} from "../../../utils/useSchemeQuery";

import { InsuranceContext } from "../scheme-context";

type DialogButtonProps = {
  onClick: () => void;
};

type GlobalDrawerProps = {
  confirm?: boolean; // if you want to use the confirm modal
  handleClose?: () => void; // a function that can be hooked to the close modal
  handleConfirm?: () => void; // a function that can be hooked to the close modal
  confirmText?: string; // text to show the user in the confirm modal
  confirmButton?: string | React.ComponentType<DialogButtonProps>; // can be a reactNode that uses the isLoading state
};

type ConfirmProp = { firstTime: boolean } | boolean;

const allowedKeys = ["schemeTag", "schemeName"];
const checkhasChanged = (
  initial: InsuranceSchemeType | null,
  current: InsuranceSchemeType | null
) => {
  if (!initial || !current) return false;
  return ["schemeName", "schemeTag"].some((key) => {
    if (current.hasOwnProperty(key)) {
      return initial[key as keyof InsuranceSchemeType]
        ? initial[key as keyof InsuranceSchemeType] !==
            current[key as keyof InsuranceSchemeType]
        : (initial[key as keyof InsuranceSchemeType] = "") !==
            current[key as keyof InsuranceSchemeType];
    }
    return (
      (initial[key as keyof InsuranceSchemeType] = "") !==
      current[key as keyof InsuranceSchemeType]
    );
  });
};
export default React.memo(function EditSchemeModal({
  confirm,

  handleClose,
  handleConfirm,
}: GlobalDrawerProps) {
  // context
  const { toggleDrawer } = React.useContext(InsuranceContext);
  const { setModalOpen, data } = React.useContext(ModalContext);
  // react-query hooks

  const { mutateAsync: editInsurance } = useEditScheme();

  const [editValue, setEditValue] = React.useState<InsuranceSchemeType | null>({
    schemeName: data?.description ?? "",
    schemeTag: data?.tags ?? "",
  });

  const [errored, setErrored] = React.useState<Record<string, boolean>>({});

  // check is amy data has changed and there are no errors
  const hasChanged = data
    ? checkhasChanged(
        {
          schemeName: data?.description ?? "",
          schemeTag: data?.tags ?? "",
        },
        editValue
      ) && allowedKeys.every((key) => !errored[key])
    : false;

  // on input change update value record by the field name
  const handleValueChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const {
        target: { name, value },
      } = e;
      setEditValue(
        (prev) =>
          ({ ...(prev ? prev : {}), [name]: value } as InsuranceSchemeType)
      );
    },
    []
  );

  const handleErrored = React.useCallback((name: string, state: boolean) => {
    setErrored((prev) => ({ ...prev, [name]: state }));
  }, []);

  const handleUpdateInsurance = async () => {
    if (!editValue) return;
    try {
      setModalOpen({ loading: true });
      await editInsurance(editValue);
      setModalOpen({ loading: false });
      toggleDrawer();
    } catch (error) {
      console.log(error, "ERROR");
      setModalOpen({ loading: false });
    }
  };

  const [confirmModalOpen, setConfrimModalOpen] = React.useState<ConfirmProp>({
    firstTime: true,
  });

  let timer: undefined | number;

  function closeModal() {
    // check if it's the first time use of the confirmModalOpen state where confirm is true
    if (confirm && typeof confirmModalOpen === "object") {
      setConfrimModalOpen(true);
      return;
    }
    // check if confirm modal was opened, where confirm is true
    if (confirm && confirmModalOpen) {
      if (handleConfirm && typeof handleConfirm === "function") {
        handleConfirm();
      }
      // if handleClose
      if (handleClose && typeof handleClose === "function") {
        handleClose();
      }
      setConfrimModalOpen(false);
      if (timer) clearTimeout(timer);
      // close global model and reset confirmModalOpen to an object
      timer = setTimeout(handleReset, 500) as unknown as number;
      return;
    }
    if (handleClose && typeof handleClose === "function") {
      handleClose();
    }
    toggleDrawer();
    setModalOpen({ data: null, loading: false });
  }

  function handleCancel() {
    setConfrimModalOpen({
      firstTime: true,
    });
  }

  function handleReset() {
    toggleDrawer();
    setModalOpen({ data: null, loading: false });
    handleCancel();
  }

  React.useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  }, [timer]);

  return (
    <>
      <Container>
        <Box>
          <h6
            style={{
              color: "#2D3748",
              fontSize: 18,
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "28px",
              margin: 0,
            }}
          >
            Edit Scheme
          </h6>
          <p
            style={{
              color: "#2D3748",
              fontSize: 14,
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "20px",
              margin: 0,
            }}
          >
            Edit your insurance scheme
          </p>
        </Box>
        <CloseIcon
          onClick={closeModal}
          sx={{
            alignSelf: "start",
          }}
        />
      </Container>

      <StyledInput
        setError={handleErrored}
        type="input"
        placeholder="Type in name of partner or insurance"
        setValue={handleValueChange}
        value={editValue?.["schemeName"] ?? ""}
        name="schemeName"
        label="Scheme name"
        id="edit-scheme-name"
        errorText="Scheme name is required"
      />

      <StyledInput
        setError={handleErrored}
        type="input"
        placeholder="Enter scheme tag"
        value={editValue?.["schemeTag"] ?? ""}
        name="schemeTag"
        setValue={handleValueChange}
        label="Scheme tag"
        id="edit-scheme-tag"
        errorText="Scheme tag is required"
      />

      <DialogActions sx={{ alignSelf: "end" }}>
        <StyledButton
          color="#1A202C"
          bgColor="#EDF2F7"
          text="cancel"
          onClick={closeModal}
        />
        <StyledButton
          text={"Save Changes"}
          bgColor="#4C9DF8"
          onClick={handleUpdateInsurance}
          disabled={!hasChanged}
        />
      </DialogActions>
    </>
  );
});

const Container = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
}));
