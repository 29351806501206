import React from "react";
import {
  Box,
  Select,
  MenuItem,
  SelectChangeEvent,
  Typography,
} from "@mui/material";

interface LabelSelectFieldProps {
  label: string;
  name: string;
  options: { value: string; label: string }[];
  value?: string;
  onChange?: (event: SelectChangeEvent<string>) => void;
}

const LabelSelectField: React.FC<LabelSelectFieldProps> = ({
  label,
  name,
  options,
  value,
  onChange,
  ...selectProps
}) => {
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="body1">{label}</Typography>
      <Select
        value={value}
        name={name}
        onChange={onChange}
        variant="outlined"
        fullWidth
        sx={{
            backgroundColor: 'white',
            '& .MuiOutlinedInput-root': {
              backgroundColor: 'transparent',
              height: '40px',
              padding: '0px',
            },
            '& .MuiSelect-select': {
              padding: '8px 16px',
              fontSize: '0.875rem',
            },
          }}
        {...selectProps}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default LabelSelectField;
