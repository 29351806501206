import { createStyles, makeStyles } from "@mui/styles";
import React, {  useState } from "react";
import {Theme} from '@mui/material'
import { filterBySearch } from "../../utils/filters";
import { DataTableColumn } from "../../utils/types";
import { formatAsLocalDate } from "../../utils/formatDates";
import TableHeader from "../components/TableHeader";
import DataTable from "../components/Table";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
  }),
);

type MedicalHistoryProps = {
  data: [];
};

const MedicalHistory = ({
  data,
}: MedicalHistoryProps) => {
  const classes = useStyles();

  const [query, setQuery] = useState('');

  const rows = filterBySearch(query, data);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const columns: DataTableColumn[] = [
    {
      id: 'MedicalHistory',
      label: 'Medical History',
      align: 'left',
      minWidth: 200,
    },
    {
        id: 'HistoryType',
        label: 'History Type',
        minWidth: 150,
      },
      {
        id: 'HistoryDescription',
        label: 'History Description',
        minWidth: 150,
      },
    {
      id: 'Comment',
      label: 'Comment',
      minWidth: 50,
      format: (date) => formatAsLocalDate(date),
    },
    {
      id: 'DateVisited',
      label: 'Date Visted',
      minWidth: 150,
      format: (date) => formatAsLocalDate(date),
    },
  ];

  return (
    <div className={classes.root}>
      <div>
        <TableHeader onSearch={setQuery} query={query} />
        <DataTable
          page={page}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setPage={setPage}
          rows={rows?.map((r, index) => ({ ...r, number: index + 1 }))}
          columns={columns}
        />
      </div>
    </div>
  );
};

export default MedicalHistory;
