import {  Box, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Paper, Radio, RadioGroup, Snackbar, TextField, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Alert } from "@mui/lab";
import GenericDialog from "../components/GenericDialog";
import LoadingSpinner from "../components/LoadingSpinner";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ErrorType } from "../../utils/types";
import usePostRequest from "../../utils/usePostRequest";
import { createUserSuccess } from "../agent-accounts/agent-accounts-logic/profileSlice";
import SelectFromApi from "../components/SelectFromApi";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
    },

    inputStyle: {
      width: 400,
      marginBottom: theme.spacing(2),
    },
    autoComplete: {
      marginBottom: theme.spacing(1),
    },
    main: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
      zIndex: theme.zIndex.appBar - 1,
      outline: "none",
    },
    grid: {
      display: "grid",
      gridTemplateColumns: "1fr",
    },
    paper: {
      padding: theme.spacing(2),
      // textAlign: "center",
      //color: theme.palette.text.secondary,
      width: 'auto',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      color: 'black !important',
      fontFamily: "ChronicaPro,sans-serif",
      fontSize:"16px",
      fontWeight: 400,
    },
    formCustomTitle: {
      fontWeight:700,
      color: "#449afe",
      fontSize:"1.3rem",
      fontFamily: "ChronicaPro,sans-serif",
      width: 400,
    },
    CustomTitle: {
      fontWeight:700,
      color: "#022c5b",
      fontSize:"1.0rem! important",
      fontFamily: "ChronicaPro,sans-serif",
      width: 400,
    },
    inputLabel: {
      "&.focused": {
        color: "#449afe",
      },
      color: "#449afe",
    },
    errorText: {
      color: 'red',
      fontSize: 'small',
    },
  })
);



type ClearPaymentProps = {
  queryString: string;
  open: boolean;
  ticketNo: string;
  appointmentNo: string;
  // eslint-disable-next-line no-unused-vars
  setOpen: (value: boolean) => void;
  // eslint-disable-next-line no-unused-vars
};

function AssignTicket({
  queryString,
  open,
  setOpen,
  ticketNo,
  appointmentNo
}: ClearPaymentProps) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const [openSnack, setOpenSnack] = useState(true);
  
  const { selectedUser } = useAppSelector((state) => state.subscription);

  const [PaymentMethod, setPaymentMethod] = useState<string>('')
  const [AmountPaid, setAmountPaid] = useState<string>('')
  const [TransactionID, setTransactionID] = useState<string>("")
  const [TransactionIDErr, setTransactionIDErr] = useState<false | ErrorType>(false);
  const { handleSubmit, reset } = useForm();
  const [postError, setPostError] = useState<false | ErrorType>(false);
  const [visitTypeErr, setVisitTypeErr] = useState<false | ErrorType>(false);
  // const [assignedTo, setassignedTo] = useState<string>("");
  const [department, setdepartment] = useState<string>("");
  const [assignedTo, setassignedToID] = useState<string>("");
  const [assignedToName, setassignedToName] = useState<string>("");
  const [locationName, setLocationName] = useState<string>("");
  const [latitude, setLatitude] = useState<string>("");
  const [longitude, setLongitude] = useState<string>("");
  const [deliveryPoint, setDeliveryPoint] = useState<string>("");
  const [assignments, setAssignments] = useState<Array<{ assignedTo: string; department: string }>>([
    { assignedTo: "", department: "" }
  ]);
  const [departmentID, setDepartmentID] = useState<string>("a0cfbfc7-ef3a-426e-9c54-00b4c345fa5e");
  const [comment, setcomment] = useState<string>("");

  var UserID =sessionStorage.getItem('UserID');

  const onSuccess = ({ data }: any) => {
    queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.includes("Appointments"),
    });
    dispatch(createUserSuccess(data));
    setOpen(false);
    queryClient.invalidateQueries(`${queryString}`);
    
    // Clear the input fields
    setPaymentMethod("");
    setAmountPaid("");
    setTransactionID("");
    setTransactionIDErr(false);
    setcomment("");
  };
  const onFailure = (err: ErrorType) => {
    setPostError(err);
  };



  const handleDepartment = (newValue: any) => {
    setDepartmentID(newValue?.departmentID);
  };
  const handleChange = (event:any) => {
    const { name, value } = event.target;
    switch (name) {
      case 'comment':
        setcomment(value);
        break;
        default:
          break;
      }
    };
    const handleDelivery = (newValue: any) => {
      setDeliveryPoint(newValue.locationName);
      setLocationName(newValue.location);
      setLatitude(newValue.latitude);
      setLongitude(newValue.longitude);
    };
    
  const handleAssigned = (newValue: any) => {
    setassignedToID(newValue?.userID);
    setassignedToName(newValue?.fullName);
  };
  const mutation = usePostRequest(
    "appointment-assignment",
    {
      ticketNumber: ticketNo,
      assignedTo,
      appointmentID:appointmentNo,
      assignedBy: UserID,
      latitude,
      longitude,
      locationName:deliveryPoint,
      status:1,
      notes:comment
    },
    onSuccess,
    onFailure
  );
  const onSubmit = () => {
    mutation.reset();
    mutation.mutate();
  };


  const handleClose = () => {
    mutation.reset();
    reset();
  };

  const actions = [
    {
      label: 'Assign Now',
      primary: true,
      onClick: handleSubmit(onSubmit),
    },
  ];

  return (
    <div>
      {mutation.isSuccess && (
        <Snackbar
          open={openSnack}
          onClose={() => setOpenSnack(false)}
          autoHideDuration={6000}
        >
          <Alert onClose={handleClose} severity="success">
              "Ticket Successfully Assigned"
          </Alert>
        </Snackbar>
      )}

      <GenericDialog
        title='Ticket Assignment'
        showDialog={open}
        onCloseDialog={() => {
          setOpen(false);
        }}
        actions={actions}
        isLoading={mutation.isLoading}
        width="sm"
        height={400}
        verticalAlign="flex-start"
      >
       
          {mutation.isLoading ? <LoadingSpinner /> : <></>}
          {/* <div className={classes.grid}> */}
            {postError ? (
              <Alert severity="error" className="error-container">
                {postError?.message}
              </Alert>
            ) : (
              <></>
            )}
            
            <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>    
            
            {/* <Grid item xs={12} sm={12}>
        <Box className={classes.autoComplete}>
              <SelectFromApi
                itemName="Department"
                primaryKey="departmentID"
                displayValueKey="departnmentName"
                onSelect={handleDepartment}
                selected={department}
                error={visitTypeErr}
                path="api/v1/checkups/tickets/departments"
                label="Select Department"
                width="100%"
                alertWidth={500}
              />
            </Box>
        </Grid> */}
 
        <Grid item xs={12} sm={12}>
        <Box className={classes.autoComplete}>
              <SelectFromApi
                itemName={`userID-${departmentID}-${ticketNo}`}
                primaryKey="userID"
                displayValueKey="fullName"
                onSelect={handleAssigned}
                selected={assignedToName || ""}
                error={visitTypeErr}
                path={`api/v1/checkups/tickets/users-bydepartment?DepartmentID=${departmentID}`}
                label="Select Staff"
                width="100%"
                alertWidth={500}
              />
            </Box>
        </Grid>
        <Grid item xs={12} sm={12}>
        <Box className={classes.autoComplete}>
              <SelectFromApi
                itemName={`ticketNo-${ticketNo}`}
                primaryKey="id"
                displayValueKey="location"
                onSelect={handleDelivery}
                selected={locationName || ""}
                error={visitTypeErr}
                path={`get-order-locations?TickeNumber=${ticketNo}`}
                label="Select Delivery Location"
                width="100%"
                alertWidth={500}
              />
            </Box>
        </Grid>
         <Grid item xs={12} sm={12}>
          <TextField
            name="comment"
            label="Comment"
            value={comment}
            onChange={handleChange}
            fullWidth
          />
          </Grid>
          </Paper>
          </Grid>
          </Grid>

          {/* </div> */}
  
      </GenericDialog>
    </div>
  );
}

export default AssignTicket;
