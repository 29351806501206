import * as React from "react";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import DialogActions from "@mui/material/DialogActions";

// import useMediaQuery from "@mui/material/useMediaQuery";
import { toast } from "react-toastify";
import StyledInput from "./input";
import StyledButton from "./button";
// import { SelectOption } from "./types";
import { InsuranceContext } from "../scheme-context";
import { ModalContext } from "../modal-context";
import { useCreateScheme } from "../../../utils/useSchemeQuery";

type DialogButtonProps = {
  onClick: () => void;
};

type GlobalDrawerProps = {
  confirm?: boolean; // if you want to use the confirm modal
  handleClose?: () => void; // a function that can be hooked to the close modal
  handleConfirm?: () => void; // a function that can be hooked to the close modal
  confirmText?: string; // text to show the user in the confirm modal
  confirmButton?: string | React.ComponentType<DialogButtonProps>; // can be a reactNode that uses the isLoading state
};

type ConfirmProp = { firstTime: boolean } | boolean;

const allowedKeys = ["schemeName", "schemeTag", "schemeCode"];

export default React.memo(function CreateSchemeModal({
  confirm,
  handleClose,
  handleConfirm,
}: GlobalDrawerProps) {
  const clientId = sessionStorage.getItem("clientId");
  // context
  const { toggleDrawer, setPagination } = React.useContext(InsuranceContext);
  const { setModalOpen } = React.useContext(ModalContext);
  // react-query hooks
  const { mutateAsync } = useCreateScheme(clientId);

  // initial data
  const [value, setValue] = React.useState<Record<string, string>>({});
  const [errored, setErrored] = React.useState<Record<string, boolean>>({});

  // check that all required field are filled and there are no errors
  const isNotDisabled = allowedKeys.every((key) =>
    Boolean(value[key] && !errored[key])
  );

  // on input change update value record by the field name
  const handleValueChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const {
        target: { name, value },
      } = e;
      setValue((prev) => ({ ...prev, [name]: value }));
    },
    []
  );

  const handleErrored = React.useCallback((name: string, state: boolean) => {
    setErrored((prev) => ({ ...prev, [name]: state }));
  }, []);

  const handleCreateScheme = async () => {
    setModalOpen({ loading: true });
    try {
      await mutateAsync(
        {
          insuranceAccountId: clientId!,
          insuranceAccountDesc: "HGH",
          schemeListModels: [
            {
              insuranceAccountSchemeDesc: value["schemeName"],
              tags: value["schemeTag"],
              schemeCode: value["schemeCode"],
            },
          ],
        },
        {
          onSuccess: (isss) => {
            toast.success("Insurance created successfully", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: false,
              progress: 0,
              toastId: "scheme_toast",
            });
            closeModal();
            setModalOpen({ loading: false });
            setPagination(1);
          },
          onError: (er) => {
            console.log(er, "logged here");
            toast.error("Insurance creation failed", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: false,
              progress: 0,
              toastId: "scheme_toast",
            });
            setModalOpen({ loading: false });
          },
        }
      );
    } catch (error) {
      console.log(error, "ERROR");
    }
  };

  const [confirmModalOpen, setConfrimModalOpen] = React.useState<ConfirmProp>({
    firstTime: true,
  });

  let timer: undefined | number;

  function closeModal() {
    // check if it's the first time use of the confirmModalOpen state where confirm is true
    if (confirm && typeof confirmModalOpen === "object") {
      setConfrimModalOpen(true);
      return;
    }
    // check if confirm modal was opened, where confirm is true
    if (confirm && confirmModalOpen) {
      if (handleConfirm && typeof handleConfirm === "function") {
        handleConfirm();
      }
      // if handleClose
      if (handleClose && typeof handleClose === "function") {
        handleClose();
      }
      setConfrimModalOpen(false);
      if (timer) clearTimeout(timer);
      // close global model and reset confirmModalOpen to an object
      timer = setTimeout(handleReset, 500) as unknown as number;
      return;
    }
    if (handleClose && typeof handleClose === "function") {
      handleClose();
    }
    toggleDrawer();
    setModalOpen({ data: null, loading: false });
  }

  function handleCancel() {
    setConfrimModalOpen({
      firstTime: true,
    });
  }

  function handleReset() {
    toggleDrawer();
    setModalOpen({ data: null, loading: false });
    handleCancel();
  }

  React.useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  }, [timer]);

  return (
    <>
      <Container>
        <Box>
          <h6
            style={{
              color: "#2D3748",
              fontSize: 18,
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "28px",
              margin: 0,
            }}
          >
            Add New Scheme
          </h6>
          <p
            style={{
              color: "#2D3748",
              fontSize: 14,
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "20px",
              margin: 0,
            }}
          >
            Create a new scheme for your insurance
          </p>
        </Box>
        <CloseIcon
          onClick={closeModal}
          sx={{
            alignSelf: "start",
          }}
        />
      </Container>
      <StyledInput
        setError={handleErrored}
        type="input"
        placeholder="Type in name of partner or insurance"
        setValue={handleValueChange}
        value={value["schemeName"]}
        name="schemeName"
        label="Scheme name"
        id="scheme-name"
        errorText="Scheme name is required"
      />
      <StyledInput
        setError={handleErrored}
        type="input"
        placeholder="Enter a scheme code"
        setValue={handleValueChange}
        value={value["schemeCode"]}
        name="schemeCode"
        label="Scheme Code"
        id="scheme-code"
        errorText="Scheme code is required"
      />
      <StyledInput
        setError={handleErrored}
        type="input"
        placeholder="Enter a tag"
        setValue={handleValueChange}
        value={value["schemeTag"]}
        name="schemeTag"
        label="Scheme tag"
        id="scheme-tag"
        errorText="Scheme tag is required"
      />

      <DialogActions sx={{ alignSelf: "end" }}>
        <StyledButton
          color="#1A202C"
          bgColor="#EDF2F7"
          text="cancel"
          onClick={closeModal}
        />
        <StyledButton
          text={"Create Scheme"}
          bgColor="#4C9DF8"
          onClick={handleCreateScheme}
          disabled={!isNotDisabled}
        />
      </DialogActions>
    </>
  );
});

const Container = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
}));
