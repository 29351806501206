import { Grid } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../utils/hooks";
import CheckupsLogo from "../../assets/Logo2.png";

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    img: {
      width: "100%",
      height: "100%",
    },
  })
);
const Logo: React.FC = () => {
  const classes = useStyles();
  const history = useNavigate();
  const { isAuthenticated } = useAppSelector((state) => state.auth);
  const doNothing = () => {};

  return (
    <Grid
      className={classes.root}
      onClick={() => {
        isAuthenticated ? history("/") : doNothing();
      }}
      item
      style={{ cursor: isAuthenticated ? "pointer" : "default" }}
    >
      <img alt="proto os logo" className={classes.img} src={CheckupsLogo} />
    </Grid>
  );
};

export default Logo;
