/* eslint-disable @typescript-eslint/no-unused-vars */
import { createStyles, makeStyles } from "@mui/styles";
import LoadingSpinner from "../components/LoadingSpinner";
import PageContent from "../components/pageContent";
import Table from "../components/Table";
import React, { useEffect, useState } from "react";
import * as signalR from "@microsoft/signalr";
import {  useAppSelector } from "../../utils/hooks";
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from "react-query";
import {
  DataTableColumn,
  DataTableRow,
} from "../../utils/types";
import useFetch from "../../utils/useFetch";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import {  User } from "./tests-logic/testSlice";
import { Box, Button, Card, CircularProgress, Dialog, DialogTitle, Divider, Fade, Grid, IconButton, InputAdornment, Link, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Tab, Tabs, TextField, Theme } from "@mui/material";
import EmptyList from "../components/EmptyList";
import MaterialTable from "@material-table/core";
import { Icon, InlineIcon } from '@iconify/react';
import listOutline from '@iconify/icons-eva/list-outline';
import MoreVertIcon  from '@mui/icons-material/MoreVert';
import { ExportCsv, ExportPdf } from '@material-table/exporters';
import AssignmentModal from "./AssignmentModal";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Iconify from "../Ticketing/Inconify";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InvoicePayments from "./InvoicePayments";
import TestsItems from "./TestItems";
import AssignDispatch from "./AssignDispatch";
import SendLabResults from "./SendLabResults";
import CompleteOrder from "./CompleteOrder";
import DoctorReviewModal from "./DoctorReviewModal";

const useStyles = makeStyles((theme: Theme) =>
createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      marginBottom:"20px",
    },
    //root:{},
    name: {
      textTransform: "capitalize",
    },
    formField: {
      marginLeft: '4px',
      marginTop: '30px',
      [theme.breakpoints.down('md')]: {
        width: '20%',
        alignContent: 'center ',
      },
  },
  searchBox: {
    padding: theme.spacing(2, 0),
    width: "99%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  colHeader: {
    "&:hover": {
      color: "red"
    }
  },


  menuItem: {
    minWidth: 200,
    margin: theme.spacing(1, 2),
    backgroundColor: theme.palette.grey[100],
    borderRadius: '3px',
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.grey[700],
    },
  },
  menuItemNoIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuItemWithIcon: {
    display: 'grid',
    gridTemplateColumns: '50px 1fr',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    fontWeight: 800,
    fontSize: '0.95rem',
  },
  unstyledButton: {
    textTransform: 'none',
    background: 'none',
    '&:hover': {
      background: 'none',
    },
  },


}));

interface Invoice {
  status: string;
}

interface Tabz {
  value: string;
  label: string;
  color: string;
  count: number | undefined;
}

const links = [
  {
    pathname: "",
    label: "",
    permission: "",
  },
];

type Props = {
  roleName?: string;
  roleId?: string;
  userTypeCode?: string;
};
const OrderList: React.FC<Props> = ({
  roleName,
  roleId,
  userTypeCode,
}: Props) => {
  const classes = useStyles();
  const INPUT_WIDTH = 360;
  const history = useNavigate();
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [query, setQuery] = useState("");
  const [user, setUser] = useState<User>();
 const Token =sessionStorage.getItem('auth');
  const { selectedUser } = useAppSelector((state) => state.login);
 //const [data, SetnewData]=useState<string[]>([]);
  const [value, setValue] = React.useState<string>('00000');
  const [phone, setPhone] = React.useState<string>('');
  const [selectedRow, setSelectedRow] = useState(null);
  const [ticketNo, setTicketNo] = React.useState<string>('');
  const [orderNo, setOrderNo] = React.useState<string>('');
  const[latitude,setLatitude]=React.useState<number>(0);
  const[longitude,setLongitude]=React.useState<number>(0);
  const[departmentID,setDepartmentID]=React.useState<number>(0);
  const[locationName,setLocationName]=useState<string>('');
  const[clientName,setClientName]=useState<string>('');
  const[phoneNumber,setClientPhoneNumber]=useState<string>('');
  const[cycleID,setCycleID]=useState<string>('');
  const[email,setEmail]=useState<string>('');
  const[nationalID,setNationalID]=useState<string>('');
  const[ccemail,setCCEmail]=useState<string>('');
  const [editing, setEditing] = useState(false);
  const [opePay, setOpenPay]=useState(false);
  const [openSendResultModal, setOpenSendResultModal]=useState(false);
  
  const[openDispatch,setOpenDispatch]=useState(false);
  const[openCompleteOrder,setOpenCompleteOrder]=useState(false);
  const[openAssignment,setOpenAssignmentModal]=useState(false);
  const[openComplete,setOpenCompleteModal]=useState(false);
  
  const[openLabTech,setOpenLabTech]=useState(false);
  const[  isFilter,setIsFilter]=useState(false);
  
  const getDefaultStartDate = () => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 7);
    return currentDate;
  };
  const formatDate = (date: Date) => {
    return date.toISOString().split('T')[0];
  };

  const [openInvoiceModalForm,setInvoiceOpenModalForm]= useState(false);
  const [openItemsModalForm,setItemsOpenModalForm]= useState(false);
  const [filterStartDate, setFilterStartDate] = useState<string>(
    formatDate(getDefaultStartDate())
    );
  const [filterEndDate, setFilterEndDate] = useState<string>(
    formatDate(new Date())
    );
  const [filterName, setFilterName] = useState<string>('');
  const isFiltered = Boolean(filterStartDate || filterEndDate || filterName);
  const [comment, setcomment] = useState<string>("");

  var DepartmentID =sessionStorage.getItem('userDepartmentID');

  const [orderInfo, setOrderInfo] = useState({ orderId: "", message: "" });

  const onFilterStartDate = (date: any) => {
    setFilterStartDate(date);
    // Add logic to filter data based on the selected start date
  };
  const onFilterEndDate = (date: any) => {
    setFilterEndDate(date);
    // Add logic to filter data based on the selected end date
  };
  useEffect(() => {
    setFilterEndDate(
      formatDate(new Date())
      );
  }, [filterStartDate]);

  const onFilterName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterName(event.target.value);
    // Add logic to filter data based on the entered name
  };

      // useFetch(`/${subset}`, `fetchBreeds-${subset}`);
      const {
        data,
        isLoading,
        isError,
        error,
        isRefreshing,
        isSuccess,
        refetch
      } = useFetch(`api/v1/checkups/tickets/get-lab-test-patients?FromDate=${filterStartDate}&ToDate=${filterEndDate}&SearchBy=${filterName}`, ["GetPatientTests"],
    { isFilter });

  const onResetFilter = () => {
    setFilterStartDate(formatDate(getDefaultStartDate()));
    setFilterEndDate(formatDate(new Date()));
    setFilterName('');
    setIsFilter(false);
    refetch();
    // Add logic to reset filters and update your data accordingly
  };
  const onFilter = () => {
    setIsFilter(true);
    refetch();
  };
  const handleOpenInvoiceForm = (invoiceNumber:any) => {
    setInvoiceOpenModalForm(true);
    setCycleID(invoiceNumber);
  };

  const handleCloseInvoiceForm = () => {
    setInvoiceOpenModalForm(false);
  };
  const handleOpenItemsForm = (patNo:any) => {
    setItemsOpenModalForm(true);
    setCycleID(patNo);
  };

  const handleCloseItemsForm = () => {
    setItemsOpenModalForm(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value);
  };
  const handleSubmit = () => {
    setValue(phone);
  };
  const makeUrl = (defaultPath: string) =>
    query && !userTypeCode
      ? `/auth/system-user-fuzzy-search-user?searchterm=${query}`
      : defaultPath;


    // useFetch(`api/v1/checkups/patient/patient-medication-cycle?PageNumber=${page}&PageSize=${rowsPerPage}`, ["PatientVisit", `${page}`,`${rowsPerPage}`],
    // { enabled: !!value });
    const handleCompleteOrder = (event: React.MouseEvent<HTMLElement>,row:any) => {
      setTicketNo(row.ticketNumber);
      setOrderNo(row.orderID);
      setDepartmentID(row.departmentID);
      setOpenCompleteOrder(true);
    };
    const handleDispatchAssignment = (event: React.MouseEvent<HTMLElement>,row:any) => {
      setTicketNo(row.ticketNumber);
      setOrderNo(row.orderID);
      setDepartmentID(row.departmentID);
      setOpenDispatch(true);
    };
    const handleLabAssignment = (event: React.MouseEvent<HTMLElement>,row:any) => {
      setTicketNo(row.ticketNumber);
      setOrderNo(row.orderID);
      setDepartmentID(row.departmentID);
      setOpenLabTech(true);
    };
    const handleDoctorAssignment = (event: React.MouseEvent<HTMLElement>,row:any) => {
      setClientName(row.fullName);
      setCycleID(row.cycleID);
      setNationalID(row.idNumber);
      setClientPhoneNumber(row.phoneNumberUsed);
      setEmail(row.email);
      setCCEmail(row.ccEmailUsed);
      setOpenAssignmentModal(true);
    };
    const handleComplete = (event: React.MouseEvent<HTMLElement>,row:any) => {
      setCycleID(row.cycleID);
      setOpenCompleteModal(true);
      setClientName(row.fullName);
    };
    const handleSendResults = (event: React.MouseEvent<HTMLElement>,row:any) => {
      event.preventDefault();
      setClientName(row.fullName);
      setClientPhoneNumber(row.phoneNumber);
      setCycleID(row.cycleID);
      setEmail(row.email);
      setOpenSendResultModal(true);
    };

    const handlePayment = (event: React.MouseEvent<HTMLElement>,row:any) => {
      event.preventDefault();
      setTicketNo(row.ticketNumber);
      setOrderNo(row.orderID);
      setLatitude(row.latitude);
      setLongitude(row.longitude);
      setLocationName(row.locationName);
      setOpenPay(true);
    };
    const handleViewResult = (event: React.MouseEvent<HTMLElement>,row:any) => {
      console.log("row",row);
      event.preventDefault();
      const requestOptions = {
        method: 'GET',
        headers: { 'Accept': '*/*' },
      };
  
      fetch(`https://dashboardapi.checkupsmed.com:1006/api/FileUpload/test-result-file?CycleID=${row.cycleID}`, requestOptions)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.blob();
        })
        .then(blob => {
          const fileUrl = URL.createObjectURL(blob);
          window.open(fileUrl, '_blank');
        })
        .catch(error => console.error('Error:', error)); 
    };

    
    //const optionsData = data?.data || data || [] ;
    const [filterStatus, setFilterStatus] = useState<string>('all');

    const dataFiltered = applyFilter({
      inputData: isSuccess ? data : [],
      filterStatus,
      // filterStartDate,
      // filterEndDate,
    });
    const handleFilterStatus = (event: React.ChangeEvent<{}>, newValue: string) => {
      setFilterStatus(newValue);
    };
    
    const getLengthByStatus = (status: string): number => data && data.filter((item:any) => item.status === status).length;
  // color?: "inherit" | "action" | "disabled" | "primary" | "secondary" | "error" | "info" | "success" | "warning" | undefined;
    const TABS:Tabz[] = [
      { value: 'all', label: 'All', color: 'info', count: data?.length },
      { value: 'Pending', label: 'Pending Results', color: 'warning', count: getLengthByStatus('Pending') },
      { value: 'Lab', label: 'Lab Review', color: 'warning', count: getLengthByStatus('Lab') },
      { value: 'Doctor', label: 'Donctor Review', color: 'primary', count: getLengthByStatus('Doctor') },
      { value: 'Sent', label: 'Results Sent', color: 'success', count: getLengthByStatus('Sent') },
    ];
// all Pending Confirmed Assigned Enroute Arrived Delivered Returned
  const [message, setMessage]=useState<string>("");
  const [isSubmitting,setisSubmitting]=useState<boolean>(false);
  console.log(message);
  const handleClickNewBtn = () => {
    history('/new-ticket'); 
  };
  const handleClick=(patNo:any)=>{ 
    setisSubmitting(true)
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ patientNumber: patNo })
    };
    fetch('https://dashboardapi.checkupsmed.com:1006/api/v1/checkups/patient/patient-usage-report', requestOptions)
        .then(response => response.json())
        .then(data => setMessage(data));  
        setTimeout(() => setisSubmitting(false), 5000);    
  }

  const [columns, setColumns] = useState([
    {
      title:<div className={classes.colHeader}>Patient No.</div>,
      field: "patientNO",
      type: "string" as const,
     // minWidth: 30,
    },
    {
      title:<div className={classes.colHeader}>Client Name</div>,
      field: "fullName",
      type: "string" as const,
     // minWidth: 100,
    },
    {
      title:<div className={classes.colHeader}>Phone Number</div>,
      field: "phoneNumber",
      type: "string" as const,
     // minWidth: 100,
    },
    {
      title:<div className={classes.colHeader}>ID Number</div>,
      field: "idNumber",
      type: "string" as const,
     // minWidth: 100,
    },
    {
      title:<div className={classes.colHeader}>Assigned To</div>,
      field: "assignedTo",
      type: "string" as const,
      //minWidth: 150,
    },
    // {
    //   title:<div className={classes.colHeader}>Location</div>,
    //   field: "locationName",
    //   type: "string" as const,
    // },

    {
      title:<div className={classes.colHeader}>Visit Date</div>,
      field: "visitDate",
      type: "string" as const,
      //minWidth: 100,
    },
    {
      title:<div className={classes.colHeader}>Result Done</div>,
      field: "targetTime",
      type: "string" as const,
      //minWidth: 100,
    },
    {
      title:<div className={classes.colHeader}>Verified On</div>,
      field: "targetTime",
      type: "string" as const,
      //minWidth: 100,
    },
    {
      title:<div className={classes.colHeader}>Results</div>,
      field: "cycleID",
      type: "string" as const,
      render: (value: any, row: any) => (
          (DepartmentID==="3" ||  DepartmentID=="21") ?(
        <Button onClick={(e:any)=>handleOpenItemsForm(value.cycleID)} variant="outlined">
                <Icon icon={listOutline} />
         </Button>
         ): (  <Button disabled variant="outlined">
         <Icon icon={listOutline} />
       </Button>)
      )
    },
    {
      title:<div className={classes.colHeader}>Total Tests</div>,
      field: "totalTest",
      type: "string" as const,
      //minWidth: 50,
    },
    {
      title:<div className={classes.colHeader}>Results Keyed</div>,
      field: "totalResults",
      type: "string" as const,
      //minWidth: 50,
    },
    {
      title:<div className={classes.colHeader}>Status</div>,
      field: "status",
      type: "string" as const,
      //minWidth: 50,
    },
    {
      title:<div className={classes.colHeader}>Actions</div>,
      render: (rowData: any) => (
      <>
          {(rowData.status==="Sent" || rowData.status==="Doctor") &&(
      DepartmentID=="3"||  DepartmentID=="21"  ||  DepartmentID=="4"  ||  DepartmentID=="1")  &&(
              <Button
              variant="text"
              startIcon={<AccountCircleIcon fontSize="small" />}
              onClick={(event: any) => handleDoctorAssignment(event, rowData)}
            >
              Regenerate_Results
            </Button>
                )
               }
                {rowData.status==="Doctor" &&( DepartmentID=="21"  ||  DepartmentID=="4")  &&(
              <Button
              variant="text"
              startIcon={<AccountCircleIcon fontSize="small" />}
              onClick={(event: any) => handleComplete(event, rowData)}
            >
              Complete
            </Button>
      )}
       {rowData.status==="Lab" &&(
      DepartmentID=="3"||  DepartmentID=="21")  &&(
              <Button
              variant="text"
              startIcon={<AccountCircleIcon fontSize="small" />}
              onClick={(event: any) => handleSendResults(event, rowData)}
            >
              Send_Results
            </Button>
             )}
        {(rowData.status==="Sent" || rowData.status==="Lab" || rowData.status==="Doctor")  &&(
           DepartmentID=="3"||  DepartmentID=="21"  ||  DepartmentID==="4")  &&(
            <Button
              variant="text"
              startIcon={<AccountCircleIcon fontSize="small" />}
              onClick={(event: any) => handleViewResult(event, rowData)}
            >
              View_Results
            </Button>
           )}
             
        </>
        // <IconButton onClick={(event: any) => handlePayment(event, rowData)}>
        //   <MoreVertIcon />
        // </IconButton>
      ),
    },
    
  ]);

const rows: DataTableRow[] = isSuccess
? userTypeCode
  ?data && data
  : data && data
: [];



const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
const handleOpen = (event: React.MouseEvent<HTMLElement>,row:any) => {
  setAnchorEl(event.currentTarget);
  setUser(row);
  setTicketNo(row.ticketNumber);
};
const handleClose = () => {
  setAnchorEl(null);
};
const open = Boolean(anchorEl);
const [slcRow, setSlcRow] = React.useState(null);
function handleMenuClick(event:any) {
  setAnchorEl(event.currentTarget);
  //setSlcRow(row);
}

useEffect(() => {
  const connection = new signalR.HubConnectionBuilder()
    .withUrl("https://dashboardapi.checkupsmed.com:1006/orderHub")
    .configureLogging(signalR.LogLevel.Information)
    .build();

  connection.on("ReceiveOrderUpdate", (orderId, message) => {
 
    queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.includes("Orders"),
    });
    setOrderInfo({ orderId, message });
  });

  async function startConnection() {
    try {
      await connection.start();
      console.log("Connected to the hub.");
    } catch (error) {
      console.error(error);
    }
  }

  startConnection();

  // Cleanup when the component unmounts
  return () => {
    connection.stop()
      .then(() => {
        console.log("Connection stopped.");
      })
      .catch(error => {
        console.error(error);
      });
  };
}, []);

  return (
    <PageContent>
      <div className={classes.root}>

        {isLoading ? <LoadingSpinner /> : <></>}
        {/* {isFetching ? <RefreshingCard message="Refreshing Clients" /> : <></>} */}
        
      {isSuccess ? (
        <>
       {/* <TableHeader
            onClickAddNew={handleClickNewBtn}
            onSearch={setQuery}
            query={query}
            addNewLabel="NEW TICKET"
          /> */}
        <div style={{ display: 'flex' }}>

<h2> TEST RESULTS DASHBOARD</h2>

</div>    

{/* <Card> */}

<Box sx={{ bgcolor: 'background.default',mt:2,mb:2 }}>
<Stack
spacing={2}
alignItems="center"
direction={{
 xs: 'column',
 md: 'row',
}}
sx={{ px: 2.5, py: 1 }}
>
<LocalizationProvider dateAdapter={AdapterDayjs}>

 <DatePicker 
 label="Start date"
 value={filterStartDate}
 onChange={onFilterStartDate}
 renderInput={(params) => (
   <TextField
     {...params}
     fullWidth
     sx={{
       maxWidth: { md: INPUT_WIDTH },
     }}
     />
     )}
 />

</LocalizationProvider>

<LocalizationProvider dateAdapter={AdapterDayjs}>
<DatePicker
 label="End date"
 value={filterEndDate}
 onChange={onFilterEndDate}
 renderInput={(params) => (
   <TextField
     {...params}
     fullWidth
     sx={{
       maxWidth: { md: INPUT_WIDTH },
     }}
   />
 )}
/>
</LocalizationProvider>
<TextField
 fullWidth
 value={filterName}
 onChange={onFilterName}
 placeholder="Search client name..."
 InputProps={{
   startAdornment: (
     <InputAdornment position="start">
       <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
     </InputAdornment>
   ),
 }}
/>
{isFiltered && (
<Button
color="primary" // Change to a suitable color for your search button
variant="contained" // Use 'contained' for a filled button style
sx={{ flexShrink: 0 }}
onClick={onFilter} // Change the click handler to your filtering logic
startIcon={<Iconify icon="eva:search-fill" />} // Replace with your search icon
>
Filter
</Button>
)}
{isFiltered && (
 <Button
   color="error"
   variant="contained" // Use 'contained' for a filled button style
   sx={{ flexShrink: 0 }}
   onClick={onResetFilter}
   startIcon={<Iconify icon="eva:trash-2-outline" />}
 >
   Clear
 </Button>
)}
</Stack>

</Box>

<Box sx={{ bgcolor: 'background.default' }}>
<Tabs
  value={filterStatus}
  onChange={handleFilterStatus}
  sx={{
    px: 1,
    bgcolor: 'background.paper',
    width: '100%',
  }}
  variant="fullWidth"
>
  {TABS.map((tab) => (
    <Tab
      key={tab.value}
      value={tab.value}
      label={
        <div style={{textAlign: 'center' ,fontSize:'1rem',lineHeight:'2rem' }}>
          <span>{tab.label}</span>
          <br />
          <span style={{ fontSize: '1.2rem',fontWeight:'bold'}}>
            {tab.count !== undefined ? tab.count.toLocaleString() : '0'}
          </span>
          <br/>
          <span style={{color:'#03fc13'}}>
            <ArrowUpwardIcon/>
          </span>
        </div>
      }
      icon={
        <Box component="span"></Box>
      }
      sx={{
        borderRadius: '10px',
        border: tab.value === "all" ? `1px solid gray`
          : tab.value === "Delivered" ? `1px solid green`
          : tab.value === "Pending" ? `1px solid yellow`
          : tab.value === "Confirmed" ? `1px solid green`
          : tab.value === "Assigned" ? `1px solid blue`
          : tab.value === "Returned" ? `1px solid red`
          : tab.value === "Enroute" ? `1px solid orange`
          : '1px solid gray',
        marginLeft: 1,
        marginRight: 1,
        backgroundColor: 'transparent',
     
        color: 'black',
        padding: '1px 1px',

        '& .MuiTab-root': {
          borderRadius: '10px',
          backgroundColor: 'transparent',
          color: 'black',
          marginLeft: 1,
          marginRight: 1,
          '&.Mui-selected': {
            backgroundColor: 'transparent',
          },
        },
      }}
    />
  ))}
</Tabs>


 
</Box>

   <Divider />
   
     
          <MaterialTable
            title="Patients List"
            columns={columns}
            data={dataFiltered}
            actions={[
              // {
              //   icon: () => <MoreVertIcon  />,
              //   tooltip: 'Actions',
              //   isFreeAction: false,
              //   onClick: (handleOpen)
              // }
           ]}
      
           // onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
            options={  {
              columnsButton:true,
              exportMenu: [{
                label: 'Export PDF',
                exportFunc: (cols, datas) => ExportPdf(cols, datas, 'SubscriptionReport')
              }, {
                label: 'Export CSV',
                exportFunc: (cols, datas) => ExportCsv(cols, datas, 'SubscriptionReport')
              }],
              exportAllData: true,
              sorting: true,
              rowStyle: rowData => ({
                backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
              }),
              headerStyle: {
                backgroundColor: '#01579b',
                color: '#FFF',
              },
             }}

             />
       
        </>
    
      ) : (<EmptyList message="You may want to refine your filters" />)}
      </div>
      <Dialog fullWidth maxWidth="lg" open={openInvoiceModalForm} onClose={handleCloseInvoiceForm}>
        <DialogTitle>Invoice Payments</DialogTitle>
        <Grid container spacing={5}>
      <Grid item xs={12} md={12}>
      <InvoicePayments
          onCancel={handleCloseInvoiceForm}
          setOpenModalForm={setInvoiceOpenModalForm}
          InvoiceNumber={cycleID}
        />
      </Grid>
    </Grid>
    
      </Dialog>
      <Dialog fullWidth maxWidth="lg" open={openItemsModalForm} onClose={handleCloseItemsForm}>
        <DialogTitle>Test Details</DialogTitle>
        <Grid container spacing={5}>
      <Grid item xs={12} md={12}>
      <TestsItems
          onCancel={handleCloseItemsForm}
          setOpenModalForm={setItemsOpenModalForm}
          CycleID={cycleID}
        />
      </Grid>
    </Grid>
    
      </Dialog>
      <SendLabResults
        queryString="results"
        open={openSendResultModal}
        setOpen={setOpenSendResultModal}
        clientName={clientName}
        phoneNumber={phoneNumber}
        email={email}
        cycleID={cycleID}
      />
      <AssignmentModal
        queryString="clients"
        open={openAssignment}
        setOpen={setOpenAssignmentModal}
        cycleID={cycleID}
        clientName={clientName}
        phoneNUMBER={phoneNumber}
        email={email}
        ccemail={ccemail}
        IDNumber={nationalID}
      />
       <DoctorReviewModal
        queryString="clients"
        open={openComplete}
        setOpen={setOpenCompleteModal}
        cycleID={cycleID}
        clientName={clientName}
      />

        <AssignDispatch
        queryString="clients"
        open={openDispatch}
        setOpen={setOpenDispatch}
        ticketNo={ticketNo}
        orderID={orderNo}
        PDepartmentID={departmentID}
      />
        <CompleteOrder
        queryString="clients"
        open={openCompleteOrder}
        setOpen={setOpenCompleteOrder}
        ticketNo={ticketNo}
        orderID={orderNo}
        PDepartmentID={departmentID}
      />
      {/* <AssignTicket
        // queryString="clients"
        // open={opePay}
        // setOpen={setOpenPay}
        // ticketNo={ticketNo}
      /> */}
      {/* <Menu
         className={classes.root}
         id="fade-menu"
         anchorEl={anchorEl}
         transformOrigin={{
           vertical: 'top',
           horizontal: 'center',
         }}
         keepMounted
         open={open}
         onClose={handleClose}
         TransitionComponent={Fade}
       >
             {[
            <MenuItem onClick={handlePayment}
              classes={{ root: classes.menuItem }}
              className={
                // b.icon ? classes.menuItemWithIcon : 
                classes.menuItemNoIcon
              }
              key="Menu-Key"
            >
            <span className={classes.label}>Assign User</span>
            </MenuItem>
          ]}
      </Menu> */}
    </PageContent>
  );
};

OrderList.defaultProps = {
  roleName: undefined,
  roleId: undefined,
  userTypeCode: undefined,
};

export default OrderList;


const applyFilter = ({
  inputData,
  filterStatus,
}: {
  inputData: Invoice[];
  filterStatus: string;
}): Invoice[] => {
  if (filterStatus !== 'all') {
    inputData = inputData.filter((invoice) => invoice.status === filterStatus);
  }

  return inputData;
};