import { memo, useCallback, useContext, useEffect, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DropDownProps } from "./types";
import { Box } from "@mui/material";
import ActionCard from "./action-card";
import { ModalContext } from "../modal-context";

export default memo(function ActionButton({
  actions,
}: {
  actions: DropDownProps;
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { deleteWarn } = useContext(ModalContext);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  useEffect(() => {
    if (deleteWarn) {
      setAnchorEl(null);
    }
  }, [deleteWarn]);
  return (
    <Box
      onMouseLeave={handleClose}
      sx={{
        position: "relative",
        cursor: "pointer",
      }}
      role="button"
      onClick={(e) => {
        e.stopPropagation();
        handlePopoverOpen(e);
      }}
    >
      <ActionCard
        dropdownProps={actions}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClickOutside={handleClose}
      />
      <MoreVertIcon />
    </Box>
  );
},
areEqual);

function areEqual(
  prevProps: Readonly<React.PropsWithChildren<{ actions: DropDownProps }>>,
  nextProps: Readonly<React.PropsWithChildren<{ actions: DropDownProps }>>
) {
  return (
    JSON.stringify(prevProps?.actions) === JSON.stringify(nextProps?.actions)
  );
}
