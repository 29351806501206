import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import useFetch from '../../utils/useFetch';
import GenericDialog from '../components/GenericDialog';
import { Grid } from '@mui/material';

const useStyles = makeStyles({
  mapContainer: {
    width: '100%',
    height: '400px',
    marginTop: '5px',
    marginBottom: '5px',
  },
});

interface User {
  
  id: number;
  fullName: string;
  location: {
   lat: number;
   lng: number;
  }
}


interface MapComponentProps {
  users: User[];
  clientLocation: {
    lat: number;
    lng: number;
  };
  nearestUser: User | null;
}

function calculateDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
  const R = 6371; // Earth's radius in kilometers
  const dLat = ((lat2 - lat1) * Math.PI) / 180;
  const dLon = ((lon2 - lon1) * Math.PI) / 180;

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = R * c; // Distance in kilometers

  return distance;
}

function MapComponent({ users, clientLocation, nearestUser }: MapComponentProps) {
  const classes = useStyles();

  const center = clientLocation;

  return (
    <div className={classes.mapContainer}>
      <LoadScript googleMapsApiKey="AIzaSyCgKNwnDgSlZOBG31TNseJqfVFxK1x7tY8">
      <GoogleMap mapContainerStyle={{ width: '100%', height: '100%' }} center={center} zoom={12}>
          {users.map((user) => (
            <Marker
              key={user.id}
              position={user.location}
              options={{
                icon: {
                  url: user.id === nearestUser?.id ? 'http://maps.google.com/mapfiles/ms/icons/green-dot.png' : 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
                },
              }}
              label={{
                text: user.fullName,
                color: 'black',
              }}
            />
          ))}
          <Marker
            position={clientLocation}
            options={{
              icon: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png',
            }}
            label={{
              text: 'Client',
              color: 'black',
            }}
          />
        </GoogleMap>
      </LoadScript>
    </div>
  );
}

type MapProps = {
  setassignedToID: Function;
  clientlong: number;
  clientlat: number;
};
function App({setassignedToID, clientlat,clientlong}:MapProps) {
  const [nearestUser, setNearestUser] = useState<User | null>(null);
  const {
    data,
    isLoading,
    isError,
    error,
    isRefreshing,
    isSuccess
  } = useFetch('api/v1/checkups/account/users-locations', ["UsersLocation"]);
  const locations=data || [];
  const [newData, setData] = useState<User[]>([]);
  useEffect(() => {
    if (isSuccess) {
      const convertedData = locations?.map((user: User) => ({
        id: user.id,
        fullName: user.fullName,
        location: {
          lat: parseFloat(user.location?.lat.toString()),
          lng: parseFloat(user.location?.lng.toString()),
        },
      }));

      // Update the state with the converted data
      setData(convertedData);
    }
  }, [isSuccess, locations]);


 
 
const data2 = [
  {
    id: 17,
    fullName: "GARE VINCENT",
    location: {lat: -1.688983,
    lng: 36.028069
    }
  },
  {
    id: 1126,
    fullName: "Godie",
    location: {lat: -1.5254,
    lng: 36.497637}
  },
  {
    id: 1150,
    fullName: "MARY Christine",
    location: {lat: -1.634621,
    lng: 36.253966
    }
  },
  {
    id: 1155,
    fullName: "Owuor Gare",
    location: {lat: -1.301011,
    lng: 36.834058}
  },
  {
    id: 1164,
    fullName: "Neil Simiyu",
    location: {lat: -0.5496587,
    lng: 36.943709}
  },
  {
    id: 1170,
    fullName: "MAUREEN OMUNGA",
    location: {lat: -1.2848575,
    lng: 36.8144523}
  },
  {
    id: 1176,
    fullName: "SHARON LAWI",
    location: {lat: -1.218459,
    lng: 36.886906}
  },
  {
    id: 1179,
    fullName: "Joey string",
    location: {lat: -1.29,
    lng: 6.79}
  },
  {
    id: 18,
    fullName: "Jane K",
    location: {lat: -0.0606108,
    lng: 35.7768412
    }
  },
  {
    id: 19,
    fullName: "Omondi J",
    location: {lat: -0.0746108,
    lng: 34.6768412}
  },
  {
    id: 20,
    fullName: "MARY JANE",
    location: {lat: -0.0586108,
      lng: 34.7868412}
  },
  {
    id: 21,
    fullName: "Owuor Otieno",
    location: {lat: -0.0756108,
      lng: 34.7868412}
  },
  {
    id: 22,
    fullName: "Nick S",
    location: {lat: -0.0517391,
    lng: 34.787888}
  },
  {
    id: 23,
    fullName: "Nancy Ngetich",
    location: {lat: -1.7400731,
      lng: 36.766906}
  },
  {
    id: 24,
    fullName: "Kiplimo Kiprop",
    location: {lat: -1.8400731,
    lng: 36.866906}
  },
  {
    id: 25,
    fullName: "Ronoh Kipgetich",
    location: {lat: -1.8120731,
      lng: 36.874906}
  },
  {
    id: 26,
    fullName: "Kiplagat R",
    location: {lat: -1.8320731,
      lng: 36.866906}
  },
  {
    id: 27,
    fullName: "Chemutai C",
    location: {lat: -1.8020731,
      lng: 36.856906}
  },
  {
    id: 28,
    fullName: "Ole Kiprop",
    location: {lat: -1.8420731,
      lng: 36.886906}
  },
  {
    id: 29,
    fullName: "James Makoli",
    location: {lat: -1.5379375,
    lng: 37.2374375}
  },
  {
    id: 30,
    fullName: "Tabitha W",
    location: {lat: -1.5176837,
    lng: 37.2634146}
  },
  {
    id: 31,
    fullName: "JACOB OMOL",
    location: {lat: -1.797963,
    lng: 37.6230199}
  },
  {
    id: 32,
    fullName: "Virginia",
    location: {lat: -1.757963,
    lng: 37.6030199}
  },
  {
    id: 33,
    fullName: "Rose",
    location: {lat: -1.777963,
    lng: 37.0130199}
  },
  {
    id: 34,
    fullName: "Mueni",
    location: {lat: -1.787963,
    lng: 37.2430199}
  },
  {
    id: 35,
    fullName: "Brian",
    location: {lat: -1.2144,
    lng: 36.5676007}
  },
  {
    id: 36,
    fullName: "MARY KARIUKI",
    location: {lat: -1.234621,
    lng: 36.823966
    }
  },
  {
    id: 37,
    fullName: "Leonard",
    location: {lat: -1.0393611,
    lng: 37.072255}
  },
  {
    id: 38,
    fullName: "Evan Odh",
    location: {lat: -1.0387569,
    lng: 37.1833753}
  },
  {
    id: 39,
    fullName: "Milly Caro",
    location: {lat: -1.223186,
    lng: 36.711897}
  },
  {
    id: 40,
    fullName: "Yvonne Mary",
    location: {lat: -0.4227629,
    lng: 36.953777}
  },
  {
    id: 41,
    fullName: "Michel O",
    location: {lat: -0.723685799,
    lng: 37.1606968}
  },
  {
    id: 42,
    fullName: "Marry",
    location: {lat: -0.6590564,
    lng: 37.3827234
    }
  },
  {
    id: 43,
    fullName: "Peter Mulasa",
    location: {lat: -1.2254,
    lng: 36.897637}
  },
  {
    id: 44,
    fullName: "MARY Jane",
    location: {lat: -1.322344,
    lng: 36.9406718
    }
  },
  {
    id: 45,
    fullName: "Gare G",
    location: {lat: -1.3938636,
    lng: 36.7442377}
  },
  {
    id: 46,
    fullName: "Greg K",
    location: {lat: -1.2558961,
    lng: 36.986028}
  },
  {
    id: 47,
    fullName: "Samson Om",
    location: {lat: -1.274357,
    lng: 36.9057288}
  },
  {
    id: 48,
    fullName: "Solomon",
    location: {lat: -1.2773942,
    lng: 36.9157976}
  },
  {
    id: 49,
    fullName: "Clare Sereti",
    location: {lat: -1.2744208,
    lng: 36.7027622}
  }

]
const [users, setUsers] = useState<User[]>([]);
useEffect(() => {
  setUsers(locations);
}, [locations]);
// const users: User[] = data;
// const users: User[] = newData.length ? newData : data;
// Add more user data here

  const clientLocation = { lat: clientlat, lng: clientlong };

useEffect(() => {
  let nearest: User | null = null;
  let nearestDistance: number = Infinity;

  users.forEach((user: User) => {
    const distance = calculateDistance(
      clientLocation.lat,
      clientLocation.lng,
      user.location.lat,
      user.location.lng
    );

    if (distance < nearestDistance) {
      nearest = user;
      nearestDistance = distance;
    }
  });

  if (nearest !== nearestUser) {
    setNearestUser(nearest);
   // setassignedToID(nearestUser?.id || 0);
  }
}, [clientLocation.lat, clientLocation.lng, users,nearestUser]);

useEffect(()=>{
  setassignedToID(nearestUser?.id || 0);
},[nearestUser])
  return (
  //   <GenericDialog
  //   title='Ticket Assignment'
  //   showDialog={open}
  //   onCloseDialog={() => {
  //     setOpen(false);
  //   }}
  //   actions={[]}
  //   width="sm"
  //   height={400}
  //   isLoading={false}
  //   verticalAlign="flex-start"
  // >
     <Grid>
      <Typography variant="h4" gutterBottom>
       Nearest User: {nearestUser?.fullName}
      </Typography>
      <MapComponent users={users} clientLocation={clientLocation} nearestUser={nearestUser} />
      </Grid>
  // </GenericDialog>
  );
}

export default App;
