import { createStyles, makeStyles } from "@mui/styles";
import {
  Theme,
  Grid,
  Button,
  Typography,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import LabelTextField from "../components/LabelTextField";
import LoadingSpinner from "../components/LoadingSpinner";
import ProgressTracker, { EnrollmentData } from "../components/ProgressTracker";
import Swal from "sweetalert2";
import Toast, { showToast } from "../components/Toast";
import BackButton from "../components/BackButton";
import ActivityHistory from "../components/ActivityHistory";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
  })
);

interface Data {
  ticketNo: string;
  refillDate: string;
  location: string;
}

const EnrollDetails = () => {
  const { id } = useParams();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [memberId, setMemberId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [townAddress, setTownAddress] = useState("");
  const [dateOfVisit, setDateOfVisit] = useState("");
  const [lastVisitLocation, setLastVisitLocation] = useState("");
  const [typeOfVisit, setTypeOfVisit] = useState("");
  const [insurance, setInsurance] = useState("");
  const [insuranceScheme, setInsuranceScheme] = useState("");
  const [enrollmentDate, setEnrollmentDate] = useState("");
  const [enrollmentTime, setEnrollmentTime] = useState("");
  const [diagnosis, setDiagnosis] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState<any>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState<Data[]>([]);
  const [insuranceEnrollmentDataPoint, setInsuranceEnrollmentDataPoint] =
    useState<EnrollmentData | undefined>(undefined);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    handleSubmit();
    setOpen(false);
  };

  // Format dates
  const formatDate = (dateStr: string) =>
    new Date(dateStr).toLocaleDateString();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_API_URL3}api/InsuranceEnrollment/enrollment-by-enrollmentId?enrollmentId=${id}`,
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          }
        );
        if (!response.ok) throw new Error("Network response was not ok");
        const result = await response.json();
        const data = result[0];

        setData(result[0]);
        // Set state based on response data
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setMemberId(data.memberID);
        setPhoneNumber(data.phone);
        setEmailAddress(data.email);
        setTownAddress(data.town);
        setDateOfVisit(formatDate(data.lastVisitDate));
        setLastVisitLocation(data.lastVisitLocation);
        setTypeOfVisit(data.visitType);
        setInsuranceScheme(data.scheme);
        setInsurance(data.insurance);
        setEnrollmentDate(formatDate(data.enrollmentDate));
        setEnrollmentTime(data.enrollmentTime);
        setDiagnosis(
          data.enrollmentDiagnosisEntities
            .map((d: { diagnosisName: any }) => d.diagnosisName)
            .join(", ")
        );
        setInsuranceEnrollmentDataPoint(data.insuranceEnrollmentDataPoint);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Clear rows to prevent old data from showing
        setRows([]);

        const response = await fetch(
          `${
            process.env.REACT_APP_BASE_API_URL3
          }api/InsuranceEnrollment/medication-refill-byphone?pageNumber=${
            page + 1
          }&pageSize=${rowsPerPage}&phoneNumber=${phoneNumber}`,
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          }
        );

        if (!response.ok) throw new Error("Network response was not ok");

        const result = await response.json();

        setRows(result.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [page, phoneNumber, rowsPerPage]);

  //submit booking refill
  const handleSubmit = async () => {
    const requestBody = {
      recipientPhoneNumber: phoneNumber,
    };

    try {
      Swal.fire({
        text: "Booking refill...",
        didOpen: () => {
          Swal.showLoading();
        },
        allowOutsideClick: false,
      });
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL3}api/InsuranceEnrollment/send-medicationrefill`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      await response.json();
      showToast("success", "Refill booked successfully!");
      Swal.close();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const classes = useStyles();
  if (!data) {
    return <LoadingSpinner />;
  }
  const { insuranceEnrollmentDetails = [] } = data;

  if (loading) return <LoadingSpinner />;

  return (
    <div className={classes.root}>
      <Toast />
      <div>
        <div>
          <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle sx={{ fontWeight: "bold", textAlign: "center" }}>
              Are you sure?
            </DialogTitle>
            <DialogContent sx={{ padding: "20px" }}>
              <Typography
                variant="body1"
                sx={{ marginBottom: "16px", textAlign: "center" }}
              >
                You won't be able to revert this!
              </Typography>
            </DialogContent>
            <DialogActions
              sx={{ justifyContent: "center", paddingBottom: "20px" }}
            >
              <Button
                onClick={handleClose}
                color="error"
                variant="outlined"
                sx={{ marginRight: "8px", padding: "8px 16px" }}
              >
                No, cancel!
              </Button>
              <Button
                onClick={handleConfirm}
                variant="contained"
                sx={{ backgroundColor: "#00a6fb" }}
              >
                Yes, book refill!
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <Box sx={{ flexGrow: 1, mt: 4, pl: 2, pr: 2 }}>
          <Box sx={{ mt: 4, mb: 4 }}>
            <BackButton />
          </Box>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={6}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Patient Information
                </Typography>
                <Typography variant="body1">
                  VIew details of patients from your insurance partners
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box display="flex" justifyContent="flex-end" gap={2}>
                {" "}
                <Button
                  onClick={handleClickOpen}
                  variant="contained"
                  sx={{
                    backgroundColor: "#00a6fb",
                    color: "white",
                    "&:hover": { backgroundColor: "grey.800" },
                  }}
                >
                  Book Refill
                </Button>
                {/* <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#00a6fb",
                    color: "white",
                    "&:hover": { backgroundColor: "grey.800" },
                  }}
                >
                  Send message
                </Button> */}
              </Box>
            </Grid>
          </Grid>

          <Box
            sx={{
              mt: 6,
              padding: "20px",
            }}
          >
            <ProgressTracker enrollmentData={insuranceEnrollmentDataPoint} />
          </Box>

          <Box sx={{ width: "100%", mt: "40px", padding: "20px" }}>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                flexWrap: "wrap",
              }}
            >
              <Box
                sx={{
                  flex: 1.5,
                  minWidth: 0,
                }}
              >
                {/* patients personl data */}
                <Box>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    PERSONAL DATA
                  </Typography>
                  <Grid container spacing={3}>
                    {/* First Row */}
                    <Grid container item spacing={3} xs={12} sx={{ mt: 0 }}>
                      <Grid item xs={4}>
                        <LabelTextField label="First Name" value={firstName} />
                      </Grid>
                      <Grid item xs={4}>
                        <LabelTextField label="Last Name" value={lastName} />
                      </Grid>
                      <Grid item xs={4}>
                        <LabelTextField
                          label="Patient's Member ID"
                          value={memberId}
                        />
                      </Grid>
                    </Grid>

                    {/* Second Row */}
                    <Grid container item spacing={3} xs={12} sx={{ mt: 1 }}>
                      <Grid item xs={4}>
                        <LabelTextField
                          label="Phone Number"
                          value={phoneNumber}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <LabelTextField
                          label="Email Address"
                          value={emailAddress || "-"}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <LabelTextField
                          label="Town Address"
                          value={townAddress}
                        />
                      </Grid>
                    </Grid>

                    {/* Third Row */}
                    <Grid container item spacing={3} xs={12} sx={{ mt: 1 }}>
                      <Grid item xs={4}>
                        <LabelTextField
                          label="Date of Visit"
                          value={dateOfVisit}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <LabelTextField
                          label="Last Visit Location"
                          value={lastVisitLocation}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <LabelTextField
                          label="Type of visit"
                          value={typeOfVisit}
                        />
                      </Grid>
                    </Grid>

                    {/* Fourth row */}
                    <Grid container item spacing={2} xs={12} sx={{ mt: 0 }}>
                      <Grid item xs={3}>
                        <LabelTextField label="Insurance" value={insurance} />
                      </Grid>
                      <Grid item xs={3}>
                        <LabelTextField
                          label="Insurance Scheme"
                          value={insuranceScheme}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <LabelTextField
                          label="Enrollment Date"
                          value={enrollmentDate}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <LabelTextField
                          label="Enrollment Time"
                          value={enrollmentTime}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Box
                    sx={{
                      mt: 8,
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: "bold", mb: 2 }}
                    >
                      BILLING DATA
                    </Typography>

                    <Box
                      sx={{
                        mt: 2,
                      }}
                    >
                      <LabelTextField
                        label="Ailment/Diagnosis"
                        value={diagnosis}
                      />
                    </Box>

                    <Typography variant="body1" sx={{ mb: 2, mt: 6 }}>
                      Drugs/Billing
                    </Typography>
                    {/* Content for the second box */}
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow
                            sx={{
                              backgroundColor: "#f0f0f0",
                            }}
                          >
                            <TableCell>Item Type</TableCell>
                            <TableCell>Item</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Claim Amount</TableCell>
                            <TableCell>Checkups Price</TableCell>
                            {/* <TableCell>Discount</TableCell> */}
                            <TableCell>Savings</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {insuranceEnrollmentDetails.map(
                            (detail: any, index: any) => (
                              <TableRow key={index}>
                                <TableCell>
                                  {detail.itemType.toUpperCase()}
                                </TableCell>
                                <TableCell>{detail.itemDescription}</TableCell>
                                <TableCell>{detail.quantity}</TableCell>
                                <TableCell>
                                  {`KSh ${detail.claimAmount.toFixed(2)}`}
                                </TableCell>
                                <TableCell>{`KSh ${detail.checkupsPrice}`}</TableCell>
                                {/* <TableCell>{0}</TableCell> */}
                                <TableCell>{`KSh ${detail.savings}`}</TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
              </Box>

              {/* appointment histiory */}
              <Box
                sx={{
                  flex: 1,
                  borderLeft: "1px solid #ebebeb",
                  padding: "20px",
                }}
              >
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  ACTIVITY HISTORY
                </Typography>
                <ActivityHistory
                  enrollmentData={insuranceEnrollmentDataPoint}
                />
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              mt: 12,
            }}
          ></Box>
        </Box>
      </div>
    </div>
  );
};

export default EnrollDetails;
