import { createStyles, makeStyles } from "@mui/styles";
import React, {  useState } from "react";
import {Theme} from '@mui/material'
import { filterBySearch } from "../../utils/filters";
import { DataTableColumn } from "../../utils/types";
import { formatAsLocalDate } from "../../utils/formatDates";
import TableHeader from "../components/TableHeader";
import DataTable from "../components/Table";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
  }),
);

type PatientJourneyProps = {
  cycleID: "";
};

const PatientJourney = ({
  cycleID,
}: PatientJourneyProps) => {
  const classes = useStyles();
  const [query, setQuery] = useState('');
  const[data, setData] = useState([]);
  const url = "https://registrationapi.checkupsmed.com:8002/api/v1/checkups/register/patients-journey?cycleId=";
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
    body: JSON.stringify({
      cycleID
    }),
  };
  fetch(url, options)
    .then((response) => response.json())
    .then((data) => {
      setData(data);
    });
  const rows = filterBySearch(query, data);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const columns: DataTableColumn[] = [
    {
      id: 'stage',
      label: 'Stage',
      align: 'center',
      minWidth: 200,
    },
    {
        id: 'AssignedTo',
        label: 'Assigned To',
        minWidth: 150,
      },
      {
        id: 'DateAssigned',
        label: 'Date Assigned',
        minWidth: 150,
        format: (date) => formatAsLocalDate(date),
      },
    {
      id: 'DateClosed',
      label: 'Date Closed',
      minWidth: 50,
      format: (date) => formatAsLocalDate(date),
    },
    {
      id: 'DateClosed',
      label: 'Time Taken(Mins)',
      minWidth: 50,
      format: (date) => formatAsLocalDate(date),
    },
    {
      id: 'comment',
      label: 'Comment',
      minWidth: 150,
    },
  ];

  return (
    <div className={classes.root}>
      <div>
        <TableHeader onSearch={setQuery} query={query} />
        <DataTable
          page={page}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setPage={setPage}
          rows={rows?.map((r, index) => ({ ...r, number: index + 1 }))}
          columns={columns}
        />
      </div>
    </div>
  );
};

export default PatientJourney;
