import * as React from "react";
import Card from "@mui/material/Card";
import { styled } from "@mui/material/styles";

import SlideInDialog from "./slideindrawer";
// import useMediaQuery from "@mui/material/useMediaQuery";

// import { SelectOption } from "./types";

import { ModalContext } from "../modal-context";
import LoadingModal from "./submitting-modal";
import {} from "../../../utils/useInsuranceQuery";

import { InsuranceContext } from "../scheme-context";

import CreateSchemeModal from "./create-scheme-modal";
import EditSchemeModal from "./edit-modal";

type DialogButtonProps = {
  onClick: () => void;
};

type GlobalDrawerProps = {
  confirm?: boolean; // if you want to use the confirm modal
  handleClose?: () => void; // a function that can be hooked to the close modal
  handleConfirm?: () => void; // a function that can be hooked to the close modal
  confirmText?: string; // text to show the user in the confirm modal
  confirmButton?: string | React.ComponentType<DialogButtonProps>; // can be a reactNode that uses the isLoading state
};

type ConfirmProp = { firstTime: boolean } | boolean;

export default React.memo(function ModalCard({
  confirm,
  confirmText,
  handleClose,
  handleConfirm,
}: GlobalDrawerProps) {
  const containerRef = React.useRef<HTMLDivElement>(null);
  // context
  const { toggleDrawer } = React.useContext(InsuranceContext);
  const { setModalOpen, action } = React.useContext(ModalContext);

  const [confirmModalOpen, setConfrimModalOpen] = React.useState<ConfirmProp>({
    firstTime: true,
  });

  let timer: undefined | number;

  function closeModal() {
    // check if it's the first time use of the confirmModalOpen state where confirm is true
    if (confirm && typeof confirmModalOpen === "object") {
      setConfrimModalOpen(true);
      return;
    }
    // check if confirm modal was opened, where confirm is true
    if (confirm && confirmModalOpen) {
      if (handleConfirm && typeof handleConfirm === "function") {
        handleConfirm();
      }
      // if handleClose
      if (handleClose && typeof handleClose === "function") {
        handleClose();
      }
      setConfrimModalOpen(false);
      if (timer) clearTimeout(timer);
      // close global model and reset confirmModalOpen to an object
      timer = setTimeout(handleReset, 500) as unknown as number;
      return;
    }
    if (handleClose && typeof handleClose === "function") {
      handleClose();
    }
    toggleDrawer();
    setModalOpen({ data: null, loading: false });
  }

  function handleCancel() {
    setConfrimModalOpen({
      firstTime: true,
    });
  }

  function handleReset() {
    toggleDrawer();
    setModalOpen({ data: null, loading: false });
    handleCancel();
  }

  React.useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  }, [timer]);

  return (
    <StyledCard ref={containerRef}>
      {action === "create" ? <CreateSchemeModal /> : <EditSchemeModal />}
      <SlideInDialog
        open={typeof confirmModalOpen === "object" ? false : confirmModalOpen}
        handleCancel={handleCancel}
        handleClose={closeModal}
        DialogButton={confirmText ? confirmText : "Close"}
      />
      <LoadingModal />
    </StyledCard>
  );
});

const StyledCard = styled(Card)(() => ({
  padding: "16px 24px",
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: "8px",
  background: "#f9fafb",
  alignItems: "flex-start",
  position: "relative",
}));
