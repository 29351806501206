import {  containerClasses, FormControl, FormControlLabel, FormLabel, Grid, Paper, Radio, RadioGroup, Snackbar, TextField, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Alert } from "@mui/lab";
import GenericDialog from "../components/GenericDialog";
import LoadingSpinner from "../components/LoadingSpinner";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";

import { ErrorType } from "../../utils/types";
import usePostRequest from "../../utils/usePostRequest";
import { useParams } from "react-router-dom";
//import { createUserSuccess } from "./agent-accounts-logic/profileSlice";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
    },

    inputStyle: {
      width: 400,
      marginBottom: theme.spacing(2),
    },
    autoComplete: {
      marginBottom: theme.spacing(1),
    },
    main: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
      zIndex: theme.zIndex.appBar - 1,
      outline: "none",
    },
    grid: {
      display: "grid",
      gridTemplateColumns: "1fr",
    },
    paper: {
      padding: theme.spacing(2),
      // textAlign: "center",
      //color: theme.palette.text.secondary,
      width: 'auto',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      color: 'black !important',
      fontFamily: "ChronicaPro,sans-serif",
      fontSize:"16px",
      fontWeight: 400,
    },
    formCustomTitle: {
      fontWeight:700,
      color: "#449afe",
      fontSize:"1.3rem",
      fontFamily: "ChronicaPro,sans-serif",
      width: 400,
    },
    CustomTitle: {
      fontWeight:700,
      color: "#022c5b",
      fontSize:"1.0rem! important",
      fontFamily: "ChronicaPro,sans-serif",
      width: 400,
    },
    inputLabel: {
      "&.focused": {
        color: "#449afe",
      },
      color: "#449afe",
    },
    errorText: {
      color: 'red',
      fontSize: 'small',
    },
  })
);



type CreateCollectionDetailsProps = {
  queryString: string;
  open: boolean;
  // eslint-disable-next-line no-unused-vars
  setOpen: (value: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  setEditing?: (value: boolean) => void;
  editing?: boolean;
  setEditLoading?: boolean;
  setEditError?: boolean;
};

function CreateCollectionDetails({
  queryString,
  open,
  setOpen,
  editing,
  setEditing,
  setEditLoading,
  setEditError,
}: CreateCollectionDetailsProps) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { id }: any = useParams();
  const [openSnack, setOpenSnack] = useState(true);

  const { selectedUser } = useAppSelector((state) => state.profile);

  const [sampleCollectionDate, setCollectionDate] = useState<String>("")
  const [sampleLabelling, setSampleLabelling] = useState<String>("")
  const [staffInvolved, setStaffInvolved] = useState<String>("")
  const [sampleTransport, setSampleTransport] = useState<String>("")
  const [sampleAnalysisDate, setAnalysisDate] = useState<String>("")
  const [methodologyUsed, setMethodologyUsed] = useState<String>("")
  const [sampleHandling, setSampleHandling] = useState<String>("")
  const [machinesUsed, setMachineUsed] = useState<String>("")
  const [assuranceCheckDoneBy, setAssurance] = useState<String>("")
  const [assuranceCheckDoneOn, setAssuranceDate] = useState<String>("")
  const [resultsTimeline, setTimeline] = useState<String>("")


  const [TransactionIDErr, setTransactionIDErr] = useState<false | ErrorType>(false);
  const { handleSubmit, reset } = useForm();
  const [postError, setPostError] = useState<false | ErrorType>(false);

  const onSuccess = ({ data }: any) => {
    queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.includes("screensummary"),
    });
    //dispatch(createUserSuccess(data));
    setOpen(false);
    //setEditing(false);
    queryClient.invalidateQueries(`${queryString}`);
  };
  const onFailure = (err: ErrorType) => {
    setPostError(err);
  };
  const handleAnalysisDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAnalysisDate(event.target.value);
  };
  const handleAssurance = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAssurance(event.target.value);
  };
  const handleAssuranceDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAssuranceDate(event.target.value);
  };
  const handleCollectionDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCollectionDate(event.target.value);
  };
  const handleMachinesUsed = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMachineUsed(event.target.value);
  };
  const handleMethodologyUsed = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMethodologyUsed(event.target.value);
  };
  const handleResultsTimeline = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTimeline(event.target.value);
  };
  const handleSampleLabelling = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSampleLabelling(event.target.value);
  };
  const handleStaffInvolved = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStaffInvolved(event.target.value);
  };
  const handleSampleHandling = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSampleHandling(event.target.value);
  };
  const handleSampleTransport = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSampleTransport(event.target.value);
  };
  var userId =sessionStorage.getItem('UserID');
  const mutation = usePostRequest(
    "api/Reports/sampleCollectionReporting",
    {
      nrC_Report_Id:id,
      sampleCollectionDate,
      createdBy:userId,
      sampleAnalysisDate,
      sampleLabelling,
      staffInvolved,
      sampleTransport,
      methodologyUsed,
      sampleHandling,
      machinesUsed,
      assuranceCheckDoneBy,
      assuranceCheckDoneOn,
      resultsTimeline
    },
    onSuccess,
    onFailure
  );
  const onSubmit = () => {
    mutation.reset();
    mutation.mutate();
  };


  const handleClose = () => {
    mutation.reset();
    reset();
  };

  const actions = [
    {
      label: 'UPDATE NOW',
      primary: true,
      onClick: handleSubmit(onSubmit),
    },
  ];

  return (
    <div>
      {mutation.isSuccess && (
        <Snackbar
          open={openSnack}
          onClose={() => setOpenSnack(false)}
          autoHideDuration={6000}
        >
          <Alert onClose={handleClose} severity="success">
            {editing
              ? "Details Successfully"
              : "Details Successfully"}
          </Alert>
        </Snackbar>
      )}
      {editing && setEditError && (
        <Snackbar open={openSnack} onClose={() => setOpenSnack(false)}>
          <Alert onClose={handleClose} severity="error">
            'Something wrong happened, could not edit user'
          </Alert>
        </Snackbar>
      )}

      <GenericDialog
        title=''
        showDialog={open}
        onCloseDialog={() => {
          setOpen(false);
        }}
        actions={actions}
        isLoading={mutation.isLoading}
        width="sm"
        height={400}
        verticalAlign="flex-start"
      >
       
          {mutation.isLoading || setEditLoading ? <LoadingSpinner /> : <></>}
          <div className={classes.grid}>
            {postError ? (
              <Alert severity="error" className="error-container">
                {postError?.message}
              </Alert>
            ) : (
              <></>
            )}
            
            <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
            <Grid container spacing={3}>       
            
            <Grid item xs={12} sm={12}>
            <TextField
              size="small"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              name="sampleCollectionDate"
              label="Sample Collection Date"
              color="secondary"
              variant="outlined"
              fullWidth
              defaultValue={sampleCollectionDate}
              onChange={handleCollectionDate}
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              size="small"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              name="sampleLabelling"
              label="Sample Labelling"
              color="secondary"
              variant="outlined"
              fullWidth
              defaultValue={sampleLabelling}
              onChange={handleSampleLabelling}
              autoComplete="off"
            />
          </Grid>
         
          <Grid item xs={12} sm={12}>
            <TextField
              size="small"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              name="staffInvolved"
              label="Staff Involved"
              color="secondary"
              variant="outlined"
              fullWidth
              defaultValue={staffInvolved}
              onChange={handleStaffInvolved}
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              size="small"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              name="sampleTransport"
              label="Sample Transport"
              color="secondary"
              variant="outlined"
              fullWidth
              defaultValue={sampleTransport}
              onChange={handleSampleTransport}
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              size="small"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              name="sampleAnalysisDate"
              label="Sample Analysis Date"
              color="secondary"
              variant="outlined"
              fullWidth
              defaultValue={sampleAnalysisDate}
              onChange={handleAnalysisDate}
              autoComplete="off"
            />
          </Grid>
         
          <Grid item xs={12} sm={12}>
            <TextField
              size="small"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              name="methodologyUsed"
              label="Methodology Used"
              color="secondary"
              variant="outlined"
              fullWidth
              defaultValue={methodologyUsed}
              onChange={handleMethodologyUsed}
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              size="small"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              name="sampleHandling"
              label="Sample Handling"
              color="secondary"
              variant="outlined"
              fullWidth
              defaultValue={sampleHandling}
              onChange={handleSampleHandling}
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              size="small"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              name="machinesUsed"
              label="Machines Used"
              color="secondary"
              variant="outlined"
              fullWidth
              defaultValue={machinesUsed}
              onChange={handleMachinesUsed}
              autoComplete="off"
            />
          </Grid>
         
          <Grid item xs={12} sm={12}>
            <TextField
              size="small"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              name="assuranceCheckDoneBy"
              label="Assurance Check Done By"
              color="secondary"
              variant="outlined"
              fullWidth
              defaultValue={assuranceCheckDoneBy}
              onChange={handleAssurance}
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              size="small"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              name="assuranceCheckDoneOn"
              label="Assurance Check Done On"
              color="secondary"
              variant="outlined"
              fullWidth
              defaultValue={assuranceCheckDoneOn}
              onChange={handleAssuranceDate}
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              size="small"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              name="resultsTimeline"
              label="Results Timeline Date"
              color="secondary"
              variant="outlined"
              fullWidth
              defaultValue={resultsTimeline}
              onChange={handleResultsTimeline}
              autoComplete="off"
            />
          </Grid>
          </Grid>
          </Paper>
          </Grid>

          </div>
  
      </GenericDialog>
    </div>
  );
}

CreateCollectionDetails.defaultProps = {
  setEditError: false,
  setEditLoading: false,
};
export default CreateCollectionDetails;
